import React, { useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';

import { CheckIcon, ChevronDown, ChevronRight } from 'assets/icons';
import ModalContainer from 'components/ModalContainer';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import Table from 'react-bootstrap/Table';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { getBalances } from 'redux/actions/BudgetsAction';
import { getAvailableBalance, getCurrency, groupSourceOptions } from 'utils/helper';
import { numFormatter } from 'utils/utility';
import './styles.scss';
import { toastError } from 'components/UI/toast';

const BulkApprovalModal = ({
  openModal,
  rows = [],
  tab,
  columns,
  onCancel,
  onConfirm,
  handleUpdate,
  isSuccess,
  isLoading = false,
  title,
  amount,
  fee,
  total,
}) => {
  const menuPortalTarget = document.body;
  const dispatch = useDispatch();

  const {
    getBalances: { data: balances, loading: loadingBalances, success: successBalance },
  } = useSelector(({ budgets }) => budgets);

  const [hasMore, setHasMore] = useState(false);
  const [loadPage, setLoadPage] = useState(0);
  const [balanceList, setBalanceList] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [tableRows, setTableRows] = useState(rows);

  useEffect(() => {
    dispatch(getBalances());
  }, []);

  useEffect(() => {
    const transformRows = rows?.map((opt) => ({
      approvalCode: opt?.approvalCode,
      code: opt?.code,
      user: opt?.user
        ? {
            firstName: `${opt?.user?.firstName}`,
            lastName: `${opt?.user?.lastName}`,
          }
        : null,
      amount: opt?.amount,
      vendor: opt?.vendor ? { name: opt?.vendor?.name } : null,
      type: opt?.type,
      source: opt?.source?.code
        ? balanceList.find((item) => item?.value === opt?.source?.code)
        : null,
      currency: opt?.currency,
      category: opt?.category?.code
        ? {
            value: opt?.category?.code,
            label: opt?.category?.name,
          }
        : null,
      approvalType: opt?.approvalType,
    }));

    setTableRows(transformRows);
  }, [rows.length, balanceList.length]);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets);
      const available_balance = getAvailableBalance(balances?.balances);
      setBalanceList([...budget, ...available_balance]);
    }
  }, [balances?.budgets?.length, balances?.balances?.length]);

  async function loadAccountOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = balanceList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );
    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  const handleOnChange = (code, key, value) => {
    setTableRows((prevTableDataList) =>
      prevTableDataList.map((tableData) => {
        if (tableData.code === code) {
          return { ...tableData, [key]: value };
        }
        return tableData;
      }),
    );
  };

  const handleConfirm = () => {
    const hasSourceCode = tableRows.every((row) => row.source?.value);

    if (!hasSourceCode) {
      toastError('Please select a source for all items.');
      return;
    }
    return onConfirm();
  };

  return (
    <Modal show={openModal} centered>
      <ModalContainer
        xl
        loading={isLoading}
        onCancel={onCancel}
        actionBtnText={`Confirm ${title}`}
        cancelBtn="Cancel"
        onConfirm={handleConfirm}
        title={
          <div className="d-flex gap-3 align-items-center">
            <h5 className="mb-0">
              Please confirm {rows.length} {rows.length === 1 ? tab : `${tab}s`} {title}
            </h5>
            {isSuccess && (
              <p style={{ color: '#12B76A' }} className="pb-0">
                Selection saved{' '}
                <CheckIcon
                  className="align-text-top"
                  stroke="#12B76A"
                  width="13"
                  height="17"
                />
              </p>
            )}
          </div>
        }
      >
        <div className="pb-1 px-0">
          <div className="border approval-table-modal border-bottom-0 rounded-3">
            <Table
              className="members-table overflow-x-scroll mb-0 rounded-3"
              style={{ maxWidth: '800px' }}
              responsive
            >
              <thead>
                <tr>
                  {columns.map((option, indx) => {
                    return (
                      <React.Fragment key={indx}>
                        <th>{option}</th>
                      </React.Fragment>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {tableRows.map((tableRow) => {
                  return (
                    <tr key={tableRow.code}>
                      <td style={{ minWidth: '160px' }}>
                        <span className="text-sm">
                          {tableRow.user
                            ? `${tableRow.user.firstName} ${tableRow.user.lastName}`
                            : '-'}
                        </span>
                      </td>
                      <td style={{ minWidth: '100px' }}>
                        <CurrencyFormat
                          prefix={getCurrency(tableRow.currency)}
                          value={numFormatter(tableRow.amount / 100)}
                          displayType="text"
                          thousandSeparator={true}
                          isNumericString
                          className="text-sm"
                        />
                      </td>
                      <td style={{ minWidth: '160px' }}>
                        <span className="text-sm">
                          {tableRow.vendor &&
                          (tableRow.type === 'PAYMENT' || tab === 'reimbursement')
                            ? tableRow.vendor.name
                            : '-'}
                        </span>
                      </td>
                      <td style={{ minWidth: '100px' }}>
                        {tableRow?.type === 'PAYMENT' || tab === 'reimbursement' ? (
                          <CategoryDropdown
                            placeholder="Select a category"
                            onChange={(val) => {
                              handleOnChange(tableRow.code, 'category', val);
                              handleUpdate(
                                tableRow.code,
                                'category',
                                val.value,
                                tableRow?.approvalType,
                              );
                            }}
                            name="category"
                            defaultValue={tableRow.category}
                            value={tableRow.category}
                            customStyles={{
                              control: { width: 230 },
                            }}
                            menuPortalTarget={menuPortalTarget}
                          />
                        ) : (
                          '-'
                        )}
                      </td>
                      <td>
                        <CustomSelectRadio
                          placeholder="Select a source"
                          name="source"
                          onChange={(val) => {
                            handleOnChange(tableRow.code, 'source', val);
                            handleUpdate(
                              tableRow.code,
                              'source',
                              val.value,
                              tableRow?.approvalType,
                            );
                          }}
                          value={tableRow.source}
                          customStyles={{
                            control: { width: 230 },
                            container: { width: 230 },
                          }}
                          menuPortalTarget={menuPortalTarget}
                          isLoading={loadingBalances}
                          loadOptions={loadAccountOptions}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <div className="divider"></div>

          <div className="w-100 heading-confirm">
            <div
              onClick={() => setShowDetails(!showDetails)}
              className="d-flex justify-content-between text-sm"
            >
              <h5 className="text-sm">
                Total {showDetails ? <ChevronDown /> : <ChevronRight />}
              </h5>
              <h6 className="text-sm">{total}</h6>
            </div>
            {showDetails && (
              <>
                {fee && (
                  <div
                    style={{ color: '#7F7F7D' }}
                    className="d-flex justify-content-between"
                  >
                    <h5 className="text-sm">Transaction fees</h5>
                    <h6 className="text-sm">{fee}</h6>
                  </div>
                )}
                <div
                  style={{ color: '#7F7F7D' }}
                  className="d-flex justify-content-between"
                >
                  <h5 className="text-sm">Amount</h5>
                  <h6 className="text-sm">{amount}</h6>
                </div>
              </>
            )}
          </div>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default BulkApprovalModal;
