import { updateScheduledInvoice } from 'redux/actions/InvoiceAction';

export const FETCH_INVOICES_REQUEST = 'FETCH_INVOICES_REQUEST';
export const FETCH_INVOICES_ERROR = 'FETCH_INVOICES_ERROR';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';

export const FETCH_INVOICE_REQUEST = 'FETCH_INVOICE_REQUEST';
export const FETCH_INVOICE_ERROR = 'FETCH_INVOICE_ERROR';
export const FETCH_INVOICE_SUCCESS = 'FETCH_INVOICE_SUCCESS';

export const FETCH_INVOICE_TEMPLATES_REQUEST = 'FETCH_INVOICE_TEMPLATE_REQUEST';
export const FETCH_INVOICE_TEMPLATES_ERROR = 'FETCH_INVOICE_TEMPLATE_ERROR';
export const FETCH_INVOICE_TEMPLATES_SUCCESS = 'FETCH_INVOICE_TEMPLATE_SUCCESS';

export const CREATE_INVOICE_REQUEST = 'CREATE_INVOICE_REQUEST';
export const CREATE_INVOICE_ERROR = 'CREATE_INVOICE_ERROR';
export const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS';

export const FETCH_SCHEDULED_INVOICE_REQUEST = 'FETCH_SCHEDULED_INVOICE_REQUEST';
export const FETCH_SCHEDULED_INVOICE_ERROR = 'FETCH_SCHEDULED_INVOICE_ERROR';
export const FETCH_SCHEDULED_INVOICE_SUCCESS = 'FETCH_SCHEDULED_INVOICE_SUCCESS';

export const FETCH_SINGLE_SCHEDULED_INVOICE_REQUEST =
  'FETCH_SINGLE_SCHEDULED_INVOICE_REQUEST';
export const FETCH_SINGLE_SCHEDULED_INVOICE_ERROR =
  'FETCH_SINGLE_SCHEDULED_INVOICE_ERROR';
export const FETCH_SINGLE_SCHEDULED_INVOICE_SUCCESS =
  'FETCH_SINGLE_SCHEDULED_INVOICE_SUCCESS';

export const SEARCH_INVOICE_CUSTOMERS_REQUEST = 'SEARCH_INVOICE_CUSTOMERS_REQUEST';
export const SEARCH_INVOICE_CUSTOMERS_ERROR = 'SEARCH_INVOICE_CUSTOMERS_REQUEST';
export const SEARCH_INVOICE_CUSTOMERS_SUCCESS = 'SEARCH_INVOICE_CUSTOMERS_SUCCESS';

export const SHARE_INVOICE_REQUEST = 'SHARE_INVOICE_REQUEST';
export const SHARE_INVOICE_ERROR = 'SHARE_INVOICE_ERROR';
export const SHARE_INVOICE_SUCCESS = 'SHARE_INVOICE_SUCCESS';

export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_ERROR = 'CREATE_CUSTOMER_ERROR';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';

export const CREATE_BULK_CUSTOMER_REQUEST = 'CREATE_BULK_CUSTOMER_REQUEST';
export const CREATE_BULK_CUSTOMER_ERROR = 'CREATE_BULK_CUSTOMER_ERROR';
export const CREATE_BULK_CUSTOMER_SUCCESS = 'CREATE_BULK_CUSTOMER_SUCCESS';

export const FETCH_CUSTOMERS_REQUEST = 'FETCH_CUSTOMERS_REQUEST';
export const FETCH_CUSTOMERS_ERROR = 'FETCH_CUSTOMERS_ERROR';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';

export const FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMER_REQUEST';
export const FETCH_CUSTOMER_ERROR = 'FETCH_CUSTOMER_ERROR';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';

export const DELETE_INVOICE_REQUEST = 'DELETE_INVOICE_REQUEST';
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_ERROR = 'DELETE_INVOICE_ERROR';

export const DELETE_SCHEDULED_INVOICE_REQUEST = 'DELETE_SCHEDULED_INVOICE_REQUEST';
export const DELETE_SCHEDULED_INVOICE_SUCCESS = 'DELETE_SCHEDULED_INVOICE_SUCCESS';
export const DELETE_SCHEDULED_INVOICE_ERROR = 'DELETE_SCHEDULED_INVOICE_ERROR';

export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_ERROR = 'DELETE_CUSTOMER_ERROR';

export const MARK_AS_PAID_REQUEST = 'MARK_AS_PAID_REQUEST';
export const MARK_AS_PAID_SUCCESS = 'MARK_AS_PAID_SUCCESS';
export const MARK_AS_PAID_ERROR = 'MARK_AS_PAID_ERROR';

export const UPDATE_INVOICE_REQUEST = 'UPDATE_INVOICE_REQUEST';
export const UPDATE_INVOICE_ERROR = 'UPDATE_INVOICE_ERROR';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';

export const UPDATE_SCHEDULED_INVOICE_REQUEST = 'UPDATE_SCHEDULED_INVOICE_REQUEST';
export const UPDATE_SCHEDULED_INVOICE_ERROR = 'UPDATE_SCHEDULED_INVOICE_ERROR';
export const UPDATE_SCHEDULED_INVOICE_SUCCESS = 'UPDATE_SCHEDULED_INVOICE_SUCCESS';

export const SCAN_INVOICE_REQUEST = 'SCAN_INVOICE_REQUEST';
export const SCAN_INVOICE_ERROR = 'SCAN_INVOICE_ERROR';
export const SCAN_INVOICE_SUCCESS = 'SCAN_INVOICE_SUCCESS';

export const RESET_FLAGS_INVOICE = 'RESET_FLAGS_INVOICE';
export const RESET_BLOCK_INVOICE = 'RESET_BLOCK_INVOICE';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  fetchInvoices: { ...block },
  fetchInvoice: { ...block },
  createInvoice: { ...block },
  invoiceCustomers: { ...block },
  shareInvoice: { ...block },
  invoiceCustomer: { ...block },
  customers: { ...block },
  deleteCustomer: { ...block },
  customer: { ...block },
  deleteInvoice: { ...block },
  markInvoiceAsPaid: { ...block },
  updateInvoice: { ...block },
  updateScheduledInvoice: { ...block },
  scanFile: { ...block },
  fetchScheduledInvoice: { ...block },
  fetchSingleScheduledInvoice: { ...block },
  deleteScheduledInvoice: { ...block },
  createBulkCustomers: { ...block },
  fetchInvoiceTemplates: { ...block },
};

export const InvoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INVOICES_REQUEST:
      return {
        ...state,
        fetchInvoices: {
          ...state.fetchInvoices,
          loading: true,
        },
      };
    case FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        fetchInvoices: {
          ...state.fetchInvoices,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case FETCH_INVOICES_ERROR:
      return {
        ...state,
        fetchInvoices: {
          ...state.fetchInvoices,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case FETCH_INVOICE_REQUEST:
      return {
        ...state,
        fetchInvoice: {
          ...state.fetchInvoice,
          loading: true,
        },
      };
    case FETCH_INVOICE_SUCCESS:
      return {
        ...state,
        fetchInvoice: {
          ...state.fetchInvoice,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case FETCH_INVOICE_ERROR:
      return {
        ...state,
        fetchInvoice: {
          ...state.fetchInvoice,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case CREATE_INVOICE_REQUEST:
      return {
        ...state,
        createInvoice: {
          ...state.createInvoice,
          loading: true,
        },
      };
    case CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        createInvoice: {
          ...state.createInvoice,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case CREATE_INVOICE_ERROR:
      return {
        ...state,
        createInvoice: {
          ...state.createInvoice,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case FETCH_INVOICE_TEMPLATES_REQUEST:
      return {
        ...state,
        fetchInvoiceTemplates: {
          ...state.fetchInvoiceTemplates,
          loading: true,
        },
      };
    case FETCH_INVOICE_TEMPLATES_SUCCESS:
      return {
        ...state,
        fetchInvoiceTemplates: {
          ...state.fetchInvoiceTemplates,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case FETCH_INVOICE_TEMPLATES_ERROR:
      return {
        ...state,
        fetchInvoiceTemplates: {
          ...state.fetchInvoiceTemplates,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case FETCH_SCHEDULED_INVOICE_REQUEST:
      return {
        ...state,
        fetchScheduledInvoice: {
          ...state.fetchScheduledInvoice,
          loading: true,
        },
      };
    case FETCH_SCHEDULED_INVOICE_SUCCESS:
      return {
        ...state,
        fetchScheduledInvoice: {
          ...state.fetchScheduledInvoice,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case FETCH_SCHEDULED_INVOICE_ERROR:
      return {
        ...state,
        fetchScheduledInvoice: {
          ...state.fetchScheduledInvoice,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case FETCH_SINGLE_SCHEDULED_INVOICE_REQUEST:
      return {
        ...state,
        fetchSingleScheduledInvoice: {
          ...state.fetchSingleScheduledInvoice,
          loading: true,
        },
      };
    case FETCH_SINGLE_SCHEDULED_INVOICE_SUCCESS:
      return {
        ...state,
        fetchSingleScheduledInvoice: {
          ...state.fetchSingleScheduledInvoice,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case FETCH_SCHEDULED_INVOICE_ERROR:
      return {
        ...state,
        fetchSingleScheduledInvoice: {
          ...state.fetchSingleScheduledInvoice,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case DELETE_SCHEDULED_INVOICE_REQUEST:
      return {
        ...state,
        deleteScheduledInvoice: {
          ...state.deleteScheduledInvoice,
          loading: true,
        },
      };

    case DELETE_SCHEDULED_INVOICE_SUCCESS:
      return {
        ...state,
        deleteScheduledInvoice: {
          ...state.deleteScheduledInvoice,
          loading: false,
          success: true,
          data: action.data,
        },
      };

    case DELETE_SCHEDULED_INVOICE_ERROR:
      return {
        ...state,
        deleteScheduledInvoice: {
          ...state.deleteScheduledInvoice,
          loading: false,
          success: false,
          data: action.error,
        },
      };

    case UPDATE_INVOICE_REQUEST:
      return {
        ...state,
        updateInvoice: {
          ...state.updateInvoice,
          loading: true,
        },
      };
    case UPDATE_INVOICE_SUCCESS:
      return {
        ...state,
        updateInvoice: {
          ...state.updateInvoice,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case UPDATE_INVOICE_ERROR:
      return {
        ...state,
        updateInvoice: {
          ...state.updateInvoice,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case UPDATE_SCHEDULED_INVOICE_REQUEST:
      return {
        ...state,
        updateScheduledInvoice: {
          ...state.updateScheduledInvoice,
          loading: true,
        },
      };
    case UPDATE_SCHEDULED_INVOICE_SUCCESS:
      return {
        ...state,
        updateScheduledInvoice: {
          ...state.updateScheduledInvoice,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case UPDATE_SCHEDULED_INVOICE_ERROR:
      return {
        ...state,
        updateScheduledInvoice: {
          ...state.updateScheduledInvoice,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case SEARCH_INVOICE_CUSTOMERS_REQUEST:
      return {
        ...state,
        invoiceCustomers: {
          ...state.invoiceCustomers,
          loading: true,
        },
      };
    case SEARCH_INVOICE_CUSTOMERS_SUCCESS:
      return {
        ...state,
        invoiceCustomers: {
          ...state.invoiceCustomers,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case SEARCH_INVOICE_CUSTOMERS_ERROR:
      return {
        ...state,
        invoiceCustomers: {
          ...state.invoiceCustomers,
          loading: false,
          success: false,
          error: action.error,
        },
      };

    case SHARE_INVOICE_REQUEST:
      return {
        ...state,
        shareInvoice: {
          ...state.shareInvoice,
          loading: true,
        },
      };
    case SHARE_INVOICE_SUCCESS:
      return {
        ...state,
        shareInvoice: {
          ...state.shareInvoice,
          loading: false,
          success: true,
          data: action.data,
        },
      };

    case SHARE_INVOICE_ERROR:
      return {
        ...state,
        shareInvoice: {
          ...state.shareInvoice,
          loading: false,
          success: false,
          data: action.error,
        },
      };

    case CREATE_CUSTOMER_REQUEST:
      return {
        ...state,
        invoiceCustomer: {
          ...state.invoiceCustomer,
          loading: true,
        },
      };
    case CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        invoiceCustomer: {
          ...state.invoiceCustomer,
          loading: false,
          success: true,
          data: action.data,
        },
      };

    case CREATE_CUSTOMER_ERROR:
      return {
        ...state,
        invoiceCustomer: {
          ...state.invoiceCustomer,
          loading: false,
          success: false,
          data: action.error,
        },
      };

    case CREATE_BULK_CUSTOMER_REQUEST:
      return {
        ...state,
        createBulkCustomers: {
          ...state.createBulkCustomers,
          loading: true,
        },
      };
    case CREATE_BULK_CUSTOMER_SUCCESS:
      return {
        ...state,
        createBulkCustomers: {
          ...state.createBulkCustomers,
          loading: false,
          success: true,
          data: action.data,
        },
      };

    case CREATE_BULK_CUSTOMER_ERROR:
      return {
        ...state,
        createBulkCustomers: {
          ...state.createBulkCustomers,
          loading: false,
          success: false,
          data: action.error,
        },
      };

    case FETCH_CUSTOMERS_REQUEST:
      return {
        ...state,
        customers: {
          ...state.customers,
          loading: true,
        },
      };
    case FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: {
          ...state.customers,
          loading: false,
          success: true,
          data: action.data,
        },
      };

    case FETCH_CUSTOMERS_ERROR:
      return {
        ...state,
        customers: {
          ...state.customers,
          loading: false,
          success: false,
          data: action.error,
        },
      };

    case FETCH_CUSTOMER_REQUEST:
      return {
        ...state,
        customer: {
          ...state.customer,
          loading: true,
        },
      };
    case FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          loading: false,
          success: true,
          data: action.data,
        },
      };

    case FETCH_CUSTOMER_ERROR:
      return {
        ...state,
        customer: {
          ...state.customer,
          loading: false,
          success: false,
          data: action.error,
        },
      };

    case DELETE_INVOICE_REQUEST:
      return {
        ...state,
        deleteInvoice: {
          ...state.deleteInvoice,
          loading: true,
        },
      };
    case DELETE_INVOICE_SUCCESS:
      return {
        ...state,
        deleteInvoice: {
          ...state.deleteInvoice,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case DELETE_INVOICE_ERROR:
      return {
        ...state,
        deleteInvoice: {
          ...state.deleteInvoice,
          loading: false,
          success: false,
          data: action.error,
        },
      };

    case SCAN_INVOICE_REQUEST:
      return {
        ...state,
        scanFile: {
          ...state.scanFile,
          loading: true,
        },
      };
    case SCAN_INVOICE_SUCCESS:
      return {
        ...state,
        scanFile: {
          ...state.scanFile,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case SCAN_INVOICE_ERROR:
      return {
        ...state,
        scanFile: {
          ...state.scanFile,
          loading: false,
          success: false,
          data: action.error,
        },
      };

    case MARK_AS_PAID_REQUEST:
      return {
        ...state,
        markInvoiceAsPaid: {
          ...state.markInvoiceAsPaid,
          loading: true,
          success: false,
        },
      };
    case MARK_AS_PAID_SUCCESS:
      return {
        ...state,
        markInvoiceAsPaid: {
          ...state.markInvoiceAsPaid,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case MARK_AS_PAID_ERROR:
      return {
        ...state,
        markInvoiceAsPaid: {
          ...state.markInvoiceAsPaid,
          loading: false,
          success: false,
          data: action.error,
        },
      };

    case DELETE_CUSTOMER_REQUEST:
      return {
        ...state,
        deleteCustomer: {
          ...state.deleteCustomer,
          loading: true,
        },
      };

    case DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        deleteCustomer: {
          ...state.deleteCustomer,
          loading: false,
          success: true,
          data: action.data,
        },
      };

    case DELETE_CUSTOMER_ERROR:
      return {
        ...state,
        deleteCustomer: {
          ...state.deleteCustomer,
          loading: false,
          success: false,
          data: action.error,
        },
      };

    case RESET_BLOCK_INVOICE:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    case RESET_FLAGS_INVOICE:
      return {
        ...state,
        [action.blockType]: {
          ...state[action.blockType],
          data: {},
          ...block,
        },
      };
    default:
      return state;
  }
};
