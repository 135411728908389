import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Skeleton } from 'antd';
import { getCompanyLogoAsset } from 'redux/actions/CompaniesAction';
import TemplateSelector, { TEMPLATE_MODES } from './TemplateSelector';

const InvoiceAsset = ({ data, loading }) => {
  const dispatch = useDispatch();

  const {
    getCompanyAsset: { data: logoAsset },
    getCompany: { data: companyData = {}, loading: isCompanyLoading },
  } = useSelector(({ companies }) => companies);

  const isLoading = loading || isCompanyLoading;

  useEffect(() => {
    if (companyData?.logo) getCompanyLogo(companyData?.logo);
  }, [companyData]);

  const getCompanyLogo = (logo) => {
    dispatch(getCompanyLogoAsset(logo?.code));
  };

  const templatePreference = useMemo(() => {
    const isValidType = data?.template && typeof data?.template === 'object';
    return isValidType ? { value: data.template } : null;
  }, [data?.template]);

  return (
    <>
      {isLoading ? (
        <div>
          <section
            className="invoice-preview detail d-flex flex-column"
            style={{ background: 'inherit', height: 'auto' }}
          >
            <Skeleton.Image size={140} active style={{ width: '100%', height: '90vh' }} />
          </section>
        </div>
      ) : (
        <>
          <TemplateSelector
            isSingleView
            preference={templatePreference}
            mode={TEMPLATE_MODES.DASHBOARD_VIEW}
            data={{ ...data, logo: logoAsset?.asset?.url }}
          />
        </>
      )}
    </>
  );
};

export default InvoiceAsset;
