import { CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomRoleSelect from 'components/UI/CustomRoleSelect';
import RoleParagraphs from 'components/UI/CustomRoleSelect/RoleParagraphTruncateor';
import CustomSelect from 'components/UI/CustomSelect';
import Loading from 'components/UI/Loading';
import Modal from 'components/UI/Modal';
import ModalContainerWrap from 'components/UI/Modal/ModalContainerWrap';
import MultipleSelect from 'components/UI/MultipleSelect';
import { toastError } from 'components/UI/toast';
import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createBeneficiaries, getBeneficiaries } from 'redux/actions/BeneficiariesAction';
import { addMultipleBeneficiaryToBudget } from 'redux/actions/BudgetsAction';
import { getRoles } from 'redux/actions/RolesAction';
import PayAdditionalSeat from './PayAdditionalSeat';
import { LoadingSpinner } from 'assets/icons';

const BeneficiariesModal = ({
  toggleHandler,
  selectedBudget = {},
  isLink = false,
  setPayAdditionalSeat,
  payAdditionalSeat,
  isOpen,
}) => {
  const dispatch = useDispatch();

  const {
    createBeneficiaries: { loading, success },
    getBeneficiaries: { data: beneficiariesData = {} },
  } = useSelector(({ beneficiaries }) => beneficiaries);
  const {
    getBudget: { data: budgetData = {} },
    multipleBeneficiaries: { success: multipleSuccess, loading: multipleLoading },
  } = useSelector(({ budgets }) => budgets);

  const {
    user: { data: user = {} },
  } = useSelector(({ auth }) => auth);

  const {
    getRoles: { data: rolesData = [] },
  } = useSelector(({ roles }) => roles);

  const { budgets = [] } = budgetData;
  const [budgetsData, setBudgetsData] = useState();

  const {
    getBeneficiaries: { data = {} },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const { beneficiaries = [], meta } = data;

  const activeBeneficiary = beneficiaries.map((beneficiary) => ({
    value: beneficiary.code,
    label: `${beneficiary.user.firstName} ${beneficiary.user.lastName}`,
  }));

  useEffect(() => {
    if (!rolesData?.length) dispatch(getRoles());
  }, []);

  useEffect(() => {
    if (success || multipleSuccess) {
      dispatch(getBeneficiaries({ excludeInactiveBudgets: true }));
      if (isOpen) handleClose();
    }
  }, [success, multipleSuccess]);

  useEffect(() => {
    if (budgets.length > 0) {
      const budgetsOptions = budgets
        ?.filter(({ status }) => status != 'deleted')
        .map(({ code: value, name: label }) => ({ label, value }));
      setBudgetsData(budgetsOptions);
    }
  }, [budgets]);

  const [form, setForm] = useState({
    name: '',
    lastName: '',
    role: { value: null, label: 'Select a role' },
    link: { value: null, label: 'Select an option' },
    email: '',
    manager: { value: null, label: 'Select a manager' },
    beneficiaries: '',
    budget: [],
  });
  const handleChange = ({ target: { name, value } }) => {
    setForm({ ...form, [name]: value });
  };

  const value = meta?.total;
  const additionalSeat = user?.user?.company?.paymentPlan.additionalSeats;

  const activeplan = user?.user?.company?.paymentPlan?.name;

  const isNewPlan = !['start', 'growth', 'scale', 'custom'].includes(activeplan);

  const maxUser =
    (meta?.total >= 2
      ? meta?.total >= additionalSeat + value
      : meta?.total >=
        user?.user?.company?.paymentPlan?.configuration?.userManagement?.freeUsers) &&
    isNewPlan;
  //

  const handleSubmit = () => {
    if (isLink && !form.link.value) return toastError('Please select where to link from');
    if (!isLink || form.link.value === 'new') {
      if (!form.name) return toastError('Please enter member first name');
      if (!form.lastName) return toastError('Please enter member last name');
      if (!form.role.value) return toastError('Please select the member role');
      if (!form.email) return toastError('Please enter member email');
      // if (!form.budget.length) toastError('Place select assign budget');
      const isValid = !!form.name && !!form.email && !!form.role.value;

      if (maxUser) {
        toastError('Maximum number of team members exceeded.');
        return setPayAdditionalSeat(true);
      }
      if (isValid) {
        const payload = {
          firstName: form.name,
          lastName: form.lastName,
          role: form.role.code,
          email: form.email,
          manager: form.manager.code,
          // code: user?.data?.user?.code,
        };

        if (form.budget.length) {
          payload.budgets = form.budget.map((b) => b.value);
        } else {
          payload.budgets = selectedBudget.value
            ? selectedBudget.value.split(',')
            : undefined;
        }
        dispatch(createBeneficiaries(payload));
      }
    }
    if (isLink && form.link.value === 'old') {
      if (!form.beneficiaries?.length) return toastError('Please select a member');

      dispatch(
        addMultipleBeneficiaryToBudget({
          code: selectedBudget.value,
          beneficiaries: form.beneficiaries.map((item) => item.value),
        }),
      );
    }
  };

  const generateRoles = useMemo(() => {
    return rolesData?.map((datum) => {
      return {
        label: datum?.name,
        value: datum?.description ?? <RoleParagraphs datum={datum} />,
        code: datum?.code,
      };
    });
  }, [rolesData]);

  const generateOtherUsers = useMemo(() => {
    const { beneficiaries = [] } = beneficiariesData;
    return beneficiaries.map(({ user: { code, firstName, lastName, role } }) => ({
      value: `${role?.name}`,
      code,
      label: `${firstName} ${lastName}`,
    }));
  }, [beneficiariesData]);

  const handleBeneficiarySelect = (value) => {
    setForm({ ...form, beneficiaries: value });
  };

  const handleSelectAllBeneficiary = (list) => {
    const newList = form?.beneficiaries?.length === activeBeneficiary?.length ? [] : list;
    setForm({
      ...form,
      beneficiaries: newList,
    });
  };

  const handleClose = () => toggleHandler();
  const formInputs = () => {
    return (
      <>
        <Row className="mb-3 mt-4">
          <CustomInput
            label="Their first name *"
            placeholder="Enter name"
            type="text"
            name="name"
            onChange={handleChange}
            value={form.name}
          />
        </Row>
        <Row className="mb-3">
          <CustomInput
            label="Their last name *"
            placeholder="Enter name"
            type="text"
            name="lastName"
            onChange={handleChange}
            value={form.lastName}
          />
        </Row>
        <Row className="mb-3">
          <CustomInput
            label="What is their email? *"
            placeholder="Enter email"
            type="email"
            name="email"
            onChange={handleChange}
            value={form.email}
          />
        </Row>
        <Row className="mb-3">
          <CustomRoleSelect
            label="What is their role? *"
            options={generateRoles}
            placeholder="Member Roles"
            name="beneficiary"
            onChange={(val) => setForm({ ...form, role: val })}
            value={form.role}
          />
        </Row>
        <Row className="mb-3">
          <CustomRoleSelect
            label="Who is their manager? (Optional)"
            options={generateOtherUsers}
            placeholder="Who is their manager?"
            name="manager"
            onChange={(val) => setForm({ ...form, manager: val })}
            value={form.manager}
          />
        </Row>
      </>
    );
  };

  const isLengthEqual = form?.beneficiaries?.length === activeBeneficiary?.length;

  const AddMemberCard = (
    <ModalContainerWrap
      isWhite
      goBack={null}
      modalName={isLink ? 'Add member' : 'Create new member'}
      onClose={handleClose}
    >
      <div>
        <div className="px-4">
          <h3 className="header-title">Provide details for the new member</h3>
          {isLink ? (
            <>
              <Row className="my-3">
                <CustomSelect
                  label="Choose member type"
                  placeholderText="Select an option"
                  name="beneficiary"
                  options={[
                    { value: 'new', label: 'New Member' },
                    { value: 'old', label: 'Existing Member' },
                    // { value: 'manager', label: 'Manager ' },
                  ]}
                  onChange={(val) => setForm({ ...form, link: val })}
                  value={form.link}
                />
              </Row>
              {form.link.value === 'old' ? (
                <Row className="mb-3">
                  <MultipleSelect
                    placeholder="Select members"
                    label="Members"
                    name="beneficiaries"
                    options={activeBeneficiary}
                    onChange={handleBeneficiarySelect}
                    value={form?.beneficiaries}
                    extendComponent={
                      <span className="d-flex align-items-center gap-2">
                        {isLengthEqual ? <MinusOutlined /> : <PlusOutlined />}
                        {isLengthEqual ? 'Unselect all members' : 'Select all members'}
                      </span>
                    }
                    selectAllBudgets={() => handleSelectAllBeneficiary(activeBeneficiary)}
                  />
                </Row>
              ) : form.link.value === 'new' ? (
                formInputs()
              ) : null}
            </>
          ) : (
            formInputs()
          )}

          <Row className="mb-3">
            <MultipleSelect
              placeholder="Select budget"
              label="Assign Budget"
              isDisabled={
                !form.name || !form.lastName || Object.keys(selectedBudget).length > 0
              }
              name="budget"
              defaultValue={selectedBudget?.value ? selectedBudget : null}
              options={budgetsData}
              onChange={(val) => setForm({ ...form, budget: val })}
            />
          </Row>
        </div>

        <div className="position-fixed bottom-0 card-modal-footer bg-white px-4 py-2 border-top w-100">
          <div className="d-flex gap-2 align-items-center justify-content-end">
            <button className="border btn" onClick={handleClose}>
              Cancel
            </button>

            <button
              onClick={handleSubmit}
              loading={loading}
              disabled={loading}
              className="black btn"
            >
              {loading || multipleLoading ? (
                <Loading color="#D28B28" size={20} />
              ) : (
                'Invite'
              )}
            </button>
          </div>
        </div>
      </div>
    </ModalContainerWrap>
  );
  return payAdditionalSeat ? (
    <PayAdditionalSeat
      toggleHandler={() => setPayAdditionalSeat(false)}
      values={{ ...form, seats: 1 }}
    />
  ) : (
    AddMemberCard
  );
};
export default BeneficiariesModal;
