import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import BillingAddress from '../components/BillingAddress';
import OrderSummary from '../components/OrderSummary';

import { ArrowLeft, TicketIcon, TrendUpIcon } from 'assets/icons';
import classNames from 'classnames';
import { toastError } from 'components/UI/toast';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';

import {
  bulkCreateBeneficiaries,
  createBeneficiaries,
} from 'redux/actions/BeneficiariesAction';
import {
  fetchplansAction,
  subscribeToPlan,
  viewPlan,
} from 'redux/actions/BillingActions';
import {
  addOneYear,
  calculateProratedCost,
  getCurrency,
  getCurrentPlanType,
  getFormattedDate,
  proratedCosts,
} from 'utils/helper';
import './styles.scss';

const CheckoutV2 = () => {
  const [selectedPaymentOption, setSelectedPaymentOption] = useState('bujeti');
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const planType = location.state?.type;
  const action = location.state?.action;
  const plan = location.state?.plan;

  const {
    createBeneficiaries: { loading: loadingBeneficiary, success: successBeneficiary },
    bulkCreateBeneficiaries: {
      loading: loadingBulkBeneficiary,
      success: successBulkBeneficiary,
    },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const [seats, setSeats] = useState(plan?.seats ?? 1);

  const isBuySeat = action === 'buy_seat';

  const [billing, setBilling] = useState({
    billingPeriod: planType
      ? { value: planType, label: `Paid ${planType}` }
      : { value: 'monthly', label: 'Paid monthly' },
    type: 'balance',
  });

  const { user } = useSelector(({ auth }) => auth);
  const { data: userData = {} } = user;

  const goBack = () => {
    history.goBack();
  };

  const {
    fetchPlans: { data: planData },
    viewPlan: { loading },
    subscribeToPlan: { loading: isLoading, success },
  } = useSelector(({ billing }) => billing);

  const {
    viewPlan: { data: viewPlanData },
  } = useSelector(({ billing }) => billing);

  useEffect(() => {
    if (!planType && !action) return history.push('/');
    if (!planData?.plans?.length) dispatch(fetchplansAction());
  }, []);

  useEffect(() => {
    if (params.planCode) dispatch(viewPlan(params.planCode));
  }, [params.planCode]);

  useEffect(() => {
    if (success) history.push('/settings/billings');
    if (successBeneficiary || successBulkBeneficiary) history.push('/teams/people');
  }, [success, successBeneficiary, successBulkBeneficiary]);

  const onHandleSelectedPayment = (option) => {
    setSelectedPaymentOption(option);
  };

  const planDetails = location.state?.plan;
  const activeType = userData?.user?.company?.paymentPlan?.name;

  const getAddonText = (data, selected, active) => {
    const activeIndex = data?.findIndex((item) => item.name === active);
    const selectedIndex = data?.findIndex((item) => item.name === selected);
    if (activeIndex > selectedIndex) return 'Downgrade';
    if (activeIndex < selectedIndex) return 'Upgrade';
  };

  const handlePayment = () => {
    if (!billing.firstName) return toastError('Please enter your first name');
    if (!billing.lastName) return toastError('Please enter your last name');

    if (!billing.country) return toastError('Please select a country');
    if (!billing.address) return toastError('Please enter an address');
    if (!billing.state) return toastError('Please select a state');
    if (!billing.city) return toastError('Please enter a city');
    if (!billing.balance) return toastError('Please select an account');

    const isAvailableBalance = billing?.balance?.value?.startsWith('blc_');
    const isAvailableBackAccount = billing?.balance?.value?.startsWith('bnk_');

    const budget =
      isAvailableBalance || isAvailableBackAccount ? undefined : billing?.balance?.value;
    const method =
      billing.type === 'balance' && budget
        ? 'budget'
        : billing.type === 'balance' && !budget && isAvailableBalance
        ? 'balance'
        : 'directdebit';

    const paymentMethod = {
      method,
      directDebit:
        !budget && isAvailableBackAccount
          ? {
              bankAccount: billing?.balance?.value,
            }
          : undefined,
      budget,
      balance: !budget && isAvailableBalance ? billing?.balance?.value : undefined,
    };

    const payload = {
      billingPeriod: billing.billingPeriod.value,
      contactEmail: billing?.email,
      address: billing?.address,
      state: billing?.state?.label,
      country: billing?.country?.label,
      companyName: billing?.companyName,
      city: billing?.city,
      firstName: billing?.firstName,
      lastName: billing?.lastName,
      code: params.planCode,
      ...paymentMethod,
    };

    const buySeatPayload = {
      firstName: plan?.name,
      lastName: plan?.lastName,
      role: plan?.role?.code,
      budgets: plan?.budget?.map((item) => item.value),
      email: plan?.email,
      additionalSeats: seats,
      ...paymentMethod,
    };

    if (isBuySeat) {
      if (plan?.beneficiaries?.length)
        dispatch(
          bulkCreateBeneficiaries({
            beneficiaries: plan?.beneficiaries,
            ...paymentMethod,
            additionalSeats: seats,
          }),
        );
      else dispatch(createBeneficiaries(buySeatPayload));
    } else dispatch(subscribeToPlan(payload));
  };

  const gradeAction = getAddonText(planData?.plans, params?.planType, activeType);

  const getUserPlan = userData?.user?.company?.paymentPlan;

  const amount = Math.round(
    (billing?.billingPeriod?.value === 'monthly'
      ? planDetails?.amount
      : planDetails.amount * (1 - 0.1) * 12) / 100,
  );

  const planAmount = proratedCosts(
    getUserPlan?.expiryDate,
    getUserPlan?.paidOn,
    getUserPlan?.amount,
    amount,
    billing?.billingPeriod?.value,
  );

  return (
    <div className="plan-checkout-page">
      <div className="position-relative px-0 mx-0">
        <div className="pl-4">
          <div className="mt-4 py-2 create__invoice-container border-bottom">
            <div className="back-click mb-3" onClick={goBack}>
              <ArrowLeft color="#D28B28" height="10" width="10" />
              Back
            </div>
            <div className="d-flex justify-content-between align-items-center flex-wrap mt-4 pb-3">
              <div className="d-flex gap-3 align-items-center">
                <div
                  className={classNames('header-icon lg', {
                    'bg-bright-green': !isBuySeat,
                  })}
                >
                  {isBuySeat ? <TicketIcon /> : <TrendUpIcon />}
                </div>
                <div>
                  <h1 className="headerText m-0">
                    {isBuySeat
                      ? 'Buy an additional seat'
                      : `${gradeAction} to ${planDetails?.name} plan`}
                  </h1>
                  <h6 className="subtext m-0">
                    {isBuySeat
                      ? 'Expand your team at your pace'
                      : 'Scale your team to up to 25 users members'}
                  </h6>
                </div>
              </div>

              <div className="d-flex gap-1 gap-md-3 right-side position-relative pe-4">
                <div className="d-flex flex-column align-items-end">
                  <h1 className="headerText m-0">
                    <CurrencyFormat
                      prefix={getCurrency('NGN')}
                      value={amount}
                      displayType="text"
                      thousandSeparator={true}
                    />
                  </h1>
                  <h6 className="subtext m-0">
                    <span className="text-black">{billing?.billingPeriod?.label}</span>{' '}
                    Next payment:{' '}
                    {getFormattedDate(
                      isBuySeat ? getUserPlan?.expiryDate : planAmount?.newExpiryDate,
                    )}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100">
          <section className="d-flex overview-holder medium payment-section">
            <div className="col-sm-12 col-md-7 border-right p-4 left-side">
              <BillingAddress
                getSelectedOption={onHandleSelectedPayment}
                info={userData?.user}
                setBilling={setBilling}
                billing={billing}
                isBuySeat={isBuySeat}
                seats={seats}
              />
            </div>
            <div className="col-sm-12 col-md-5 py-4 right-side">
              <OrderSummary
                selectedPayment={selectedPaymentOption}
                type={params?.planType}
                getAddonText={isBuySeat ? 'Confirm payment' : gradeAction}
                handlePayment={handlePayment}
                isLoading={isLoading || loadingBeneficiary || loadingBulkBeneficiary}
                setBilling={setBilling}
                billing={billing}
                isBuySeat={isBuySeat}
                seats={seats}
                setSeats={setSeats}
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CheckoutV2;
