import { FileAttachment02Icon } from 'assets/icons';
import mp3Icon from 'assets/images/mp3IconL.png';
import mp4Icon from 'assets/images/mp4IconL.png';
import pdfImage from 'assets/images/pdfIcon.png';
import xlsxIcon from 'assets/images/xlsxIcon.png';
import classNames from 'classnames';
import FileUpload from 'components/UI/FileUpload';
import { useEffect } from 'react';
import { transactionAcceptedFiles, transactionFileSupported } from 'utils/helper';

const getImageSource = {
  pdf: pdfImage,
  mp4: mp4Icon,
  mp3: mp3Icon,
  csv: xlsxIcon,
  xlsx: xlsxIcon,
};

/**

 * @param {array} receiptAssets: array of object consisting of name, url
 * @param {function} viewImgUrl: a callback function to open the receipt viewer
 * @returns
 */
const NewReceiptUpload = ({
  receiptAssets,
  viewImgUrl,
  removeFile,
  addMore,
  onChange,
  loading,
  label,
}) => {
  useEffect(() => {
    if (receiptAssets?.length) viewImgUrl(receiptAssets[0], 'receipt');
  }, [receiptAssets?.length]);

  return (
    <div className={classNames('receipt-asset-wrapper')}>
      {/* <div className="h-100 w-50 bg-black">w</div> */}

      {receiptAssets?.map((item, index) => (
        <div
          key={index}
          className={
            item?.name?.includes('pdf') || item?.name?.includes('xlsx')
              ? 'receipt-asset pdf-small lg parent-img-hover position-relative overflow-hidden w-100'
              : 'receipt-asset lg parent-img-hover position-relative overflow-hidden w-100'
          }
          onClick={() => viewImgUrl(item, 'receipt')}
        >
          <div className="img-hover position-absolute h-100 w-100 d-flex justify-content-center align-items-center">
            <div className="bg-white rounded-2 gap-1 px-2 py-1 align-items-center justify-content-center d-flex cursor border-2 border-light">
              <span>View</span>
              <span>
                <FileAttachment02Icon />
              </span>
            </div>
          </div>

          <img
            src={getImageSource[item?.name] ?? item?.url}
            alt={item?.name}
            className="lg"
          />
        </div>
      ))}
      <div style={{ marginTop: '-16px', marginBottom: '-12px', paddingRight: '12px' }}>
        <FileUpload
          label={label}
          hoverButton
          name="file"
          onChange={onChange}
          multiple
          removeFile={removeFile}
          roundSize="lg"
          wrapperClass="new-receipt"
          isRounded={true}
          uploadingFile={loading}
          supportType={transactionFileSupported}
          acceptedFile={transactionAcceptedFiles}
          preview
        />
      </div>
    </div>
  );
};

export default NewReceiptUpload;
