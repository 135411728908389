import { InvoiceAmountInformation, InvoicePreviewTable } from '.';
import PoweredBy from 'assets/images/powered-by-bujeti.png';

import dayjs from 'dayjs';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { capitalizeFirstLetter, getCurrency, convertNaNToZero } from 'utils/helper';
import { TEMPLATE_MODES } from '../TemplateSelector';
import CurrencyFormat from 'react-currency-format';

const Classic = ({ data = {}, mode, isRecurring, LogoIcon, isInstalment }) => {
  const headers = [
    { key: 'item', minWidth: 130, label: 'Item Detail' },
    { key: 'quantity', label: 'Qty', minWidth: 40 },
    { key: 'rate', label: 'Rate' },
    { key: 'amount', label: 'Amount', align: 'right' },
  ];

  return (
    <section
      className={classNames(
        'invoice-preview template-foundation position-relative d-flex flex-column pt-5 px-5',
        {
          'is-demo': mode === TEMPLATE_MODES.DEMO,
          detail: mode === TEMPLATE_MODES.DASHBOARD_VIEW,
          'customer-view': mode === TEMPLATE_MODES.CUSTOMER_VIEW,
        },
      )}
    >
      {Array.from({ length: 2 }).map((_, i) => (
        <div
          key={i}
          className={classNames('diagonal-slash', {
            top: i === 0,
            bottom: i === 1,
          })}
        />
      ))}

      <InvoiceHeader data={data} LogoIcon={LogoIcon} />

      {isInstalment && data?.amountRemaining && (
        <section
          className="d-flex mt-4 align-items-end flex-column lhs"
          style={{ padding: '0 20px' }}
        >
          <div className="caption">Balance due</div>
          <p className="value currency pt-1" style={{ marginBottom: 0, fontSize: 16 }}>
            <CurrencyFormat
              value={convertNaNToZero(data?.amountRemaining / 100).toFixed(2)}
              displayType="text"
              thousandSeparator={true}
              prefix={getCurrency(data?.currency || 'NGN')}
            />
          </p>
        </section>
      )}

      <section className="preview-table template-foundation mt-4 px-0">
        <InvoicePreviewTable headers={headers} isRecurring={isRecurring} data={data} />
      </section>

      <section className="d-flex justify-content-end">
        <div className="account-info template-foundation">
          <InvoiceAmountInformation
            data={data}
            mode={mode}
            totalText="Total"
            caption="Subtotal"
            isRecurring={isRecurring}
          />
        </div>
      </section>

      <section className="invoice-preview-footer px-0 mt-auto">
        <InvoiceFooter data={data} />
      </section>
    </section>
  );
};

const InvoiceHeader = ({ data, LogoIcon }) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between pb-2">
        <div className="invoice-image-holder">
          <img src={data?.logo ?? LogoIcon} alt="company logo" />
        </div>

        <div className="ms-auto w-50">
          {data?.title && <p className="value invoice-no">Invoice #{data?.title}</p>}
        </div>
      </div>

      <div className="w-35 ms-auto">
        <div className="d-flex justify-content-between mb-1">
          <span className="caption">Invoice date:</span>
          <span className="value">
            {dayjs(data?.issuedDate || new Date()).format('DD MMM, YYYY')}
          </span>
        </div>

        <div className="d-flex justify-content-between ">
          <span className="caption">Due date:</span>
          <span className="value">
            {data?.dueDate ? dayjs(data?.dueDate).format('DD MMM, YYYY') : '-'}
          </span>
        </div>
      </div>

      <Row className="mt-5">
        <Col>
          <Row>
            <Col md={4} className="px-0">
              <p className="caption" style={{ fontSize: 16 }}>
                Billed from:
              </p>
            </Col>

            <Col md={8} className="">
              <span className="value">{`${data?.company?.name}`}</span>
              <span className="value">{data?.company?.contactEmail}</span>
              <span className="value">
                {data?.company?.contact_phone ? `+${data.company.contact_phone}` : ''}
              </span>
              <span className="value">{data?.address}</span>
            </Col>
          </Row>
        </Col>

        {/* Billed to */}
        <Col>
          <Row>
            <Col md={4} className="px-0">
              <p className="caption" style={{ fontSize: 16 }}>
                Billed to:
              </p>
            </Col>
            <Col md={8}>
              <span className="value">
                {data?.customer?.name ||
                  `${data?.customer?.firstName} ${data?.customer?.lastName}`}
              </span>
              <span className="value">{data?.customer?.email}</span>
              <span className="value">
                {data?.customer?.phoneNumber?.localFormat
                  ? `+${data?.customer?.phoneNumber?.countryCode ?? ''}${
                      data?.customer?.phoneNumber?.localFormat ?? ''
                    }`
                  : null}
              </span>

              {data?.customer?.address?.street && (
                <span className="value">
                  {`${data?.customer?.address?.street}, ${
                    data?.customer?.address?.city
                  }, ${capitalizeFirstLetter(
                    data?.customer?.address?.country?.toLowerCase(),
                  )}`}
                </span>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

const InvoiceFooter = ({ data }) => {
  const { description, account: bankDetails = {} } = data;
  return (
    <section className="mt-3">
      {description && (
        <Col md={6}>
          <Row className="pb-3 max-w-300">
            <div className="lhs">
              <div style={{ marginRight: 15 }}>
                <h1 className="caption">Notes:</h1>
                <p className="value">{description}</p>
              </div>
            </div>
          </Row>
        </Col>
      )}
      {bankDetails?.accountName && (
        <Col className="mt-2">
          <div className="max-w-300">
            <div className="lhs">
              <h1 className="caption">Payment information:</h1>
              <span className="value">{bankDetails?.bank || '-'}</span>
              <span className="value">{bankDetails?.accountName || '-'}</span>
              <span className="value">{bankDetails?.accountNumber || '-'}</span>
            </div>
          </div>
        </Col>
      )}

      <div className="mt-4 mb-3 powered-holder d-flex justify-content-end">
        <img src={PoweredBy} alt="Bujeti" />
      </div>
    </section>
  );
};

export default Classic;
