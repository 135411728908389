import {
  Document,
  Image,
  Link,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
  Svg,
} from 'components/UIHooks/JsPDF';

import { convertNaNToZero } from 'utils/helper';
import dayjs from 'dayjs';
import LogoIcon from 'assets/icons/Vector.png';
import Slash from 'assets/icons/diagonal-slash.png';

import {
  handleNumberFormatting,
  FormatAmount,
  companyAddressAliaser,
} from '../../DownloadPdfComponent';

const styles = StyleSheet.create({
  page: {
    position: 'relative',
    flexDirection: 'column',
    backgroundColor: '#fff',
    width: '100%',
    padding: '40px',
  },

  totalContainer: {
    width: '242px',
    marginTop: 14,
    marginBottom: 80,
  },

  invoiceTitle: {
    fontWeight: 800,
    fontSize: 12,
    color: '#000',
  },
  invoiceSubTitle: {
    fontWeight: 500,
    fontSize: 12,
    color: '#5A5A5A',
  },
  dFlex: {
    display: 'flex',
  },
  topSectionContainer: {
    padding: '20px 50px 69px 50px',
  },
  flexCol: {
    flexDirection: 'column',
  },
  flexRow: {
    flexDirection: 'row',
  },

  noMargin: {
    marginBottom: 0,
  },
  title: {
    color: '#181818',
    fontSize: '12px',
    marginBottom: 5,
  },
  image: {
    width: 150,
    height: 150,
  },
  rightAlign: {
    float: 'right',
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  mt4: {
    paddingTop: 20,
  },
  mt3: {
    paddingTop: 10,
  },
  mt1: {
    paddingTop: 4,
  },
  p16: {
    padding: 16,
  },
  px2: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  px20: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  lightText: {
    color: '#586068',
    fontSize: '10px',
    fontWeight: 200,
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#EAECF0',
    marginTop: 50,
    marginBottom: 34,
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerText: {
    color: '#121722',
    fontSize: 12,
    fontWeight: 400,
  },
  table: {
    display: 'table',
    width: '100%',
    border: '1px solid #E7E5E4',
    marginBottom: 2,
  },
  th: {
    color: '#79716B',
    fontWeight: 400,
    fontSize: 10,
  },
  tableHeader: {
    backgroundColor: '#F5F5F4',
    fontWeight: 'bold',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottom: '1px solid #e7e5e4',
  },
  tableCell: {
    width: '35%',
    margin: 5,
    fontSize: 10,
  },
  alignLeft: {
    textAlign: 'left',
  },

  borderB: { borderBottom: '0.5px', borderColor: '#1C1917' },
  main: {
    position: 'relative',
  },
  footerStyles: {
    marginTop: 'auto',
    width: '100%',
  },
  footer: {
    position: 'absolute',
    bottom: 10,
    left: 40,
    right: 40,
  },

  imageContainer: {
    width: 60,
    height: 30,
    overflow: 'hidden',
  },
  logo: {
    width: '100%',
    height: '100%',
  },
  link: {
    color: '#D28B28',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  diagonalSlash: {
    position: 'absolute',
    width: '100%',
    height: 1,
  },
});

const FoundationPdf = ({ data }) => {
  const isInstalment = !!data?.installments?.length && data?.amountRemaining;

  return (
    <Document style={{ width: '100%', height: '100vh', margin: 0, padding: 0 }}>
      <Page size="A4" style={styles.page}>
        <View style={{ top: 0, width: '100vw', position: 'absolute', height: 8 }}>
          <Image src={Slash} />
        </View>

        <View style={{ bottom: 0, width: '100vw', position: 'absolute', height: 8 }}>
          <Image src={Slash} />
        </View>

        <Header data={data} isInstalment={isInstalment} companyData={data?.company} />

        <View>
          <View style={{ paddingTop: 18 }}>
            <Table data={data} />
          </View>

          <View style={styles.rightAlign}>
            <TotalContainer data={data} />
          </View>
        </View>

        <View style={styles.footer}>
          <PdfFooter data={data} />
          <View style={[styles.dFlex, styles.center]}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'flex-end',
              }}
            >
              <Text
                style={[
                  styles.footerText,
                  styles.invoiceSubTitle,
                  { marginTop: '5px', paddingBottom: 12 },
                ]}
              >
                Powered by{' '}
                <Link style={styles.link} src="https://www.bujeti.com">
                  <Text>Bujeti.com</Text>
                </Link>{' '}
                - Take control of your finances today!
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const Header = ({ data, isInstalment, companyData }) => {
  return (
    <View>
      {/* Top Section */}
      <View style={[styles.dFlex, styles.flexRow, styles.spaceBetween]}>
        <Image
          src={
            data?.company?.logo
              ? `data:${data?.type};base64,${data?.base64Data}`
              : LogoIcon
          }
          style={{
            height: data?.company?.logo ? data?.newHeight : '40px',
          }}
          alt="logo"
        />

        {data?.title && (
          <View style={{ width: '140px' }}>
            <FlexWrapper>
              <Text style={([styles.invoiceTitle], { fontSize: '22px' })}>
                Invoice #{data?.title}
              </Text>
            </FlexWrapper>
          </View>
        )}
      </View>

      <FlexWrapper>
        <View style={{ width: '60%' }}></View>
        <View style={{ width: '140px' }}>
          <FlexWrapper>
            <Text style={[styles.invoiceSubTitle]}>Invoice date</Text>
            <Text style={([styles.invoiceTitle], { fontSize: '10px' })}>
              {dayjs(data?.created_at).format('DD MMM, YYYY')}
            </Text>
          </FlexWrapper>

          <FlexWrapper style={{ paddingTop: '4px' }}>
            <Text style={[styles.invoiceSubTitle]}>Due date</Text>
            <Text style={([styles.invoiceTitle], { fontSize: '10px' })}>
              {data?.due_date ? dayjs(data?.due_date).format('DD MMM, YYYY') : '-'}
            </Text>
          </FlexWrapper>
        </View>
      </FlexWrapper>

      <View style={{ marginTop: 20 }}>
        <FlexWrapper>
          <View style={{ width: '50%' }}>
            <FlexWrapper style={{ justifyContent: 'unset' }}>
              <Text style={[styles.invoiceSubTitle, styles.mt1, { fontSize: 14 }]}>
                Billed from:
              </Text>
              <View style={{ maxWidth: '180px', width: '100%' }}>
                <Text style={[styles.invoiceTitle, styles.mt1]}>
                  {data?.company?.name}
                </Text>
                <Text style={[styles.invoiceSubTitle, styles.mt1]}>
                  {data?.company?.contactEmail || data?.company?.contact_email}
                </Text>
                <Text style={[styles.invoiceSubTitle, styles.mt1]}>
                  {data?.company?.contact_phone}
                </Text>
                <Text style={[styles.invoiceSubTitle, { textAlign: 'left' }]}>
                  {companyAddressAliaser(companyData)}
                </Text>
              </View>
            </FlexWrapper>
          </View>

          <View style={{ width: '50%' }}>
            <FlexWrapper style={{ justifyContent: 'unset' }}>
              <Text style={[styles.invoiceSubTitle, styles.mt1, { fontSize: 14 }]}>
                Billed to:
              </Text>
              <View style={{ maxWidth: '180px', width: '100%' }}>
                <Text style={[styles.invoiceSubTitle]}>Billed to</Text>
                <Text style={[styles.invoiceTitle, styles.mt1]}>
                  {data?.customer?.name}
                </Text>
                <Text style={[styles.invoiceSubTitle, styles.mt1]}>
                  {data?.customer?.email}
                </Text>
                <Text style={[styles.invoiceSubTitle, styles.mt1]}>
                  {data?.customer?.phoneNumber?.localFormat}
                </Text>
              </View>
            </FlexWrapper>
          </View>
        </FlexWrapper>
      </View>

      {isInstalment && (
        <View style={{ paddingTop: 20, textAlign: 'right' }}>
          <FlexWrapper>
            <View> </View>
            <View style={{ textAlign: 'right' }}>
              <Text style={[styles.invoiceSubTitle, { textAlign: 'right' }]}>
                Balance due
              </Text>
              <Text style={[styles.invoiceTitle, { paddingTop: 4, textAlign: 'right' }]}>
                {handleNumberFormatting(data?.currency, data?.amountRemaining)}
              </Text>
            </View>
          </FlexWrapper>
        </View>
      )}
    </View>
  );
};

const PdfFooter = ({ data }) => {
  const selectBank =
    data?.customer?.bankAccounts.length && data?.customer?.bankAccounts[0];
  const BankAccount = typeof selectBank === 'object' && selectBank !== null;
  return (
    <View
      style={[
        styles.dFlex,
        styles.flexRow,
        styles.spaceBetween,
        styles.px2,
        styles.footerStyles,
      ]}
    >
      {data?.description && (
        <View style={{ maxWidth: 220 }}>
          <Text style={{ fontSize: 10, color: '#000', marginBottom: 3 }}>
            Notes / payment terms:
          </Text>
          <Text style={{ fontSize: 10, color: '#525866' }}>{data?.description}</Text>
        </View>
      )}
      {(data?.bankDetails || BankAccount) && (
        <View style={{ width: 220 }}>
          <Text
            style={{ fontSize: 12, color: '#5A5A5A', marginBottom: 8, fontWeight: 800 }}
          >
            Payment Information:
          </Text>
          {BankAccount ? (
            <>
              <Text style={{ fontSize: 10, color: '#525866', marginBottom: 3 }}>
                {selectBank.bankName}
              </Text>
              <Text style={{ fontSize: 10, color: '#525866', marginBottom: 3 }}>
                {selectBank.accountName}
              </Text>

              <Text style={{ fontSize: 10, color: '#525866' }}>{selectBank.number}</Text>
            </>
          ) : (
            <Text style={{ fontSize: 10, color: '#525866' }}>{data?.bankDetails}</Text>
          )}
        </View>
      )}
    </View>
  );
};

const FlexWrapper = ({ children }) => {
  return (
    <View style={[styles.dFlex, styles.flexRow, styles.spaceBetween, styles.mt3]}>
      {children}
    </View>
  );
};

const Table = ({ data }) => {
  const isRecurring = data?.code?.split('_')[0] === 'sci';
  return (
    <View style={styles.table}>
      <View style={[styles.tableRow, styles.tableHeader]}>
        <View style={[styles.tableCell, styles.alignLeft, { width: '60%' }]}>
          <Text style={[styles.th]}>Item Detail</Text>
        </View>

        <View style={[styles.tableCell, styles.alignLeft]}>
          <Text style={[styles.th]}>Qty</Text>
        </View>

        <View style={[styles.tableCell, { width: '35%' }]}>
          <Text style={[styles.th]}>Rate</Text>
        </View>

        <View style={[styles.tableCell, { width: '25%', textAlign: 'right' }]}>
          <Text style={[styles.th]}>Amount</Text>
        </View>
      </View>

      {data?.invoiceProducts?.map((datum, index) => (
        <View style={styles.tableRow} key={index}>
          <View style={[styles.tableCell, styles.alignLeft, { width: '60%' }]}>
            <Text>{datum?.product?.name}</Text>
            <Text
              style={{
                color: '#586068',
                fontWeight: 400,
                paddingTop: 4,
                marginBottom: 0,
              }}
            >
              {datum?.product?.description}
            </Text>
          </View>

          <View style={[styles.tableCell, styles.alignLeft]}>
            <Text>{datum?.quantity}</Text>
          </View>

          <View style={[styles.tableCell, styles.alignLeft, { width: '35%' }]}>
            <Text>{handleNumberFormatting(data?.currency, datum?.product?.price)}</Text>
          </View>

          <View style={[styles.tableCell, { width: '25%', textAlign: 'right' }]}>
            {isRecurring ? (
              <Text>
                {handleNumberFormatting(
                  data?.currency,
                  datum?.unitPrice * datum?.quantity,
                )}
              </Text>
            ) : (
              <Text>{handleNumberFormatting(data?.currency, datum?.amount)}</Text>
            )}
          </View>
        </View>
      ))}
    </View>
  );
};

const TotalContainer = ({ data }) => {
  const isRecurring = data?.code?.split('_')[0] === 'sci';

  const subTotal = data?.invoiceProducts?.reduce((accumulator, currentValue) => {
    if (isRecurring) {
      return accumulator + currentValue?.unitPrice * currentValue?.quantity;
    } else {
      return accumulator + currentValue?.amount;
    }
  }, 0);

  const handleAmount = (payment) => {
    const { type, percentage, amount } = payment;
    if (type === 'percentage') {
      return FormatAmount((((percentage / 100) * data.amount) / 100).toFixed(2));
    }

    return FormatAmount(amount / 100);
  };

  const discount =
    data?.discount_type === 'percentage'
      ? (data?.discount / 100) * subTotal
      : data?.discount;

  return (
    <View style={styles.totalContainer}>
      <FlexWrapper>
        <Text style={styles.invoiceSubTitle}>Subtotal</Text>
        <Text style={styles.invoiceTitle}>
          {`${data?.currency}${FormatAmount(convertNaNToZero(subTotal / 100))}`}
        </Text>
      </FlexWrapper>

      <FlexWrapper>
        <Text style={styles.invoiceSubTitle}>
          Discount
          {data?.discount_type === 'percentage' && <Text> ({data?.discount}%)</Text>}
        </Text>

        <Text style={styles.invoiceTitle}>
          <Text>- </Text>
          {handleNumberFormatting(data?.currency, discount)}
        </Text>
      </FlexWrapper>

      <View style={{ paddingBottom: 6 }}>
        <FlexWrapper>
          <Text style={styles.invoiceSubTitle}>
            Tax <Text>({data?.vat}%)</Text>
          </Text>
          <Text style={styles.invoiceTitle}>
            {handleNumberFormatting(data?.currency, data?.vatAmount ?? 0)}
          </Text>
        </FlexWrapper>
      </View>

      <FlexWrapper>
        <Text style={[styles.invoiceTitle]}>Total:</Text>

        <Text style={[styles.invoiceTitle]}>
          {handleNumberFormatting(data?.currency, data?.amount)}
        </Text>
      </FlexWrapper>

      <View style={{ marginTop: 16 }}>
        {data?.installments?.map((payment, index) => (
          <View key={index} style={{ marginBottom: 4 }}>
            <FlexWrapper>
              <View>
                <Text
                  style={[
                    styles.invoiceSubTitle,
                    { fontSize: 10, color: '#1C1917', fontWeight: 500 },
                  ]}
                >
                  Payment {index + 1}
                </Text>

                <>
                  {payment?.status === 'paid' ? (
                    <Text
                      style={[
                        styles.invoiceSubTitle,
                        { fontSize: 10, color: '#79716B', marginTop: 4 },
                      ]}
                    >
                      Paid on {dayjs(payment?.paid_on).format('DD MMM, YYYY')}
                    </Text>
                  ) : (
                    <Text
                      style={[
                        styles.invoiceSubTitle,
                        { fontSize: 10, color: '#79716B', marginTop: 4 },
                      ]}
                    >
                      Due on {dayjs(payment?.due_date).format('DD MMM, YYYY')}
                    </Text>
                  )}
                </>
              </View>

              <Text
                style={[
                  styles.invoiceSubTitle,
                  { fontSize: 10, color: '#57534E', fontWeight: 500 },
                ]}
              >
                {`${data?.currency} ${handleAmount(payment)}`}
              </Text>
            </FlexWrapper>
          </View>
        ))}
      </View>
    </View>
  );
};

export const PDF = () => (
  <PDFViewer style={{ width: '1024px', height: '1024px' }}>
    <FoundationPdf />
  </PDFViewer>
);

export default FoundationPdf;
