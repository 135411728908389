import CustomInput from 'components/UI/CustomInput';
import CustomPercentageInput from 'components/UI/CustomPercentageInput';
import CustomSelect from 'components/UI/CustomSelect';

import dayjs from 'dayjs';
import { addDays } from 'date-fns';

import CustomDatePicker from 'components/UI/CustomDatePicker';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import CustomTextarea from 'components/UI/CustomTextarea';

import CurrencyFormat from 'react-currency-format';
import {
  convertNaNToZero,
  getCurrency,
  capitalizeFirstLetter,
  transactionAcceptedFiles,
} from 'utils/helper';
import { Skeleton, Switch, Segmented } from 'antd';
import NewReceiptUpload from 'components/UI/CustomDrawer/components/NewReceiptUpload';

const Loader = () => (
  <>
    <div className="w-75 pt-4">
      <div className="mb-3">
        <Skeleton.Input active size={12} style={{ borderRadius: '4px' }} />

        <div className="details-holder">
          <div className="d-flex gap-3 border-bottom pb-3">
            <Skeleton.Avatar active size={18} style={{ borderRadius: '12px' }} />
            <div className="d-flex flex-column">
              <Skeleton.Input active size={12} style={{ borderRadius: '4px' }} />
              <Skeleton.Input active size={12} style={{ borderRadius: '4px' }} />
            </div>
          </div>

          <div className="d-flex flex-column pt-4">
            <Skeleton.Input active size={12} style={{ borderRadius: '4px' }} />
            <Skeleton.Input
              active
              size={40}
              style={{ borderRadius: '4px', width: '30rem' }}
            />
          </div>

          <div className="border-top mt-4 pt-4">
            <div className="d-flex flex-column">
              <Skeleton.Input active size={12} style={{ borderRadius: '4px' }} />
              <Skeleton.Input
                active
                size={182}
                style={{ borderRadius: '8px', width: '30rem' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

const FooterRow = ({
  getFooterValue,
  values,
  getDescription,
  getAccount,
  totalAmount,
  max_length,
  charCount,
  text,
  loadingBalances,
  loadOptions,
  isLoading,
  isInstalment,
  setIsInstalment,
  instalmentPayload,
  setInstalmentPayload,
  instalmentOptions,
  isAmount,
  amountSum,
  checkValue,
  handlePaymentSwitch,
  setAssetViewer,
  setAttachments,
  attachments,
}) => {
  const INSTALMENTSTATEDATA = {
    type: 'percentage',
    option: instalmentOptions[0],
    payments: [
      { amount: '', dueDate: '' },
      { amount: '', dueDate: '' },
    ],
  };

  const { payments } = instalmentPayload;
  const typeOptions = [
    { label: 'Percentage %', value: 'percentage' },
    { label: 'Amount ₦', value: 'amount' },
  ];

  const handleChange = (val, target) => {
    setInstalmentPayload((prevInstalmentPayload) => {
      const newPayments =
        val?.value > prevInstalmentPayload.payments.length
          ? [
              ...prevInstalmentPayload.payments,
              ...Array.from({
                length: val.value - prevInstalmentPayload.payments.length,
              }).map(() => ({
                amount: '',
                dueDate: '',
              })),
            ]
          : prevInstalmentPayload.payments.slice(0, val.value);

      return {
        ...prevInstalmentPayload,
        payments: newPayments,
        [target]: val,
      };
    });
  };

  const handleInput = (value, index, target) => {
    if (target === 'amount' && isAmount) value = value?.target?.rawValue ?? '';

    if (index === instalmentPayload.payments.length - 1 && target !== 'amount') {
      getFooterValue({ target: { name: 'dueDate', value } });
    }

    setInstalmentPayload((prevInstalmentPayload) => {
      const updatedPayments = [...prevInstalmentPayload.payments];
      updatedPayments[index][target] = value;

      return {
        ...prevInstalmentPayload,
        payments: updatedPayments,
      };
    });
  };

  const checkValidity = (index) => {
    if (amountSum > checkValue) {
      const previousMap = payments.filter((_, i) => i !== index);
      const sumOfPrevious = previousMap.reduce(
        (total, payment) => total + Number(payment.amount || 0),
        0,
      );

      const updatedPayments = [...payments];
      updatedPayments[index].amount = checkValue - sumOfPrevious;

      setInstalmentPayload((prevInstalmentPayload) => ({
        ...prevInstalmentPayload,
        payments: updatedPayments,
      }));
    }
  };

  const handleDateValidity = (index) => {
    return index === 0
      ? addDays(dayjs(new Date()).toDate(), 1)
      : addDays(payments[index - 1].dueDate, 1);
  };

  const handleSwitch = (value) => {
    handlePaymentSwitch();
    handleChange(value, 'type');
  };

  const handleInstalmentSwitch = (value) => {
    setIsInstalment(value);
    setInstalmentPayload(INSTALMENTSTATEDATA);
  };

  const viewImgUrl = (asset) => {
    setAssetViewer(asset);
    // getReceiptList(receiptAssets);
  };

  return (
    <div className="border-top border-bottom bg-transparent my-4 pb-4">
      {isLoading ? (
        <div className="mt-4">
          <Loader />
        </div>
      ) : (
        <>
          <section className="mt-4">
            <h2 className="region-header mt-4 pt-2">Payment & instalment settings </h2>

            <div className="details-holder details-holder-width">
              <section className="border-bottom pb-4 mb-4">
                <div className="d-flex gap-3 ">
                  <Switch
                    className="invoice-switch"
                    checked={isInstalment}
                    onChange={(value) => handleInstalmentSwitch(value)}
                  />
                  <div>
                    <span
                      className="region-header d-block"
                      style={{ fontWeight: 500, fontSize: '0.875rem' }}
                    >
                      Payment instalments
                    </span>
                    <span
                      className="d-block"
                      style={{ color: '#79716B', fontSize: '0.875rem' }}
                    >
                      Allow your customers to make payments over time by creating multiple
                      instalments for this invoice.
                    </span>

                    {isInstalment && (
                      <div className="mt-3 w-60 pb-1 fade-in">
                        <CustomSelect
                          classNamePrefix="invoice-select"
                          placeholder="2 instalments"
                          name="account"
                          options={instalmentOptions}
                          onChange={(val) => handleChange(val, 'option')}
                          value={instalmentPayload.option}
                        />
                        <div className="mt-3">
                          <Segmented
                            block
                            value={instalmentPayload.type}
                            options={typeOptions}
                            onChange={(value) => {
                              handleSwitch(value);
                            }}
                          />

                          {payments.map((_, index) => (
                            <div className="d-flex gap-3 w-100 pt-3" key={index}>
                              <div className="w-100 form-group">
                                {isAmount ? (
                                  <CustomInput
                                    label={
                                      index === 0 &&
                                      capitalizeFirstLetter(instalmentPayload.type)
                                    }
                                    classNamePrefix="invoice-select"
                                    name="amount"
                                    placeholder="₦0.00"
                                    isAmount
                                    otherCurrency={false}
                                    useCurrency={false}
                                    onBlur={() => checkValidity(index)}
                                    onChange={(e) => handleInput(e, index, 'amount')}
                                    value={payments[index].amount}
                                  />
                                ) : (
                                  <div>
                                    {index === 0 && (
                                      <label
                                        className="form-label custom"
                                        style={{ marginBottom: '4px !important' }}
                                      >
                                        {capitalizeFirstLetter(instalmentPayload.type)}
                                      </label>
                                    )}
                                    <CustomPercentageInput
                                      className="invoice-select"
                                      currency={getCurrency(values?.currency?.value)}
                                      subValue={(
                                        (+payments[index].amount / 100) *
                                        totalAmount
                                      ).toFixed(2)}
                                      placeholder="0%"
                                      name="amount"
                                      onBlur={() => checkValidity(index)}
                                      onChange={(e) => handleInput(e, index, 'amount')}
                                      value={payments[index].amount}
                                    />
                                  </div>
                                )}
                              </div>

                              <div className="w-100 form-group">
                                <CustomDatePicker
                                  id="invoice-date"
                                  label={index === 0 && 'Due date'}
                                  onChange={(val) => handleInput(val, index, 'dueDate')}
                                  minDate={handleDateValidity(index)}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  placeholderText="DD / MM / YYYY"
                                  selected={payments[index].dueDate}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                        <span className="d-block pt-3 instalment">
                          Request{' '}
                          <span style={{ color: '#1C1917', fontWeight: 500 }}> 100%</span>{' '}
                          of{' '}
                          <span>
                            {' '}
                            <CurrencyFormat
                              prefix={getCurrency(values?.currency?.value)}
                              value={convertNaNToZero(totalAmount)}
                              displayType="text"
                              thousandSeparator={true}
                              isNumericString
                            />
                          </span>{' '}
                          invoice balance over {instalmentPayload?.option?.value}{' '}
                          payments.
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </section>

              <div className="w-55 ">
                <CustomSelectRadio
                  blurInputOnSelect
                  classNamePrefix="invoice-select"
                  label="Account to credit"
                  name="budget"
                  placeholder="Select a source"
                  hasRadio={false}
                  onChange={(val) => getAccount(val)}
                  value={values.account}
                  isLoading={loadingBalances}
                  isDisabled={loadingBalances}
                  loadOptions={loadOptions}
                />
              </div>
              <div className="border-top mt-4 d-flex gap-3">
                <div className="w-55 pt-4">
                  <p className="form-label">
                    Notes{' '}
                    <span style={{ color: '#79716B', fontWeight: 400 }}>(Optional)</span>{' '}
                  </p>
                  <CustomTextarea
                    placeholder="Thanks for doing business with us!"
                    rowSize={6}
                    name="description"
                    value={text || values?.description}
                    onChange={getDescription}
                    maxLength={max_length}
                    showCounter={true}
                    charCount={charCount}
                  />
                </div>
                <div className="pt-4 invoice-attachment overflow-x-scroll">
                  <NewReceiptUpload
                    label={
                      <p className="form-label mb-2">
                        Attachments
                        <span style={{ color: '#79716B', fontWeight: 400 }}>
                          (Optional)
                        </span>
                      </p>
                    }
                    receiptAssets={[]}
                    viewImgUrl={viewImgUrl}
                    addMore={true}
                    removeFile={true}
                    acceptedFile={transactionAcceptedFiles}
                    onChange={setAttachments}
                  />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default FooterRow;
