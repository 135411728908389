import {
  Document,
  Font,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from 'components/UIHooks/JsPDF';
import { format } from 'date-fns';
import { numFormatter } from '../../utils/utility';

// Register font
Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    width: '100%',
  },
  dFlex: {
    display: 'flex',
  },
  topSectionContainer: {
    padding: '20px 50px 69px 50px',
  },
  flexCol: {
    flexDirection: 'column',
  },
  flexRow: {
    flexDirection: 'row',
  },
  bg2: {
    backgroundColor: '#F9F1EC',
  },
  mainContainer: {
    backgroundColor: 'rgba(242, 244, 241, 0.5)',
    borderRadius: 8,
    padding: 25,
  },
  mainContainerTitle: {
    color: '#586068',
    fontSize: 12,
    marginBottom: 5,
  },
  noMargin: {
    marginBottom: 0,
  },
  title: {
    color: '#000',
    fontSize: 12,
    marginBottom: 5,
  },
  mainContainerBodyText: {
    color: '#000',
    fontSize: 12,
  },
  maxWidth: {
    maxWidth: '80%',
  },
  image: {
    width: 150,
    height: 150,
  },
  rowStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  },
  rightAlign: {
    float: 'right',
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
});

const getCurrencySymbol = (val) => {
  switch (val) {
    case 'NGN':
      return '₦';
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
  }
};

const ReceiptPdf = ({ data, companyData, type }) => {
  const date = new Date().getFullYear();

  const isBudgetDownloadable =
    type === 'budget' && !!Object.keys(data?.transaction || {}).length;

  return (
    <Document style={{ width: '100%', height: '100vh', margin: 0, padding: 0 }}>
      <Page style={styles.page}>
        <View style={[styles.dFlex, styles.topSectionContainer]}>
          <View style={[styles.dFlex]}>
            <Image
              src={require('../../assets/logos/blackLogo.png')}
              style={{ width: 50 }}
            />
            <Text
              style={{
                position: 'absolute',
                top: 10,
                right: 20,
                lineHeight: 40,
                fontSize: 14,
              }}
            >
              Transaction Receipt
            </Text>
          </View>
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 19,
              paddingBottom: 20,
            }}
          >
            <Text style={{ fontSize: 12 }}>
              Generated on Bujeti on{' '}
              {data?.created_at
                ? format(new Date(data.created_at), 'dd MMM, yyyy - h:mma')
                : '-'}
            </Text>
          </View>
          <View style={[styles.mainContainer, styles.dFlex]}>
            <View
              style={{
                borderBottom: '1px solid #E5E8E3',
                paddingTop: 8,
                paddingBottom: 10,
              }}
            >
              <View
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
              >
                <Text style={[styles.mainContainerTitle, styles.noMargin]}>To</Text>
                <Text style={{ paddingLeft: 4, fontSize: 12 }}>
                  {' '}
                  {type === 'budget'
                    ? data?.paidTo
                    : data?.recipient?.name ??
                      `${data?.recipient?.firstName} ${data?.recipient?.lastName}`}
                </Text>
              </View>

              <View
                style={{
                  marginTop: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Text
                  style={[
                    styles.mainContainerTitle,
                    styles.noMargin,
                    { paddingRight: 4 },
                  ]}
                >
                  Amount
                </Text>
                {data?.currency === 'NGN' && (
                  <Image
                    src={require('../../assets/icons/naira.png')}
                    style={{ width: 15 }}
                  />
                )}

                <Text
                  style={{
                    fontSize: 14,
                    color: '#000',
                  }}
                >
                  {data?.currency === 'USD' && '$'}
                  {numFormatter(data?.amount / 100)}
                </Text>
              </View>

              {data?.description && (
                <View
                  style={{
                    marginTop: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={[
                      styles.mainContainerTitle,
                      styles.noMargin,

                      { paddingRight: 4 },
                    ]}
                  >
                    Description
                  </Text>

                  <Text
                    style={{
                      fontSize: 14,
                      color: '#000',
                      fontSize: 12,
                    }}
                  >
                    {data?.description}
                  </Text>
                </View>
              )}
            </View>

            {/** Payer's information */}
            <View
              style={{
                paddingTop: 15,
                borderBottom: '1px solid #E5E8E3',
                paddingBottom: 15,
              }}
            >
              <Text style={[styles.title]}>Payer Information</Text>
              <View
                style={{
                  paddingTop: 15,
                }}
              >
                <View style={[styles.rowStyle]}>
                  <View>
                    <Text style={[styles.mainContainerTitle]}>Legal Name</Text>
                    <Text style={[styles.mainContainerBodyText]}>
                      {companyData?.name}
                    </Text>
                  </View>

                  {data?.senderAccount && (
                    <View>
                      <Text style={[styles.mainContainerTitle, styles.rightAlign]}>
                        Bank Name
                      </Text>
                      <Text style={[styles.mainContainerBodyText, styles.rightAlign]}>
                        {data?.senderAccount?.bankName}
                      </Text>
                    </View>
                  )}
                </View>
                <View style={[styles.rowStyle]}>
                  {/* Commenting out just incase we need to add again - 9/07/2024 */}

                  {/* <View>
                      <Text style={[styles.mainContainerTitle]}>Address:</Text>
                      <Text style={[styles.mainContainerBodyText, styles.maxWidth]}>
                        {companyData?.address?.street} {companyData?.address?.state}
                      </Text>
                    </View> */}

                  <View>
                    <Text style={[styles.mainContainerTitle]}>Paid On</Text>
                    <Text style={[styles.mainContainerBodyText]}>
                      {data?.paidOn ? format(new Date(data.paidOn), 'dd MMM, yyyy') : '-'}
                    </Text>
                  </View>

                  {data?.senderAccount && (
                    <View>
                      <Text style={[styles.mainContainerTitle, styles.rightAlign]}>
                        Account Number
                      </Text>
                      <Text style={[styles.mainContainerBodyText, styles.rightAlign]}>
                        {data?.senderAccount?.number.substring(0, 3)}••••
                        {data?.senderAccount?.number.substring(
                          data?.senderAccount?.number - 3,
                        )}
                      </Text>
                    </View>
                  )}
                </View>
              </View>
            </View>

            {/** Account Details */}
            <View
              style={{
                paddingTop: 15,
              }}
            >
              {isBudgetDownloadable || data?.bank_account?.bankName ? (
                <Text style={[styles.title]}>{`Receiver's Details`} </Text>
              ) : null}
              <View
                style={{
                  paddingTop: 15,
                }}
              >
                {isBudgetDownloadable || data?.bank_account?.bankName ? (
                  <View style={[styles.rowStyle]}>
                    <View>
                      <Text style={[styles.mainContainerTitle]}>Bank Name</Text>

                      <Text style={[styles.mainContainerBodyText]}>
                        {isBudgetDownloadable
                          ? data?.transaction?.bank_account?.bankName
                          : data?.bank_account?.bankName}
                      </Text>
                    </View>

                    <View style={{ textAlign: 'right' }}>
                      <Text style={[styles.mainContainerTitle, styles.rightAlign]}>
                        Account Number
                      </Text>
                      <Text style={[styles.mainContainerBodyText, styles.rightAlign]}>
                        {isBudgetDownloadable
                          ? data?.transaction?.bank_account?.number
                          : data?.bank_account?.number}
                      </Text>
                    </View>
                  </View>
                ) : null}

                <View style={[styles.rowStyle]}>
                  <View>
                    <Text style={[styles.mainContainerTitle]}>Reference ID</Text>
                    <Text style={[styles.mainContainerBodyText]}>{data?.code}</Text>
                  </View>
                </View>
              </View>
              {/* <View style={{ paddingTop: 40 }}>
                <Text style={[styles.mainContainerTitle]}>Address</Text>
                <Text style={[styles.mainContainerBodyText]}>
                  {''}
                </Text>
              </View> */}
            </View>
          </View>
        </View>

        <View style={[styles.section, styles.dFlex, styles.bg2, { padding: 28 }]}>
          <View>
            <Text
              style={{
                fontSize: 12,
                marginBottom: 8,
                color: '#000000',
              }}
            >
              bujeti.com
            </Text>
            <Text style={{ fontSize: 12, color: '#586068' }}>
              &copy; Bujeti Ltd {date}, All Rights Reserved
            </Text>
          </View>
          <View
            style={{
              paddingTop: 8,
              display: 'flex',
              width: '100%',
              position: 'relative',
            }}
          >
            <Image
              src={require('../../assets/logos/blackLogo.png')}
              style={{ width: 56.25 }}
            />
            <Text
              style={{
                fontSize: 10,
                color: '#000',
                position: 'absolute',
                top: 15,
                left: 65,
              }}
            >
              The right tool for your business to smart spending and budgeting needs
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export const PDF = () => (
  <PDFViewer>
    <ReceiptPdf />
  </PDFViewer>
);

export default ReceiptPdf;
