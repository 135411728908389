export const CREATE_CARD_REQUEST = 'CREATE_CARD_REQUEST';
export const CREATE_CARD_SUCCESS = 'CREATE_CARD_SUCCESS';
export const CREATE_CARD_ERROR = 'CREATE_CARD_ERROR';

export const REASSIGN_CARD_REQUEST = 'REASSIGN_CARD_REQUEST';
export const REASSIGN_CARD_SUCCESS = 'REASSIGN_CARD_SUCCESS';
export const REASSIGN_CARD_ERROR = 'REASSIGN_CARD_ERROR';

export const GET_CARD_REQUEST = 'GET_CARD_REQUEST';
export const GET_CARD_SUCCESS = 'GET_CARD_SUCCESS';
export const GET_CARD_ERROR = 'GET_CARD_ERROR';

export const GET_MY_CARDS_REQUEST = 'GET_MY_CARDS_REQUEST';
export const GET_MY_CARDS_SUCCESS = 'GET_MY_CARDS_SUCCESS';
export const GET_MY_CARDS_ERROR = 'GET_MY_CARDS_ERROR';

export const GET_SELECTED_CARD_REQUEST = 'GET_SELECTED_CARD_REQUEST';
export const GET_SELECTED_CARD_SUCCESS = 'GET_SELECTED_CARD_SUCCESS';
export const GET_SELECTED_CARD_ERROR = 'GET_SELECTED_CARD_ERROR';

export const DEACTIVATE_CARD_REQUEST = 'DEACTIVATE_CARD_REQUEST';
export const DEACTIVATE_CARD_SUCCESS = 'DEACTIVATE_CARD_SUCCESS';
export const DEACTIVATE_CARD_ERROR = 'DEACTIVATE_CARD_ERROR';

export const DELETE_CARD_REQUEST = 'DELETE_CARD_REQUEST';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_ERROR = 'DELETE_CARD_ERROR';

export const DELETE_CARD_REQUEST_REQUEST = 'DELETE_CARD_REQUEST_REQUEST';
export const DELETE_CARD_REQUEST_SUCCESS = 'DELETE_CARD_REQUEST_SUCCESS';
export const DELETE_CARD_REQUEST_ERROR = 'DELETE_CARD_REQUEST_ERROR';

export const GET_SINGLE_CARD_REQUEST = 'GET_SINGLE_CARD_REQUEST';
export const GET_SINGLE_CARD_SUCCESS = 'GET_SINGLE_CARD_SUCCESS';
export const GET_SINGLE_CARD_ERROR = 'GET_SINGLE_CARD_ERROR';

export const FREEZE_UNFREEZE_CARD_REQUEST = 'FREEZE_UNFREEZE_CARD_REQUEST';
export const FREEZE_UNFREEZE_CARD_SUCCESS = 'FREEZE_UNFREEZE_CARD_SUCCESS';
export const FREEZE_UNFREEZE_CARD_ERROR = 'FREEZE_UNFREEZE_CARD_ERROR';

export const LOAD_UNLOAD_CARD_REQUEST = 'LOAD_UNLOAD_CARD_REQUEST';
export const LOAD_UNLOAD_CARD_SUCCESS = 'LOAD_UNLOAD_CARD_SUCCESS';
export const LOAD_UNLOAD_CARD_ERROR = 'LOAD_UNLOAD_CARD_ERROR';

export const ACTIVATE_PHYSICAL_CARD_REQUEST = 'ACTIVATE_PHYSICAL_CARD_REQUEST';
export const ACTIVATE_PHYSICAL_CARD_SUCCESS = 'ACTIVATE_PHYSICAL_CARD_SUCCESS';
export const ACTIVATE_PHYSICAL_CARD_ERROR = 'ACTIVATE_PHYSICAL_CARD_ERROR';

export const PHYSICAL_CARD_REQUEST = 'PHYSICAL_CARD_REQUEST';
export const PHYSICAL_CARD_REQUEST_SUCCESS = 'PHYSICAL_CARD_REQUEST_SUCCESS';
export const PHYSICAL_CARD_REQUEST_ERROR = 'PHYSICAL_CARD_REQUEST_ERROR';

export const TOPUP_CARD_REQUEST = 'TOPUP_CARD_REQUEST';
export const TOPUP_CARD_SUCCESS = 'TOPUP_CARD_SUCCESS';
export const TOPUP_CARD_ERROR = 'TOPUP_CARD_ERROR';

export const SET_NEW_PIN_REQUEST = 'SET_NEW_PIN_REQUEST';
export const SET_NEW_PIN_SUCCESS = 'SET_NEW_PIN_SUCCESS';
export const SET_NEW_PIN_ERROR = 'SET_NEW_PIN_ERROR';

export const GET_REQUESTED_CARDS_REQUEST = 'GET_REQUESTED_CARDS_REQUEST';
export const GET_REQUESTED_CARDS_SUCCESS = 'GET_REQUESTED_CARDS_SUCCESS';
export const GET_REQUESTED_CARDS_ERROR = 'GET_REQUESTED_CARDS_ERROR';

export const GET_DEFAULT_PIN_REQUEST = 'GET_DEFAULT_PIN_REQUEST';
export const GET_DEFAULT_PIN_SUCCESS = 'GET_DEFAULT_PIN_SUCCESS';
export const GET_DEFAULT_PIN_ERROR = 'GET_DEFAULT_PIN_ERROR';

export const GET_MCCS_REQUEST = 'GET_MCCS_REQUEST';
export const GET_MCCS_SUCCESS = 'GET_MCCS_SUCCESS';
export const GET_MCCS_ERROR = 'GET_MCCS_ERROR';

export const UPDATE_CARD_SETTINGS_REQUEST = 'UPDATE_CARD_SETTINGS_REQUEST';
export const UPDATE_CARD_SETTINGS_SUCCESS = 'UPDATE_CARD_SETTINGS_SUCCESS';
export const UPDATE_CARD_SETTINGS_ERROR = 'UPDATE_CARD_SETTINGS_ERROR';

export const GET_REQUESTED_CARD_REQUEST = 'GET_REQUESTED_CARD_REQUEST';
export const GET_REQUESTED_CARD_SUCCESS = 'GET_REQUESTED_CARD_SUCCESS';
export const GET_REQUESTED_CARD_ERROR = 'GET_REQUESTED_CARD_ERROR';

export const RESET_BLOCK_CARD = 'RESET_BLOCK_CARD';

export const RESET_FLAGS_CARD = 'RESET_FLAGS_CARD';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getCard: { ...block, data: {} },
  getMyCards: { ...block, data: {} },
  getSelectedCard: { ...block },
  createCard: { ...block },
  deactivateCard: { ...block },
  deleteCard: { ...block },
  deleteCardRequest: { ...block },
  getSingleCard: { ...block },
  freezeUnfreezeCard: { ...block },
  loadUnloadCard: { ...block },
  topUpCard: { ...block },
  setCardPin: { ...block },
  activatePhysicalCard: { ...block },
  physicalCardRequest: { ...block },
  getRequestedCards: { ...block },
  getRequestedCard: { ...block },
  getDefaultPin: { ...block },
  getMccs: { ...block },
  updateCardSettings: { ...block },
  reassignCard: { ...block },
};

export const CardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CARD_REQUEST:
      return { ...state, getCard: { ...state.getCard, loading: true } };
    case GET_CARD_SUCCESS:
      return {
        ...state,
        getCard: {
          ...state.getCard,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_CARD_ERROR:
      return {
        ...state,
        getCard: { ...state.getCard, loading: false, error: action.error },
      };

    case GET_MY_CARDS_REQUEST:
      return { ...state, getMyCards: { ...state.getMyCards, loading: true } };
    case GET_MY_CARDS_SUCCESS:
      return {
        ...state,
        getMyCards: {
          ...state.getMyCards,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_MY_CARDS_ERROR:
      return {
        ...state,
        getMyCards: { ...state.getMyCards, loading: false, error: action.error },
      };

    case GET_REQUESTED_CARDS_REQUEST:
      return {
        ...state,
        getRequestedCards: { ...state.getRequestedCards, loading: true },
      };
    case GET_REQUESTED_CARDS_SUCCESS:
      return {
        ...state,
        getRequestedCards: {
          ...state.getRequestedCards,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_REQUESTED_CARDS_ERROR:
      return {
        ...state,
        getRequestedCards: {
          ...state.getRequestedCards,
          loading: false,
          error: action.error,
        },
      };

    case GET_REQUESTED_CARD_REQUEST:
      return {
        ...state,
        getRequestedCard: { ...state.getRequestedCard, loading: true },
      };
    case GET_REQUESTED_CARD_SUCCESS:
      return {
        ...state,
        getRequestedCard: {
          ...state.getRequestedCard,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_REQUESTED_CARD_ERROR:
      return {
        ...state,
        getRequestedCard: {
          ...state.getRequestedCard,
          loading: false,
          error: action.error,
        },
      };

    case GET_SELECTED_CARD_REQUEST:
      return { ...state, getSelectedCard: { ...state.getSelectedCard, loading: true } };
    case GET_SELECTED_CARD_SUCCESS:
      return {
        ...state,
        getSelectedCard: {
          ...state.getSelectedCard,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SELECTED_CARD_ERROR:
      return {
        ...state,
        getSelectedCard: {
          ...state.getSelectedCard,
          loading: false,
          error: action.error,
        },
      };

    case CREATE_CARD_REQUEST:
      return { ...state, createCard: { ...state.createCard, loading: true } };
    case CREATE_CARD_SUCCESS:
      return {
        ...state,
        createCard: {
          ...state.createCard,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case CREATE_CARD_ERROR:
      return {
        ...state,
        createCard: { ...state.createCard, loading: false, error: action.error },
      };

    case DEACTIVATE_CARD_REQUEST:
      return { ...state, deactivateCard: { ...state.deactivateCard, loading: true } };
    case DEACTIVATE_CARD_SUCCESS:
      return {
        ...state,
        deactivateCard: {
          ...state.deactivateCard,
          loading: false,
          success: true,
          error: '',
        },
      };
    case DEACTIVATE_CARD_ERROR:
      return {
        ...state,
        deactivateCard: { ...state.deactivateCard, loading: false, error: action.error },
      };

    case DELETE_CARD_REQUEST:
      return { ...state, deleteCard: { ...state.deleteCard, loading: true } };
    case DELETE_CARD_SUCCESS:
      return {
        ...state,
        deleteCard: {
          ...state.deleteCard,
          loading: false,
          success: true,
          error: '',
        },
      };
    case DELETE_CARD_ERROR:
      return {
        ...state,
        deleteCard: { ...state.deleteCard, loading: false, error: action.error },
      };

    case DELETE_CARD_REQUEST_REQUEST:
      return {
        ...state,
        deleteCardRequest: { ...state.deleteCardRequest, loading: true },
      };
    case DELETE_CARD_REQUEST_SUCCESS:
      return {
        ...state,
        deleteCardRequest: {
          ...state.deleteCardRequest,
          loading: false,
          success: true,
          error: '',
        },
      };
    case DELETE_CARD_REQUEST_ERROR:
      return {
        ...state,
        deleteCardRequest: {
          ...state.deleteCardRequest,
          loading: false,
          error: action.error,
        },
      };

    case GET_SINGLE_CARD_REQUEST:
      return { ...state, getSingleCard: { ...state.getSingleCard, loading: true } };
    case GET_SINGLE_CARD_SUCCESS:
      return {
        ...state,
        getSingleCard: {
          ...state.getSingleCard,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_SINGLE_CARD_ERROR:
      return {
        ...state,
        getSingleCard: { ...state.getSingleCard, loading: false, error: action.error },
      };

    case FREEZE_UNFREEZE_CARD_REQUEST:
      return {
        ...state,
        freezeUnfreezeCard: { ...state.freezeUnfreezeCard, loading: true },
      };
    case FREEZE_UNFREEZE_CARD_SUCCESS:
      return {
        ...state,
        freezeUnfreezeCard: {
          ...state.freezeUnfreezeCard,
          loading: false,
          success: true,
          error: '',
        },
      };
    case FREEZE_UNFREEZE_CARD_ERROR:
      return {
        ...state,
        freezeUnfreezeCard: {
          ...state.freezeUnfreezeCard,
          loading: false,
          error: action.error,
        },
      };

    case LOAD_UNLOAD_CARD_REQUEST:
      return {
        ...state,
        loadUnloadCard: { ...state.loadUnloadCard, loading: true },
      };
    case LOAD_UNLOAD_CARD_SUCCESS:
      return {
        ...state,
        loadUnloadCard: {
          ...state.loadUnloadCard,
          loading: false,
          success: true,
          error: '',
        },
      };
    case LOAD_UNLOAD_CARD_ERROR:
      return {
        ...state,
        loadUnloadCard: {
          ...state.loadUnloadCard,
          loading: false,
          error: action.error,
        },
      };

    case TOPUP_CARD_REQUEST:
      return {
        ...state,
        topUpCard: { ...state.topUpCard, loading: true },
      };
    case TOPUP_CARD_SUCCESS:
      return {
        ...state,
        topUpCard: {
          ...state.topUpCard,
          loading: false,
          success: true,
          error: '',
        },
      };
    case TOPUP_CARD_ERROR:
      return {
        ...state,
        topUpCard: {
          ...state.topUpCard,
          loading: false,
          error: action.error,
        },
      };

    case SET_NEW_PIN_REQUEST:
      return {
        ...state,
        setCardPin: { ...state.setCardPin, loading: true },
      };
    case SET_NEW_PIN_SUCCESS:
      return {
        ...state,
        setCardPin: {
          ...state.setCardPin,
          loading: false,
          success: true,
          error: '',
        },
      };
    case SET_NEW_PIN_ERROR:
      return {
        ...state,
        setCardPin: {
          ...state.setCardPin,
          loading: false,
          error: action.error,
        },
      };

    case ACTIVATE_PHYSICAL_CARD_REQUEST:
      return {
        ...state,
        activatePhysicalCard: { ...state.activatePhysicalCard, loading: true },
      };
    case ACTIVATE_PHYSICAL_CARD_SUCCESS:
      return {
        ...state,
        activatePhysicalCard: {
          ...state.activatePhysicalCard,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case ACTIVATE_PHYSICAL_CARD_ERROR:
      return {
        ...state,
        activatePhysicalCard: {
          ...state.activatePhysicalCard,
          loading: false,
          error: action.error,
        },
      };

    case PHYSICAL_CARD_REQUEST:
      return {
        ...state,
        physicalCardRequest: { ...state.physicalCardRequest, loading: true },
      };
    case PHYSICAL_CARD_REQUEST_SUCCESS:
      return {
        ...state,
        physicalCardRequest: {
          ...state.physicalCardRequest,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case PHYSICAL_CARD_REQUEST_ERROR:
      return {
        ...state,
        physicalCardRequest: {
          ...state.physicalCardRequest,
          loading: false,
          error: action.error,
        },
      };

    case GET_DEFAULT_PIN_REQUEST:
      return {
        ...state,
        getDefaultPin: { ...state.getDefaultPin, loading: true },
      };
    case GET_DEFAULT_PIN_SUCCESS:
      return {
        ...state,
        getDefaultPin: {
          ...state.getDefaultPin,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_DEFAULT_PIN_ERROR:
      return {
        ...state,
        getDefaultPin: {
          ...state.getDefaultPin,
          loading: false,
          error: action.error,
        },
      };

    case GET_MCCS_REQUEST:
      return {
        ...state,
        getMccs: { ...state.getMccs, loading: true },
      };
    case GET_MCCS_SUCCESS:
      return {
        ...state,
        getMccs: {
          ...state.getMccs,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_MCCS_ERROR:
      return {
        ...state,
        getMccs: {
          ...state.getMccs,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_CARD_SETTINGS_REQUEST:
      return {
        ...state,
        updateCardSettings: { ...state.updateCardSettings, loading: true },
      };
    case UPDATE_CARD_SETTINGS_SUCCESS:
      return {
        ...state,
        updateCardSettings: {
          ...state.updateCardSettings,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case UPDATE_CARD_SETTINGS_ERROR:
      return {
        ...state,
        updateCardSettings: {
          ...state.updateCardSettings,
          loading: false,
          error: action.error,
        },
      };

    case REASSIGN_CARD_REQUEST:
      return {
        ...state,
        reassignCard: { ...state.reassignCard, loading: true },
      };
    case REASSIGN_CARD_SUCCESS:
      return {
        ...state,
        reassignCard: {
          ...state.reassignCard,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case REASSIGN_CARD_ERROR:
      return {
        ...state,
        reassignCard: {
          ...state.reassignCard,
          loading: false,
          error: action.error,
        },
      };

    //reset block with flag and data
    case RESET_BLOCK_CARD:
      return {
        ...state,
        [action.blockType]: {
          ...initialState[action.blockType],
        },
      };

    default:
      return state;
  }
};
