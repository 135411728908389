import pdfFileType from 'assets/images/pdf-filetype-icon.png';
import ConfirmDialog from 'components/ConfirmDialog';
import DeleteDialog from 'components/DeleteDialog';
import ReceiptPdf from 'components/ReceiptPdf';
import CustomTextarea from 'components/UI/CustomTextarea';
import { PDFDownloadLink } from 'components/UIHooks/JsPDF';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useRef, useState } from 'react';
import { Modal as BootstrapModal, Modal, Row, Tabs } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getBeneficiaries, searchRecipients } from 'redux/actions/BeneficiariesAction';
import { deleteCategories, fetchCategories } from 'redux/actions/CategoryAction';
import { getCompanyStats } from 'redux/actions/CompaniesAction';
import {
  approveTransaction,
  cancelTransaction,
  editTransactionById,
  getAllTransactionAsset,
  getSingleTransaction,
  getTransaction,
  requeryTransaction,
  requestTransactionDetails,
  retryTransaction,
} from 'redux/actions/TransactionsAction';
import { RESET_BLOCK_TRANSACTIONS } from 'redux/reducers/TransactionsReducer';
import {
  asciiToHex,
  capitalizeFirstLetter,
  convertNaNToZero,
  getAvailableBalance,
  getCard,
  getColor,
  getCurrency,
  getFormattedDate,
  groupSourceOptions,
  truncateMiddle,
} from 'utils/helper';
import './styles.scss';
import {
  AlertIcon,
  ArrowDownIcon,
  ArrowUpRightIcon,
  BankNoteO1Icon,
  CancelICon,
  ChevronDown,
  CopyIcon,
  DownloadIcon,
  EyeIcon,
  MailIcon,
  PencilIcon,
  RetryIcon,
  SendIcon,
  UploadIcon,
  WarningIcon,
} from 'assets/icons';
import bankIcon from 'assets/icons/bank-icon2.svg';
import BanksIcons from 'assets/icons/banks';
import { Tab } from 'bootstrap';
import classNames from 'classnames';
import BujetiPayModal from 'components/bujetiPayModal';
import Timeline from 'components/Timeline';
import CustomButton from 'components/UI/CustomButton';
import NewReceiptUpload from 'components/UI/CustomDrawer/components/NewReceiptUpload';
import PaymentHeader from 'components/UI/CustomDrawer/components/PaymentHeader';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import ImgCard from 'components/UI/ImgCard';
import Loading from 'components/UI/Loading';
import ModalHeader from 'components/UI/Modal/ModalHeader';
import CustomPopover from 'components/UI/Popover';
import BadgeType from 'components/UI/Table/BadgeType';
import { toastError, toastSuccess } from 'components/UI/toast';
import ApprovalModal from 'pages/Approvals/components/ApprovalModal';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { reviewRequest } from 'redux/actions/ApprovalAction';
import { getBalances, getBudgets } from 'redux/actions/BudgetsAction';
import { syncAccountingEntity } from 'redux/actions/IntegrationsActions';
import { payNowAction } from 'redux/actions/PaymentAction';
import { getAllPolicyAsset } from 'redux/actions/PoliciesAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { ReactComponent as ReloadIcon } from '../../assets/icons/retry-icon.svg';
import { DeclineStateComponent } from './DeclineApproval';
import ItemInfo from './itemInfo';
import SchedulePayment from './SchedulePayment';
import { scrollHook } from 'components/UI/CustomDrawer/components/scroll-hook';
import { PaymentSkeleton } from 'components/UI/CustomDrawer/components/payment-skeleton';
import ModalContainerWrap from 'components/UI/Modal/ModalContainerWrap';
import LoadingReciept from 'components/UI/CustomDrawer/components/LoadingReciept';

const initailState = {
  recipientName: '',
  description: undefined,
  payer: '',
  category: '',
};

const CARDS_TYPES = {
  physical: 'Physical',
  virtual: 'Virtual',
  flash: 'Flash',
};

const createOption = (label) => ({
  label,
  value: label,
});

const TransactionDetails = ({
  selectTransaction,
  setSelectTransaction,
  setSelectedAsset,
  setAssetData,
}) => {
  if (!selectTransaction) return;

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const locationArray = location.pathname.split('/')[1];
  const { transactionCode: transactionUrlCode } = useParams();
  const parentRef = useRef(null); // Create a ref for the parent element

  const [receiptCode, setReceiptCode] = useState('');

  const {
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);

  const { user: { data: user = {} } = {} } = useSelector(({ auth }) => auth);

  const {
    fetchCategories: { data: categoryData },
    createCategories: { data, success },
    deleteCategories: { success: successDelete, loading: loadingDelete },
  } = useSelector(({ categories }) => categories);

  const {
    policyAssets: { data: policyAssets },
  } = useSelector(({ policies }) => policies);

  const {
    reviewRequest: { loading: loadingReview, success: successReview },
  } = useSelector(({ approval }) => approval);

  const {
    editTransactionById: {
      loading: isEditing,
      success: isEditSuccess,
      error: isEditError,
    },
    retryTransaction: { loading: retryLoading, success: retrySuccess },
    cancelTransaction: { loading: cancelLoading, success: cancelSuccess },
    getSingleTransaction: {
      data: singleTransaction = {},
      loading: singleLoading,
      success: singleSuccess,
      error: singleError,
    },
    transactionReceipts: {
      data: receiptAssets,
      loading: loadingReceipt,
      success: receiptSuccess,
    },
    approveTransaction: { loading: approveLoading, success: approveSuccess },
    requeryTransaction: { loading: loadingRequery, success: successRequery },
    requestTransactionDetails: { loading: sendingDetails, success: detailsSent },
  } = useSelector(({ transaction }) => transaction);

  const {
    payNowAction: { loading: loadingPayNow, success: successPayNow },
  } = useSelector(({ payments }) => payments);

  const {
    searchRecipients: { data: searchRecipient = [] },
    getBeneficiaries: { data: beneficiariesData = {} },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const {
    getBudget: { data: budgetData = {} },
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);
  const { budgets = [] } = budgetData;

  const { beneficiaries = [], meta = {} } = beneficiariesData;

  const [categoryVisible, setCategoryVisible] = useState(false);
  const [budgetVisible, setBudgetVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [showCategoryDeleteModal, setShowCategoryDeleteModal] = useState(false);
  const [description, setDescription] = useState({
    visible: false,
    data: '',
    isEditingDescription: false,
  });
  const [saveButton, setSaveButton] = useState(false);
  const [expenseCategories, setExpenseCategories] = useState({
    value: '',
  });

  const [ruleCode, setRuleCode] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [dialogTitle, setDialogTitle] = useState();
  const [paymentData, setPaymentData] = useState(initailState);
  const [options, setOptions] = useState([]);
  const [assetType, setAssetType] = useState('receipt');
  const [createable, setCreateable] = useState({
    isLoading: false,
    options: [],
    value: undefined,
  });
  const [budgetLoading, setBudgetLoading] = useState(false);
  const singleData = singleTransaction?.data;

  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 200);
  const { permissions } = allPermissions();
  const canViewBeneficiary = hasPermission({
    permissions,
    scopes: ['employee-*', 'employee-view', 'employee-edit'],
  });
  const [source, setSource] = useState();
  const [payNow, setPayNow] = useState(true);
  const [approve, setIsApproving] = useState(false);
  const [hasApproved, setHasApproved] = useState(false);
  const [declineState, setDeclineState] = useState(false);

  const [assetViewer, setAssetViewer] = useState(false);
  const [showSendFunds, setShowSendFunds] = useState(false);
  const [recipientCode, setRecipientCode] = useState('');
  const handleTogglePopover = () => setIsPopoverOpen(!isPopoverOpen);

  const canEditTransaction = hasPermission({
    permissions,
    scopes: ['transaction-edit', 'transaction-*'],
  });

  const canViewPolicy = hasPermission({
    permissions,
    scopes: ['policy-view'],
  });

  const canViewBudget = hasPermission({
    permissions,
    scopes: ['budget-view'],
  });

  const canViewDash = hasPermission({
    permissions,
    scopes: ['dash-view'],
  });

  const policyRequirements = (requirements) => {
    let requirementList = '';
    let index = 1;
    let requirementsLength = Object.keys(requirements).length;
    for (let requirement in requirements) {
      requirementList +=
        index === requirementsLength
          ? `${requirement}.`
          : index === requirementsLength - 1
          ? `${requirement}, and `
          : `${requirement}, `;
      index++;
    }
    return requirementList;
  };

  useEffect(() => {
    if (!budgets?.length && canViewBudget) dispatch(getBudgets({ status: 'active' }));
  }, []);

  useEffect(() => {
    if (
      !transactionUrlCode &&
      !singleLoading &&
      selectTransaction?.transactionData?.code
    ) {
      dispatch(getSingleTransaction(selectTransaction?.transactionData?.code));
    }
  }, [selectTransaction]);

  useEffect(() => {
    if (singleData?.description || selectTransaction?.description) {
      setDescription({
        ...description,
        data: singleData?.description ?? selectTransaction?.description,
      });
    }
  }, [singleData, selectTransaction]);

  useEffect(() => {
    if (!balances?.budgets?.length) dispatch(getBalances());
  }, []);

  const [budgetList, setBudgetList] = useState([]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets, 'budgets');
      const available_balance = getAvailableBalance(balances?.balances, 32, false, true);
      setBudgetList((prevOptions) => [...available_balance].concat([...budget]));
    }
  }, [balances?.budgets?.length, balances?.balances?.length]);

  async function loadOptions(search, loadedOptions, { page }) {
    let filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore: false,
      additional: {
        page: page + 1,
      },
    };
  }

  useEffect(() => {
    if (successDelete) setShowCategoryDeleteModal(false);
  }, [successDelete]);

  // Handle Change After Debounce
  useEffect(() => {
    if (debouncedValue.length > 3) dispatch(searchRecipients({ query: debouncedValue }));
  }, [debouncedValue]);

  useEffect(() => {
    const searched = searchRecipient?.recipients?.filter(
      ({ status }) => status === 'active',
    );
    const opt = searched?.map(({ name: label, code: value }) => ({ label, value }));

    if (opt) setOptions(opt);
  }, [searchRecipient]);

  useEffect(() => {
    setCreateable({ ...createable, options: options });
  }, [options]);

  const handleDescriptionChange = (val) => {
    setDescription({
      ...description,
      data: val.target.value,
    });
    setSaveButton(true);
  };

  // Handle Createable
  const handleCreate = (inputValue) => {
    if (inputValue.length < 4) return toastError('Name is too short');
    setCreateable({ isLoading: true });
    setTimeout(() => {
      const { options } = createable;
      const newOption = createOption(inputValue);
      setCreateable({
        value: newOption,
        options: [...options, newOption],
        isLoading: false,
      });
      setPaymentData({ ...paymentData, recipientName: newOption });
    }, 1000);
  };

  const handleSelect = (value) => {
    if (!value)
      return (
        setCreateable({ ...createable, value: undefined }),
        setPaymentData({
          ...paymentData,
          recipientName: value,
        })
      );

    setPaymentData({
      ...paymentData,
      recipientName: value,
    });
    setCreateable({ ...createable, value });
  };

  useEffect(() => {
    if (paymentData.recipientName) {
      const { value } = paymentData.recipientName;
      const data = {
        recipient: value,
        transaction_code: transactionCode,
      };
      dispatch(editTransactionById(data));
      setIsLoading('recipient');
    }
  }, [paymentData.recipientName?.value]);

  useEffect(() => {
    if (paymentData.payer) {
      const { value } = paymentData.payer;
      const data = {
        payer: value,
        transaction_code: transactionCode,
      };
      dispatch(editTransactionById(data));
      setIsLoading('payer');
    }
  }, [paymentData.payer?.value]);

  //Everything that has to do with categories
  useEffect(() => {
    if (!categoryData?.categories?.length) dispatch(fetchCategories());

    if (!beneficiaries.length && canViewBeneficiary) dispatch(getBeneficiaries());
  }, []);

  const { transactionData: { code: transactionCode = '' } = {} } =
    selectTransaction || {};

  const { recipient = {} } = singleTransaction?.data ?? {};

  useEffect(() => {
    if (categoryData) {
      setExpenseCategories({ ...expenseCategories, options: categoryData?.categories });
    }
  }, [categoryData]);

  const onHandleGetSelected = (value) => {
    editTransaction(value.value);
    setCategoryVisible(false);

    setExpenseCategories({ value });

    setPaymentData({
      ...paymentData,
      category: {
        code,
        name: value.name,
      },
    });
  };

  const openRule = (code) => {
    setRuleCode(code);
  };

  const closeApprovalRule = () => setRuleCode(null);

  useEffect(() => {
    if (data?.category?.name)
      setPaymentData({ ...paymentData, category: data?.category?.name });
  }, [success]);

  const editTransaction = (code) => {
    const data = {
      category: code,
      transaction_code: transactionCode,
    };
    dispatch(editTransactionById(data));
    setIsLoading('category');
  };

  const onOpenDeleteModal = (name, code) => {
    setSelectedCategory({
      code,
      name,
    });
    setShowCategoryDeleteModal(true);
  };

  const onHandleDeleteCategory = () => {
    dispatch(deleteCategories(selectedCategory.code));
  };

  useEffect(() => {
    if (receiptCode?.receipt?.length) {
      setSaveButton(true);
    } else setSaveButton(false);
  }, [receiptCode?.receipt]);
  // Ends here

  const handleClose = () => {
    if (!isPopoverOpen && !isDelete) {
      setSelectTransaction(null);
      setCategoryVisible(false);
      setAssetViewer(false);
      setAssetData && setAssetData(null);
      setDescription({
        visible: false,
        data: '',
      });
      setSaveButton(false);
      toggleAssetViewer(false);
      dispatch({ type: RESET_BLOCK_TRANSACTIONS, blockType: 'getSingleTransaction' });
      if (transactionUrlCode) history.push('/transactions/payments');
      if (isEditSuccess) {
        if (locationArray === 'transactions') {
          dispatch(getTransaction());
        } else {
          dispatch(getCompanyStats({ id: user?.user?.company?.code }));
        }
        dispatch({ type: RESET_BLOCK_TRANSACTIONS, blockType: 'editTransactionById' });
      }
      dispatch({ type: RESET_BLOCK_TRANSACTIONS, blockType: 'transactionReceipts' });
    }
  };

  const handleCancelorRetryModal = (type) => {
    setIsDelete(!isDelete);
    setDialogTitle(type);
  };

  const possibleSource =
    singleTransaction?.data?.balance?.code ||
    singleTransaction?.data?.source?.code ||
    singleTransaction?.data?.budget?.code;

  const cancelorRetryTransaction = () => {
    if (dialogTitle === 'Refresh') dispatch(requeryTransaction(transactionCode));
    if (dialogTitle === 'Retry') dispatch(retryTransaction(transactionCode));
    if (dialogTitle === 'Cancel') dispatch(cancelTransaction(transactionCode));
    if (dialogTitle === 'Approve') dispatch(approveTransaction(transactionCode));
    if (dialogTitle === 'Pay') {
      if (possibleSource) {
        return dispatch(payNowAction({ code: transactionCode }));
      } else if (!source) {
        return toastError('Select a source');
      }
      return dispatch(payNowAction({ code: transactionCode, source: source.value }));
    }
  };

  useEffect(() => {
    if (
      retrySuccess ||
      cancelSuccess ||
      successRequery ||
      approveLoading ||
      successPayNow
    ) {
      setIsDelete(false);
      setSelectTransaction(null);
      dispatch({ type: RESET_BLOCK_TRANSACTIONS, blockType: 'getSingleTransaction' });
      if (locationArray !== 'transactions' && canViewDash) {
        dispatch(getCompanyStats({ id: user?.user?.company?.code }));
      }
    }
  }, [retrySuccess, cancelSuccess, successRequery, approveLoading, successPayNow]);

  const paidTo =
    recipient?.name ||
    `${recipient?.firstName ?? ''} ${recipient?.lastName ?? ''}`.trim() ||
    selectTransaction?.paidTo?.value?.props?.name ||
    '';

  const handleDescription = () => {
    setDescription({
      ...description,
      isEditingDescription: true,
    });

    const data = {
      description: description?.data,
      transaction_code: transactionCode,
    };

    if (description?.data !== singleData?.description) {
      dispatch(editTransactionById(data));
    }

    if (!!receiptCode?.receipt?.length) {
      dispatch(
        editTransactionById({
          receipt: receiptCode?.receipt,
          transaction_code: transactionCode,
        }),
      );
      setReceiptCode({
        ...receiptCode,
        isUpdating: true,
      });
    }
  };

  const saveBudget = (budget) => {
    const isAvailableBalance = budget?.value?.startsWith('blc_');
    const isAvailableBackAccount = budget?.value?.startsWith('bnk_');
    const budgetCode =
      isAvailableBalance || isAvailableBackAccount ? undefined : budget.value;

    const data = {
      directDebit:
        !budgetCode && isAvailableBackAccount
          ? {
              bankAccount: budget.value,
            }
          : undefined,
      budget: budgetCode,
      balance: !budgetCode && isAvailableBalance ? budget.value : undefined,
      transaction_code: transactionCode,
    };

    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== undefined),
    );

    setBudgetLoading(true);
    dispatch(editTransactionById(filteredData));
  };

  useEffect(() => {
    if (policyAssets) viewImgUrl(policyAssets[0], 'policy document');
  }, [policyAssets]);

  useEffect(() => {
    if (isEditSuccess && !isEditing && singleData?.code) {
      dispatch(getSingleTransaction(singleData?.code));
      setCategoryVisible(false);
      setDescription({
        ...description,
        visible: false,
        isEditingDescription: false,
      });
      setSaveButton(false);
      setIsLoading(null);
      setBudgetLoading(false);
      dispatch({ type: RESET_BLOCK_TRANSACTIONS, blockType: 'editTransactionById' });
    }
  }, [isEditSuccess, isEditing]);

  useEffect(() => {
    if (!isEditing && isEditError) {
      setBudgetLoading(false);
    }
  }, [isEditing, isEditError]);

  const viewImgUrl = (asset, type) => {
    setAssetType(type);
    setSelectedAsset(asset);
    setAssetViewer(true);
  };

  const toggleAssetViewer = () => {
    setAssetViewer(!assetViewer);
  };

  const paymentMethodSelector = selectTransaction?.paymentMethod;
  const paymentType =
    typeof paymentMethodSelector === 'object'
      ? paymentMethodSelector.type
      : selectTransaction?.paymentMethod?.toLowerCase();

  const isTransaction = !selectTransaction?.isTransaction;
  const isReversal = selectTransaction?.isReversal;
  const isCardTrx = !singleData?.amount && isTransaction;
  const method = selectTransaction?.ledgerData?.transaction?.method
    ? capitalizeFirstLetter(selectTransaction?.ledgerData?.transaction?.method)
    : ['top up', 'withdrawal', 'closure'].includes(paymentType)
    ? capitalizeFirstLetter(selectTransaction?.paymentMethod)
    : `${capitalizeFirstLetter(selectTransaction?.paymentMethod?.type)} ${(
        selectTransaction?.paymentMethod?.value || 'payment'
      )?.toLowerCase()}`;

  const transactionStatus = (
    selectTransaction?.ledgerData?.status ?? singleData?.status
  )?.toLowerCase();

  const approvers = [];

  singleData?.approvalRequest?.approvalStages.forEach((item) => {
    if ([1, 'all'].includes(item.threshold)) {
      approvers.push({
        status: 'pending',
        message: '',
        type: 'any',
        approvers: item?.approvers?.map((stage) => {
          return {
            user: stage.user,
            approver_code: stage.code,
            message: '',
          };
        }),
      });
    } else {
      stages.approvers.forEach((item) => {
        approvers.push({
          status: 'pending',
          message: '',
          type: 'all',
          user: item.user,
        });
      });
    }
  });

  singleData?.approvalRequest?.approvals?.forEach((item) => {
    approvers.push({
      user: item?.approver?.user,
      date: getFormattedDate(item?.updated_at),
      status: item?.status,
      message: item?.reason ?? '',
    });
  });

  let alreadyApprovedUser = null;
  approvers.forEach((item) => {
    if (item.status === 'pending') {
      item.approvers.forEach((approver) => {
        if (approver?.user?.code === user?.user?.code) {
          alreadyApprovedUser = false;
        }
      });
    }
    if (item.status === 'approved' && item?.user.code === user?.user?.code) {
      alreadyApprovedUser = true;
    }
  });

  const hasManager =
    user?.user?.code === singleData?.payer?.code && user?.user?.manager?.code;

  let yourTurnToApprove = null;

  (approvers.length &&
    approvers.forEach((stage) => {
      stage?.approvers?.forEach((item) => {
        if (
          stage.status === 'pending' &&
          item?.user?.code === user?.user?.code &&
          alreadyApprovedUser === false
        ) {
          yourTurnToApprove = true;
        }
      });
    })) ||
    (singleData?.approvalRequest?.status === 'pending' &&
      !singleData?.approvalRequest?.code &&
      !hasManager);

  const checkConditions = (approvers) => {
    // Check if there's only one object with the specified conditions
    const pendingApprovers = approvers.filter((data) => data?.status === 'pending');
    const singleObjectCheck =
      pendingApprovers?.length === 1 &&
      pendingApprovers[0]?.type !== 'all' &&
      pendingApprovers[0]?.approvers &&
      pendingApprovers[0]?.approvers.some(
        (approver) => approver?.user?.code === user?.user?.code,
      );

    // Check if all approvers have the specified user code
    const allApproversCheck = pendingApprovers.every((data) =>
      data?.approvers?.every((approver) => approver?.user?.code === user?.user?.code),
    );

    return singleObjectCheck || allApproversCheck;
  };

  const canApprovePay = checkConditions(approvers) && yourTurnToApprove;

  const [isButtonToggle, setIsButtonToggle] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const ApprovalActions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            setPayNow(false);
            setIsApproving(true);
            handleButtonToggle();
          }}
        >
          Approve only
        </div>

        <div
          className="actionLink"
          onClick={() => {
            if (!singleData?.balance?.code) return toastError('select a source');
            setIsSchedule(true);
            handleButtonToggle();
          }}
        >
          Approve and schedule payment
        </div>
      </div>
    );
  };

  const handleApproveRequest = ({ schedule }) => {
    dispatch(
      reviewRequest({
        code: singleData?.approvalRequest?.code,
        status: 'approved',
        actionLater: schedule ? undefined : !payNow,
        schedule,
      }),
    );
  };

  useEffect(() => {
    if (successReview) {
      setIsApproving(false);
      dispatch(getSingleTransaction(singleData?.code));
    }
  }, [successReview]);
  // approvals end

  const canFetchAsset =
    !singleLoading && singleSuccess && !!singleTransaction?.data?.receipts?.length;

  useEffect(() => {
    if (canFetchAsset) {
      dispatch(getAllTransactionAsset(singleTransaction?.data?.receipts));
    }
    if (!singleLoading && !singleSuccess && singleError) {
      handleClose();
    }
  }, [canFetchAsset]);

  const showFooter =
    isTransaction &&
    !isCardTrx &&
    (['failed', 'cancelled', 'processing'].includes(transactionStatus) ||
      !!singleData?.violations?.length ||
      saveButton === true);

  const handleSyncZoho = (transaction) => {
    dispatch(
      syncAccountingEntity({
        codes: [transaction.code],
        platform: 'zoho',
        element: 'transactions',
      }),
    );
  };

  const Actions = ({ singleData }) => {
    const status = singleData?.status;

    return (
      <div className="actions-dialog">
        {!['approved', 'pending', 'failed', 'success'].includes(status) && (
          <div
            className="actionLink"
            onClick={(event) => {
              setIsPopoverOpen(false);
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            No options available
          </div>
        )}
        {['approved'].includes(status) && (
          <div
            className="actionLink"
            onClick={() => {
              handleTogglePopover();
              handleCancelorRetryModal('Pay');
            }}
          >
            <BankNoteO1Icon style={{ marginLeft: '-4px', marginRight: '6px' }} /> Make
            payment
          </div>
        )}

        {['pending'].includes(status?.toLowerCase()) && (
          <div
            className="actionLink svg-danger text-danger"
            onClick={() => {
              handleTogglePopover();
              handleCancelorRetryModal('Cancel');
            }}
          >
            <CancelICon className="mr-4" width={10} height={10} /> Cancel transaction
          </div>
        )}
        {['failed'].includes(status?.toLowerCase()) && (
          <div
            className="actionLink"
            onClick={() => {
              handleTogglePopover();
              handleCancelorRetryModal('Retry');
            }}
          >
            <RetryIcon stroke="#79716B" width={16} height={16} className="mr-4" />
            Retry transaction
          </div>
        )}
        {status?.toLowerCase() === 'success' && (
          <>
            <div
              className="actionLink ms-1"
              onClick={() => {
                handleTogglePopover();
                setShowSendFunds(true);
                setRecipientCode({
                  code: singleTransaction?.data?.recipient?.code,
                  email: singleTransaction?.data?.recipient?.email,
                  amount: singleTransaction?.data?.amount,
                  source: singleTransaction?.data?.source,
                  category: singleTransaction?.data?.category,
                  bank_account: singleTransaction?.data?.bank_account,
                });
              }}
            >
              <SendIcon stroke="#79716B" width={16} height={16} className="mr-4" /> Make
              new payment
            </div>
            <div
              className="actionLink ms-1"
              onClick={() => {
                handleTogglePopover();
                setShowSendFunds(true);
                setRecipientCode({
                  code: singleTransaction?.data?.recipient?.code,
                  amount: singleTransaction?.data?.amount,
                  source: singleTransaction?.data?.source,
                  category: singleTransaction?.data?.category,
                  bank_account: singleTransaction?.data?.bank_account,
                  repeat: true,
                });
              }}
            >
              <RetryIcon stroke="#79716B" width={16} height={16} className="mr-4" />{' '}
              Repeat transaction
            </div>

            <PDFDownloadLink
              document={
                <ReceiptPdf data={singleTransaction?.data} companyData={companyData} />
              }
              fileName={`Transaction receipt for ${selectTransaction?.description}.pdf`}
              style={{
                textDecoration: 'none',
                height: 40,
                display: 'flex',
                width: '100%',
                fontFamily: 'Inter var !important',
                color: '#212529',
                alignItems: 'center',
              }}
              className="actionLink"
              onClick={() =>
                setTimeout(() => {
                  handleTogglePopover();
                  return toastSuccess('Receipt downloaded');
                }, 1000)
              }
            >
              <DownloadIcon className="mr-4" width={16} height={16} />
              Download receipt
            </PDFDownloadLink>
            {!selectTransaction?.transactionData?.isZohoSynced && (
              <div className="actionLink mr-4" onClick={() => handleSyncZoho(list)}>
                <UploadIcon className="actionLink mr-4" width={16} height={16} />
                Sync to Zoho Books
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const handleUpdateRequest = (code, field, value) => {
    dispatch(
      editTransactionById({
        [field]: value,
        transaction_code: code,
        showSuccessToast: false,
      }),
    );
  };

  const isTopup = paymentType === 'top up';
  const isWithdrawalWithoutCode =
    paymentType === 'withdrawal' && !selectTransaction?.transactionData?.code;

  const moneyData =
    isTopup || isWithdrawalWithoutCode || paymentType === 'closure'
      ? {
          currency: selectTransaction?.ledgerData?.currency,
          amount: Math.abs(selectTransaction?.ledgerData?.amount),
        }
      : {
          currency: singleData?.currency || selectTransaction?.ledgerData?.currency,
          amount: singleData?.amount || selectTransaction?.ledgerData?.amount,
        };

  const cancelDescriptionEdit = () => {
    setDescription({
      ...description,
      visible: false,
    });
    setSaveButton(false);
    setReceiptCode('');
  };

  const {
    bankAccounts = [],
    BankAccounts = [],
    code = '',
    beneficiary = {},
  } = singleTransaction?.data?.recipient ?? {};
  const accounts = !!bankAccounts.length ? bankAccounts : BankAccounts;
  const isVendor = code?.split('_')[0] === 'vdr';
  const isBeneficiary = beneficiary?.code?.split('_')[0] === 'bnf';

  if (ruleCode)
    return (
      <ApprovalModal
        selectedRuleCode={ruleCode}
        isOpen={!!ruleCode}
        isEdit
        handleClose={closeApprovalRule}
        clearSelectedRule={closeApprovalRule}
      />
    );

  const requestCode =
    singleTransaction?.data?.fundRequest?.code ??
    singleTransaction?.data?.reimbursement?.code;

  const isFundReq = requestCode?.startsWith('frq_');

  useEffect(() => {
    if (receiptAssets?.length || policyAssets?.length)
      setAssetData &&
        setAssetData(assetType === 'receipt' ? receiptAssets : policyAssets);
  }, [receiptAssets?.length, policyAssets?.length, assetType]);

  const { scrollSize, fontSize, iconSize, setScrollSize } = scrollHook(parentRef);

  const [key, setKey] = useState('details');

  const handleSelectKey = (tabKey) => {
    setKey(tabKey);
    setScrollSize(0);
  };

  const handleImgError = (e) => {
    e.target.onerror = null;
    e.target.src = bankIcon;
  };

  return (
    <ModalContainerWrap
      parentRef={parentRef}
      isWhite={declineState}
      goBack={declineState ? () => setDeclineState(false) : null}
      modalName={
        declineState
          ? 'Reason for decline'
          : isTopup
          ? 'Top up details'
          : 'Transaction details'
      }
      onClose={handleClose}
      action={
        !declineState && (
          <Actions singleData={singleData ?? selectTransaction?.ledgerData} />
        )
      }
      isPopoverOpen={isPopoverOpen}
      handleTogglePopover={handleTogglePopover}
      loader={<PaymentSkeleton />}
      loading={singleLoading}
    >
      <>
        {declineState ? (
          <div className="px-4">
            <DeclineStateComponent
              showTitle={false}
              code={singleData?.approvalRequest?.code}
              goBack={() => setDeclineState(false)}
              setData={setSelectTransaction}
            />
          </div>
        ) : (
          <>
            <div
              className={classNames(
                'px-4 bg-white fixed-header',
                scrollSize > 80 ? 'pb-0' : 'pb-2',
              )}
            >
              <PaymentHeader
                fontSize={fontSize}
                iconSize={iconSize}
                scrollSize={scrollSize}
                status={
                  !!singleData?.violations?.length
                    ? 'Out-Of-Policy'
                    : isTopup || isWithdrawalWithoutCode || paymentType === 'closure'
                    ? selectTransaction?.status?.value
                    : singleTransaction?.data?.status?.toLowerCase()
                }
                recipientLink={
                  selectTransaction?.ledgerData?.budget?.code
                    ? `/expenses/budgets/${selectTransaction?.ledgerData?.budget?.code}/overview`
                    : isVendor
                    ? `/expenses/vendors/profile/${recipient?.code}`
                    : isBeneficiary
                    ? `/teams/members/profile/${recipient?.beneficiary?.code}`
                    : null
                }
                receipient={
                  isTopup || isWithdrawalWithoutCode || paymentType === 'closure'
                    ? selectTransaction?.ledgerData?.budget?.name ?? paidTo
                    : paidTo
                }
                moneyData={moneyData}
                type="transaction"
                badgeIcon={
                  !!singleData?.violations?.length ? (
                    <WarningIcon className="me-1" />
                  ) : null
                }
                createdByMe={false}
              />
            </div>

            <Tabs
              id="contolled-tab-example"
              activeKey={key}
              onSelect={handleSelectKey}
              className="bg-white position-fixed w-100 pt-0 mt-0"
              style={{ zIndex: 99999, top: 220 - (scrollSize / 100) * 40 }}
            >
              <Tab
                eventKey="details"
                title={<div className="d-flex">Details</div>}
                tabClassName="new-tab ms-4"
                style={{ paddingTop: 165 }}
              >
                <div className="slide-in-right pb-5">
                  <div className="details-tab p-4">
                    <div className="details-view mb-4">
                      <p>Overview</p>
                      <div className="container">
                        {isTransaction && !isCardTrx && (
                          <ItemInfo
                            title={'Paid by'}
                            value={
                              ['top up', 'closure'].includes(paymentType)
                                ? 'selectTransaction?.by?.value?.props?.name'
                                : `${singleData?.payer?.firstName || 'Unknown'} ${
                                    singleData?.payer?.lastName || ''
                                  }`
                            }
                            icon={
                              <div>
                                <ImgCard
                                  initials={
                                    ['top up', 'closure'].includes(paymentType)
                                      ? selectTransaction?.by?.value?.props?.name
                                      : `${
                                          (singleData?.payer?.firstName || '-').slice(
                                            '',
                                          )[0]
                                        }`
                                  }
                                />
                              </div>
                            }
                            withBadge={true}
                          />
                        )}

                        {!isTopup && paymentType !== 'closure' && (
                          <div className="padding-top-base">
                            <ItemInfo
                              title="Payment method"
                              value={method}
                              icon={
                                <div className="transfer-in-icon sm">
                                  <ArrowDownIcon height={16} width={16} />
                                </div>
                              }
                              withBadge={true}
                            />
                          </div>
                        )}

                        {singleData?.requester && requestCode && (
                          <div className="padding-top-base">
                            <ItemInfo
                              title={'Requested by'}
                              value={`${singleData?.requester?.firstName} ${singleData?.requester?.lastName}`}
                              icon={
                                <div>
                                  <ImgCard
                                    initials={
                                      (singleData?.requester?.firstName || '-').slice(
                                        '',
                                      )[0]
                                    }
                                  />
                                </div>
                              }
                              withBadge={true}
                            />
                          </div>
                        )}
                        {isTopup && selectTransaction?.by?.value?.props?.name && (
                          <ItemInfo
                            title="Topped up by"
                            value={selectTransaction?.by?.value?.props?.name}
                            icon={
                              <span className="detail-thumbnail thumbnail-primary thumbnail-small text-xs">
                                {selectTransaction?.by?.value?.props?.name?.slice('')[0]}
                              </span>
                            }
                            withBadge={true}
                          />
                        )}
                        {isWithdrawalWithoutCode && (
                          <ItemInfo
                            title="Withdrawn by"
                            value={selectTransaction?.by?.value?.props?.name}
                            icon={
                              <span className="detail-thumbnail thumbnail-primary thumbnail-small text-xs">
                                {selectTransaction?.by?.value?.props?.name?.slice('')[0]}
                              </span>
                            }
                            withBadge={true}
                          />
                        )}

                        {isTopup && (
                          <div className="padding-top-bases">
                            <ItemInfo
                              title="Type"
                              value={`${capitalizeFirstLetter(
                                String(
                                  typeof selectTransaction?.type === 'string'
                                    ? selectTransaction?.type
                                    : '',
                                ),
                              )} top up`}
                              withBadge={selectTransaction?.type === 'card'}
                              icon={
                                <span className="card-badge">
                                  {getCard(selectTransaction?.ledgerData?.card?.brand)}
                                </span>
                              }
                            />
                          </div>
                        )}

                        {paymentType === 'closure' && (
                          <div className="padding-top-base">
                            <ItemInfo
                              title="Type"
                              value={`Card ${capitalizeFirstLetter(paymentType)}`}
                              withBadge={selectTransaction?.type === 'card'}
                              icon={
                                <span className="card-badge">
                                  {getCard(selectTransaction?.ledgerData?.card?.brand)}
                                </span>
                              }
                            />
                          </div>
                        )}
                        {selectTransaction?.type === 'card' && (
                          <div className="padding-top-base">
                            <ItemInfo
                              title={'Card'}
                              value={`${selectTransaction?.ledgerData?.card?.type} ${selectTransaction?.ledgerData?.card?.pan}`}
                              isLink={
                                selectTransaction?.ledgerData?.card?.code
                                  ? `/cards/${selectTransaction?.ledgerData?.card?.code}`
                                  : false
                              }
                            />
                          </div>
                        )}

                        {paymentType === 'closure' && (
                          <div className="padding-top-base">
                            <ItemInfo
                              title={'Card'}
                              value={
                                selectTransaction?.ledgerData?.card?.code
                                  ? `${selectTransaction?.ledgerData?.card?.type} ${selectTransaction?.ledgerData?.card?.pan}`
                                  : '-'
                              }
                              isLink={
                                selectTransaction?.ledgerData?.card?.code
                                  ? `/cards/${selectTransaction?.ledgerData?.card?.code}`
                                  : false
                              }
                            />
                          </div>
                        )}

                        <div className="padding-top-base">
                          <ItemInfo
                            title="Initiated on"
                            value={selectTransaction?.date?.props?.name}
                          />
                        </div>
                        {selectTransaction?.paidOn && (
                          <div className="padding-top-base">
                            <ItemInfo
                              title="Paid on"
                              value={selectTransaction?.paidOn?.props?.name}
                            />
                          </div>
                        )}

                        <div className="padding-top-base">
                          <ItemInfo
                            title="Transaction ID"
                            isCopy
                            icon={<CopyIcon stroke="#D28B28" width="16" height="16" />}
                            value={
                              isTopup ||
                              isWithdrawalWithoutCode ||
                              paymentType == 'closure'
                                ? selectTransaction?.ledgerData?.code
                                : selectTransaction?.transactionData.code
                            }
                          />
                        </div>

                        {singleData?.sessionId && (
                          <div className="padding-top-base">
                            <ItemInfo
                              title="Session ID"
                              isCopy
                              icon={<CopyIcon stroke="#D28B28" width="16" height="16" />}
                              value={singleData?.sessionId}
                            />
                          </div>
                        )}
                        {requestCode && (
                          <div className="padding-top-base">
                            <ItemInfo
                              title="Request ID"
                              isLink={
                                requestCode
                                  ? isFundReq
                                    ? `/requests/funds/${requestCode}`
                                    : `/requests/reimbursements/${requestCode}`
                                  : false
                              }
                              withAction={requestCode}
                              value={requestCode ?? '-'}
                              icon={
                                <ArrowUpRightIcon
                                  stroke="#D28B28"
                                  width="16"
                                  height="16"
                                />
                              }
                            />
                          </div>
                        )}

                        {singleTransaction?.data?.status === 'failed' &&
                          singleTransaction?.data?.failure_reason && (
                            <div className="padding-top-base">
                              <ItemInfo
                                title="Failure reason"
                                withBadge={true}
                                icon={
                                  <AlertIcon stroke="#a70045" width={20} height={20} />
                                }
                                value={singleTransaction?.data?.failure_reason}
                              />
                            </div>
                          )}

                        {!!singleData?.violations?.length && (
                          <>
                            {singleData.violations.map((item, index) => (
                              <div
                                key={index}
                                className="warning-info-box box-spacing d-flex gap-2 px-3 pt-3 pb-3"
                              >
                                <WarningIcon
                                  width={'16'}
                                  height={'16'}
                                  className="flex-shrink-0"
                                />
                                <div>
                                  <h6 className="text-sm">
                                    {canViewPolicy ? (
                                      <Link
                                        to={`/compliances/policies/${item?.policy?.code}`}
                                      >
                                        {item?.policy?.name}
                                      </Link>
                                    ) : (
                                      item?.policy?.name
                                    )}
                                  </h6>
                                  <p className="m-0 ms-1">
                                    {item?.policy?.description} requires{' '}
                                    {policyRequirements({
                                      receipt: item?.policy?.requiresReceipt,
                                      description: item?.policy?.requiresDecription,
                                      category: item?.policy?.requiresCategory,
                                      budget: item?.policy?.requiresBudget,
                                    })}
                                  </p>
                                  {item?.policy?.documents.map((document, idx) => (
                                    <div
                                      key={idx}
                                      className={`border rounded-2 py-2 px-3 mt-2`}
                                    >
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center gap-3">
                                          <img width={20} src={pdfFileType} />
                                          <div>
                                            <p className="text-xs mb-0 gray-500">
                                              {truncateMiddle(document?.asset?.name, 40)}
                                            </p>
                                          </div>
                                        </div>

                                        <div>
                                          <EyeIcon
                                            onClick={() => {
                                              dispatch(
                                                getAllPolicyAsset([
                                                  { ...document.asset },
                                                ]),
                                              );
                                            }}
                                            width="16"
                                            height="16"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                    {!!accounts?.length && (
                      <div className="details-view mb-4">
                        <p>Recipient details</p>
                        <div className="container">
                          <>
                            <div className="mb-3">
                              <ItemInfo
                                title={isVendor ? 'Vendor name' : 'Recipient'}
                                withAction
                                value={
                                  <div className="d-flex align-items-center gap-2">
                                    <ImgCard
                                      size="small"
                                      fullWidth={false}
                                      initials={recipient?.name?.charAt(0)}
                                    />
                                    <span>{recipient?.name}</span>
                                  </div>
                                }
                                action={() =>
                                  history.push(
                                    `/expenses/vendors/profile/${recipient?.code}`,
                                  )
                                }
                                icon={
                                  isVendor ? (
                                    <ArrowUpRightIcon
                                      stroke="#D28B28"
                                      width="16"
                                      height="16"
                                    />
                                  ) : null
                                }
                              />
                            </div>

                            <div className="mb-3">
                              <ItemInfo
                                title="Bank"
                                value={bankAccounts[0]?.bankName}
                                withBadge
                                icon={
                                  <ImgCard
                                    size="small"
                                    fullWidth={false}
                                    initials={bankAccounts[0]?.bankName?.charAt(0)}
                                  >
                                    <img
                                      onError={handleImgError}
                                      className="account-logo"
                                      src={
                                        BanksIcons[
                                          bankAccounts[0]?.bankName?.toLowerCase()
                                        ] ?? bankIcon
                                      }
                                    />
                                  </ImgCard>
                                }
                              />
                            </div>

                            <ItemInfo
                              title="Account number"
                              value={bankAccounts[0]?.number}
                            />
                          </>
                        </div>
                      </div>
                    )}

                    <div className="details-view mb-4">
                      <p>Payment details</p>
                      <div className="container">
                        <ItemInfo
                          title={isTopup ? 'Topup amount' : 'Amount'}
                          value={
                            <CurrencyFormat
                              prefix={getCurrency(
                                selectTransaction?.transactionData?.currency ||
                                  selectTransaction?.ledgerData?.currency,
                              )}
                              value={convertNaNToZero(
                                Math.abs(selectTransaction?.transactionData?.amount) /
                                  100 ||
                                  Math.abs(selectTransaction?.ledgerData?.amount) / 100,
                              )}
                              displayType="text"
                              thousandSeparator={true}
                            />
                          }
                        />
                        {!isTopup &&
                          !isWithdrawalWithoutCode &&
                          paymentType !== 'closure' && (
                            <div className="padding-top-base">
                              <ItemInfo
                                title="Fee"
                                value={
                                  <CurrencyFormat
                                    prefix={getCurrency(
                                      singleData?.currency ||
                                        singleData?.ledgerData?.currency,
                                    )}
                                    value={singleData?.fee / 100}
                                    displayType="text"
                                    thousandSeparator={true}
                                  />
                                }
                              />
                            </div>
                          )}

                        {isTransaction && !isCardTrx && (
                          <div className="padding-top-base">
                            <ItemInfo
                              title="Total paid"
                              value={
                                <CurrencyFormat
                                  prefix={getCurrency(
                                    singleData?.currency ??
                                      selectTransaction?.transactionData.currency,
                                  )}
                                  value={
                                    (((singleData?.fee ??
                                      selectTransaction?.transactionData?.fee) ||
                                      0) +
                                      (singleData?.amount ??
                                        Math.abs(
                                          selectTransaction?.transactionData.amount,
                                        ))) /
                                    100
                                  }
                                  displayType="text"
                                  thousandSeparator={true}
                                />
                              }
                            />
                          </div>
                        )}

                        {!isTopup &&
                          !isWithdrawalWithoutCode &&
                          paymentType !== 'closure' && (
                            <>
                              {isTransaction && (
                                <div className="padding-top-base">
                                  <ItemInfo
                                    title="Category"
                                    isLink={
                                      singleData?.category?.code
                                        ? `/compliances/categories/${singleData?.category?.code}/details`
                                        : false
                                    }
                                    hideLinkIcon
                                    value={
                                      <BadgeType
                                        value={{
                                          value:
                                            expenseCategories.value.label ??
                                            singleData?.category?.name ??
                                            '-',
                                          rightIcon: singleData?.category?.code && (
                                            <ArrowUpRightIcon
                                              stroke={asciiToHex(
                                                expenseCategories.value.label ??
                                                  singleData?.category?.name ??
                                                  '-',
                                              )}
                                              width="16"
                                              height="16"
                                            />
                                          ),
                                          color: getColor(
                                            expenseCategories.value.label ??
                                              singleData?.category?.name ??
                                              '-',
                                          ),
                                        }}
                                      />
                                    }
                                    withAction={[
                                      'failed',
                                      'success',
                                      'processed',
                                    ].includes(transactionStatus)}
                                    action={() => setCategoryVisible(!categoryVisible)}
                                    isLoading={isLoading === 'category'}
                                    icon={
                                      <PencilIcon
                                        stroke="#D28B28"
                                        width="18"
                                        height="18"
                                      />
                                    }
                                  />
                                </div>
                              )}
                              {isTransaction && !isCardTrx && categoryVisible && (
                                <div className="padding-top-xxs">
                                  <CategoryDropdown
                                    placeholder="Select a category"
                                    onChange={(val) => onHandleGetSelected(val)}
                                    value={
                                      expenseCategories.value ?? {
                                        label: singleData?.category?.name,
                                        value: singleData?.category?.code,
                                      }
                                    }
                                    name="category"
                                    defaultValue={{
                                      label: singleData?.category?.name,
                                      value: singleData?.category?.code,
                                    }}
                                  />
                                </div>
                              )}
                              {isTransaction && (
                                <div className="padding-top-base">
                                  <ItemInfo
                                    title="Source"
                                    isLink={
                                      singleData?.budget?.code
                                        ? `/expenses/budgets/${singleData?.budget?.code}/overview`
                                        : singleData?.balance?.code
                                        ? `/accounts/${singleData?.balance?.code}`
                                        : false
                                    }
                                    withAction={[
                                      'success',
                                      'failed',
                                      'pending',
                                      'approved',
                                    ].includes(transactionStatus)}
                                    icon={
                                      <PencilIcon
                                        stroke="#D28B28"
                                        width="18"
                                        height="18"
                                      />
                                    }
                                    value={
                                      <div className="d-flex align-items-center gap-2">
                                        {(singleData?.balance?.name ||
                                          singleData?.budget?.name) && (
                                          <ImgCard
                                            size="small"
                                            fullWidth={false}
                                            initials={(
                                              singleData?.balance?.name ||
                                              singleData?.budget?.name
                                            )?.charAt(0)}
                                          >
                                            <img
                                              onError={handleImgError}
                                              className="account-logo"
                                              src={bankIcon}
                                            />
                                          </ImgCard>
                                        )}

                                        <span>
                                          {(singleData?.balance?.name ||
                                            singleData?.budget?.name) ??
                                            '-'}
                                        </span>
                                      </div>
                                    }
                                    action={() => setBudgetVisible(!budgetVisible)}
                                    isLoading={budgetLoading}
                                  />
                                </div>
                              )}
                              {isTransaction && budgetVisible && (
                                <div className="padding-top-xxs">
                                  <CustomSelectRadio
                                    label="Where are you paying from?"
                                    name="source"
                                    placeholder="Select a source"
                                    onChange={(val) => saveBudget(val)}
                                    isClearable
                                    defaultValue={{
                                      value:
                                        singleData?.balance?.code ||
                                        singleData?.budget?.code,
                                      label:
                                        singleData?.balance?.name ||
                                        singleData?.budget?.name,
                                    }}
                                    isLoading={loadingBalances}
                                    isDisabled={loadingBalances}
                                    loadOptions={loadOptions}
                                  />
                                </div>
                              )}
                            </>
                          )}
                      </div>
                    </div>

                    <div>
                      <div className="padding-top-xxs">
                        <CustomTextarea
                          label="Description"
                          action={() => {
                            setDescription({
                              ...description,
                              visible: !description.visible,
                            });
                            setSaveButton(!saveButton);
                          }}
                          isLoading={description?.isEditingDescription}
                          icon={
                            !isReversal &&
                            canEditTransaction &&
                            !['declined', 'processing'].includes(transactionStatus) && (
                              <span
                                className="text-sm d-flex gap-2 align-items-center"
                                style={{ color: '#D28B28' }}
                              >
                                {description.visible ? 'Cancel' : 'Edit'}
                                {description.visible ? (
                                  <CancelICon stroke="#D28B28" width="10" height="10" />
                                ) : (
                                  <PencilIcon stroke="#D28B28" width="18" height="18" />
                                )}
                              </span>
                            )
                          }
                          name="description"
                          placeholder="Description"
                          value={description?.data}
                          onChange={handleDescriptionChange}
                          rowSize={4}
                          disabled={
                            isReversal ||
                            !canEditTransaction ||
                            !description.visible ||
                            ['declined', 'processing'].includes(transactionStatus)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="attachements"
                title={<div className="d-flex">Attachments</div>}
                tabClassName="new-tab"
                style={{ paddingTop: 165 }}
              >
                <div className="slide-in-right p-4 pb-5">
                  <div className="details-tab">
                    <div className="details-view mb-4">
                      <p>Receipts</p>
                      <div className="container gap-2 d-flex overflow-x-scroll">
                        {loadingReceipt && !receiptAssets?.length && <LoadingReciept />}
                        <NewReceiptUpload
                          receiptAssets={receiptAssets}
                          viewImgUrl={viewImgUrl}
                          addMore={true}
                          removeFile={!receiptCode.receipt?.length}
                          onChange={(value) =>
                            setReceiptCode({
                              receipt: value?.map((item) => item.assetCode),
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="updates"
                title={<div className="d-flex">Updates</div>}
                tabClassName="new-tab"
                style={{ paddingTop: 165 }}
              >
                <div className="slide-in-right p-4 pb-5">
                  <div className="details-tab">
                    <div className="details-view mb-4">
                      <Timeline
                        data={approvers}
                        goToRule={openRule}
                        pageFrom={`/transactions`}
                        code={singleTransaction?.data?.code}
                      />
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
            <div className="position-fixed bottom-0 card-modal-footer bg-white px-4 py-2 border-top w-100">
              {showFooter && (
                <div className="d-flex gap-2 align-items-center justify-content-end">
                  {!['pending', 'failed', 'cancelled', 'success'].includes(
                    transactionStatus,
                  ) && (
                    <CustomButton
                      className="base-button black-transparent text-sm font-medium"
                      onClick={() => handleCancelorRetryModal('Refresh')}
                    >
                      <RetryIcon width="20" height="20" stroke="#44403C" />
                      Refresh status
                    </CustomButton>
                  )}
                  {!!singleData?.violations?.length && canEditTransaction && (
                    <>
                      <CustomButton
                        className="base-button black-transparent text-sm font-medium"
                        onClick={() =>
                          dispatch(requestTransactionDetails(singleData?.code))
                        }
                        fullWidth={true}
                        loading={sendingDetails}
                      >
                        <MailIcon width="20" height="20" stroke="#79716B" />
                        Request details
                      </CustomButton>
                      <CustomButton
                        className="base-button dark-button text-sm font-medium"
                        onClick={() => handleCancelorRetryModal('Approve')}
                        fullWidth={true}
                      >
                        Resolve
                      </CustomButton>
                    </>
                  )}
                  {['failed', 'cancelled'].includes(transactionStatus) && (
                    <CustomButton
                      className="base-button black-transparent text-sm font-medium"
                      onClick={() => handleCancelorRetryModal('Retry')}
                    >
                      <RetryIcon width="20" height="20" stroke="#44403C" />
                      Retry transaction
                    </CustomButton>
                  )}
                  {saveButton && (
                    <>
                      <CustomButton
                        onClick={cancelDescriptionEdit}
                        className="base-button text-sm font-medium  black-transparent"
                      >
                        Cancel
                      </CustomButton>
                      <CustomButton
                        onClick={handleDescription}
                        className="base-button text-sm font-medium dark-button"
                      >
                        {description?.isEditingDescription ? (
                          <Loading color="#D28B28" size={20} />
                        ) : (
                          'Save changes'
                        )}
                      </CustomButton>
                    </>
                  )}
                </div>
              )}

              {yourTurnToApprove &&
                !saveButton &&
                ['pending'].includes(
                  singleTransaction?.data?.approvalRequest?.status,
                ) && (
                  <div className="d-flex align-items-center justify-content-end">
                    <CustomButton
                      withoutBg
                      className="base-button text-sm font-medium danger-button"
                      onClick={() => setDeclineState(true)}
                      disabled={hasApproved}
                      style={{
                        width: 'fit-content',
                        padding: '10px',
                        minWidth: 0,
                      }}
                    >
                      Decline
                    </CustomButton>

                    <div className="d-flex">
                      <button
                        disabled={hasApproved ? true : loadingReview}
                        onClick={() => {
                          if (!possibleSource && canApprovePay)
                            return toastError('select a source');
                          setIsApproving(true);
                          setPayNow(canApprovePay);
                        }}
                        className={classNames(
                          'px-3 nowrap dropdown-btn action-btn text-white',
                          {
                            ['rounded-3 px-4']: !canApprovePay,
                          },
                        )}
                      >
                        {canApprovePay ? 'Approve and pay' : 'Approve only'}
                      </button>

                      {canApprovePay && (
                        <CustomPopover
                          placement="top-start"
                          id="invoice-schedule"
                          zIndex="9999"
                          content={<ApprovalActions />}
                          showPopover={isButtonToggle}
                          clickOutside={handleButtonToggle}
                        >
                          <button
                            className="dropdown-btn"
                            disabled={hasApproved ? true : loadingReview}
                            onClick={handleButtonToggle}
                          >
                            <ChevronDown
                              color="#ffffff"
                              className={`icon ${isButtonToggle && 'is-toggled'}`}
                            />
                          </button>
                        </CustomPopover>
                      )}
                    </div>
                  </div>
                )}
            </div>
          </>
        )}
      </>

      {/*  */}
      <BootstrapModal show={isDelete} centered dialogClassName="custom-dialog">
        <ConfirmDialog
          title={`${dialogTitle} transaction`}
          subTitle={
            <>
              Are you sure you want to {dialogTitle} this transaction?
              <br />
              {dialogTitle === 'Cancel' && 'This action cannot be undone'}
            </>
          }
          extraChild={
            ((!possibleSource && dialogTitle === 'pay') ||
              (singleTransaction?.data?.status === 'failed' &&
                selectTransaction?.isTransaction)) && (
              <div className="border-top" style={{ maxWidth: '300px' }}>
                <Row className="align-items-center">
                  <CustomSelectRadio
                    label="Where are you paying from?"
                    name="source"
                    placeholder="Select a source"
                    onChange={(val) => {
                      setSource(val);
                      singleTransaction?.data?.status === 'failed' &&
                        handleUpdateRequest(
                          singleTransaction?.data?.code,
                          'source',
                          val.value,
                        );
                    }}
                    value={source}
                    isLoading={loadingBalances}
                    isDisabled={loadingBalances}
                    loadOptions={loadOptions}
                  />
                </Row>
              </div>
            )
          }
          onConfirm={cancelorRetryTransaction}
          loading={
            retryLoading ||
            cancelLoading ||
            loadingRequery ||
            approveLoading ||
            loadingPayNow
          }
          disabled={isEditing}
          onCancel={() => handleCancelorRetryModal(dialogTitle)}
          isDeleteDialog={false}
          dialogIcon={dialogTitle === 'Retry' ? <ReloadIcon /> : null}
        />
      </BootstrapModal>

      {showCategoryDeleteModal && (
        <Modal show={true} centered dialogClassName="custom-dialog">
          <DeleteDialog
            title={`Delete ${selectedCategory?.name} Category`}
            subTitle="Are you sure you want to delete this category? This action cannot be undone and all transactions in this category would be categorized as unknown"
            onCancel={() => setShowCategoryDeleteModal(false)}
            onDelete={onHandleDeleteCategory}
            styles={{ width: 400 }}
            isLoading={loadingDelete}
          />
        </Modal>
      )}

      <Modal show={isSchedule} centered dialogClassName="custom-dialog">
        <SchedulePayment
          onClose={() => {
            setIsSchedule(false);
          }}
          loading={loadingReview}
          setSchedule={handleApproveRequest}
          // handleCreate={onHandleSubmit}
        />
      </Modal>

      {approve && (
        <BootstrapModal show={true} centered dialogClassName="custom-dialog">
          <ConfirmDialog
            title="Approve request"
            subTitle="Are you sure you want to approve this request"
            onConfirm={handleApproveRequest}
            loading={loadingReview}
            onCancel={() => setIsApproving(false)}
            isDeleteDialog={false}
          />
        </BootstrapModal>
      )}

      {showSendFunds && (
        <BujetiPayModal
          recipientCode={recipientCode}
          setOpenModal={setShowSendFunds}
          setSelectTransaction={setSelectTransaction}
        />
      )}
    </ModalContainerWrap>
  );
};

export default TransactionDetails;
