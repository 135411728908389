import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { Axios } from 'api/axios';
import { getSimplifiedError } from 'utils/error';
import {
  CREATE_CARD_REQUEST,
  CREATE_CARD_SUCCESS,
  CREATE_CARD_ERROR,
  GET_CARD_REQUEST,
  GET_CARD_SUCCESS,
  GET_CARD_ERROR,
  GET_MY_CARDS_ERROR,
  GET_MY_CARDS_SUCCESS,
  GET_MY_CARDS_REQUEST,
  DEACTIVATE_CARD_REQUEST,
  DEACTIVATE_CARD_SUCCESS,
  DEACTIVATE_CARD_ERROR,
  GET_SINGLE_CARD_ERROR,
  GET_SINGLE_CARD_SUCCESS,
  GET_SINGLE_CARD_REQUEST,
  FREEZE_UNFREEZE_CARD_REQUEST,
  FREEZE_UNFREEZE_CARD_SUCCESS,
  FREEZE_UNFREEZE_CARD_ERROR,
  GET_SELECTED_CARD_SUCCESS,
  GET_SELECTED_CARD_ERROR,
  GET_SELECTED_CARD_REQUEST,
  LOAD_UNLOAD_CARD_REQUEST,
  LOAD_UNLOAD_CARD_SUCCESS,
  LOAD_UNLOAD_CARD_ERROR,
  TOPUP_CARD_REQUEST,
  TOPUP_CARD_ERROR,
  TOPUP_CARD_SUCCESS,
  RESET_BLOCK_CARD,
  SET_NEW_PIN_SUCCESS,
  SET_NEW_PIN_ERROR,
  SET_NEW_PIN_REQUEST,
  ACTIVATE_PHYSICAL_CARD_SUCCESS,
  ACTIVATE_PHYSICAL_CARD_ERROR,
  ACTIVATE_PHYSICAL_CARD_REQUEST,
  PHYSICAL_CARD_REQUEST_SUCCESS,
  PHYSICAL_CARD_REQUEST_ERROR,
  PHYSICAL_CARD_REQUEST,
  GET_REQUESTED_CARDS_SUCCESS,
  GET_REQUESTED_CARDS_ERROR,
  GET_REQUESTED_CARDS_REQUEST,
  GET_DEFAULT_PIN_SUCCESS,
  GET_DEFAULT_PIN_ERROR,
  GET_DEFAULT_PIN_REQUEST,
  GET_MCCS_SUCCESS,
  GET_MCCS_ERROR,
  GET_MCCS_REQUEST,
  UPDATE_CARD_SETTINGS_SUCCESS,
  UPDATE_CARD_SETTINGS_ERROR,
  UPDATE_CARD_SETTINGS_REQUEST,
  DELETE_CARD_SUCCESS,
  DELETE_CARD_ERROR,
  DELETE_CARD_REQUEST,
  DELETE_CARD_REQUEST_SUCCESS,
  DELETE_CARD_REQUEST_ERROR,
  DELETE_CARD_REQUEST_REQUEST,
  REASSIGN_CARD_REQUEST,
  REASSIGN_CARD_SUCCESS,
  REASSIGN_CARD_ERROR,
  GET_REQUESTED_CARD_SUCCESS,
  GET_REQUESTED_CARD_ERROR,
  GET_REQUESTED_CARD_REQUEST,
} from '../reducers/CardReducer';
import { toastError, toastSuccess } from 'components/UI/toast';

async function getCard(params = {}) {
  return await Axios.get('/cards/', { params });
}
function* handleGetCard({ query }) {
  try {
    const response = yield call(getCard, query);
    if (response) {
      yield put({
        type: GET_CARD_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_CARD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getMyCards(params = {}) {
  return await Axios.get('/cards/', { params });
}
function* handleGetMyCard({ query }) {
  try {
    const response = yield call(getMyCards, query);
    if (response) {
      yield put({
        type: GET_MY_CARDS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_MY_CARDS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getRequestedCards(params = {}) {
  return await Axios.get('/card-request/', { params });
}
function* handleGetRequestedCards({ payload }) {
  try {
    const response = yield call(getRequestedCards, payload);
    if (response) {
      yield put({
        type: GET_REQUESTED_CARDS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_REQUESTED_CARDS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getRequestedCard(code) {
  return await Axios.get(`/card-request/${code}`);
}
function* handleGetRequestedCard({ payload }) {
  try {
    const response = yield call(getRequestedCard, payload);
    if (response) {
      yield put({
        type: GET_REQUESTED_CARD_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_REQUESTED_CARD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSelectedCard(payload) {
  return await Axios.get('/cards', { params: payload });
}
function* handleGetSelectedCard({ payload }) {
  try {
    const response = yield call(getSelectedCard, payload);
    if (response) {
      yield put({
        type: GET_SELECTED_CARD_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SELECTED_CARD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createCard(payload) {
  return await Axios.post('/cards', payload);
}
function* handleCreateCard({ payload }) {
  try {
    const response = yield call(createCard, payload);
    if (response) {
      yield put({
        type: CREATE_CARD_SUCCESS,
        data: response,
      });
      toastSuccess(response?.message);

      // yield put({
      //   type: GET_CARD_REQUEST,
      // });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_CARD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deactivateCard(payload) {
  return await Axios.post('/budgets/bdg_P96lAFthlYiVhAnvq', payload);
}
function* handleDeactivateCard({ payload }) {
  try {
    const response = yield call(deactivateCard, payload);
    if (response) {
      yield put({
        type: DEACTIVATE_CARD_SUCCESS,
        data: response,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DEACTIVATE_CARD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteCard(code) {
  return await Axios.delete(`/cards/${code}`);
}
function* handleDeleteCard({ payload }) {
  try {
    const response = yield call(deleteCard, payload);
    if (response) {
      yield put({
        type: DELETE_CARD_SUCCESS,
        data: response,
      });
      toastSuccess(response?.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_CARD,
        blockType: 'deleteCard',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_CARD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteCardRequest(code) {
  return await Axios.delete(`/card-request/${code}`);
}
function* handleDeleteCardRequest({ payload }) {
  try {
    const response = yield call(deleteCardRequest, payload);
    if (response) {
      yield put({
        type: DELETE_CARD_REQUEST_SUCCESS,
        data: response,
      });
      toastSuccess(response?.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_CARD,
        blockType: 'deleteCardRequest',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_CARD_REQUEST_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function singleCard({ id }) {
  return await Axios.get(`/cards/${id}`);
}
function* handleSingleCard({ payload }) {
  try {
    const response = yield call(singleCard, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_CARD_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SINGLE_CARD_ERROR,
      error: getSimplifiedError(error),
    });

    yield put({
      type: RESET_BLOCK_CARD,
      blockType: 'getSingleCard',
    });
  }
}

async function freezeUnfreeze({ code, type }) {
  return await Axios.put(`/cards/${code}/status?type=${type}`);
}
function* handleFreezeUnfreezeCard({ payload }) {
  try {
    const response = yield call(freezeUnfreeze, payload);
    if (response) {
      yield put({
        type: FREEZE_UNFREEZE_CARD_SUCCESS,
        data: response.data,
      });

      toastSuccess(response?.message);

      yield delay(300);

      yield put({
        type: RESET_BLOCK_CARD,
        blockType: 'freezeUnfreezeCard',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FREEZE_UNFREEZE_CARD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function loadUnloadCard({ code }) {
  return await Axios.post(`/cards/${code}/liquidate`);
}
function* handleLoadUnloadCard({ payload }) {
  try {
    const response = yield call(loadUnloadCard, payload);
    if (response) {
      yield put({
        type: LOAD_UNLOAD_CARD_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: LOAD_UNLOAD_CARD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function topUpCard(payload) {
  const requestPayload = { ...payload };
  delete requestPayload?.code;
  return await Axios.patch(`/cards/${payload.code}/fund`, requestPayload);
}
function* handleTopUpCard({ payload }) {
  try {
    const response = yield call(topUpCard, payload);
    if (response) {
      yield put({
        type: TOPUP_CARD_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: TOPUP_CARD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function setNewPin(payload) {
  const requestPayload = { ...payload };
  delete requestPayload?.code;
  return await Axios.patch(`/cards/${payload.code}/pin`, requestPayload);
}
function* handleSetNewPin({ payload }) {
  try {
    const response = yield call(setNewPin, payload);
    if (response) {
      yield put({
        type: SET_NEW_PIN_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: SET_NEW_PIN_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function activatePhysicalCard(payload) {
  return await Axios.post(`/physical-card/activate`, payload);
}
function* handleActivatePhysicalCard({ payload }) {
  try {
    const response = yield call(activatePhysicalCard, payload);
    if (response) {
      yield put({
        type: ACTIVATE_PHYSICAL_CARD_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: ACTIVATE_PHYSICAL_CARD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function physicalCardRequest(payload) {
  return await Axios.post(`/card-request`, payload);
}
function* handlePhysicalCardRequest({ payload }) {
  try {
    const response = yield call(physicalCardRequest, payload);
    if (response) {
      yield put({
        type: PHYSICAL_CARD_REQUEST_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_CARD,
        blockType: 'physicalCardRequest',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: PHYSICAL_CARD_REQUEST_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getDefaultPin(payload) {
  return await Axios.get(`/physical-card/${payload}/default-pin`);
}
function* handleGetDefaultPin({ payload }) {
  try {
    const response = yield call(getDefaultPin, payload);
    if (response) {
      yield put({
        type: GET_DEFAULT_PIN_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_DEFAULT_PIN_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getMccs(params) {
  return await Axios.get(`/cards/mccs`, { params });
}
function* handleGetMccs({ payload }) {
  try {
    const response = yield call(getMccs, payload);
    if (response) {
      yield put({
        type: GET_MCCS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_MCCS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateCardSettings(data) {
  const { code, ...payload } = data;
  return await Axios.put(`/cards/${code}`, payload);
}
function* handleUpdateCardSettings({ payload }) {
  try {
    const response = yield call(updateCardSettings, payload);
    if (response) {
      yield put({
        type: UPDATE_CARD_SETTINGS_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_CARD,
        blockType: 'updateCardSettings',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_CARD_SETTINGS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function reassignCard(data) {
  const { code, ...payload } = data;
  return await Axios.post(`/cards/${code}/reassign`, payload);
}
function* handleReassignCard({ payload }) {
  try {
    const response = yield call(reassignCard, payload);
    if (response) {
      yield put({
        type: REASSIGN_CARD_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
      yield delay(300);
      yield put({
        type: RESET_BLOCK_CARD,
        blockType: 'reassignCard',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: REASSIGN_CARD_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_CARD_REQUEST, handleGetCard),
  takeLatest(GET_MY_CARDS_REQUEST, handleGetMyCard),
  takeLatest(GET_SELECTED_CARD_REQUEST, handleGetSelectedCard),
  takeLatest(CREATE_CARD_REQUEST, handleCreateCard),
  takeLatest(DEACTIVATE_CARD_REQUEST, handleDeactivateCard),
  takeLatest(DELETE_CARD_REQUEST, handleDeleteCard),
  takeLatest(DELETE_CARD_REQUEST_REQUEST, handleDeleteCardRequest),
  takeLatest(GET_SINGLE_CARD_REQUEST, handleSingleCard),
  takeLatest(FREEZE_UNFREEZE_CARD_REQUEST, handleFreezeUnfreezeCard),
  takeLatest(LOAD_UNLOAD_CARD_REQUEST, handleLoadUnloadCard),
  takeLatest(TOPUP_CARD_REQUEST, handleTopUpCard),
  takeLatest(SET_NEW_PIN_REQUEST, handleSetNewPin),
  takeLatest(ACTIVATE_PHYSICAL_CARD_REQUEST, handleActivatePhysicalCard),
  takeLatest(PHYSICAL_CARD_REQUEST, handlePhysicalCardRequest),
  takeLatest(GET_REQUESTED_CARDS_REQUEST, handleGetRequestedCards),
  takeLatest(GET_REQUESTED_CARD_REQUEST, handleGetRequestedCard),
  takeLatest(GET_DEFAULT_PIN_REQUEST, handleGetDefaultPin),
  takeLatest(GET_MCCS_REQUEST, handleGetMccs),
  takeLatest(UPDATE_CARD_SETTINGS_REQUEST, handleUpdateCardSettings),
  takeLatest(REASSIGN_CARD_REQUEST, handleReassignCard),
]);
