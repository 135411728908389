import { CloseOutlined } from '@ant-design/icons';
import { createContext, useContext, useEffect, useState } from 'react';
import { Col, Container, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useRutterLink } from 'react-rutter-link';

import { QuickbooksIcon, SageIcon, SlackIcon, XeroIcon, ZohoIcon } from 'assets/icons';

import SettlementAccount from 'pages/Settlement-account';
import { useDispatch, useSelector } from 'react-redux';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import './settings.styles.css';

import Integrations from 'pages/Integrations';
import IntegrationConnectProcess from 'pages/Integrations/components/IntegrationConnectProcess';
import {
  authorize,
  disconnectQuickbooks,
  fetchApps,
} from 'redux/actions/IntegrationsActions';

import { Axios } from 'api/axios';
import Loading from 'components/UI/Loading';
import { toastSuccess } from 'components/UI/toast';
import BankAccounts from 'pages/Profile/BankAccounts';
import CompanyProfile from 'pages/Profile/CompanyProfile';
import UserProfile from 'pages/Profile/UserProfile';
import Plans from 'pages/Settings/Plans';
import { getBeneficiaryBank } from 'redux/actions/BeneficiariesAction';
import Billings from './Billings';
import Developers from './Developers';

export const IntegrationsContext = createContext();

const values = [
  {
    title: 'Effortless syncing',
    description:
      'Automatically sync transactions from Bujeti directly to Quickbooks seamlessly.',
  },
  {
    title: 'Easy expense categorization',
    description:
      'Match and categorize your expenses on Bujeti and Quickbooks effortlessly, providing you with a unified, accurate financial picture.',
  },
  {
    title: 'Secure connection',
    description:
      'Connect with confidence. Your details are transmitted securely, ensuring complete data protection.',
  },
];

const appsLogo = {
  QUICKBOOKS: QuickbooksIcon,
  SAGE: SageIcon,
  XERO: XeroIcon,
  SLACK: SlackIcon,
  ZOHO: ZohoIcon,
};

const Settings = () => {
  const location = useLocation();
  const history = useHistory();
  const locationArray = location.pathname.split('/');
  const [key, setKey] = useState('profile');
  const { permissions, onboardingStatus, isPremium, isAdmin } = allPermissions();
  const canviewSettlement = hasPermission({
    permissions,
    scopes: ['org-*', 'settlement-view'],
  });

  const canviewIntegrations = hasPermission({
    permissions,
    scopes: ['org-*', 'integrations-*', 'integrations-view'],
  });

  const canManage = hasPermission({
    permissions,
    scopes: ['org-*', 'org-view'],
  });

  const {
    authorizingQuickbooks: { loading, error, success, data },
    integrationApps: { loading: fetchingApps, success: appsFetched, data: apps },
    disconnectApps: { loading: isDisconnecting, success: isDisconnected },
  } = useSelector(({ integrations }) => integrations);
  const dispatch = useDispatch();

  const [active, setActive] = useState('list');
  const [selectedIntegration, setSelectedIntegration] = useState({
    isProcessActive: false,
  });
  const [publicToken, setPublicToken] = useState(null);
  const [processingRutter, setProcessingRutter] = useState(false);
  const [appList, setAppList] = useState([]);
  const [isDelete, setIsDelete] = useState(false);

  const onHandleConnectModal = (event, option) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedIntegration({
      ...selectedIntegration,
      isProcessActive: true,
      selected: option,
      step: 'view',
    });
    setActive('connectionModal');
  };

  const openCreateModal = (event, option) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedIntegration({
      ...selectedIntegration,
      isProcessActive: true,
      selected: option,
      step: 'create',
    });
    setActive('connectionModal');
  };

  const onHandleGoBack = (state) => {
    setActive(state);
    setSelectedIntegration({
      ...selectedIntegration,
      isProcessActive: false,
    });
  };

  const onSuccess = (publicToken) => {
    setPublicToken(publicToken);
  };

  const rutterConfig = {
    publicKey: process.env.REACT_APP_RUTTER_PUBLIC_KEY,
    onSuccess: onSuccess,
  };
  const { open } = useRutterLink(rutterConfig);

  const openZoho = async () => {
    const { data } = await Axios.get('/integration/zoho');
    window.open(data.redirectURL);
  };

  const onHandleSelect = (tabKey) => {
    setKey(tabKey);
    if (tabKey != 'settings') {
      history.push(
        `/settings/${tabKey}`,
        tabKey === 'profile' ? { disableFade: true } : {},
      );
    } else {
      history.push(`/settings`);
    }
  };

  const handleConnect = () => {
    setProcessingRutter(true);
    switch (selectedIntegration?.selected?.name) {
      case 'QUICKBOOKS':
        open({ platform: selectedIntegration?.selected?.name?.toUpperCase() });
        break;
      case 'ZOHO':
        openZoho();
        break;
      default:
        toastSuccess(selectedIntegration?.selected?.name);
    }
  };

  useEffect(() => {
    if (isDisconnected) setIsDelete(false);
  }, [isDisconnected]);

  const openDeleteModal = (event) => {
    event.preventDefault();
    event.stopPropagation(0);
    setIsDelete(true);
  };

  useEffect(() => {
    if (!fetchingApps && apps?.length) {
      appMapper(apps, publicToken, success);
      if (location?.state?.platform) {
        const platformName = location.state.platform.toUpperCase();
        setSelectedIntegration({
          selected: {
            ...apps?.find(({ name }) => name.toUpperCase() === platformName),
            imageUrl: appsLogo[platformName],
            values,
          },
          isProcessActive: true,
          step: 'view',
        });
        window.history.replaceState({}, '');
      }
    }
    // else dispatch(fetchApps());
  }, [apps, publicToken, success, data]);

  const appMapper = (apps, publicToken, success) => {
    const newApps = apps?.map((app, i) => {
      if (app.name === Object.keys(appsLogo)[i]) {
        return {
          ...app,
          imageUrl: Object.values(appsLogo)[i],
          values,
        };
      }
      return app;
    });
    setAppList(newApps);
    if (publicToken && success) {
      setProcessingRutter(false);
      setSelectedIntegration({
        ...selectedIntegration,
        selected: newApps[0],
        step: 'create',
      });
      setActive('connectionModal');
    }
  };

  useEffect(() => {
    if (publicToken && !loading && !error.length && !success) {
      const payload = {
        publicToken,
        type: selectedIntegration?.selected?.name,
      };
      dispatch(authorize(payload, selectedIntegration?.selected?.name));
    }
  }, [publicToken, loading, error, success]);

  useEffect(() => {
    if (!loading && success && data) {
      dispatch(fetchApps());

      // setProcessingRutter(false);
      // setSelectedIntegration({
      //   ...selectedIntegration,
      //   step: 'create',
      // });
      // setActive('connectionModal');
      // setProcessingRutter(false);
    } else if (!loading && !success && !error.length) {
      setProcessingRutter(false);
    }
  }, [loading, success]);

  const handleDisconnect = () => {
    dispatch(disconnectQuickbooks());
  };

  useEffect(() => {
    if (!isDisconnecting && isDisconnected) setIsDelete(false);
    setSelectedIntegration({
      ...selectedIntegration,
      isProcessActive: false,
    });
    setActive('list');
    dispatch(fetchApps());
  }, [isDisconnecting, isDisconnected]);

  const finalizeZoho = async (code, location) => {
    await Axios.get(`/integration/zoho/authorize?code=${code}&location=${location}`);
    dispatch(fetchApps());
    history.push('/settings/integrations');
  };

  useEffect(() => {
    if (locationArray) {
      setKey(locationArray[locationArray.length - 1]);
    }
  }, [locationArray]);

  useEffect(() => {
    dispatch(getBeneficiaryBank());

    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('code'))
      finalizeZoho(searchParams.get('code'), searchParams.get('location'));
  }, []);

  // useEffect(() => {
  //   if (!canviewSettlement) {
  //     toastError("You don't have permission to view this page");
  //     setTimeout(() => {
  //       window.location.replace('/');
  //     }, 1000);
  //   }
  // }, [canviewSettlement]);

  return (
    <section className="settings-wrapper position-relative">
      <IntegrationsContext.Provider
        value={{
          active,
          onHandleConnectModal,
          openCreateModal,
          openDeleteModal,
          selectedIntegration,
          onHandleGoBack,
          handleConnect,
          loading,
          processingRutter,
          fetchingApps,
          appList,
          apps,
          isDelete,
          setIsDelete,
          handleDisconnect,
          isDisconnecting,
        }}
      >
        {selectedIntegration?.isProcessActive ? (
          <IntegrationConnectProcess />
        ) : (
          <>
            <section className="header">
              <Container className="pt-4">
                <h1 className="big-title">Settings</h1>
              </Container>
            </section>
            <Container>
              <Row>
                <Col xs={12} className="mt-4">
                  <Tabs
                    id="controlled-tab-exammple"
                    activeKey={key}
                    onSelect={onHandleSelect}
                    className="mb-2"
                  >
                    {isAdmin && (
                      <Tab eventKey="billings" tabClassName="new-tab" title="Billings">
                        <div
                          className="fade-in-fast overview-holder"
                          style={{ height: 'calc(100vh - 10rem)' }}
                        >
                          <Billings />
                        </div>
                      </Tab>
                    )}
                    <Tab eventKey="profile" title="Your profile" tabClassName="new-tab">
                      <div
                        className="fade-in-fast overview-holder"
                        style={{ height: 'calc(100vh - 10rem)' }}
                      >
                        <UserProfile />
                      </div>
                    </Tab>
                    {canManage && (
                      <Tab eventKey="company" title="Company" tabClassName="new-tab">
                        <div
                          className="fade-in-fast overview-holder"
                          style={{ height: 'calc(100vh - 10rem)' }}
                        >
                          <CompanyProfile />
                        </div>
                      </Tab>
                    )}

                    {!['pending', 'rejected'].includes(onboardingStatus) && (
                      <Tab eventKey="bank" title="Bank accounts" tabClassName="new-tab">
                        <div
                          className="fade-in-fast overview-holder"
                          style={{ height: 'calc(100vh - 10rem)' }}
                        >
                          <BankAccounts />
                        </div>
                      </Tab>
                    )}
                    {canviewSettlement && (
                      <Tab
                        eventKey="settlement-account"
                        tabClassName="new-tab"
                        title={<div>Settlement Account</div>}
                      >
                        <div
                          className="fade-in-fast overview-holder"
                          style={{ height: 'calc(100vh - 10rem)' }}
                        >
                          <SettlementAccount />
                        </div>
                      </Tab>
                    )}
                    {canviewIntegrations && isPremium && (
                      <Tab
                        eventKey="integrations"
                        tabClassName="new-tab"
                        title={
                          <div>
                            Integrations <span className="new-tag text-xs">Beta</span>
                          </div>
                        }
                      >
                        <div
                          className="fade-in-fast overview-holder"
                          style={{ height: 'calc(100vh - 10rem)' }}
                        >
                          <Integrations />
                        </div>
                      </Tab>
                    )}
                    {isAdmin && (
                      <Tab
                        eventKey="plans"
                        tabClassName="new-tab"
                        title={<div>Plans</div>}
                      >
                        <div
                          className="fade-in-fast overview-holder"
                          style={{ height: 'calc(100vh - 10rem)' }}
                        >
                          <Plans />
                        </div>
                      </Tab>
                    )}

                    {isAdmin && (
                      <Tab
                        eventKey="Developers"
                        tabClassName="new-tab"
                        title={<div>Developers</div>}
                      >
                        <div
                          className="fade-in-fast overview-holder"
                          style={{ height: 'calc(100vh - 10rem)' }}
                        >
                          <Developers />
                        </div>
                      </Tab>
                    )}
                  </Tabs>
                </Col>
              </Row>
            </Container>
          </>
        )}
        <DeleteModal />
      </IntegrationsContext.Provider>
    </section>
  );
};

export default Settings;

const DeleteModal = () => {
  const { isDelete, setIsDelete, handleDisconnect, isDisconnecting } =
    useContext(IntegrationsContext);

  return (
    <Modal
      show={isDelete}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setIsDelete(false)}
      className="integration_modal_wrapper"
    >
      <div className="integrations__deleteModal">
        <header className="d-flex justify-content-between align-items-center">
          <Cancel />
          <CloseOutlined onClick={() => setIsDelete(false)} />
        </header>
        <main>
          <h1>Are you sure you want to disconnect?</h1>
          <p>You might loose all your beautiful integrations and syncs done</p>
          <footer className="d-flex">
            <button onClick={() => setIsDelete(false)}>Cancel</button>
            <button onClick={handleDisconnect}>
              {isDisconnecting ? <Loading size={18} color="#D28B28" /> : 'Disconnect'}
            </button>
          </footer>
        </main>
      </div>
    </Modal>
  );
};

const Cancel = () => {
  return (
    <span className="cancel">
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 8L8 14M8 8L14 14M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
          stroke="#414240"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
