import { useState } from 'react';
import { ChevronDown } from 'assets/icons/index';
import CustomPopover from 'components/UI/Popover';
import { useHistory } from 'react-router-dom';
import TemplateSelector, { TEMPLATE_MODES } from './components/TemplateSelector';

export const PreviewInvoice = ({
  data,
  loading,
  actions: Actions,
  onSubmit,
  preview,
  onHandlePreview,
  isRecurring,
  isEdit,
  handleEdit,
  updateInvoiceLoading,
  templatePreference,
  updateTemplatePreference,
}) => {
  const { push } = useHistory();

  const [isButtonToggle, setIsButtonToggle] = useState(false);
  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  return (
    <section className="border-top mt-4 overview-holder">
      <main className="invoice-preview-wraper mt-4">
        <TemplateSelector
          data={data}
          mode={TEMPLATE_MODES.PREVIEW}
          preference={templatePreference}
          handleSelect={updateTemplatePreference}
        />
      </main>

      <div className="border-top my-4">
        <div className="d-flex justify-content-between flex-wrap mt-3">
          <div
            className="d-flex ms-auto gap-3 right-side align-items-center position-relative"
            style={{ padding: '10px' }}
          >
            <button
              className="add-button add-custom w-100"
              onClick={preview ? onHandlePreview : () => push('/receivables/invoices')}
            >
              {preview ? 'Edit' : 'Cancel'}
            </button>

            <div
              className="add-button tab-hover-effect add-custom min-w-fit"
              onClick={() => (isEdit ? handleEdit() : onSubmit(true))}
              disabled={updateInvoiceLoading || loading}
            >
              Save as draft
            </div>

            <div className="d-flex">
              <button
                disabled={loading}
                onClick={() => onSubmit(false)}
                className={`${
                  isRecurring && 'is-plain'
                } px-3 nowrap dropdown-btn action-btn text-white`}
              >
                Save and send
              </button>

              {!isRecurring && (
                <CustomPopover
                  placement="top-start"
                  id="invoice-schedule"
                  zIndex="1"
                  content={<Actions />}
                  showPopover={isButtonToggle}
                  clickOutside={handleButtonToggle}
                >
                  <button
                    className="dropdown-btn"
                    disabled={loading}
                    onClick={handleButtonToggle}
                  >
                    <ChevronDown
                      color="#ffffff"
                      className={`icon ${isButtonToggle && 'is-toggled'}`}
                    />
                  </button>
                </CustomPopover>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
