import {
  ArrowUpRightIcon,
  ChevronDown,
  PencilIcon,
  PlusIcon,
  WalletIconO1,
  WarningIcon,
} from 'assets/icons';
import ConfirmDialog from 'components/ConfirmDialog';
import Timeline from 'components/Timeline';
import ItemInfo from 'components/TransactionModal/itemInfo';
import CustomButton from 'components/UI/CustomButton';
import DrawerBody from 'components/UI/CustomDrawer/components/DrawerBody';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import CustomTextarea from 'components/UI/CustomTextarea';
import Loading from 'components/UI/Loading';
import { toastError } from 'components/UI/toast';
import ApprovalModal from 'pages/Approvals/components/ApprovalModal';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Modal as BootstrapModal, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  fetchSingleApproval,
  getPendingApprovalAsset,
  reviewRequest,
} from 'redux/actions/ApprovalAction';
import { updateBill } from 'redux/actions/BillAction';
import { getBalances, getBudgets } from 'redux/actions/BudgetsAction';
import { updateReimbursements } from 'redux/actions/ReimbursementsAction';
import {
  editBatchTransactionById,
  editTransactionById,
} from 'redux/actions/TransactionsAction';

import bankIcon from 'assets/icons/bank-icon2.svg';
import BanksIcons from 'assets/icons/banks';
import classNames from 'classnames';
import SchedulePayment from 'components/TransactionModal/SchedulePayment';
import NewReceiptUpload from 'components/UI/CustomDrawer/components/NewReceiptUpload';
import { PaymentSkeleton } from 'components/UI/CustomDrawer/components/payment-skeleton';
import PaymentHeader from 'components/UI/CustomDrawer/components/PaymentHeader';
import { scrollHook } from 'components/UI/CustomDrawer/components/scroll-hook';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';
import ImgCard from 'components/UI/ImgCard';
import ModalContainerWrap from 'components/UI/Modal/ModalContainerWrap';
import CustomPopover from 'components/UI/Popover';
import BadgeType from 'components/UI/Table/BadgeType';
import CreateNewVendor from 'pages/Vendor/CreateNewVendor';
import { RESET_BLOCK_APPROVAL } from 'redux/reducers/ApprovalReducer';
import { RESET_BLOCK_REIMBURSEMENTS } from 'redux/reducers/ReimbursementsReducer';
import { RESET_BLOCK_TRANSACTIONS } from 'redux/reducers/TransactionsReducer';
import { returnSliceString } from 'utils';
import {
  buildVendorsTableData,
  capitalizeFirstLetter,
  getAvailableBalance,
  getColor,
  getFormattedDate,
  groupSourceOptions,
  STATUS,
} from 'utils/helper';
import { QUERY_TYPE_MAP } from 'utils/utility';
import DeadlinePicker from 'components/TransactionModal/DeadlinePicker';
import InputDialog from 'components/TransactionModal/InputDialog';
import { getRequests } from 'redux/actions/RequestsAction';

const PendingApprovalModal = ({
  isOpen,
  data,
  setData,
  userCode,
  typeQuery,
  reloadData,
  setAssetData,
  setSelectedAsset,
}) => {
  if (!data) return;

  const [declineState, setDeclineState] = useState(false);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const parentRef = useRef(null);
  const {
    fetchSingleApproval: {
      loading: singleLoading,
      success: singleSuccess,
      data: approvalData,
    },
    relatedApprovals: { data: relatedData },
  } = useSelector(({ approval }) => approval);

  const [assetViewer, setAssetViewer] = useState(false);

  useEffect(() => {
    if (data?.code && !singleLoading && !singleSuccess) {
      dispatch(fetchSingleApproval(data?.code));
    }
  }, [data]);

  // const createByMe = user?.code === data?.transaction?.user?.code;

  const handleClose = () => {
    setAssetData && setAssetData(null);
    setData(null);
    dispatch({ type: RESET_BLOCK_APPROVAL, blockType: 'fetchSingleApproval' });
    dispatch({ type: RESET_BLOCK_APPROVAL, blockType: 'pendingApprovalAssets' });
    setAssetViewer(false);
    setSelectedAsset(null);
    toggleAssetViewer(false);
  };

  const setReceiptList = (list) => {
    setAssetData && setAssetData(list);
  };

  const handleAssetViewer = (asset) => {
    setSelectedAsset(asset);
    setAssetViewer(true);
  };

  const toggleAssetViewer = () => setAssetViewer(!assetViewer);

  return (
    <ModalContainerWrap
      parentRef={parentRef}
      isWhite={declineState}
      modalName={declineState ? 'Decline request' : 'Request details'}
      onClose={handleClose}
      loader={<PaymentSkeleton />}
      loading={singleLoading || !approvalData}
      goBack={declineState ? () => setDeclineState(false) : null}
    >
      <PendingView
        parentRef={parentRef}
        declineState={declineState}
        typeQuery={typeQuery}
        setDeclineState={setDeclineState}
        data={data}
        approvalData={approvalData}
        relatedData={relatedData && relatedData}
        setData={setData}
        userCode={userCode}
        reloadData={reloadData}
        handleClose={handleClose}
        setAssetViewer={handleAssetViewer}
        getReceiptList={setReceiptList}
      />
    </ModalContainerWrap>
  );
};

const DeclineStateComponent = ({ goBack, code, setData, typeQuery, reloadData }) => {
  const [input, setInput] = useState({ reason: '' });
  const dispatch = useDispatch();
  const onHandleValueChange = (event) => {
    setInput({
      reason: event.target.value,
    });
  };

  const {
    reviewRequest: { loading, success },
  } = useSelector(({ approval }) => approval);

  useEffect(() => {
    if (success) {
      setData(null);

      if (typeQuery === QUERY_TYPE_MAP.INVOICE) reloadData();
    }
  }, [success]);

  const handleSubmit = () => {
    if (!input.reason) return toastError('Please enter a reason');
    dispatch(reviewRequest({ code, status: 'declined', reason: input.reason }));
  };

  return (
    <section className="information-wrapper decline-request-container">
      <div className="d-flex flex-column justify-content-between px-4">
        <Row className="mb-3">
          <CustomTextarea
            row={5}
            label="Reason for declining"
            name="reason"
            value={input?.reason}
            onChange={onHandleValueChange}
            placeholder="Specify the reason for the decline"
          />
        </Row>

        <div
          className="position-fixed bottom-0 card-modal-footer bg-white px-4 py-2 border-top w-100"
          style={{ right: 0 }}
        >
          <div className="d-flex align-items-center gap-2 justify-content-end">
            <CustomButton
              withoutBg
              onClick={goBack}
              disabled={loading}
              className="base-button text-sm font-medium border  black-transparent"
            >
              Cancel
            </CustomButton>
            <CustomButton
              onClick={handleSubmit}
              disabled={loading}
              loading={loading}
              className="base-button text-sm font-medium dark-button"
            >
              Decline
            </CustomButton>
          </div>
        </div>
      </div>
    </section>
  );
};

export const TRIGGER_STATE = {
  transaction: 'payer',
  reimbursement: 'user',
  batchTransactions: 'payer',
  invoice: 'user',
  bill: 'vendor',
};

const TRIGGER_STATE_RECEIVER = {
  transaction: 'recipient',
  reimbursement: 'vendor',
  batchTransactions: '',
  bill: 'vendor',
};

const PendingView = ({
  declineState,
  data,
  approvalData,
  relatedData,
  setData,
  setDeclineState,
  userCode,
  handleClose,
  setAssetViewer,
  getReceiptList,
  typeQuery,
  reloadData,
  parentRef,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    pendingApprovalAssets: { loading: assetLoading, data: allAssets },
  } = useSelector(({ approval }) => approval);
  const {
    reviewRequest: { loading, success },
  } = useSelector(({ approval }) => approval);

  const {
    fetchCategories: { data: categoryData },
    deleteCategories: { success: successDelete, loading: loadingDelete },
  } = useSelector(({ categories }) => categories);

  const {
    getBalances: { data: balances, loading: loadingBalances, success: successBalance },
    getBudget: { data: budgetData = {} },
  } = useSelector(({ budgets }) => budgets);

  const {
    updateReimbursement: {
      loading: updateLoading,
      success: updatingSuccess,
      error: updatingError,
    },
  } = useSelector(({ reimbursement }) => reimbursement);

  const {
    editTransactionById: {
      loading: isEditing,
      success: isEditSuccess,
      error: isEditError,
    },
    editBatchTransactionById: {
      loading: isEditingBatch,
      success: isEditSuccessBatch,
      error: isEditErrorBatch,
    },
  } = useSelector(({ transaction }) => transaction);

  const {
    updateBill: { loading: isUpdating, success: updateSuccess },
  } = useSelector(({ bills }) => bills);

  const {
    updateVendor: { success: successUpdateVendors },
  } = useSelector(({ vendors }) => vendors);

  const [categoryVisible, setCategoryVisible] = useState(false);
  const [isEditingCategory, setIsEditingCategory] = useState(false);
  const [expenseCategories, setExpenseCategories] = useState({
    value: '',
  });

  const [ruleCode, setRuleCode] = useState(null);
  const [approvalType, setApprovalType] = useState(null);
  const [receiptCode, setReceiptCode] = useState('');
  const [receiptUploading, setReceiptUploading] = useState(false);
  const [saveButton, setSaveButton] = useState(false);
  const [budgetVisible, setBudgetVisible] = useState(false);
  const [budgetLoading, setBudgetLoading] = useState(false);
  const [addDeadLine, setAddDeadLine] = useState(false);
  const [editAmount, setEditAmount] = useState(false);

  const [approve, setIsApproving] = useState(false);

  const [description, setDescription] = useState({
    visible: false,
    data: '',
    isEditingDescription: false,
  });

  const { budgets = [] } = budgetData;

  const [loadPage, setLoadPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  useEffect(() => {
    if (budgets.length === 0) dispatch(getBudgets({ status: 'active' }));
  }, []);

  const budgetsList = useMemo(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets, 'budgets');
      const available_balance = getAvailableBalance(balances?.balances, 32, false, true);

      return [...available_balance].concat([...budget]);
    }
  }, [balances?.budgets, balances?.balances]);

  const { code, approvals, batchData, type } = data;

  async function loadOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = budgetsList
      .filter((item) => item?.value !== approvalData?.source?.code)
      .filter((item) => item?.name?.toLowerCase().includes(search?.toLowerCase()));
    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  const {
    user: { data: userData },
  } = useSelector(({ auth }) => auth);

  const onHandleGetSelected = (value) => {
    hitEndpoint(approvalType, dataInfo?.code, 'category', value.value);
    setCategoryVisible(false);

    setExpenseCategories({ value });
    setIsEditingCategory(true);
  };

  const handleDescriptionChange = (val) => {
    setDescription({
      ...description,
      data: val.target.value,
    });
    setSaveButton(true);
  };

  /**
   * @param {string} approvalType if this is a reimbursment or transaction, just past the approvalType variable
   * @param {string} code the code of the transaction type; just use the dataInfo?.code
   * @param {string} key the property your are trying to edit
   * @param {*} value the value of the property you are editing
   */
  const hitEndpoint = (approvalType, code, key, value) => {
    if (approvalType === 'reimbursement') {
      dispatch(updateReimbursements({ code, [key]: value }));
    } else if (approvalType === 'batchTransactions') {
      dispatch(editBatchTransactionById({ transaction_code: code, [key]: value }));
    } else if (approvalType === 'bill') {
      dispatch(updateBill({ method: key, [key]: value, code }));
    } else {
      dispatch(
        editTransactionById({
          [key]: value,
          transaction_code: code,
        }),
      );
    }
  };

  const handleDescription = () => {
    setDescription({
      ...description,
      isEditingDescription: true,
    });
    if (description?.data !== dataInfo?.description) {
      hitEndpoint(approvalType, dataInfo?.code, 'description', description?.data);
    }
  };

  const saveBudget = (budget) => {
    setBudgetLoading(true);
    const source = budget?.value?.startsWith('blc_') ? 'balance' : 'budget';
    hitEndpoint(approvalType, dataInfo?.code, source, budget?.value);
  };

  const triggerMap = {
    reimbursement: approvalData?.reimbursement,
    batchTransactions: approvalData?.batchTransactions,
    invoice: approvalData?.invoice,
    bill: approvalData?.bill,
  };

  let triggerType = 'transaction';

  for (const [type, value] of Object.entries(triggerMap)) {
    if (value !== null) {
      triggerType = type;
      break;
    }
  }

  const dataInfo = approvalData ? approvalData[triggerType] ?? {} : {};
  const { amount, currency, payer: org } = dataInfo ?? {};

  useEffect(() => {
    setApprovalType(triggerType);
  }, [triggerType]);

  useEffect(() => {
    if (dataInfo) {
      setDescription({
        ...description,
        data: dataInfo?.description,
      });
    }
  }, [dataInfo]);

  const isBatchPayment = type === 'Batch';

  useEffect(() => {
    if (receiptCode?.length) {
      dispatch(
        approvalType === 'reimbursement'
          ? updateReimbursements({ receipt: receiptCode, code: dataInfo?.code })
          : editTransactionById({
              receipt: receiptCode,
              transaction_code: dataInfo?.code,
            }),
      );
      setReceiptUploading(true);
    }
  }, [receiptCode, approvalType]);

  useEffect(() => {
    if (!updateLoading && updatingSuccess) {
      dispatch(fetchSingleApproval(approvalData?.code));
      dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'updateReimbursement' });
      turnOffLoaders();
    }

    if (!(isEditing || isEditingBatch) && (isEditSuccess || isEditSuccessBatch)) {
      dispatch(fetchSingleApproval(approvalData?.code));
      dispatch({
        type: RESET_BLOCK_TRANSACTIONS,
        blockType: 'editTransactionById',
      });
      turnOffLoaders();
    }

    if (!updateLoading && !updatingSuccess && updatingError) {
      turnOffLoaders();
    }

    if (
      !(isEditing || isEditingBatch) &&
      !(isEditSuccess || isEditSuccessBatch) &&
      (isEditError || isEditErrorBatch)
    ) {
      turnOffLoaders();
    }

    if (updateSuccess || successUpdateVendors) {
      dispatch(fetchSingleApproval(approvalData?.code));
      turnOffLoaders();
    }
  }, [
    updateLoading,
    updatingSuccess,
    updatingError,
    isEditSuccess,
    isEditSuccessBatch,
    isEditErrorBatch,
    isEditing,
    isEditError,
    updateSuccess,
    successUpdateVendors,
  ]);

  const turnOffLoaders = () => {
    setIsEditingCategory(false);
    setBudgetLoading(false);
    setDescription({
      ...description,
      isEditingDescription: false,
    });
  };

  const [hasApproved, setHasApproved] = useState(false);
  const [payNow, setPayNow] = useState(true);

  const handleApproveRequest = ({ schedule }) => {
    dispatch(
      reviewRequest({
        code,
        status: 'approved',
        actionLater: schedule ? undefined : !payNow,
        schedule,
      }),
    );
  };

  useEffect(() => {
    if (success) {
      setIsApproving(false);
      dispatch(fetchSingleApproval(approvalData?.code));

      if ([QUERY_TYPE_MAP.INVOICE, QUERY_TYPE_MAP.BILL].includes(typeQuery)) {
        reloadData();
      }
    }
  }, [success]);

  useEffect(() => {
    if (approvals && userCode) {
      approvals.forEach((approval) => {
        if (approval.approver?.user?.code === userCode) {
          setHasApproved(true);
        }
      });
    }
  }, [approvals, userCode]);

  const dataMap = {
    Payments: approvalData?.transaction?.recipient,
    Reimbursements: approvalData?.reimbursement?.user,
    Bill: approvalData?.bill?.vendor,
  };

  const vendorDataAliaser = () => {
    const payload = dataMap[approvalData?.trigger] || {};

    const transactionAccount = approvalData?.transaction?.bank_account;

    const { name: vendorName = '', bankAccounts = [], BankAccounts = [] } = payload;

    const name = vendorName || `${payload?.firstName} ${payload?.lastName}`;
    const code = ['Payments', 'Bill'].includes(approvalData?.trigger)
      ? payload?.code
      : approvalData?.reimbursement?.vendor?.code ?? '';
    const isVendor = code?.split('_')[0] === 'vdr';

    return {
      name,
      code,
      isVendor,
      bankAccount: bankAccounts[0] || BankAccounts[0] || transactionAccount,
    };
  };

  const receipientName =
    dataInfo[TRIGGER_STATE[triggerType]]?.name ||
    `${dataInfo[TRIGGER_STATE[triggerType]]?.firstName} ${
      dataInfo[TRIGGER_STATE[triggerType]]?.lastName
    }`;
  const recipientCode = dataInfo[TRIGGER_STATE[triggerType]]?.code;

  const receiverType = TRIGGER_STATE_RECEIVER[triggerType];
  const isVendor = dataInfo?.[receiverType]?.code?.startsWith('vdr');
  const isBeneficiary = dataInfo?.[receiverType]?.code?.startsWith('usr');

  const getRecipientDetail = (property) => {
    if (triggerType === 'batchTransactions') {
      return dataInfo?.[property] ?? '';
    }

    if (triggerType === 'invoice') {
      return dataInfo?.customer?.[property] ?? '';
    }

    return property === 'code'
      ? isVendor
        ? dataInfo[receiverType]?.code
        : dataInfo[receiverType]?.beneficiary?.code
      : dataInfo[receiverType]?.name ??
          `${dataInfo[receiverType]?.firstName}  ${dataInfo[receiverType]?.lastName}`;
  };

  const receiver =
    receiverType === 'vendor' ? dataInfo[receiverType]?.name : getRecipientDetail('name');

  const receiverCode =
    receiverType === 'vendor' ? dataInfo[receiverType]?.code : getRecipientDetail('code');

  const entity =
    approvalData?.entity === 'transactions' ? 'transaction' : approvalData?.entity;
  const bodyData = [
    {
      title: 'Requested by',
      value: receipientName,
      icon: (
        <div>
          <ImgCard initials={receipientName?.slice('')[0]} />
        </div>
      ),
      withBadge: true,
    },
    {
      title: 'Transaction type',
      value: capitalizeFirstLetter(
        triggerType === 'batchTransactions' ? 'Batch transactions' : 'Payment',
      ),
    },
    ...(typeQuery === 'invoice'
      ? [{ title: 'Invoice id', value: approvalData[triggerType]?.code }]
      : []),
    { title: 'Requested on', value: getFormattedDate(approvalData?.created_at) },
    {
      title: 'Deadline on',
      value: approvalData?.[entity].deadLine ? (
        approvalData?.[entity]?.deadLine
      ) : (
        <span style={{ color: '#D28B28' }}>Add a deadline</span>
      ),
      onClick: () => {
        setAddDeadLine(true);
      },
    },
  ];
  if (triggerType === 'reimbursement')
    bodyData.push({ title: 'Team', value: dataInfo?.team?.name ?? '-' });

  useEffect(() => {
    if (dataInfo?.receipts?.length) {
      dispatch(getPendingApprovalAsset(dataInfo?.receipts));
    }
  }, [dataInfo]);

  const viewImgUrl = (asset) => {
    setAssetViewer(asset);
    getReceiptList(allAssets);
  };

  const approvers = [];

  const approvalStages = approvalData?.rules.map(({ approvalStages, rule }) => ({
    approvalStages,
    rule,
  }));

  relatedData?.relatedApprovalRequests?.forEach((relations) => {
    const rule = relations.rule;
    relations.approvalStages.forEach((stages) => {
      if ([1, 'all'].includes(stages.threshold)) {
        approvers.push({
          rule,
          status: 'pending',
          message: '',
          type: stages.threshold,
          approvers: stages?.approvers?.map((stage) => {
            return {
              user: stage.user,
              approver_code: stage.code,
              message: '',
            };
          }),
        });
      } else {
        stages.approvers.forEach((item) => {
          approvers.push({
            rule,
            status: 'pending',
            message: '',
            type: 'all',
            user: item.user,
          });
        });
      }
    });

    if (relations.approvals.length) {
      relations.approvals.forEach((item) => {
        approvers.push({
          rule,
          user: item?.approver?.user,
          date: getFormattedDate(item?.updated_at),
          status: item?.status,
          message: item?.reason ?? '',
        });
      });
    }
  });

  let alreadyApprovedUser = null;
  approvers.forEach((item) => {
    if (item.status === 'pending') {
      item.approvers.forEach((approver) => {
        if (approver?.user?.code === userData?.user?.code) {
          alreadyApprovedUser = false;
        }
      });
    }
    if (item.status === 'approved' && item?.user.code === userData?.user?.code) {
      alreadyApprovedUser = true;
    }
  });

  const approvalRequestUser = dataInfo[TRIGGER_STATE[triggerType]];
  const hasManager =
    userData?.user?.code === approvalRequestUser?.code && userData?.user?.manager?.code;

  let yourTurnToApprove = null;

  (approvers.length &&
    approvers.forEach((stage) => {
      stage?.approvers?.forEach((item) => {
        if (
          stage.status === 'pending' &&
          item?.user?.code === userData?.user?.code &&
          alreadyApprovedUser === false
        ) {
          yourTurnToApprove = true;
        }
      });
    })) ||
    (approvalData?.status === 'pending' && !approvalData?.code && !hasManager);

  const cancelDescriptionEdit = () => {
    setDescription({
      ...description,
      visible: false,
    });
    setSaveButton(false);
  };

  const goToRule = (code) => {
    setRuleCode(code);
  };

  const closeApprovalRule = () => setRuleCode(null);

  const sliceBudgetValue = returnSliceString(
    approvalData?.source?.name,

    35,
  );

  const isBalance = dataInfo?.source?.code?.startsWith('blc_');

  const checkConditions = (approvers) => {
    // Check if there's only one object with the specified conditions
    const pendingApprovers = approvers.filter((data) => data?.status === 'pending');
    const singleObjectCheck =
      pendingApprovers?.length === 1 &&
      pendingApprovers[0]?.type !== 'all' &&
      pendingApprovers[0]?.approvers &&
      pendingApprovers[0]?.approvers.some(
        (approver) => approver?.user?.code === userData?.user?.code,
      );

    // Check if all approvers have the specified user code
    const allApproversCheck = pendingApprovers.every((data) =>
      data?.approvers?.every((approver) => approver?.user?.code === userData?.user?.code),
    );

    return singleObjectCheck || allApproversCheck;
  };

  const canApprovePay = checkConditions(approvers) && yourTurnToApprove;

  const [isButtonToggle, setIsButtonToggle] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            setPayNow(false);
            setIsApproving(true);
            handleButtonToggle();
          }}
        >
          Approve only
        </div>

        {triggerType !== 'batchTransactions' && (
          <div
            className="actionLink"
            onClick={() => {
              setIsSchedule(true);
              handleButtonToggle();
            }}
          >
            Approve and schedule payment
          </div>
        )}
      </div>
    );
  };

  const [isRequest, setIsRequest] = useState(false);
  const [isVendorOpen, setIsVendorOpen] = useState(false);
  const vendorData = buildVendorsTableData([dataMap[approvalData?.trigger] || {}]);

  const additionalInfo = () => {
    setIsVendorOpen(!isVendorOpen);
    setIsRequest(true);
  };

  const DialogSubTitle = () => {
    const defaultText = 'Are you sure you want to approve this request?';

    return typeQuery === QUERY_TYPE_MAP.BILL && !vendorDataAliaser().bankAccount && payNow
      ? `${defaultText} Vendor account missing, bill would be approved only.`
      : defaultText;
  };

  const routeToVendor = () => {
    history.push(`/expenses/vendors/profile/${vendorDataAliaser()?.code}`);
  };

  const UpdateVendorDetail = ({ text }) => (
    <span
      className="d-flex align-items-center gap-2 icon-animate"
      style={{ color: '#D28B28' }}
      onClick={additionalInfo}
    >
      <PlusIcon width="12" height="12" /> {text}
    </span>
  );

  const { scrollSize, fontSize, iconSize, setScrollSize } = scrollHook(parentRef);

  const [tabKey, setTabKey] = useState('details');

  const handleSelectKey = (tabKey) => {
    setTabKey(tabKey);
    setScrollSize(0);
  };

  const handleImgError = (e) => {
    e.target.onerror = null;
    e.target.src = bankIcon;
  };
  const createdByMe = userData?.user?.code === recipientCode;

  const RequestDetails = ({ typeQuery }) => (
    <>
      <div
        className={classNames(
          'px-4 bg-white fixed-header',
          scrollSize > 80 ? 'pb-0' : 'pb-2',
        )}
      >
        <PaymentHeader
          fontSize={fontSize}
          iconSize={iconSize}
          scrollSize={scrollSize}
          status={approvalData?.status}
          receipient={receiver}
          recipientLink={
            isVendor
              ? `/expenses/vendors/profile/${receiverCode}`
              : isBeneficiary
              ? `/teams/members/profile/${receiverCode}`
              : false
          }
          moneyData={{
            amount,
            currency,
          }}
          badgeIcon={
            !!approvalData?.violations?.length ? <WarningIcon className="me-1" /> : null
          }
          imageIcon={WalletIconO1}
          createdByMe={createdByMe}
          onClick={() => setEditAmount(approvalData?.status === STATUS.PENDING)}
        />
      </div>

      <Tabs
        id="contolled-tab-example"
        activeKey={tabKey}
        onSelect={handleSelectKey}
        className="bg-white position-fixed w-100 pt-0 mt-0"
        style={{ zIndex: 99999, top: 220 - (scrollSize / 100) * 40 }}
      >
        <Tab
          eventKey="details"
          title={<div className="d-flex">Details</div>}
          tabClassName="new-tab ms-4"
          style={{ paddingTop: 165 }}
        >
          <div className="slide-in-right pb-5">
            <div className="details-tab p-4">
              <div className="details-view mb-4">
                <p>Overview</p>
                <div className="container">
                  <DrawerBody data={bodyData} />
                </div>
              </div>

              {typeQuery !== QUERY_TYPE_MAP.INVOICE && (
                <div className="details-view mb-4">
                  <p>Recipient details</p>
                  <div className="container">
                    {typeQuery !== QUERY_TYPE_MAP.INVOICE && (
                      <>
                        <div>
                          <div>
                            <ItemInfo
                              title={
                                vendorDataAliaser().isVendor ? 'Vendor name' : 'Name'
                              }
                              withAction
                              value={
                                <div className="d-flex align-items-center gap-2">
                                  <ImgCard
                                    size="small"
                                    fullWidth={false}
                                    initials={vendorDataAliaser()?.name.charAt(0)}
                                  />
                                  <span>{vendorDataAliaser().name}</span>
                                </div>
                              }
                              action={routeToVendor}
                              icon={
                                vendorDataAliaser()?.isVendor ? (
                                  <ArrowUpRightIcon
                                    stroke="#D28B28"
                                    width="16"
                                    height="16"
                                  />
                                ) : null
                              }
                            />
                          </div>

                          <div className="my-3">
                            <ItemInfo
                              title="Bank"
                              value={
                                vendorDataAliaser()?.isVendor ||
                                vendorDataAliaser()?.bankAccount?.bankName
                                  ? vendorDataAliaser()?.bankAccount?.bankName ?? (
                                      <UpdateVendorDetail text="Add bank name" />
                                    )
                                  : 'Bank details not available'
                              }
                              withBadge
                              icon={
                                vendorDataAliaser()?.bankAccount?.bankName ? (
                                  <ImgCard
                                    size="small"
                                    fullWidth={false}
                                    initials={vendorDataAliaser()?.bankAccount?.bankName?.charAt(
                                      0,
                                    )}
                                  >
                                    <img
                                      onError={handleImgError}
                                      className="account-logo"
                                      src={
                                        BanksIcons[
                                          vendorDataAliaser()?.bankAccount?.bankName?.toLowerCase()
                                        ] ?? bankIcon
                                      }
                                    />
                                  </ImgCard>
                                ) : null
                              }
                            />
                          </div>

                          <ItemInfo
                            title="Account number"
                            value={
                              vendorDataAliaser()?.isVendor ||
                              vendorDataAliaser()?.bankAccount?.number
                                ? vendorDataAliaser()?.bankAccount?.number ?? (
                                    <UpdateVendorDetail text="Add account number" />
                                  )
                                : 'Bank details not available'
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}

              <div className="details-view mb-4">
                <p>Payment details</p>
                <div className="container">
                  {typeQuery !== QUERY_TYPE_MAP.INVOICE && (
                    <>
                      <ItemInfo
                        title="Category"
                        isLink={
                          dataInfo?.category?.code
                            ? `/compliances/categories/${dataInfo?.category?.code}/details`
                            : false
                        }
                        withAction={
                          !['pending', 'declined'].includes(approvalData?.status)
                        }
                        action={() => setCategoryVisible(!categoryVisible)}
                        isLoading={isEditingCategory}
                        hideLinkIcon
                        value={
                          <BadgeType
                            value={{
                              value:
                                expenseCategories.value.label ??
                                dataInfo?.category?.name ??
                                '-',
                              rightIcon: dataInfo?.category?.name && (
                                <ArrowUpRightIcon stroke="#000" width="16" height="16" />
                              ),
                              color: getColor(
                                expenseCategories.value.label ??
                                  dataInfo?.category?.name ??
                                  '-',
                              ),
                            }}
                          />
                        }
                        icon={<PencilIcon stroke="#D28B28" width="18" height="18" />}
                      />
                    </>
                  )}

                  {categoryVisible && (
                    <div className="padding-top-xxs">
                      <CategoryDropdown
                        placeholder="Select a category"
                        onChange={(val) => onHandleGetSelected(val)}
                        value={
                          expenseCategories.value ?? {
                            value: dataInfo?.category?.code,
                            label: dataInfo?.category?.name,
                          }
                        }
                        defaultValue={{
                          value: dataInfo?.category?.code,
                          label: dataInfo?.category?.name,
                        }}
                        name="category"
                      />
                    </div>
                  )}

                  {![QUERY_TYPE_MAP.INVOICE].includes(typeQuery) && (
                    <div className="padding-top-2">
                      <ItemInfo
                        title="Source"
                        isLink={
                          isBalance
                            ? `/accounts/${approvalData?.source?.code}`
                            : approvalData?.source?.type === 'budget'
                            ? `/expenses/budgets/${approvalData?.source?.code}/overview`
                            : false
                        }
                        withAction={
                          !['success', 'declined', 'processing'].includes(
                            approvalData?.status,
                          )
                        }
                        action={() => setBudgetVisible(!budgetVisible)}
                        value={
                          <div className="d-flex align-items-center gap-2">
                            {sliceBudgetValue && (
                              <ImgCard
                                size="small"
                                fullWidth={false}
                                initials={sliceBudgetValue?.charAt(0)}
                              >
                                <img
                                  onError={handleImgError}
                                  className="account-logo"
                                  src={bankIcon}
                                />
                              </ImgCard>
                            )}

                            <span>{sliceBudgetValue}</span>
                          </div>
                        }
                        isLoading={budgetLoading}
                        icon={
                          ![STATUS.APPROVED, STATUS.DECLINED].includes(
                            approvalData?.status,
                          ) && <PencilIcon stroke="#D28B28" width="18" height="18" />
                        }
                      />
                    </div>
                  )}

                  {budgetVisible && (
                    <div className="padding-top-xxs">
                      <CustomSelectRadio
                        placeholder="Select a source"
                        name="source"
                        onChange={(val) => saveBudget(val)}
                        defaultValue={
                          approvalData?.source?.code && {
                            value: approvalData?.source?.code,
                            label: approvalData?.source?.name,
                          }
                        }
                        loadOptions={loadOptions}
                      />
                    </div>
                  )}
                </div>
              </div>

              {![QUERY_TYPE_MAP.INVOICE, QUERY_TYPE_MAP.BILL].includes(typeQuery) && (
                <div>
                  <ItemInfo
                    title="Description"
                    flex={1}
                    value={dataInfo?.description}
                    withAction={
                      !['declined', 'processing'].includes(approvalData?.status)
                    }
                    hideValue={true}
                    action={() => {
                      setDescription({ ...description, visible: !description.visible });
                      setSaveButton(!saveButton);
                    }}
                    isLoading={description?.isEditingDescription}
                    icon={
                      !['declined', 'processing'].includes(approvalData?.status) && (
                        <PencilIcon stroke="#D28B28" width="18" height="18" />
                      )
                    }
                  />
                  <div className="padding-top-xxs">
                    <CustomTextarea
                      name="description"
                      placeholder="Description"
                      value={description?.data}
                      onChange={handleDescriptionChange}
                      disabled={
                        ['declined', 'processing'].includes(approvalData?.status) ||
                        !description?.visible
                      }
                      rowSize={4}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Tab>
        <Tab
          eventKey="attachements"
          title={<div className="d-flex">Attachments</div>}
          tabClassName="new-tab"
          style={{ paddingTop: 165 }}
        >
          <div className="slide-in-right p-4 pb-5">
            <div className="details-tab">
              <div className="details-view mb-4">
                <p>Receipts</p>
                <div className="container overflow-x-scroll">
                  {assetLoading && !allAssets?.length && (
                    <Loading color="#D28B28" size={24} />
                  )}
                  <NewReceiptUpload
                    receiptAssets={allAssets}
                    viewImgUrl={viewImgUrl}
                    addMore={true}
                    removeFile={!receiptCode?.length}
                    onChange={(value) =>
                      setReceiptCode(value?.map((item) => item.assetCode))
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Tab>
        <Tab
          eventKey="updates"
          title={<div className="d-flex">Updates</div>}
          tabClassName="new-tab"
          style={{ paddingTop: 165 }}
        >
          <div className="slide-in-right p-4 pb-5">
            <div className="details-tab">
              <div className="details-view mb-4">
                <Timeline
                  goToRule={goToRule}
                  data={approvers}
                  code={approvalData?.code}
                  pageFrom={`/transactions/requests`}
                  multipleRule={approvalData.rules.length > 1}
                />
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>

      <div className="position-fixed bottom-0 card-modal-footer bg-white px-4 py-2 border-top w-100">
        {saveButton && (
          <div className="d-flex align-items-center gap-2 justify-content-end">
            <CustomButton
              onClick={cancelDescriptionEdit}
              className="base-button text-sm font-medium  black-transparent"
            >
              Cancel
            </CustomButton>
            <CustomButton
              onClick={handleDescription}
              className="base-button text-sm font-medium dark-button"
            >
              {description?.isEditingDescription ? (
                <Loading color="#D28B28" size={20} />
              ) : (
                'Save changes'
              )}
            </CustomButton>
          </div>
        )}
        {yourTurnToApprove &&
          !saveButton &&
          !['declined', 'cancelled'].includes(approvalData?.status) && (
            <div className="d-flex align-items-center gap-2 justify-content-end">
              <CustomButton
                withoutBg
                className="base-button text-sm font-medium danger-button"
                onClick={() => setDeclineState(true)}
                disabled={hasApproved}
                style={{ width: 'fit-content', padding: '10px', minWidth: 0 }}
              >
                Decline
              </CustomButton>

              {/* <CustomButton
              className="base-button dark-button  text-sm font-medium"
              onClick={() => setIsApproving(true)}
              disabled={hasApproved ? true : loading}
            >
              Approve
            </CustomButton> */}

              <div className="d-flex">
                <button
                  disabled={hasApproved ? true : loading}
                  onClick={() => {
                    setIsApproving(true);
                    setPayNow(canApprovePay);
                  }}
                  className={classNames(
                    'px-3 nowrap dropdown-btn action-btn text-white',
                    {
                      ['rounded-3 px-4']: !canApprovePay,
                      'is-plain': typeQuery === QUERY_TYPE_MAP.INVOICE,
                    },
                  )}
                >
                  {canApprovePay
                    ? typeQuery === QUERY_TYPE_MAP.INVOICE
                      ? 'Approve and send'
                      : 'Approve and pay'
                    : 'Approve only'}
                </button>

                {typeQuery !== QUERY_TYPE_MAP.INVOICE && canApprovePay && (
                  <CustomPopover
                    placement="top-start"
                    id="invoice-schedule"
                    zIndex="9999"
                    content={<Actions />}
                    showPopover={isButtonToggle}
                    clickOutside={handleButtonToggle}
                  >
                    <button
                      className="dropdown-btn"
                      disabled={hasApproved ? true : loading}
                      onClick={handleButtonToggle}
                    >
                      <ChevronDown
                        color="#ffffff"
                        className={`icon ${isButtonToggle && 'is-toggled'}`}
                      />
                    </button>
                  </CustomPopover>
                )}
              </div>
            </div>
          )}

        <Modal show={editAmount} centered dialogClassName="custom-dialog">
          <InputDialog
            onClose={() => setEditAmount(false)}
            loading={loading}
            title="Update the amount"
            label="Enter the amount"
            onSubmit={({ amount, currency }) => {
              dispatch(
                approvalType === 'reimbursement'
                  ? updateReimbursements({
                      code: dataInfo?.code,
                      amount,
                    })
                  : editTransactionById({
                      amount,
                      transaction_code: dataInfo?.code,
                    }),
              );
              setTimeout(() => dispatch(getRequests()), 300);
            }}
            defaultValue={amount / 100}
            currency={currency}
          />
        </Modal>
        <Modal show={addDeadLine} centered dialogClassName="custom-dialog">
          <DeadlinePicker
            onClose={() => setAddDeadLine(false)}
            loading={loading}
            onDatePicked={({ pick }) => {
              dispatch(
                approvalType === 'reimbursement'
                  ? updateReimbursements({ deadLine: pick.date, code: dataInfo?.code })
                  : editTransactionById({
                      deadLine: pick.date,
                      transaction_code: dataInfo?.code,
                    }),
              );
            }}
          />
        </Modal>
        <Modal show={isSchedule} centered dialogClassName="custom-dialog">
          <SchedulePayment
            onClose={() => {
              setIsSchedule(false);
            }}
            loading={loading}
            setSchedule={handleApproveRequest}
            // handleCreate={onHandleSubmit}
          />
        </Modal>

        {approve && (
          <BootstrapModal
            show={true}
            centered
            dialogClassName="custom-dialog"
            className="custom-dialog"
          >
            <ConfirmDialog
              title="Approve request"
              subTitle={DialogSubTitle()}
              onConfirm={handleApproveRequest}
              loading={loading}
              onCancel={() => setIsApproving(false)}
              isDeleteDialog={false}
            />
          </BootstrapModal>
        )}

        <CreateNewVendor
          isOpen={isVendorOpen}
          isUpdate={true}
          toggleHandler={() => setIsVendorOpen(false)}
          data={vendorData?.[0]}
          isRequest={isRequest}
        />
      </div>
    </>
  );

  if (ruleCode)
    return (
      <ApprovalModal
        selectedRuleCode={ruleCode}
        isOpen={!!ruleCode}
        isEdit
        handleClose={closeApprovalRule}
        clearSelectedRule={closeApprovalRule}
      />
    );

  if (!declineState) {
    return <>{RequestDetails({ typeQuery })}</>;
  }
  return (
    <>
      <DeclineStateComponent
        goBack={() => setDeclineState(false)}
        setData={setData}
        code={isBatchPayment ? batchData?.code : code}
        typeQuery={typeQuery}
        reloadData={reloadData}
      />
    </>
  );
};

export default PendingApprovalModal;
