import React from 'react';
import './styles.scss';
import { AlertIcon, CancelICon, CloseMenuIcon } from 'assets/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toggleAlertBox } from 'redux/actions/ToggleAction';
import { WarningFilled } from '@ant-design/icons';
const FixedAlert = ({ alert }) => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  return (
    <div className="fixed-alert">
      <div className="fixed-alert-container">
        <div className="d-flex align-items-center gap-2">
          <div className="fixed-alert-content">
            <WarningFilled style={{ color: '#dcbc03' }} />
            <p>
              {alert?.reason} <span>{alert?.info}</span>
            </p>
          </div>
          <button onClick={() => push(alert?.action)} className="btn border">
            {alert?.button}
          </button>
        </div>
        <CancelICon
          onClick={() => dispatch(toggleAlertBox({}))}
          className="close-icon cursor"
          stroke="#fff"
        />
      </div>
    </div>
  );
};

export default FixedAlert;
// alert: { reason: null, info: null, button: null, action: null },
