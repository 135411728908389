import React from 'react';
import { getFormattedDate } from 'utils/helper';

export const approvalState = ({ data: singleData, user }) => {
  const approvers = [];

  singleData?.relatedApprovalRequests?.forEach((relations) => {
    const rule = relations.rule;
    relations.approvalStages.forEach((stages) => {
      if ([1, 'all'].includes(stages.threshold)) {
        approvers.push({
          rule,
          status: 'pending',
          message: '',
          type: stages.threshold,
          approvers: stages?.approvers?.map((stage) => {
            return {
              user: stage.user,
              approver_code: stage.code,
              message: '',
            };
          }),
        });
      } else {
        stages.approvers.forEach((item) => {
          approvers.push({
            rule,
            status: 'pending',
            message: '',
            type: 'all',
            user: item.user,
          });
        });
      }
    });

    if (relations.approvals.length) {
      relations.approvals.forEach((item) => {
        approvers.push({
          rule,
          user: item?.approver?.user,
          date: getFormattedDate(item?.updated_at),
          status: item?.status,
          message: item?.reason ?? '',
        });
      });
    }
  });

  let alreadyApprovedUser = null;
  approvers.forEach((item) => {
    if (item.status === 'pending') {
      item.approvers.forEach((approver) => {
        if (approver?.user?.code === user?.user?.code) {
          alreadyApprovedUser = false;
        }
      });
    }
    if (item.status === 'approved' && item?.user.code === user?.user?.code) {
      alreadyApprovedUser = true;
    }
  });

  const hasManager =
    user?.user?.code === singleData?.payer?.code && user?.user?.manager?.code;

  let yourTurnToApprove = null;

  (approvers.length &&
    approvers.forEach((stage) => {
      stage?.approvers?.forEach((item) => {
        if (
          stage.status === 'pending' &&
          item?.user?.code === user?.user?.code &&
          alreadyApprovedUser === false
        ) {
          yourTurnToApprove = true;
        }
      });
    })) ||
    (singleData?.approvalRequest?.status === 'pending' &&
      !singleData?.approvalRequest?.code &&
      !hasManager);

  const checkConditions = (approvers) => {
    // Check if there's only one object with the specified conditions
    const pendingApprovers = approvers.filter((data) => data?.status === 'pending');
    const singleObjectCheck =
      pendingApprovers?.length === 1 &&
      pendingApprovers[0]?.type !== 'all' &&
      pendingApprovers[0]?.approvers &&
      pendingApprovers[0]?.approvers.some(
        (approver) => approver?.user?.code === user?.user?.code,
      );

    // Check if all approvers have the specified user code
    const allApproversCheck = pendingApprovers.every((data) =>
      data?.approvers?.every((approver) => approver?.user?.code === user?.user?.code),
    );

    return singleObjectCheck || allApproversCheck;
  };

  const canApprovePay = checkConditions(approvers) && yourTurnToApprove;

  return {
    canApprovePay,
    yourTurnToApprove,
    approvers,
    code: singleData?.relatedApprovalRequests?.[0]?.code,
  };
};
