import React from 'react';
import { getFormattedDate } from 'utils/helper';

export const approvalState = ({ data: singleRequestData, user }) => {
  const alreadyApprovedUser = singleRequestData?.approvalRequest?.approvals?.find(
    (item) => item.approver?.user?.code === user?.user?.code,
  );

  const hasManager =
    user?.user?.code === singleRequestData?.user?.code && user?.user?.manager?.code;

  let yourApprovalRequest = singleRequestData?.relatedApprovalRequests?.find(
    (request) => {
      const foundApprover =
        request.status === 'pending' &&
        request.approvalStages?.some((stage) => {
          return (
            stage.status === 'pending' &&
            stage.approvers.some(
              (approver) =>
                approver.user.code === user?.user?.code && !alreadyApprovedUser,
            )
          );
        });
      return foundApprover && request;
    },
  );

  yourApprovalRequest = Array.isArray(yourApprovalRequest)
    ? yourApprovalRequest.length && yourApprovalRequest[0]
    : yourApprovalRequest;

  const yourTurnToApprove =
    !!yourApprovalRequest ||
    (singleRequestData?.status === 'pending' &&
      !hasManager &&
      singleRequestData?.relatedApprovalRequests.length === 0); // is pending, doesn't have a manager and does not have approval flow

  const approvers = [];

  singleRequestData?.relatedApprovalRequests?.forEach((approvalRule) => {
    approvalRule.approvalStages.forEach((item) => {
      if (approvalRule.status !== 'pending') return;
      if ([1, 'all'].includes(item.threshold)) {
        approvers.push({
          rule: { name: approvalRule.rule.name, code: approvalRule.rule.code },
          status: 'pending',
          message: '',
          type: item.threshold,
          approvers: item?.approvers?.map((stage) => {
            return {
              user: stage.user,
              approver_code: stage.code,
              message: '',
            };
          }),
        });
      } else {
        yourApprovalRequest?.approvers?.forEach((item) => {
          approvers.push({
            rule: { name: approvalRule.rule.name, code: approvalRule.rule.code },
            status: 'pending',
            message: '',
            type: 'all',
            user: item.user,
          });
        });
      }
    });
  });

  singleRequestData?.relatedApprovalRequests?.forEach((item) => {
    item.approvals?.forEach((item) => {
      approvers.push({
        user: item?.approver?.user,
        date: getFormattedDate(item?.updated_at),
        status: item?.status,
        message: item?.reason ?? '',
      });
    });
  });

  if (singleRequestData?.status !== 'pending' && singleRequestData?.reviewer !== null)
    approvers.push({
      user: singleRequestData?.reviewer,
      date: getFormattedDate(singleRequestData?.reviewed_on),
      status: singleRequestData?.status,
      message: singleRequestData?.note ?? '',
    });

  const checkConditions = (approvers) => {
    // Check if there's only one object with the specified conditions
    const pendingApprovers = approvers.filter((data) => data?.status === 'pending');
    const singleObjectCheck =
      pendingApprovers?.length === 1 &&
      pendingApprovers[0]?.type !== 'all' &&
      pendingApprovers[0]?.approvers &&
      pendingApprovers[0]?.approvers.some(
        (approver) => approver?.user?.code === user?.user?.code,
      );

    // Check if all approvers have the specified user code
    const allApproversCheck = pendingApprovers.every((data) =>
      data?.approvers?.every((approver) => approver?.user?.code === user?.user?.code),
    );

    return singleObjectCheck || allApproversCheck;
  };

  const canApprovePay = checkConditions(approvers) && yourTurnToApprove;

  return {
    canApprovePay,
    yourTurnToApprove,
    yourApprovalRequest,
    approvers,
    code: singleRequestData?.approvalRequest?.code,
  };
};
