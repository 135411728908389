import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomTextarea from 'components/UI/CustomTextarea';
import Success from 'components/UI/ModalSaving/Success';
import { toastError } from 'components/UI/toast';
import { useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reviewRequest } from 'redux/actions/ApprovalAction';

const DeclineRequest = ({ setStep, isLoaded, onClose, step, approvalCode }) => {
  const [value, setValue] = useState('');
  const history = useHistory();

  const handleValueChange = (e) => {
    setValue(e.target.value);
  };

  const dispatch = useDispatch();
  const {
    reviewRequest: { loading, success },
  } = useSelector(({ approval }) => approval);

  const handleSubmit = () => {
    if (!value) return toastError('Enter Description');

    if (approvalCode) {
      return dispatch(
        reviewRequest({ code: approvalCode, status: 'declined', reason: value }),
      );
    }
  };

  const handleCloseDecline = () => {
    if (setStep) setStep(1);
  };

  const closeModal = () => {
    if (step == 2 && !isLoaded) return handleCloseDecline();
    return onClose();
  };

  const infoCard = () => {
    return (
      <div className="information-wrapper decline-request-container">
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="color-grey-900 text-ds-xs font-semibold">Decline request</h2>
        </div>
        <Row className="mb-3">
          <CustomTextarea
            row={5}
            label="Reason for declining"
            name="reason"
            onChange={handleValueChange}
            value={value}
            placeholder="Specify the reason for the decline"
          />
        </Row>
        <Row className="mt-3">
          <div className="col-sm-6 col-md-6">
            <CustomButton
              withoutBg
              fullWidth
              className="add-button"
              onClick={handleCloseDecline}
              disabled={loading}
            >
              Cancel
            </CustomButton>
          </div>
          <div className="col-sm-6 col-md-6">
            <CustomButton
              fullWidth
              className="add-button"
              onClick={handleSubmit}
              disabled={loading}
              loading={loading}
            >
              Decline
            </CustomButton>
          </div>
        </Row>
      </div>
    );
  };

  return (
    <>
      <div className="card-modal-header" onClick={closeModal}>
        <div className="d-flex align-items-center cursor">
          {isLoaded ? <CloseOutlined /> : <ArrowLeftOutlined />}
          <span className="ps-1">{isLoaded ? 'Close' : 'Back'}</span>
        </div>
      </div>
      {isLoaded ? (
        <Success
          title="Thank you!"
          message="Your rejection has been taken into account and the applicant will be informed"
        />
      ) : (
        infoCard()
      )}
    </>
  );
};

export default DeclineRequest;
