import { InvoiceAmountInformation, InvoicePreviewTable } from '.';

import dayjs from 'dayjs';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { capitalizeFirstLetter, getCurrency, convertNaNToZero } from 'utils/helper';
import { TEMPLATE_MODES } from '../TemplateSelector';
import CurrencyFormat from 'react-currency-format';

const NewDawn = ({ data = {}, mode, isRecurring, LogoIcon, isInstalment }) => {
  const headers = [
    {
      key: 'item',
      minWidth: 130,
      label: `Item${data?.invoiceProducts?.length > 1 ? 's' : ''}`,
    },
    { key: 'quantity', label: 'Qty', minWidth: 40 },
    { key: 'rate', label: 'Unit Price' },
    { key: 'amount', label: 'Amount excl. Tax', align: 'right' },
  ];

  return (
    <section
      className={classNames('invoice-preview d-flex flex-column', {
        'is-demo': mode === TEMPLATE_MODES.DEMO,
        detail: mode === TEMPLATE_MODES.DASHBOARD_VIEW,
        'customer-view': mode === TEMPLATE_MODES.CUSTOMER_VIEW,
      })}
    >
      <InvoiceHeader data={data} LogoIcon={LogoIcon} />

      {isInstalment && data?.amountRemaining && (
        <section
          className="d-flex mt-4 align-items-end flex-column lhs"
          style={{ padding: '0 20px' }}
        >
          <div className="text">Balance due</div>
          <p className="header-title pt-1" style={{ marginBottom: 0, fontSize: 16 }}>
            <CurrencyFormat
              value={convertNaNToZero(data?.amountRemaining / 100).toFixed(2)}
              displayType="text"
              thousandSeparator={true}
              prefix={getCurrency(data?.currency || 'NGN')}
            />
          </p>
        </section>
      )}

      <section className="preview-table">
        <InvoicePreviewTable headers={headers} isRecurring={isRecurring} data={data} />
      </section>

      <section className="d-flex justify-content-end">
        <div className="account-info template-dawn">
          <InvoiceAmountInformation
            data={data}
            mode={mode}
            taxText="VAT"
            isRecurring={isRecurring}
          />
        </div>
      </section>

      <section className="invoice-preview-footer mt-auto">
        <InvoiceFooter data={data} />
      </section>
    </section>
  );
};

const InvoiceHeader = ({ data, LogoIcon }) => {
  return (
    <>
      <div className=" d-flex justify-content-between pb-3 mx-4">
        <div>
          <span style={{ fontSize: 28, fontWeight: 700 }}>Invoice</span>
        </div>

        <div className="invoice-image-holder">
          <img src={data?.logo ?? LogoIcon} alt="company logo" />
        </div>
      </div>

      <header className="bg-secondary-50 border-12">
        <Row>
          <Col>
            {data?.title && (
              <Row className="pb-3">
                <div className="lhs">
                  <div>
                    <h1 className="header-title">Title/Purchase order</h1>
                    <p className="text">{data?.title}</p>
                  </div>
                </div>
              </Row>
            )}

            <Row className="pb-3">
              <div className="lhs">
                <div>
                  <h1 className="header-title">Issue Date</h1>
                  <p className="text pt-1">
                    {dayjs(data?.issuedDate || new Date()).format('DD MMM, YYYY')}
                  </p>
                </div>
              </div>
            </Row>

            <Row>
              <div className="lhs">
                <div>
                  <h1 className="header-title">Due Date</h1>
                  <p className="text pt-1">
                    {data?.dueDate ? dayjs(data?.dueDate).format('DD MMM, YYYY') : '-'}
                  </p>
                </div>
              </div>
            </Row>
          </Col>
          <Col>
            <Row>
              <div className="lhs">
                <div>
                  <h1 className="header-title">From:</h1>
                  <p className="header-sub">{`${data?.company?.name}`}</p>
                </div>
                <p className="text">{data?.company?.contactEmail}</p>
                <p className="text">
                  {data?.company?.contact_phone ? `+${data.company.contact_phone}` : ''}
                </p>
                <p className="text">{data?.address}</p>
              </div>
            </Row>
          </Col>
          <Col>
            <div className="lhs">
              <div>
                <h1 className="header-title">Billed to:</h1>
                <p className="header-sub">
                  {data?.customer?.name ||
                    `${data?.customer?.firstName} ${data?.customer?.lastName}`}
                </p>
              </div>
              <p className="text">{data?.customer?.email}</p>
              <p className="text">
                {data?.customer?.phoneNumber?.localFormat
                  ? `+${data?.customer?.phoneNumber?.countryCode ?? ''}${
                      data?.customer?.phoneNumber?.localFormat ?? ''
                    }`
                  : null}
              </p>
              {data?.customer?.address?.street && (
                <p className="text">
                  {`${data?.customer?.address?.street}, ${
                    data?.customer?.address?.city
                  }, ${capitalizeFirstLetter(
                    data?.customer?.address?.country?.toLowerCase(),
                  )}`}
                </p>
              )}
              {data?.customer?.phoneNumber?.tin && (
                <p className="text">TIN: {data?.customer?.phoneNumber?.tin}</p>
              )}
            </div>
          </Col>
        </Row>
      </header>
    </>
  );
};

const InvoiceFooter = ({ data }) => {
  const { description, account: bankDetails = {} } = data;
  return (
    <Row className="mt-3">
      {description && (
        <Col md={6}>
          <Row className="pb-3 max-w-300">
            <div className="lhs">
              <div style={{ marginRight: 15 }}>
                <h1 className="header-title">Notes / Payment Terms:</h1>
                <p className="text preview">{description}</p>
              </div>
            </div>
          </Row>
        </Col>
      )}

      {bankDetails?.accountName && (
        <Col md={5} className="ms-auto">
          <Row className="max-w-300">
            <div className="lhs">
              <h1 className="header-title">Bank Details</h1>
              <p className="text justify-content-between d-flex align-items-center pb-1">
                <span className="text preview">Bank</span>
                <span className="text preview w-50">{bankDetails?.bank || '-'}</span>
              </p>

              <p className="text justify-content-between d-flex align-items-center pb-1">
                <span className="text preview">Account name</span>
                <span className="text preview w-50">
                  {bankDetails?.accountName || '-'}
                </span>
              </p>

              <p className="text justify-content-between d-flex align-items-center pb-1">
                <span className="text preview"> Account number</span>
                <span className="text preview w-50">
                  {bankDetails?.accountNumber || '-'}
                </span>
              </p>
            </div>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default NewDawn;
