import React, { useState } from 'react';
import './styles.scss';
import { MinusIcon, PlusIcon } from 'assets/icons';
import classNames from 'classnames';

const Counter = ({ setSeats, seats, min }) => {
  const handleIncrease = () => setSeats(seats + 1);
  const handleDecrease = () => seats > 0 && setSeats(seats - 1);

  return (
    <div className="counter">
      <button
        className={classNames('counter__button', {
          'not-clickable': seats === min,
          clickable: seats > min,
        })}
        disabled={seats === min}
        onClick={handleDecrease}
      >
        <MinusIcon width="16" height="16" color={seats === min ? '#D8D8D6' : '#5C5C5A'} />
      </button>
      <span className="counter__value">{seats}</span>
      <button className="counter__button clickable" onClick={handleIncrease}>
        <PlusIcon width="8" height="8" stroke="#5C5C5A" />
      </button>
    </div>
  );
};

export default Counter;
