import { DeleteUserIcon } from 'assets/icons';
import PeopleImage from 'assets/images/emptystate/people-image.png';
import ConfirmDialog from 'components/ConfirmDialog';
import NoData from 'components/NoData';
import Table from 'components/Table';
import TopBar from 'components/TopBar';
import CustomButton from 'components/UI/CustomButton';
import EmptyState from 'components/UI/EmptyState';
import Loading from 'components/UI/Loading';
import { useDebounce } from 'hooks/useDebounce';
import useIsFiltered from 'hooks/useIsFiltered';
import { useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getBeneficiaries } from 'redux/actions/BeneficiariesAction';
import {
  budgetBeneficiariesAPI,
  createNewBeneficiaries,
  deleteBudgetBeneficiary,
} from 'redux/actions/BudgetsAction';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import { buildBeneficiaryTableData } from 'utils/helper';
import { columnsSingleBudgetBeneficiaries } from 'utils/mockData';
import { RolesType, StatusType } from '../../../components/FilterModal/FilterHelper';
import BeneficiariesModal from '../../Beneficiaries/BeneficiariesModal';
import BeneficiariesTableModal from '../../Beneficiaries/BeneficiariesTable/BeneficiariesTableModal';
import MigrateOldBudgetInfo from '../MigrateOldBudgetInfo';
import PendingOnboardingNotice from '../PendingOnboardingNotice';
import ModalComponent from 'components/UI/Modal/ModalComponent';
import PayAdditionalSeat from 'pages/Beneficiaries/PayAdditionalSeat';

const BudgetBeneficiaries = () => {
  const dispatch = useDispatch();
  const { budgetId } = useParams();
  const {
    getSingleBudget: { data: budgetData = {} },
    getBudgetBeneficiaries: {
      data: { beneficiaries: budgetBeneficiaries = [], meta = {} } = {},
      loading,
    },
    createNewBeneficiaries: { success: createSuccess },
    deleteBudgetBeneficiaries: { success: deleteSuccess, loading: isRemoving },
  } = useSelector(({ budgets }) => budgets);
  const { permissions } = allPermissions();
  const {
    getBeneficiaries: { data: allBeneficiaries = {} },
    updateBeneficiaries: { success: successUpdateBeneficiary },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const { beneficiaries: allBeneficiariesData = [] } = allBeneficiaries;

  const canViewEmployee = hasPermission({
    permissions,
    scopes: ['employee-*', 'employee-view'],
  });

  const canEdit = hasPermission({
    permissions,
    scopes: ['employee-*', 'employee-edit', 'employee-create'],
  });

  const [selectBeneficiaries, setSelectBeneficiaries] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [BeneficiaryIsOpen, setNewBeneficiaryIsOpen] = useState(false);
  const [filterData, setFilterData] = useState([...StatusType, ...RolesType]);
  const [filterOnchangeData, setFilterOnchangeData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [beneficiaryCode, setBeneficiaryCode] = useState();
  const [searchVal, setSearchVal] = useState('');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 600);
  const { filtered, isFiltered, filteredQuery, setFilteredQuery } = useIsFiltered();
  const [payAdditionalSeat, setPayAdditionalSeat] = useState(false);

  const { page = 1, total = 1, hasMore, perPage = 50, nextPage } = meta;

  const toggleModalHandler = () => {
    setIsOpen(!isOpen);
  };
  const beneficiariesAPI = () => {
    dispatch(budgetBeneficiariesAPI({ budget: budgetId }));
  };

  useEffect(() => {
    if (canViewEmployee && !allBeneficiariesData?.length) dispatch(getBeneficiaries());
  }, [budgetId]);

  useEffect(() => {
    if (successUpdateBeneficiary || createSuccess)
      dispatch(budgetBeneficiariesAPI({ budget: budgetId }));
  }, [successUpdateBeneficiary, createSuccess]);

  useEffect(() => {
    if (beneficiaryCode)
      if (isChecked) {
        dispatch(
          createNewBeneficiaries({
            budgetsCode: budgetId,
            beneficiariesCode: beneficiaryCode,
            amount: 0,
          }),
        );
      } else {
        dispatch(
          deleteBudgetBeneficiary({
            budgetsCode: budgetId,
            beneficiariesCode: beneficiaryCode,
          }),
        );
      }
  }, [beneficiaryCode, isChecked]);

  useEffect(() => {
    if (deleteSuccess && isConfirmModal) {
      setSelectBeneficiaries(null);
      setSelectedOption(null);
      setIsConfirmModal(false);
    }
    if (deleteSuccess) setSelectBeneficiaries(null);
  }, [deleteSuccess]);

  // Handle search change after debounce
  useEffect(() => {
    if (debouncedValue) {
      filteredQuery.search = debouncedValue;
      dispatch(
        budgetBeneficiariesAPI({
          ...filteredQuery,
          budget: budgetId,
          search: debouncedValue,
        }),
      );
    }
    if (!debouncedValue && filtered) {
      delete filteredQuery.search;
      dispatch(budgetBeneficiariesAPI({ budget: budgetId, ...filteredQuery }));
      isFiltered.current = Object.keys(filteredQuery).length ? true : false;
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const handleRowClick = (row) => {
    setSelectBeneficiaries(row);
  };
  const handleFilterApply = (query) => {
    dispatch(budgetBeneficiariesAPI({ budget: budgetId, ...query }));
    setFilteredQuery({ budget: budgetId, ...query });
    isFiltered.current = !!Object.keys(query).length;
  };

  useEffect(() => {
    if (!!allBeneficiariesData?.length) {
      const data = [];
      const filteredBeneficiary = allBeneficiariesData
        .sort((a, bbx) => (a.user.firstName > bbx.user.firstName) * 2 - 1)
        .filter(
          ({ user }) =>
            user.firstName.toLowerCase().includes(searchVal) ||
            user.email.toLowerCase().includes(searchVal) ||
            user.lastName.toLowerCase().includes(searchVal),
        );

      filteredBeneficiary.forEach((b, i) => {
        const {
          code,
          user: { firstName, lastName },
        } = b;

        data.push({
          value: code,
          label: `${firstName} ${lastName}`,
          isSelected: !!budgetBeneficiaries
            .filter((item) => code === item.code)
            .map((item) => item.code === code)
            .toString(),
        });
      });

      setFilterOnchangeData([{ title: 'Beneficiary', list: data }]);
    }
  }, [allBeneficiariesData, searchVal]);

  const addBeneficiary = () => {
    setNewBeneficiaryIsOpen(!BeneficiaryIsOpen);
  };

  const handlePreviousPage = (page) => {
    dispatch(budgetBeneficiariesAPI({ perPage, page, ...filteredQuery }));
  };

  const handleNextPage = (page) => {
    dispatch(budgetBeneficiariesAPI({ perPage, page, ...filteredQuery }));
  };

  const tableColumn = columnsSingleBudgetBeneficiaries.filter((item) =>
    canEdit ? item : item.Header !== 'Actions',
  );

  const actionHandler = (event, type, value) => {
    event?.stopPropagation();
    event?.preventDefault();
    setIsPopoverOpen(true);
    setSelectedOption(value);
    setIsConfirmModal(true);
  };

  const Actions = ({ list }) => {
    const status = list?.status?.value.toLowerCase();
    return (
      <div className="actions-dialog">
        <div
          className="actionLink svg-danger text-danger"
          onClick={(event) => actionHandler(event, 'deleted', list)}
        >
          <DeleteUserIcon className="mr-4" /> Remove Member
        </div>
      </div>
    );
  };

  const handleDeleteBeneficiaryFromBudget = () => {
    dispatch(
      deleteBudgetBeneficiary({
        budgetsCode: budgetId,
        beneficiariesCode: selectedOption?.code,
      }),
    );
  };

  const isClosed = budgetData?.status === 'closed';

  const mainData = {
    title: 'Collaborate with team members or employees on budget management.',
    body: 'Add members, manage teams, manage team member roles and access.. See how it works here.',
    image: PeopleImage,
    action: () =>
      hasPermission({
        permissions,
        scopes: ['employee-create', 'employee-edit', 'employee-*'],
      }) &&
      !isClosed && (
        <CustomButton className="add-button w-auto" onClick={toggleModalHandler}>
          Add Member
        </CustomButton>
      ),
  };

  if (loading && !filtered) return <Loading isPage color="#D28B28" />;

  const show =
    !!budgetBeneficiaries?.length || (filtered && !budgetBeneficiaries?.length);

  const BudgetBeneficiaryList = () => {
    return (
      <>
        {budgetBeneficiaries.length ? (
          <Container className="p-0 selected-budget-beneficiaries-table">
            <Row className="mt-4">
              <Col xs={12} className="spaced-table">
                <Table
                  columns={tableColumn}
                  data={buildBeneficiaryTableData(budgetBeneficiaries, budgetData)}
                  pagination
                  hasMore={hasMore}
                  hasCheckBox={false}
                  currentPage={page}
                  nextPage={() => handleNextPage(nextPage)}
                  previousPage={() => handlePreviousPage(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                  onRowClick={handleRowClick}
                  popoverAction={Actions}
                  popoverState={isPopoverOpen}
                  setPopoverState={setIsPopoverOpen}
                />
              </Col>
            </Row>
          </Container>
        ) : (
          <div>
            {filtered ? (
              <div className="tabinnerWrapper">
                <NoData
                  headerText="You have no beneficiaries for this filter"
                  bodyText="Alter filter to see beneficiaries..."
                  withButton={false}
                />
              </div>
            ) : (
              <div className="mt-5">
                <EmptyState main={mainData} />
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="budgets-wrapper">
      <PendingOnboardingNotice />
      <MigrateOldBudgetInfo />

      <TopBar
        addOnClick={toggleModalHandler}
        searchVal={search}
        setSearchVal={setSearch}
        filterData={filterData}
        withOutSearch
        showBarSearch={show}
        inputPlaceholder="Search by name or email"
        filterOnchangeData={filterOnchangeData}
        handleFilterSelect={(updateVal) => {
          setFilterData(updateVal);
        }}
        handleFilterSelectOnChange={(updateVal, { value, checked }) => {
          setFilterOnchangeData(updateVal);
          setBeneficiaryCode(value);
          setIsChecked(checked);
        }}
        addBeneficiary={addBeneficiary}
        handleFilterApply={handleFilterApply}
        filterOnchange
        withDate
        showFilter={show}
        addIcon={show}
      />

      {BudgetBeneficiaryList()}

      <BeneficiariesTableModal
        selectBeneficiaries={selectBeneficiaries}
        setSelectBeneficiaries={setSelectBeneficiaries}
        isDisable
        isBudgetBeneficiary
        selectedBudget={{ value: budgetData?.code, label: budgetData?.name }}
      />

      <ModalComponent active={!!isOpen} scroll={false} stack={payAdditionalSeat}>
        <BeneficiariesModal
          toggleHandler={toggleModalHandler}
          selectedBudget={{ value: budgetData?.code, label: budgetData?.name }}
          onSave={beneficiariesAPI}
          isLink={allBeneficiariesData?.length}
          setPayAdditionalSeat={setPayAdditionalSeat}
          payAdditionalSeat={payAdditionalSeat}
          isOpen={isOpen}
        />
      </ModalComponent>

      {isConfirmModal && (
        <Modal
          show={true}
          centered
          dialogClassName="custom-dialog"
          className="custom-dialog"
        >
          <ConfirmDialog
            title="Remove this member"
            subTitle="Are your sure you want to remove this member from this budget?"
            onConfirm={handleDeleteBeneficiaryFromBudget}
            isDeleteDialog={true}
            onCancel={() => setIsConfirmModal(false)}
            loading={isRemoving}
          />
        </Modal>
      )}

      <ModalComponent
        active={!!BeneficiaryIsOpen}
        scroll={false}
        stack={payAdditionalSeat}
      >
        <BeneficiariesModal
          toggleHandler={addBeneficiary}
          setPayAdditionalSeat={setPayAdditionalSeat}
          payAdditionalSeat={payAdditionalSeat}
          isOpen={BeneficiaryIsOpen}
        />
      </ModalComponent>
    </div>
  );
};
export default BudgetBeneficiaries;
