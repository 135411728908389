import classNames from 'classnames';
import AssetViewer from 'components/AssetViewer';
import useOutsideClick from 'components/clickoutside';
import { useEffect, useRef } from 'react';
import './styles.scss';
import ModalContainerWrap from './ModalContainerWrap';

const ModalComponent = ({
  children,
  onClick,
  active,
  parentRef = null,
  size,
  position = 'side',
  singleData,
  selectedAsset,
  setSelectedAsset,
  assetData,
  background = '#fff',
  scroll = true,
  customClass,
  isLoading,
  stack,
  handleInvoiceDownload,
}) => {
  const ref = useRef(null);
  const buttonClickedOutside = useOutsideClick(parentRef ?? ref);

  useEffect(() => {
    if (buttonClickedOutside && active) {
      onClick && onClick(!active);
    }
  }, [buttonClickedOutside, onClick, active]);

  useEffect(() => {
    if (active) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [active]);

  const SideModal = (
    <>
      <div
        className={classNames(
          active ? 'opacity-100' : 'pointer-events-none opacity-0',
          'overlay',
        )}
      ></div>

      <div
        className="position-fixed top-0"
        style={{
          zIndex: 99,
          left: '30rem',
          transform: 'translateX(-50%)',
          maxHeight: '100vh',
          overflowY: 'auto',
        }}
      >
        {selectedAsset && (
          <AssetViewer
            isLoading={isLoading}
            toggleVisibility={() => setSelectedAsset(null)}
            data={assetData}
            selectedAsset={selectedAsset}
            singleData={singleData}
            onInvoiceDownload={handleInvoiceDownload}
          />
        )}
      </div>

      <div
        className={classNames(
          'hide-scrollbar modal-container-side',
          active ? 'active-container' : 'inactive-container',
          { [customClass]: customClass },
        )}
      >
        <div
          ref={parentRef ?? ref}
          className={classNames(
            'hide-scrollbar modal-container-child bg-white',
            scroll ? 'overflow-scroll' : 'overflow-hidden',
          )}
        >
          <div className="w-100 h-100" style={{ background }}>
            {children}
          </div>
        </div>
      </div>

      {/* {stack && ( */}
      <>
        <div
          className={classNames(
            'hide-scrollbar modal-container-side',
            stack ? 'active-container stacked-modal' : 'inactive-container',
          )}
        >
          <div className={classNames('opacity-50 overlay')} />
          <div
            className={classNames(
              'hide-scrollbar modal-container-child',
              scroll ? 'overflow-scroll' : 'overflow-hidden',
            )}
            style={{ background: '#f5f5f4' }}
          >
            <div className="w-100 h-100" style={{ background: '#f5f5f4' }}>
              <ModalContainerWrap
                background="#f5f5f4"
                goBack={null}
                modalName="Team Limit Reached"
              >
                <div className="position-fixed bottom-0 card-modal-footer bg-white px-4 py-2 border-top w-100">
                  <div className="d-flex gap-2 align-items-center justify-content-end">
                    <div className="py-4"></div>
                  </div>
                </div>
              </ModalContainerWrap>
            </div>
          </div>
        </div>
      </>
      {/* )} */}
    </>
  );

  const CenteredModal = (
    <>
      <div
        // tabindex="-1"
        className={classNames(
          'modal hide-scrollbar  modal-container-center',
          active ? 'opacity-100' : 'pointer-events-none opacity-0',
        )}
      >
        <div
          ref={ref}
          className={classNames(
            'mx-auto w-11/12 bg-white origin-bottom transform rounded-[10px] pt-6 transition-all delay-200 duration-500 xl:pt-10 ',
            active
              ? 'translate-y-0 scale-100 opacity-100'
              : 'translate-y-10 scale-90 opacity-0',
            size ? size : 'max-w-lg',
          )}
        >
          {/* {close && (
            <button
              onClick={() => onClick(!active)}
              className="absolute right-0 flex h-6 w-6 -translate-x-5 -translate-y-[0.4rem] rounded-full border  outline-none transition-all duration-500 ease-in-out hover:bg-red-500 hover:text-white xl:h-7 xl:w-7 xl:-translate-x-8 xl:-translate-y-[0.7rem]"
            >
              <CloseOutlined className="m-auto" />
            </button>
          )} */}

          {children}
        </div>
      </div>
    </>
  );
  return (
    <div className="modal-component">
      {position === 'center' ? CenteredModal : SideModal}
    </div>
  );
};

export default ModalComponent;
