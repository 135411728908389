import { ArrowUpRightIcon, CopyIcon, PencilIcon, WalletIconO1 } from 'assets/icons';
import bankIcon from 'assets/icons/bank-icon2.svg';
import classNames from 'classnames';
import DeleteDialog from 'components/DeleteDialog';
import Timeline from 'components/Timeline';
import ItemInfo from 'components/TransactionModal/itemInfo';
import CustomButton from 'components/UI/CustomButton';
import DrawerBody from 'components/UI/CustomDrawer/components/DrawerBody';
import LoadingReciept from 'components/UI/CustomDrawer/components/LoadingReciept';
import NewReceiptUpload from 'components/UI/CustomDrawer/components/NewReceiptUpload';
import { PaymentSkeleton } from 'components/UI/CustomDrawer/components/payment-skeleton';
import PaymentHeader from 'components/UI/CustomDrawer/components/PaymentHeader';
import { scrollHook } from 'components/UI/CustomDrawer/components/scroll-hook';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import CustomTextarea from 'components/UI/CustomTextarea';
import ImgCard from 'components/UI/ImgCard';
import Loading from 'components/UI/Loading';
import ModalContainerWrap from 'components/UI/Modal/ModalContainerWrap';
import BadgeType from 'components/UI/Table/BadgeType';
import ApprovalModal from 'pages/Approvals/components/ApprovalModal';
import { useEffect, useRef, useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getBalances, getBudgets } from 'redux/actions/BudgetsAction';
import {
  deleteReimbursements,
  getAllReimbursementAsset,
  getReimbursements,
  getSingleReimbursement,
  updateReimbursements,
} from 'redux/actions/ReimbursementsAction';
import { RESET_BLOCK_REIMBURSEMENTS } from 'redux/reducers/ReimbursementsReducer';
import {
  asciiToHex,
  getAvailableBalance,
  getColor,
  getFormattedDate,
  groupSourceOptions,
  STATUS,
  transactionAcceptedFiles,
} from 'utils/helper';

const ViewReimbursementDetails = ({
  setAssetData,
  setSelectedAsset,
  selectReimbursement,
  setSelectReimbursement,
}) => {
  if (!selectReimbursement) return;
  const dispatch = useDispatch();
  const history = useHistory();

  const [deleting, setDeleting] = useState(false);
  const [assetViewer, setAssetViewer] = useState(false);
  const [isLoading, setIsLoading] = useState('');
  // const [selectedAsset, setSelectedAsset] = useState(null);
  const parentRef = useRef(null);

  const {
    getAsset: { data: assetData = {} },
  } = useSelector(({ assets }) => assets);
  const { asset = [] } = assetData;

  const {
    deleteReimbursement: { success },
    reimbursementReceipts: { data: receiptAssets, loading: loadingReceipt },
  } = useSelector(({ reimbursement }) => reimbursement);
  const {
    reimbursementsData: {
      budget,
      code,
      reviewer = {},
      reviewed_on = null,
      expense_date,
      status,
      approvalRequest = {},
      user: reimbursementUser,
    } = {},
  } = selectReimbursement || {};
  const { approvals: completedApprovals = [] } = approvalRequest || {};

  const {
    user: {
      data: { user },
    },
  } = useSelector(({ auth }) => auth);

  const [categoryVisible, setCategoryVisible] = useState(false);
  const [isEditingCategory, setIsEditingCategory] = useState(false);
  const [expenseCategories, setExpenseCategories] = useState({
    value: '',
  });

  const [budgetVisible, setBudgetVisible] = useState(false);
  const [budgetLoading, setBudgetLoading] = useState(false);
  const [ruleCode, setRuleCode] = useState(null);
  const [budgetsList, setBudgetsList] = useState([]);
  const [receiptCode, setReceiptCode] = useState([]);

  const [description, setDescription] = useState({
    visible: false,
    data: '',
    isEditingDescription: false,
  });
  const [saveButton, setSaveButton] = useState(false);

  const {
    getBalances: { data: balances, loading: loadingBalances, success: successBalance },
    getBudget: { data: budgetData = {} },
    getTransferableBalance: { data: budgetBalance = {} },
  } = useSelector(({ budgets }) => budgets);

  const { budgets = [] } = budgetData;

  const {
    getSingleReimbursement: { data: singleData = {}, loading: singleLoading },
  } = useSelector(({ reimbursement }) => reimbursement);
  const {
    fetchCategories: { data: categoryData },
    deleteCategories: { success: successDelete, loading: loadingDelete },
  } = useSelector(({ categories }) => categories);

  const createdByMe = user?.code === reimbursementUser?.code;

  useEffect(() => {
    if (!singleLoading && code) {
      dispatch(getSingleReimbursement(code));
    }
  }, [selectReimbursement]);

  useEffect(() => {
    if (singleData?.data?.reimbursement?.code) {
      dispatch(getAllReimbursementAsset(singleData?.data?.reimbursement?.receipts));
    }
  }, [singleData]);

  useEffect(() => {
    if (receiptCode?.receipt?.length) {
      setSaveButton(false);
    }
  }, [receiptCode?.receipt]);

  const handleClose = () => {
    setAssetViewer(false);
    setSelectedAsset(null);
    setAssetData && setAssetData(null);
    dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'getSingleReimbursement' });
    dispatch({ type: RESET_BLOCK_REIMBURSEMENTS, blockType: 'reimbursementReceipts' });
    toggleAssetViewer(false);
    setSelectReimbursement(null);
  };

  const onHandleGetSelected = (value) => {
    editReimbursement(code, value.value);
    setCategoryVisible(false);

    setExpenseCategories({ value });
  };

  const editReimbursement = (code, categoryCode) => {
    dispatch(updateReimbursements({ code, category: categoryCode }));
    setIsEditingCategory(true);
  };

  const handleClick = () => {
    history.push(`/expenses/budgets/${budget?.code}/overview`);
  };

  const attemptDelete = () => {
    setDeleting(true);
  };

  const deleteReimbursement = () => {
    dispatch(deleteReimbursements(code));
  };

  useEffect(() => {
    if (budgets.length === 0) dispatch(getBudgets({ status: 'active' }));
  }, []);

  useEffect(() => {
    if (budgets.length > 0) {
      const budgetsOptions = budgets?.map(({ code: value, name: label }) => ({
        value,
        label,
      }));

      setBudgetsList(budgetsOptions);
    }
  }, [budgets]);

  const saveBudget = (budget) => {
    setBudgetLoading(true);
    return dispatch(updateReimbursements({ code, budget: budget?.value }));
  };

  useEffect(() => {
    if (success) {
      window.setTimeout(function () {
        setSelectReimbursement(null);
        dispatch(getReimbursements({ view_as: 'reviewer', status: ['pending'] }));
      }, 1000);
    }
  }, [success]);

  const viewImgUrl = (asset) => {
    setSelectedAsset(asset);
    setAssetViewer(true);
  };

  const toggleAssetViewer = () => {
    setAssetViewer(!assetViewer);
  };

  const closeApprovalRule = () => setRuleCode(null);

  if (ruleCode)
    return (
      <ApprovalModal
        selectedRuleCode={ruleCode}
        isOpen={!!ruleCode}
        isEdit
        handleClose={closeApprovalRule}
        clearSelectedRule={closeApprovalRule}
      />
    );

  const openRule = (code) => {
    setRuleCode(code);
  };

  const handleDescriptionChange = (val) => {
    setDescription({
      ...description,
      data: val.target.value,
    });
    setSaveButton(true);
  };

  const cancelEdit = () => {
    setReceiptCode('');
    setSaveButton(false);
  };

  const handleEdit = () => {
    if (!!receiptCode?.receipt?.length) {
      dispatch(updateReimbursements({ code, receipt: receiptCode?.receipt }));
      setReceiptCode({
        ...receiptCode,
        isUpdating: true,
      });
    }
  };

  const approvers = [];

  approvalRequest?.approvalStages.forEach((item) => {
    item.approvers.forEach((approver) => {
      const user = approver.user;
      const approver_code = approver.code;
      approvers.push({ user, approver_code, status: 'pending', message: '' });
    });
  });

  completedApprovals?.forEach((item) => {
    approvers.push({
      user: item?.approver?.user,
      date: getFormattedDate(item?.updated_at),
      status: item?.status,
      message: item?.reason ?? '',
    });
  });

  //
  useEffect(() => {
    dispatch(getBalances());
  }, []);

  const [loadPage, setLoadPage] = useState(0);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  const [hasMore, setHasMore] = useState(false);
  const [budgetList, setBudgetList] = useState([]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets, 'budgets');
      const available_balance = getAvailableBalance(balances?.balances, 32, false, true);
      setBudgetList((prevOptions) => [...available_balance].concat([...budget]));
    }
  }, [balances?.budgets]);

  async function loadOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  //

  useEffect(() => {
    if (receiptAssets?.length) setAssetData && setAssetData(receiptAssets);
  }, [receiptAssets?.length]);

  useEffect(() => {
    if (singleData?.data?.reimbursement?.description && !description?.data.length) {
      setDescription({
        ...description,
        data: singleData?.data?.reimbursement?.description,
      });
    }
  }, [singleData?.data?.reimbursement?.description]);

  const bodyData = [
    {
      title: 'Requested by',
      value: `${singleData?.data?.reimbursement?.user?.firstName} ${singleData?.data?.reimbursement?.user?.lastName}`,
      icon: (
        <span className="detail-thumbnail thumbnail-primary thumbnail-small text-xs">
          {singleData?.data?.reimbursement?.user?.firstName.slice('')[0]}
        </span>
      ),
    },
    {
      title: 'Date',
      value: getFormattedDate(expense_date),
    },
    {
      title: 'Reimbursement ID',
      value: singleData?.data?.reimbursement?.code,
      isCopy: true,
      icon: <CopyIcon stroke="#D28B28" width="20" height="20" />,
    },
  ];

  const isBalance = singleData?.data?.reimbursement?.budget?.code?.startsWith('blc_');

  const { scrollSize, fontSize, iconSize, setScrollSize } = scrollHook(parentRef);

  const [tabKey, setTabKey] = useState('details');

  const handleSelectKey = (tabKey) => {
    setTabKey(tabKey);
    setScrollSize(0);
  };

  const handleImgError = (e) => {
    e.target.onerror = null;
    e.target.src = bankIcon;
  };

  return (
    <>
      <ModalContainerWrap
        parentRef={parentRef}
        modalName="Reimbursement details"
        onClose={handleClose}
        loader={<PaymentSkeleton />}
        loading={singleLoading}
      >
        <>
          <div
            className={classNames(
              'px-4 bg-white fixed-header',
              scrollSize > 80 ? 'pb-0' : 'pb-2',
            )}
          >
            <PaymentHeader
              fontSize={fontSize}
              iconSize={iconSize}
              scrollSize={scrollSize}
              status={selectReimbursement?.status?.value}
              receipient={selectReimbursement?.vendor}
              moneyData={{
                currency: singleData?.data?.reimbursement?.currency,
                amount: singleData?.data?.reimbursement?.amount,
              }}
              imageIcon={WalletIconO1}
              isRequest
              createdByMe={createdByMe}
            />
          </div>

          <Tabs
            id="contolled-tab-example"
            activeKey={tabKey}
            onSelect={handleSelectKey}
            className="bg-white position-fixed w-100 pt-0 mt-0"
            style={{ zIndex: 99999, top: 220 - (scrollSize / 100) * 40 }}
          >
            <Tab
              eventKey="details"
              title={<div className="d-flex">Details</div>}
              tabClassName="new-tab ms-4"
              style={{ paddingTop: 165 }}
            >
              <div className="slide-in-right pb-5">
                <div className="details-tab p-4">
                  <div className="details-view mb-4">
                    <p>Overview</p>
                    <div className="container">
                      <DrawerBody data={bodyData} />

                      {singleData?.data?.reimbursement?.transaction && (
                        <div className="pt-3">
                          <ItemInfo
                            title="Transaction ID"
                            isLink={
                              singleData?.reimbursement?.transaction?.code
                                ? `/transactions/payments/${singleData?.reimbursement?.transaction?.code}/details`
                                : false
                            }
                            withAction={singleData?.reimbursement?.transaction?.code}
                            value={singleData?.reimbursement?.transaction?.code ?? '-'}
                            icon={
                              <ArrowUpRightIcon stroke="#D28B28" width="16" height="16" />
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="details-view mb-4">
                    <p>Payment details</p>
                    <div className="container">
                      <div>
                        <ItemInfo
                          title="Category"
                          value={
                            <BadgeType
                              value={{
                                value:
                                  expenseCategories.value.label ??
                                  singleData?.data?.reimbursement?.category?.name ??
                                  '-',
                                rightIcon: singleData?.data?.reimbursement?.category
                                  ?.code && (
                                  <ArrowUpRightIcon
                                    stroke={asciiToHex(
                                      expenseCategories.value.label ??
                                        singleData?.reimbursement?.category?.name ??
                                        '-',
                                    )}
                                    width="16"
                                    height="16"
                                  />
                                ),
                                color: getColor(
                                  expenseCategories.value.label ??
                                    singleData?.data?.reimbursement?.category?.name ??
                                    '-',
                                ),
                              }}
                            />
                          }
                          withAction={status !== 'declined' && createdByMe}
                          action={() => setCategoryVisible(!categoryVisible)}
                          isLoading={isEditingCategory}
                          icon={
                            status !== 'declined' &&
                            createdByMe && (
                              <PencilIcon stroke="#D28B28" width="18" height="18" />
                            )
                          }
                        />
                      </div>
                      {categoryVisible && (
                        <div className="padding-top-xxs">
                          <CategoryDropdown
                            placeholder="Select a category"
                            onChange={(val) => onHandleGetSelected(val)}
                            value={
                              expenseCategories.value ?? {
                                value: singleData?.data?.reimbursement?.category?.code,
                                label: singleData?.data?.reimbursement?.category?.name,
                              }
                            }
                            defaultValue={{
                              value: singleData?.data?.reimbursement?.category?.code,
                              label: singleData?.data?.reimbursement?.category?.name,
                            }}
                            name="category"
                          />
                        </div>
                      )}

                      <div className="padding-top-2">
                        <ItemInfo
                          title="Source"
                          isLink={
                            singleData?.data?.reimbursement?.source?.name &&
                            (isBalance
                              ? `/accounts/${singleData?.data?.reimbursement?.source?.code}`
                              : !isBalance
                              ? `/expenses/budgets/${singleData?.data?.reimbursement?.source?.code}/overview`
                              : false)
                          }
                          withAction={
                            ![
                              STATUS.SUCCESS,
                              STATUS.DECLINED,
                              STATUS.PROCESSING,
                            ].includes(status)
                          }
                          action={() => setBudgetVisible(!budgetVisible)}
                          value={
                            <div className="d-flex align-items-center gap-2">
                              {(singleData?.data?.reimbursement?.source?.name ||
                                singleData?.data?.reimbursement?.budget?.name) && (
                                <ImgCard
                                  size="small"
                                  fullWidth={false}
                                  initials={(
                                    singleData?.data?.reimbursement?.source?.name ||
                                    singleData?.data?.reimbursement?.budget?.name
                                  )?.charAt(0)}
                                >
                                  <img
                                    onError={handleImgError}
                                    className="account-logo"
                                    src={bankIcon}
                                  />
                                </ImgCard>
                              )}

                              <span>
                                {(singleData?.data?.reimbursement?.source?.name ||
                                  singleData?.data?.reimbursement?.budget?.name) ??
                                  (status === 'pending' ? 'Add source' : '-')}
                              </span>
                            </div>
                          }
                          isLoading={budgetLoading}
                          icon={
                            createdByMe && (
                              <PencilIcon stroke="#D28B28" width="18" height="18" />
                            )
                          }
                        />
                      </div>
                      {budgetVisible && (
                        <div className="padding-top-xxs">
                          <CustomSelectRadio
                            placeholder="Select a source"
                            name="source"
                            onChange={(val) => saveBudget(val)}
                            defaultValue={
                              singleData?.data?.reimbursement?.source?.code && {
                                value: singleData?.data?.reimbursement?.budget?.code,
                                label: singleData?.data?.reimbursement?.budget?.name,
                              }
                            }
                            isLoading={loadingBalances}
                            isDisabled={loadingBalances}
                            loadOptions={loadOptions}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="padding-top-2">
                    <div className="padding-top-xxs">
                      <CustomTextarea
                        label="Description"
                        action={() => {
                          setDescription({
                            ...description,
                            visible: !description.visible,
                          });
                          setSaveButton(!saveButton);
                        }}
                        icon={
                          !['declined', 'processing'].includes(status) &&
                          createdByMe && (
                            <PencilIcon stroke="#D28B28" width="18" height="18" />
                          )
                        }
                        name="description"
                        placeholder="Description"
                        value={description?.data}
                        disabled={
                          !description?.visible ||
                          ['declined', 'processing'].includes(status)
                        }
                        onChange={handleDescriptionChange}
                        rowSize={4}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="attachements"
              title={<div className="d-flex">Attachments</div>}
              tabClassName="new-tab"
              style={{ paddingTop: 165 }}
            >
              <div className="slide-in-right p-4 pb-5">
                <div className="details-tab">
                  <div className="details-view mb-4">
                    <p>Receipts</p>
                    <div className="container d-flex gap-2 overflow-x-scroll">
                      {loadingReceipt && !receiptAssets?.length && <LoadingReciept />}
                      <NewReceiptUpload
                        receiptAssets={receiptAssets}
                        viewImgUrl={viewImgUrl}
                        addMore={true}
                        removeFile={!receiptCode.receipt?.length}
                        acceptedFile={transactionAcceptedFiles}
                        onChange={(value) =>
                          setReceiptCode({
                            receipt: value?.map((item) => item.assetCode),
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="updates"
              title={<div className="d-flex">Updates</div>}
              tabClassName="new-tab"
              style={{ paddingTop: 165 }}
            >
              <div className="slide-in-right p-4 pb-5">
                <div className="details-tab">
                  <div className="details-view mb-4">
                    <Timeline data={approvers} goToRule={openRule} />
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </>

        <div className="position-fixed bottom-0 card-modal-footer bg-white px-4 py-2 border-top w-100">
          {saveButton && (
            <div className="d-flex align-items-center justify-content-end gap-2">
              <CustomButton
                onClick={cancelEdit}
                className="base-button text-sm font-medium  black-transparent"
              >
                Cancel
              </CustomButton>
              <CustomButton
                onClick={handleEdit}
                className="base-button text-sm font-medium dark-button"
              >
                {receiptCode?.isUpdating ? (
                  <Loading color="#D28B28" size={20} />
                ) : (
                  'Save changes'
                )}
              </CustomButton>
            </div>
          )}

          {status === 'pending' && createdByMe && !saveButton && (
            <div className="d-flex align-items-center justify-content-end">
              <CustomButton
                withoutBg
                fullWidth
                className="base-button danger-button text-sm font-medium border"
                onClick={attemptDelete}
              >
                Delete request
              </CustomButton>
            </div>
          )}
        </div>
      </ModalContainerWrap>
      {deleting && (
        <Modal show={true} centered dialogClassName="custom-dialog">
          <DeleteDialog
            title={`Delete request`}
            subTitle="Are you sure you want to delete this request? This action can not be undone."
            onCancel={() => setDeleting(false)}
            onDelete={deleteReimbursement}
            styles={{ width: 400 }}
            isLoading={loadingDelete}
          />
        </Modal>
      )}
    </>
  );
};
export default ViewReimbursementDetails;
