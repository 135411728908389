import {
  ArrowUpRightIcon,
  ChevronDown,
  CopyIcon,
  PencilIcon,
  WalletIconO1,
  WarningIcon,
} from 'assets/icons';
import bankIcon from 'assets/icons/bank-icon2.svg';
import ConfirmDialog from 'components/ConfirmDialog';
import Timeline from 'components/Timeline';
import ItemInfo from 'components/TransactionModal/itemInfo';
import CustomButton from 'components/UI/CustomButton';
import DrawerBody from 'components/UI/CustomDrawer/components/DrawerBody';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import CustomTextarea from 'components/UI/CustomTextarea';
import Loading from 'components/UI/Loading';
import { useEffect, useMemo, useState } from 'react';
import { Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { reviewRequest } from 'redux/actions/ApprovalAction';
import { getAssets } from 'redux/actions/AssetsAction';
import { getBalances } from 'redux/actions/BudgetsAction';
import {
  approveRequests,
  getRequestAssets,
  getRequests,
  updateRequests,
} from 'redux/actions/RequestsAction';
import {
  asciiToHex,
  getAvailableBalance,
  getColor,
  getFormattedDate,
  groupSourceOptions,
  STATUS,
} from 'utils/helper';

import BanksIcons from 'assets/icons/banks';
import classNames from 'classnames';
import DetailsTimeline from 'components/Timeline/DetailsTimeline';
import SchedulePayment from 'components/TransactionModal/SchedulePayment';
import NewReceiptUpload from 'components/UI/CustomDrawer/components/NewReceiptUpload';
import PaymentHeader from 'components/UI/CustomDrawer/components/PaymentHeader';
import { scrollHook } from 'components/UI/CustomDrawer/components/scroll-hook';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import CategoryDropdown from 'components/UI/CustomSelect/CategoryDropdown';
import ImgCard from 'components/UI/ImgCard';
import CustomPopover from 'components/UI/Popover';
import { toastError } from 'components/UI/toast';
import ApprovalModal from 'pages/Approvals/components/ApprovalModal';
import { useHistory } from 'react-router-dom';
import { payNowAction } from 'redux/actions/PaymentAction';
import { getVendors } from 'redux/actions/VendorsAction';
import BadgeType from 'components/UI/Table/BadgeType';
import LoadingReciept from 'components/UI/CustomDrawer/components/LoadingReciept';
import DeadlinePicker from 'components/TransactionModal/DeadlinePicker';
import InputDialog from 'components/TransactionModal/InputDialog';

const STATUSES = {
  paid: 'approved',
  declined: 'declined',
};

const RequestDetails = ({
  setStep,
  setIsLoading,
  selectRequest,
  setAssetViewer,
  singleData,
  getReceiptList,
  canMakePayment,
  setCanMakePayment,
  parentRef,
}) => {
  const [approve, setIsApproving] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const locationArray = location.pathname.split('/');
  const [key] = useState(locationArray[locationArray.length - 1]);
  const [categoryVisible, setCategoryVisible] = useState(false);
  const [isEditingCategory, setIsEditingCategory] = useState(false);
  const [expenseCategories, setExpenseCategories] = useState({
    value: '',
  });
  const [addDeadLine, setAddDeadLine] = useState(false);
  const [editAmount, setEditAmount] = useState(false);

  const [ruleCode, setRuleCode] = useState(null);

  const [isButtonToggle, setIsButtonToggle] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);
  const [payNow, setPayNow] = useState(true);

  const [receiptCode, setReceiptCode] = useState('');

  const [inputData, setInputData] = useState({
    budget: '',
    source: '',
  });

  const [description, setDescription] = useState({
    visible: false,
    data: '',
    isEditingDescription: false,
  });
  const [newBudget, setNewBudget] = useState({
    visible: false,
    data: '',
    isEditingBudget: false,
  });

  const [budgetVisible, setBudgetVisible] = useState({
    isVisible: false,
    isLoading: false,
  });

  const [AccountVisible, setAccountVisible] = useState({
    isVisible: false,
    isLoading: false,
  });

  const [vendorVisible, setVendorVisible] = useState({
    isVisible: false,
    isLoading: false,
  });

  const [cardVisible, setCardVisible] = useState({
    isVisible: false,
    isLoading: false,
  });

  const [sourceVisible, setSourceVisible] = useState({
    isVisible: false,
    isLoading: false,
  });

  const [saveButton, setSaveButton] = useState(false);

  const {
    approveRequest: { loading, success: successApprove },
    updateRequest: { loading: updateLoading },
    getSingleRequest: { data: { data: { fundRequest: singleRequestData } = {} } = {} },
    requestReceipts: { data: receiptAssets, loading: loadingReceipt },
  } = useSelector(({ requests }) => requests);

  const {
    fetchCategories: { data: categoryData },
    deleteCategories: { success: successDelete, loading: loadingDelete },
  } = useSelector(({ categories }) => categories);

  const {
    reviewRequest: { loading: loadingApproval, success: approvalSuccess },
  } = useSelector(({ approval }) => approval);

  const {
    getVendor: { data: vendorData, success: vendorSuccess },
  } = useSelector(({ vendors }) => vendors);

  const {
    requestData: { receipt, user: requestUser },
  } = selectRequest;

  const {
    code,
    status,
    reviewer = {},
    description: requestDescription,
    approvalRequest = {},
    meta,
  } = singleData?.fundRequest ?? {};

  const {
    approvals: completedApprovals = [],
    approvalStages: stages = [],
    code: requestCode,
  } = approvalRequest || {};

  if (reviewer && reviewer.firstName && !approvalRequest) {
    completedApprovals.push({
      approver: { user: reviewer },
      status: STATUSES[singleRequestData?.status],
      created_at: singleRequestData?.reviewedOn,
    });
  }

  const {
    user: {
      data: { user },
    },
  } = useSelector(({ auth }) => auth);

  const {
    getBalances: { data: balances, loading: loadingBalances, success: successBalance },
  } = useSelector(({ budgets }) => budgets);

  //

  useEffect(() => {
    if (!vendorData?.vendors?.length) dispatch(getVendors());
  }, []);

  const generateVendor = useMemo(() => {
    return vendorData?.vendors?.map(({ code: value, name: label }) => ({
      label,
      value,
    }));
  }, [vendorSuccess]);

  useEffect(() => {
    if (requestDescription && !description?.data.length) {
      setDescription({
        ...description,
        data: requestDescription,
      });
    }
  }, [requestDescription]);

  useEffect(() => {
    if (code) {
      dispatch(getRequestAssets(singleData?.fundRequest?.receipts));
    }
  }, [code]);

  //Everything that has to do with categories

  const onHandleGetSelected = (value) => {
    editRequest(code, value.value);
    setCategoryVisible(false);

    setExpenseCategories({ value });
  };

  const editRequest = (code, categoryCode) => {
    dispatch(updateRequests({ code, category: categoryCode }));
    setIsLoading('category');
    setIsEditingCategory(true);
  };

  const attemptApprove = (isSchedule, pay) => {
    if (!singleData?.fundRequest?.source?.code && !singleData?.fundRequest?.card && pay)
      return toastError('Please select a source');
    setCanMakePayment(!isSchedule && payNow);
    if (isSchedule) return setIsSchedule(true);
    return setIsApproving(!approve);
  };

  const requestMoreDetail = () => {
    setStep(3);
  };

  const approveRequest = ({ schedule }) => {
    if (yourApprovalRequest?.code) {
      return dispatch(
        reviewRequest({
          code: yourApprovalRequest?.code,
          status: 'approved',
          actionLater: schedule ? undefined : !payNow,
          schedule,
        }),
      );
    }
    return dispatch(
      approveRequests({ code, actionLater: schedule ? undefined : !payNow, schedule }),
    );
  };

  const handleDescriptionChange = (val) => {
    setDescription({
      ...description,
      data: val.target.value,
    });
    setSaveButton(true);
  };

  useEffect(() => {
    if (singleData?.fundRequest?.meta?.budget) {
      setNewBudget({
        ...newBudget,
        data: meta?.budget,
      });
    }
  }, [singleData?.fundRequest?.meta?.budget]);

  const handleBudgetChange = (val) => {
    setNewBudget({
      ...newBudget,
      data: val.target.value,
    });
    setSaveButton(true);
  };

  const handleDescription = () => {
    if (description.visible) {
      if (description?.data !== singleData?.fundRequest?.description) {
        setDescription({
          ...description,
          isEditingDescription: true,
        });
        return dispatch(updateRequests({ code, description: description?.data }));
      }
      return toastError('Make a change to description');
    } else if (newBudget.visible) {
      if (newBudget?.data !== singleData?.fundRequest?.meta?.budget) {
        setNewBudget({
          ...newBudget,
          isEditingBudget: true,
        });
        return dispatch(
          updateRequests({
            code,
            meta: {
              budget: newBudget.data,
            },
          }),
        );
      }
      return toastError('Make a change to the budget name');
    } else if (!!receiptCode?.receipt?.length) {
      dispatch(updateRequests({ code, receipt: receiptCode?.receipt }));
      setReceiptCode({
        ...receiptCode,
        isUpdating: true,
      });
    }
  };

  const updateRequest = (value, type) => {
    if (type === 'card') {
      setCardVisible({ isLoading: true });
      return dispatch(updateRequests({ code, card: value }));
    }
    if (type === 'budget') {
      setBudgetVisible({ isLoading: true });
      setInputData({ ...inputData, budget: value });
      return dispatch(updateRequests({ code, budget: value }));
    }
    if (type === 'vendor') {
      setVendorVisible({ isLoading: true });
      return dispatch(updateRequests({ code, vendor: value }));
    }
    if (type === 'account') {
      setAccountVisible({ isLoading: true });
      return dispatch(updateRequests({ code, bankAccount: value }));
    }
    if (type === 'source') {
      setSourceVisible({ isLoading: true });
      setInputData({ ...inputData, source: value });
      return dispatch(updateRequests({ code, source: value }));
    }
  };

  useEffect(() => {
    dispatch(getBalances());
  }, []);

  const [loadPage, setLoadPage] = useState(0);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  const [hasMore, setHasMore] = useState(false);
  const [budgetList, setBudgetList] = useState([]);
  const [accountList, setAccountList] = useState([]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets);
      const available_balance = getAvailableBalance(balances?.balances, 32, false, true);
      setBudgetList(() => [...budget]);
      setAccountList(() => [...available_balance]);
    }
  }, [balances?.budgets, balances?.balances?.length]);

  async function loadOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = budgetList
      .filter((item) => item?.value !== singleData?.fundRequest?.source?.code)
      .filter((item) => item?.name?.toLowerCase().includes(search?.toLowerCase()));

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  async function loadAccountOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = accountList
      .concat(budgetList)
      .filter((item) => item?.value !== singleData?.fundRequest?.budget?.code)
      .filter((item) => item?.name?.toLowerCase().includes(search?.toLowerCase()));
    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  async function allAccounts(search, loadedOptions, { page }) {
    let filteredOption = [...accountList, ...budgetList].filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore: false,
      additional: {
        page: page + 1,
      },
    };
  }
  //

  useEffect(() => {
    if (receipt?.code) dispatch(getAssets(receipt?.code));
  }, [selectRequest]);

  const viewImgUrl = (asset) => {
    setAssetViewer(asset);
    getReceiptList(receiptAssets);
  };

  useEffect(() => {
    if (receiptCode?.receipt?.length) {
      setSaveButton(true);
    }
  }, [receiptCode?.receipt]);

  const alreadyApprovedUser = completedApprovals.find(
    (item) => item.approver?.user?.code === user?.code,
  );

  const isLoading = loading || updateLoading || loadingApproval;

  const hasManager = user?.code === requestUser.code && user?.manager?.code;

  let yourApprovalRequest = singleRequestData?.relatedApprovalRequests?.find(
    (request) => {
      const foundApprover =
        request.status === 'pending' &&
        request.approvalStages?.some((stage) => {
          return (
            stage.status === 'pending' &&
            stage.approvers.some(
              (approver) => approver.user.code === user.code && !alreadyApprovedUser,
            )
          );
        });
      return foundApprover && request;
    },
  );
  yourApprovalRequest = Array.isArray(yourApprovalRequest)
    ? yourApprovalRequest.length && yourApprovalRequest[0]
    : yourApprovalRequest;
  const yourTurnToApprove =
    !!yourApprovalRequest ||
    (status === 'pending' &&
      !hasManager &&
      singleRequestData?.relatedApprovalRequests.length === 0); // is pending, doesn't have a manager and does not have approval flow

  const approvers = [];

  singleRequestData?.relatedApprovalRequests?.forEach((approvalRule) => {
    approvalRule.approvalStages.forEach((item) => {
      if (approvalRule.status !== 'pending') return;
      if ([1, 'all'].includes(item.threshold)) {
        approvers.push({
          rule: { name: approvalRule.rule.name, code: approvalRule.rule.code },
          status: 'pending',
          message: '',
          type: item.threshold,
          approvers: item?.approvers?.map((stage) => {
            return {
              user: stage.user,
              approver_code: stage.code,
              message: '',
            };
          }),
        });
      } else {
        stages.approvers.forEach((item) => {
          approvers.push({
            rule: { name: approvalRule.rule.name, code: approvalRule.rule.code },
            status: 'pending',
            message: '',
            type: 'all',
            user: item.user,
          });
        });
      }
    });
  });

  singleRequestData?.relatedApprovalRequests?.forEach((item) => {
    item.approvals?.forEach((item) => {
      approvers.push({
        user: item?.approver?.user,
        date: getFormattedDate(item?.updated_at),
        status: item?.status,
        message: item?.reason ?? '',
      });
    });
  });

  if (singleRequestData?.status !== 'pending' && singleRequestData?.reviewer !== null)
    approvers.push({
      user: singleRequestData?.reviewer,
      date: getFormattedDate(singleRequestData?.reviewed_on),
      status: singleRequestData?.status,
      message: singleRequestData?.note ?? '',
    });

  useEffect(() => {
    if (yourTurnToApprove && key === 'approve') {
      attemptApprove(false, false);
    }
    if (yourTurnToApprove && key === 'decline') {
      setStep(2);
    }
  }, []);

  const handleCancelRequest = () => {
    setStep(2);
  };

  const openRule = (code) => {
    setRuleCode(code);
  };

  const bodyData = [
    {
      title: 'Requested by',
      value: (
        <div className="d-flex align-items-center gap-2">
          <ImgCard
            size="small"
            fullWidth={false}
            initials={singleData?.fundRequest?.user?.firstName?.charAt(0)}
          />
          <span>{`${singleData?.fundRequest?.user?.firstName} ${singleData?.fundRequest?.user?.lastName}`}</span>
        </div>
      ),
    },
    {
      title: 'Request type',
      value: selectRequest?.type?.value,
    },
    {
      title: 'Requested on',
      value: selectRequest?.creationDate,
    },
    {
      title: 'Deadline on',
      value: selectRequest?.deadLine || (
        <span style={{ color: '#D28B28' }}>Add a deadline</span>
      ),
      onClick: () => {
        setAddDeadLine(true);
      },
    },
    {
      title: 'Request ID',
      value: selectRequest?.requestData?.code,
      isCopy: true,
      icon: <CopyIcon stroke="#D28B28" width="20" height="20" />,
    },
  ];

  const cancelDescriptionEdit = () => {
    setDescription({
      ...description,
      visible: false,
    });
    setReceiptCode('');
    setSaveButton(false);
  };

  const closeApprovalRule = () => setRuleCode(null);

  const { bankAccounts = [], BankAccounts = [] } = singleData?.fundRequest?.vendor ?? {};
  const accounts = !!bankAccounts.length ? bankAccounts : BankAccounts;

  if (ruleCode)
    return (
      <ApprovalModal
        selectedRuleCode={ruleCode}
        isOpen={!!ruleCode}
        isEdit
        handleClose={closeApprovalRule}
        clearSelectedRule={closeApprovalRule}
      />
    );

  function checkConditions(approvers) {
    // Check if there's only one object with the specified conditions
    const pendingApprovers = approvers.filter((data) => data?.status === 'pending');
    const singleObjectCheck =
      pendingApprovers?.length === 1 &&
      pendingApprovers[0]?.type !== 'all' &&
      pendingApprovers[0]?.approvers &&
      pendingApprovers[0]?.approvers.some(
        (approver) => approver?.user?.code === user?.code,
      );

    // Check if all approvers have the specified user code
    const allApproversCheck = pendingApprovers.every((data) =>
      data?.approvers?.every((approver) => approver?.user?.code === user?.code),
    );

    return singleObjectCheck || allApproversCheck;
  }

  const canApprovePay = checkConditions(approvers) && yourTurnToApprove;

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            setPayNow(false);
            attemptApprove(false, false);
            handleButtonToggle();
          }}
        >
          Approve only
        </div>
        <div
          className="actionLink"
          onClick={() => {
            attemptApprove(true, true);
            handleButtonToggle();
          }}
        >
          Approve and schedule payment
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (approvalSuccess || successApprove) {
      setIsApproving(false);
    }
  }, [approvalSuccess, successApprove]);

  const {
    payNowAction: { loading: loadingPayNow, success: successPayNow },
  } = useSelector(({ payments }) => payments);

  const [isPay, setIsPay] = useState(false);
  const [source, setSource] = useState();

  const handlePayTransactionModal = () => {
    setIsPay(!isPay);
  };

  const payTransaction = () => {
    const transactionCode = singleData?.fundRequest?.transaction?.code;

    if (singleData?.fundRequest?.source?.code) {
      return dispatch(payNowAction({ code: transactionCode }));
    } else if (!source) {
      return toastError('Select a source');
    }
    return dispatch(payNowAction({ code: transactionCode, source: source.value }));
  };

  useEffect(() => {
    if (successPayNow) {
      setIsPay(false);
    }
  }, [successPayNow]);

  const showMakePayment =
    canMakePayment &&
    singleData?.fundRequest?.transaction?.code &&
    singleData?.fundRequest?.transaction?.status === 'approved' &&
    !saveButton &&
    ['approved'].includes(singleData?.fundRequest?.status);

  const { scrollSize, fontSize, iconSize, setScrollSize } = scrollHook(parentRef);

  const [tabKey, setTabKey] = useState('details');

  const handleSelectKey = (tabKey) => {
    setTabKey(tabKey);
    setScrollSize(0);
  };

  const handleImgError = (e) => {
    e.target.onerror = null;
    e.target.src = bankIcon;
  };

  const recipientName = selectRequest?.requestData?.user?.name;
  const recipientCode = selectRequest?.requestData?.user?.beneficiary?.code;
  const createdByMe = user?.code === requestUser?.code;

  return (
    <>
      <div
        className={classNames(
          'px-4 bg-white fixed-header',
          scrollSize > 80 ? 'pb-0' : 'pb-2',
        )}
      >
        <PaymentHeader
          fontSize={fontSize}
          iconSize={iconSize}
          scrollSize={scrollSize}
          status={singleData?.fundRequest?.status}
          recipientLink={`/teams/members/profile/${recipientCode}`}
          receipient={recipientName}
          title="Request details"
          moneyData={{
            currency: selectRequest?.requestData?.currency,
            amount: selectRequest?.requestData?.amount,
          }}
          badgeIcon={
            !!singleData?.requestData?.violations?.length ? (
              <WarningIcon className="me-1" />
            ) : null
          }
          imageIcon={WalletIconO1}
          isRequest
          createdByMe={createdByMe}
          onClick={() =>
            setEditAmount(singleData?.fundRequest?.status === STATUS.PENDING)
          }
        />
      </div>

      <Tabs
        id="contolled-tab-example"
        activeKey={tabKey}
        onSelect={handleSelectKey}
        className="bg-white position-fixed w-100 pt-0 mt-0"
        style={{ zIndex: 99999, top: 220 - (scrollSize / 100) * 40 }}
      >
        <Tab
          eventKey="details"
          title={<div className="d-flex">Details</div>}
          tabClassName="new-tab ms-4"
          style={{ paddingTop: 165 }}
        >
          <div className="slide-in-right pb-5">
            <div className="details-tab p-4">
              <div className="details-view mb-4">
                <p>Overview</p>
                <div className="container">
                  <DrawerBody data={bodyData} />

                  {singleData?.fundRequest?.transaction && (
                    <div className="pt-3">
                      <ItemInfo
                        title="Transaction ID"
                        isLink={
                          singleData?.fundRequest?.transaction?.code
                            ? `/transactions/payments/${singleData?.fundRequest?.transaction?.code}/details`
                            : false
                        }
                        withAction={singleData?.fundRequest?.transaction?.code}
                        value={singleData?.fundRequest?.transaction?.code ?? '-'}
                        icon={
                          <ArrowUpRightIcon stroke="#D28B28" width="16" height="16" />
                        }
                      />
                    </div>
                  )}
                </div>
              </div>

              {singleData?.fundRequest?.type === 'PAYMENT' && (
                <div className="details-view mb-4">
                  <p>Recipient details</p>
                  <div className="container">
                    <div className="mb-3">
                      <ItemInfo
                        title="Vendor name"
                        withAction
                        value={
                          <div className="d-flex align-items-center gap-2">
                            <ImgCard
                              size="small"
                              fullWidth={false}
                              initials={singleData?.fundRequest?.vendor?.name?.charAt(0)}
                            />
                            <span>{singleData?.fundRequest?.vendor?.name}</span>
                          </div>
                        }
                        action={() =>
                          history.push(
                            `/expenses/vendors/profile/${singleData?.fundRequest?.vendor?.code}`,
                          )
                        }
                        icon={
                          <ArrowUpRightIcon stroke="#D28B28" width="16" height="16" />
                        }
                      />
                    </div>

                    {accounts[0]?.bankName && (
                      <div className="mb-3">
                        <ItemInfo
                          title="Bank"
                          value={accounts[0]?.bankName}
                          withBadge
                          icon={
                            <ImgCard
                              size="small"
                              fullWidth={false}
                              initials={accounts[0]?.bankName?.charAt(0)}
                            >
                              <img
                                onError={handleImgError}
                                className="account-logo"
                                src={
                                  BanksIcons[accounts[0]?.bankName?.toLowerCase()] ??
                                  bankIcon
                                }
                              />
                            </ImgCard>
                          }
                        />
                      </div>
                    )}

                    {accounts[0]?.number && (
                      <ItemInfo title="Account number" value={accounts[0]?.number} />
                    )}
                  </div>
                </div>
              )}

              <div className="details-view mb-4">
                <p>Payment details</p>
                <div className="container">
                  {(yourTurnToApprove || !['pending'].includes(status)) &&
                    !singleData?.fundRequest?.card && (
                      <div className="padding-top">
                        <ItemInfo
                          title="Source"
                          isLink={
                            singleData?.fundRequest?.source?.code
                              ? `/accounts/${singleData?.fundRequest?.source?.code}`
                              : false
                          }
                          withAction={
                            ['pending'].includes(status) &&
                            singleData?.fundRequest?.source?.code
                          }
                          action={() =>
                            setSourceVisible({ isVisible: !sourceVisible.isVisible })
                          }
                          value={
                            <div className="d-flex align-items-center gap-2">
                              {singleData?.fundRequest?.source?.name && (
                                <ImgCard
                                  size="small"
                                  fullWidth={false}
                                  initials={singleData?.fundRequest?.source?.name?.charAt(
                                    0,
                                  )}
                                >
                                  <img
                                    onError={handleImgError}
                                    className="account-logo"
                                    src={bankIcon}
                                  />
                                </ImgCard>
                              )}

                              <span>
                                {singleData?.fundRequest?.source?.name ??
                                  'Select a source'}
                              </span>
                            </div>
                          }
                          isLoading={AccountVisible.isLoading}
                          icon={
                            ['pending'].includes(status) && (
                              <PencilIcon stroke="#D28B28" width="18" height="18" />
                            )
                          }
                        />
                      </div>
                    )}

                  {(!singleData?.fundRequest?.source?.code ||
                    sourceVisible.isVisible) && (
                    <div className="">
                      <CustomSelectRadio
                        placeholder="Select a source"
                        name="source"
                        onChange={(val) => updateRequest(val.value, 'source')}
                        defaultValue={
                          singleData?.fundRequest?.source?.code && {
                            value: singleData?.fundRequest?.source?.code,
                            label: singleData?.fundRequest?.source?.name,
                          }
                        }
                        isLoading={loadingBalances}
                        isDisabled={loadingBalances}
                        loadOptions={loadAccountOptions}
                      />
                    </div>
                  )}

                  {singleData?.fundRequest?.type === 'PAYMENT' && (
                    <div className="pt-3">
                      <ItemInfo
                        title="Category"
                        isLink={
                          singleData?.fundRequest?.category?.code
                            ? `/compliances/categories/${singleData?.fundRequest?.category?.code}/details`
                            : false
                        }
                        hideLinkIcon
                        value={
                          <BadgeType
                            value={{
                              value:
                                expenseCategories.value.label ??
                                singleData?.fundRequest?.category?.name ??
                                'Select category',
                              rightIcon: singleData?.fundRequest?.category?.code && (
                                <ArrowUpRightIcon
                                  stroke={asciiToHex(
                                    expenseCategories.value.label ??
                                      singleData?.fundRequest?.category?.name ??
                                      'Select category',
                                  )}
                                  width="16"
                                  height="16"
                                />
                              ),
                              color: getColor(
                                expenseCategories.value.label ??
                                  singleData?.fundRequest?.category?.name ??
                                  'Select category',
                              ),
                            }}
                          />
                        }
                        withAction={
                          !['failed', 'declined'].includes(status) &&
                          singleData?.fundRequest?.category?.name
                        }
                        action={() => setCategoryVisible(!categoryVisible)}
                        isLoading={isEditingCategory}
                        icon={<PencilIcon stroke="#D28B28" width="18" height="18" />}
                      />
                    </div>
                  )}

                  {(!singleData?.fundRequest?.category?.name || categoryVisible) &&
                    singleData?.fundRequest?.type === 'PAYMENT' && (
                      <div className="padding-top-xxs">
                        <CategoryDropdown
                          placeholder="Select a category"
                          onChange={(val) => onHandleGetSelected(val)}
                          value={expenseCategories.value}
                          defaultValue={{
                            value: singleData?.fundRequest?.category?.code,
                            label: singleData?.fundRequest?.category?.name,
                          }}
                          name="category"
                        />
                      </div>
                    )}

                  {singleData?.fundRequest?.vendor && (
                    <div className="pt-3">
                      <ItemInfo
                        title="Vendor"
                        isLink={
                          singleData?.fundRequest?.vendor?.code
                            ? `/expenses/vendors/${singleData?.fundRequest?.vendor.code}`
                            : false
                        }
                        withAction={['pending'].includes(status)}
                        action={() => {
                          setVendorVisible({ isVisible: !vendorVisible.isVisible });
                        }}
                        value={
                          <div className="d-flex align-items-center gap-2">
                            <ImgCard
                              size="small"
                              fullWidth={false}
                              initials={(
                                singleData?.fundRequest?.vendor?.name ?? '-'
                              )?.charAt(0)}
                            />
                            <span>{singleData?.fundRequest?.vendor?.name ?? '-'}</span>
                          </div>
                        }
                        isLoading={vendorVisible.isLoading}
                        icon={
                          ['pending'].includes(status) && (
                            <PencilIcon stroke="#D28B28" width="18" height="18" />
                          )
                        }
                      />
                    </div>
                  )}
                  {vendorVisible.isVisible && (
                    <div className="padding-top-xxs">
                      <CustomSelect
                        name="vendor"
                        placeholder={`Select specific vendors (e.g., "Office Depot")`}
                        defaultValue={
                          singleData?.fundRequest?.vendor?.code && {
                            value: singleData?.fundRequest?.vendor?.code,
                            label: singleData?.fundRequest?.vendor?.name,
                          }
                        }
                        onChange={(val) => updateRequest(val.value, 'vendor')}
                        options={generateVendor}
                      />
                    </div>
                  )}

                  {singleData?.fundRequest?.card && (
                    <div
                      className={classNames(
                        singleData?.fundRequest?.vendor ? 'pt-3' : '',
                      )}
                    >
                      <ItemInfo
                        title="Card"
                        isLink={
                          singleData?.fundRequest?.card?.code
                            ? `/cards/${singleData?.fundRequest?.card.code}`
                            : false
                        }
                        withAction={['pending'].includes(status)}
                        action={() =>
                          setCardVisible({ isVisible: !cardVisible.isVisible })
                        }
                        value={singleData?.fundRequest?.card?.name ?? '-'}
                        isLoading={cardVisible.isLoading}
                        icon={
                          ['pending'].includes(status) && (
                            <PencilIcon stroke="#D28B28" width="18" height="18" />
                          )
                        }
                      />
                    </div>
                  )}

                  {cardVisible.isVisible && (
                    <div className="padding-top-xxs">
                      <CustomSelect
                        label="Card"
                        name="card"
                        placeholder={`Select a card`}
                        defaultValue={
                          singleData?.fundRequest?.card?.code && {
                            value: singleData?.fundRequest?.card?.code,
                            label: singleData?.fundRequest?.card?.name,
                          }
                        }
                        onChange={(val) => updateRequest(val.value, 'card')}
                        options={generateVendor}
                      />
                    </div>
                  )}

                  {singleData?.fundRequest?.bankAccount?.balance && (
                    <div className="pt-3">
                      <ItemInfo
                        title="Account"
                        isLink={
                          singleData?.fundRequest?.bankAccount?.balance?.code
                            ? `/accounts/${singleData?.fundRequest?.bankAccount?.balance?.code}`
                            : false
                        }
                        withAction={['pending'].includes(status)}
                        action={() =>
                          setAccountVisible({ isVisible: !AccountVisible.isVisible })
                        }
                        value={
                          <div className="d-flex align-items-center gap-2">
                            {singleData?.fundRequest?.bankAccount?.balance?.name && (
                              <ImgCard
                                size="small"
                                fullWidth={false}
                                initials={(
                                  singleData?.fundRequest?.bankAccount?.balance?.name ??
                                  '-'
                                )?.charAt(0)}
                              >
                                <img
                                  onError={handleImgError}
                                  className="account-logo"
                                  src={bankIcon}
                                />
                              </ImgCard>
                            )}

                            <span>
                              {singleData?.fundRequest?.bankAccount?.balance?.name ?? '-'}
                            </span>
                          </div>
                        }
                        isLoading={AccountVisible.isLoading}
                        icon={
                          ['pending'].includes(status) && (
                            <PencilIcon stroke="#D28B28" width="18" height="18" />
                          )
                        }
                      />
                    </div>
                  )}

                  {AccountVisible.isVisible && (
                    <div className="padding-top-xxs">
                      <CustomSelectRadio
                        placeholder="Select an account"
                        name="account"
                        onChange={(val) => updateRequest(val.value, 'account')}
                        defaultValue={
                          singleData?.fundRequest?.bankAccount?.balance?.code && {
                            value: singleData?.fundRequest?.bankAccount?.balance?.code,
                            label: singleData?.fundRequest?.bankAccount?.balance?.name,
                          }
                        }
                        isLoading={loadingBalances}
                        isDisabled={loadingBalances}
                        loadOptions={loadAccountOptions}
                      />
                    </div>
                  )}

                  {singleData?.fundRequest?.budget && (
                    <div className="pt-3">
                      <ItemInfo
                        title="Budget"
                        isLink={
                          singleData?.fundRequest?.budget?.code
                            ? `/expenses/budgets/${singleData?.fundRequest?.budget?.code}/overview`
                            : false
                        }
                        withAction={['pending'].includes(status)}
                        action={() =>
                          setBudgetVisible({ isVisible: !budgetVisible.isVisible })
                        }
                        value={
                          <div className="d-flex align-items-center gap-2">
                            {singleData?.fundRequest?.budget?.name && (
                              <ImgCard
                                size="small"
                                fullWidth={false}
                                initials={(
                                  singleData?.fundRequest?.budget?.name ?? '-'
                                )?.charAt(0)}
                              >
                                <img
                                  onError={handleImgError}
                                  className="account-logo"
                                  src={bankIcon}
                                />
                              </ImgCard>
                            )}

                            <span>{singleData?.fundRequest?.budget?.name ?? '-'}</span>
                          </div>
                        }
                        isLoading={budgetVisible.isLoading}
                        icon={
                          ['pending'].includes(status) && (
                            <PencilIcon stroke="#D28B28" width="18" height="18" />
                          )
                        }
                      />
                    </div>
                  )}
                  {singleData?.fundRequest?.meta?.budget && (
                    <div className="pt-3">
                      <ItemInfo
                        title="New Budget Name"
                        withAction={['pending'].includes(status)}
                        value={singleData?.fundRequest?.meta?.budget}
                        action={() =>
                          setNewBudget({
                            ...newBudget,
                            visible: !newBudget.visible,
                          })
                        }
                        icon={
                          ['pending'].includes(status) && (
                            <PencilIcon stroke="#D28B28" width="18" height="18" />
                          )
                        }
                      />
                    </div>
                  )}
                  {newBudget.visible && (
                    <div className="padding-top-xxs">
                      <CustomInput
                        label="Budget name"
                        placeholder="Enter name"
                        type="text"
                        name="budgetName"
                        onChange={handleBudgetChange}
                        value={newBudget.data}
                        maxLength="100"
                      />
                    </div>
                  )}

                  {budgetVisible.isVisible && (
                    <div className="padding-top-xxs">
                      <CustomSelectRadio
                        placeholder="Select a budget"
                        name="budget"
                        onChange={(val) => updateRequest(val.value, 'budget')}
                        defaultValue={
                          singleData?.fundRequest?.budget?.code && {
                            value: singleData?.fundRequest?.budget?.code,
                            label: singleData?.fundRequest?.budget?.name,
                          }
                        }
                        isLoading={loadingBalances}
                        isDisabled={loadingBalances}
                        loadOptions={loadOptions}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div>
                <div className="padding-top-xxs">
                  <CustomTextarea
                    label="Description"
                    action={() => {
                      setDescription({ ...description, visible: !description.visible });
                      setSaveButton(!saveButton);
                    }}
                    isLoading={description.isEditingDescription}
                    icon={
                      ['pending'].includes(status) && (
                        <PencilIcon stroke="#D28B28" width="18" height="18" />
                      )
                    }
                    name="description"
                    placeholder="Description"
                    value={description?.data}
                    disabled={!description?.visible || !['pending'].includes(status)}
                    onChange={handleDescriptionChange}
                    rowSize={4}
                  />
                </div>
              </div>
            </div>
          </div>
        </Tab>
        <Tab
          eventKey="attachements"
          title={<div className="d-flex">Attachments</div>}
          tabClassName="new-tab"
          style={{ paddingTop: 165 }}
        >
          <div className="slide-in-right p-4 pb-5">
            <div className="details-tab">
              <div className="details-view mb-4">
                <p>Receipts</p>
                <div className="container d-flex gap-2 overflow-x-scroll">
                  {loadingReceipt && !receiptAssets?.length && <LoadingReciept />}
                  <NewReceiptUpload
                    receiptAssets={receiptAssets}
                    viewImgUrl={viewImgUrl}
                    addMore={true}
                    removeFile={!receiptCode.receipt?.length}
                    onChange={(value) =>
                      setReceiptCode({
                        receipt: value?.map((item) => item.assetCode),
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Tab>
        <Tab
          eventKey="updates"
          title={<div className="d-flex">Updates</div>}
          tabClassName="new-tab"
          style={{ paddingTop: 165 }}
        >
          <div className="slide-in-right p-4 pb-5">
            <div className="details-tab">
              <div className="details-view mb-4">
                <Timeline
                  data={approvers}
                  multipleRule={singleRequestData?.relatedApprovalRequests.length > 1}
                  goToRule={openRule}
                  code={code}
                  pageFrom={`/requests/funds`}
                />
                {['pending'].includes(singleData?.fundRequest?.status) &&
                  !yourTurnToApprove &&
                  !!singleData?.fundRequest?.moreInfoLogs?.length && (
                    <>
                      <div className="divider"></div>
                      <DetailsTimeline
                        data={singleData?.fundRequest?.moreInfoLogs}
                        action={requestMoreDetail}
                      />
                    </>
                  )}
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>

      <div className="position-fixed bottom-0 card-modal-footer bg-white px-md-4 px-2 py-2 border-top w-100">
        {showMakePayment && (
          <div className="d-flex align-items-center gap-2 justify-content-end">
            <CustomButton
              className="base-button text-sm font-medium dark-button"
              onClick={handlePayTransactionModal}
            >
              Make payment
            </CustomButton>
          </div>
        )}

        {saveButton && (
          <div className="d-flex align-items-center gap-2 justify-content-end">
            <CustomButton
              onClick={cancelDescriptionEdit}
              className="base-button text-sm font-medium  black-transparent"
            >
              Cancel
            </CustomButton>

            <CustomButton
              onClick={handleDescription}
              className="base-button text-sm font-medium dark-button"
            >
              {description?.isEditingDescription ||
              newBudget.isEditingBudget ||
              receiptCode?.isUpdating ? (
                <Loading color="#D28B28" size={20} />
              ) : (
                'Save changes'
              )}
            </CustomButton>
          </div>
        )}

        {['pending'].includes(singleData?.fundRequest?.status) && (
          <>
            {yourTurnToApprove && !saveButton && (
              <div className="d-flex align-items-center gap-2 flex-wrap justify-content-end">
                <div className="mt-1">
                  <CustomButton
                    className="base-button text-sm font-medium"
                    withoutBg
                    onClick={handleCancelRequest}
                    disabled={isLoading}
                    style={{ width: 'fit-content', padding: '10px', minWidth: 0 }}
                  >
                    Decline
                  </CustomButton>
                </div>

                <div className="mt-1">
                  <CustomButton
                    className="base-button black-transparent text-sm font-medium dark-button"
                    onClick={requestMoreDetail}
                  >
                    Request more details
                  </CustomButton>
                </div>

                <div className="d-flex mt-1">
                  <button
                    disabled={isLoading || loadingBalances}
                    onClick={() => {
                      attemptApprove(false, true);
                      setPayNow(canApprovePay);
                    }}
                    className={classNames(
                      'px-3 nowrap dropdown-btn action-btn text-white',
                      {
                        ['rounded-3 px-4']: !canApprovePay,
                      },
                    )}
                  >
                    {canApprovePay ? 'Approve and pay' : 'Approve only'}
                  </button>

                  {canApprovePay && (
                    <CustomPopover
                      placement="top-start"
                      id="invoice-schedule"
                      zIndex="9999"
                      content={<Actions />}
                      showPopover={isButtonToggle}
                      clickOutside={handleButtonToggle}
                    >
                      <button
                        className="dropdown-btn"
                        disabled={isLoading || loadingBalances}
                        onClick={handleButtonToggle}
                      >
                        <ChevronDown
                          color="#ffffff"
                          className={`icon ${isButtonToggle && 'is-toggled'}`}
                        />
                      </button>
                    </CustomPopover>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <Modal
        show={isPay}
        centered
        dialogClassName="custom-dialog"
        className="custom-dialog"
      >
        <ConfirmDialog
          title={`Pay transaction`}
          subTitle={<>Are you sure you want to pay this transaction?</>}
          extraChild={
            !singleData?.fundRequest?.source?.code && (
              <div className="border-top" style={{ maxWidth: '300px' }}>
                <Row className="align-items-center">
                  <CustomSelectRadio
                    label="Where are you paying from?"
                    name="source"
                    placeholder="Select a source"
                    onChange={(val) => setSource(val)}
                    value={source}
                    isLoading={loadingBalances}
                    isDisabled={loadingBalances}
                    loadOptions={allAccounts}
                  />
                </Row>
              </div>
            )
          }
          onConfirm={payTransaction}
          loading={loadingPayNow}
          onCancel={() => handlePayTransactionModal()}
          isDeleteDialog={false}
        />
      </Modal>
      <Modal show={addDeadLine} centered dialogClassName="custom-dialog">
        <DeadlinePicker
          onClose={() => setAddDeadLine(false)}
          loading={isLoading}
          onDatePicked={({ pick }) => {
            dispatch(
              updateRequests({
                code,
                deadLine: pick.date,
              }),
            );
          }}
        />
      </Modal>
      <Modal show={editAmount} centered dialogClassName="custom-dialog">
        <InputDialog
          onClose={() => setEditAmount(false)}
          loading={isLoading}
          title="Update the amount"
          label="Enter the amount"
          onSubmit={({ amount, currency }) => {
            dispatch(
              updateRequests({
                code,
                amount,
              }),
            );
            setTimeout(() => dispatch(getRequests()), 300);
          }}
          defaultValue={selectRequest?.requestData?.amount / 100}
          currency={selectRequest?.requestData?.currency}
        />
      </Modal>
      <Modal show={isSchedule} centered dialogClassName="custom-dialog">
        <SchedulePayment
          onClose={() => setIsSchedule(false)}
          loading={isLoading}
          setSchedule={approveRequest}
        />
      </Modal>

      {approve && (
        <Modal
          show={true}
          centered
          dialogClassName="custom-dialog"
          className="custom-dialog"
        >
          <ConfirmDialog
            title="Approve request"
            subTitle="Are you sure you want to approve this request"
            onConfirm={approveRequest}
            loading={loadingApproval || loading}
            onCancel={() => setIsApproving(false)}
            isDeleteDialog={false}
          />
        </Modal>
      )}
    </>
  );
};

export default RequestDetails;
