import { InvoiceAmountInformation, InvoicePreviewTable } from '.';
import PoweredBy from 'assets/images/powered-by-bujeti.png';

import dayjs from 'dayjs';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { capitalizeFirstLetter, getCurrency, convertNaNToZero } from 'utils/helper';
import { TEMPLATE_MODES } from '../TemplateSelector';
import CurrencyFormat from 'react-currency-format';

const Classic = ({ data = {}, mode, isRecurring, LogoIcon, isInstalment }) => {
  const headers = [
    { key: 'index', label: '#' },
    { key: 'item', minWidth: 130, label: 'Item & Description' },
    { key: 'quantity', label: 'Qty', minWidth: 40 },
    { key: 'rate', label: 'Rate' },
    { key: 'amount', label: 'Amount', align: 'right' },
  ];

  return (
    <section
      className={classNames(
        'invoice-preview template-classic d-flex flex-column pt-5 px-5',
        {
          'is-demo': mode === TEMPLATE_MODES.DEMO,
          detail: mode === TEMPLATE_MODES.DASHBOARD_VIEW,
          'customer-view': mode === TEMPLATE_MODES.CUSTOMER_VIEW,
        },
      )}
    >
      <InvoiceHeader data={data} LogoIcon={LogoIcon} isInstalment={isInstalment} />

      <section className="preview-table template-classic mt-4 px-0">
        <InvoicePreviewTable headers={headers} isRecurring={isRecurring} data={data} />
      </section>

      <section className="d-flex justify-content-end">
        <div className="account-info template-classic">
          <InvoiceAmountInformation data={data} mode={mode} isRecurring={isRecurring} />
        </div>
      </section>

      <section className="invoice-preview-footer px-0 mt-auto">
        <InvoiceFooter data={data} />
      </section>
    </section>
  );
};

const InvoiceHeader = ({ data, LogoIcon, isInstalment }) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between pb-3">
        <div className="invoice-image-holder">
          <img src={data?.logo ?? LogoIcon} alt="company logo" />
        </div>

        <div className="ms-auto w-35">
          {data?.title && (
            <div className="d-flex justify-content-between mb-1">
              <span className="caption">Invoice no.</span>
              <span className="value">#{data?.title}</span>
            </div>
          )}

          {isInstalment && data?.amountRemaining && (
            <div className="d-flex justify-content-between ">
              <span className="caption">Balance due</span>
              <span className="value">
                <CurrencyFormat
                  value={convertNaNToZero(data?.amountRemaining / 100).toFixed(2)}
                  displayType="text"
                  thousandSeparator={true}
                  prefix={getCurrency(data?.currency || 'NGN')}
                />
              </span>
            </div>
          )}
        </div>
      </div>

      <section className="mt-3">
        <span className="value">{`${data?.company?.name}`}</span>
        <span className="caption">{data?.company?.contactEmail}</span>
        <span className="caption">
          {data?.company?.contact_phone ? `+${data.company.contact_phone}` : ''}
        </span>
        <span className="caption">{data?.address}</span>
      </section>

      <div className="d-flex align-items-center justify-content-between  mt-5">
        <section>
          <span className="caption">Billed to</span>
          <span className="value">
            {data?.customer?.name ||
              `${data?.customer?.firstName} ${data?.customer?.lastName}`}
          </span>
          <span className="caption">{data?.customer?.email}</span>
          <span className="caption">
            {data?.customer?.phoneNumber?.localFormat
              ? `+${data?.customer?.phoneNumber?.countryCode ?? ''}${
                  data?.customer?.phoneNumber?.localFormat ?? ''
                }`
              : null}
          </span>

          {data?.customer?.address?.street && (
            <span className="caption">
              {`${data?.customer?.address?.street}, ${
                data?.customer?.address?.city
              }, ${capitalizeFirstLetter(
                data?.customer?.address?.country?.toLowerCase(),
              )}`}
            </span>
          )}
        </section>

        <div className="ms-auto w-35">
          <div className="d-flex justify-content-between mb-1">
            <span className="caption">Invoice date</span>
            <span className="value">
              {dayjs(data?.issuedDate || new Date()).format('DD MMM, YYYY')}
            </span>
          </div>

          <div className="d-flex justify-content-between ">
            <span className="caption">Due date</span>
            <span className="value">
              {data?.dueDate ? dayjs(data?.dueDate).format('DD MMM, YYYY') : '-'}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

const InvoiceFooter = ({ data }) => {
  const { description, account: bankDetails = {} } = data;
  return (
    <section className="mt-3">
      {description && (
        <Col md={6}>
          <Row className="pb-3 max-w-300">
            <div className="lhs">
              <div style={{ marginRight: 15 }}>
                <h1 className="value">Notes</h1>
                <p className="caption">{description}</p>
              </div>
            </div>
          </Row>
        </Col>
      )}
      {bankDetails?.accountName && (
        <Col className="mt-2">
          <div className="max-w-300">
            <div className="lhs">
              <h1 className="header-title">Payment information</h1>
              <span className="caption">{bankDetails?.bank || '-'}</span>
              <span className="caption">{bankDetails?.accountName || '-'}</span>
              <span className="caption">{bankDetails?.accountNumber || '-'}</span>
            </div>
          </div>
        </Col>
      )}

      <div className="mt-4 mb-2 powered-holder">
        <img src={PoweredBy} alt="Bujeti" />
      </div>
    </section>
  );
};

export default Classic;
