import React, { useMemo } from 'react';
import { PDFDownloadLink } from 'components/UIHooks/JsPDF';

import dayjs from 'dayjs';
import { DownloadIcon } from 'assets/icons';

import NewDawnPdf from './Templates/Pdfs/NewDawnPdf';
import ClassicPdf from './Templates/Pdfs/ClassicPdf';
import FoundationPdf from './Templates/Pdfs/FoundationPdf';

import { convertNaNToZero, capitalizeFirstLetter } from 'utils/helper';

export const TemplatePdfFactory = new Map([
  ['classic', React.memo(ClassicPdf)],
  ['new dawn', React.memo(NewDawnPdf)],
  ['foundation', React.memo(FoundationPdf)],
]);

export const handleNumberFormatting = (currency = 'NGN', value) => {
  return `${currency} ${FormatAmount(convertNaNToZero(value / 100).toFixed(2))}`;
};

export const FormatAmount = (amount) => {
  return Number(amount).toLocaleString();
};

export const companyAddressAliaser = (companyData = {}) => {
  return `${companyData?.address?.street ?? '-'} ${companyData?.address?.city ?? '-'}  ${
    companyData?.address?.state ?? '-'
  }, ${
    companyData?.address?.country
      ? capitalizeFirstLetter(companyData.address.country.toLowerCase())
      : '-'
  }`;
};

const DownloadPdfComponent = ({
  invoiceSelected,
  companyData,
  companyLogo,
  pdfDownloadRef,
  handleDownloadPdf,
}) => {
  const {
    template = {},
    createdAt = '',
    created_at = '',
  } = invoiceSelected?.details || {};

  const dateAliaser = createdAt || created_at;
  const TemplateComponent = useMemo(
    () => TemplatePdfFactory.get((template?.name ?? '').toLowerCase()) || 'new dawn',
    [template],
  );

  const documentData = useMemo(() => {
    return (
      <TemplateComponent
        data={
          invoiceSelected && {
            ...invoiceSelected?.details,
            ...companyLogo,
            company: { ...companyData, logo: companyLogo?.logo },
          }
        }
      />
    );
  }, [invoiceSelected, companyData]);

  return (
    <>
      <div className="actionLink" style={{ display: 'none' }}>
        <PDFDownloadLink
          document={documentData}
          fileName={`Invoice from ${companyData?.name}_${
            invoiceSelected?.details?.code
          }_${dayjs(dateAliaser || new Date()).format('DD-MMMM-YYYY')}.pdf`}
          style={{
            textDecoration: 'none',
            color: '#212529',
          }}
        >
          <span
            ref={pdfDownloadRef}
            onClick={(event) => {
              event?.stopPropagation();
              if (handleDownloadPdf) handleDownloadPdf();
            }}
          >
            <DownloadIcon
              style={{ fontSize: '16px', marginLeft: '-5px', marginRight: '8px' }}
            />{' '}
            Download PDF
          </span>
        </PDFDownloadLink>
      </div>
    </>
  );
};

export default DownloadPdfComponent;
