import { ChevronDown } from 'assets/icons';
import CustomButton from 'components/UI/CustomButton';
import CustomPopover from 'components/UI/Popover';
import { useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  capitalizeFirstLetter,
  getCurrency,
  getCurrentPlanType,
  proratedCosts,
} from 'utils/helper';
import Counter from './Counter';
import { Skeleton } from 'antd';

export const seatPriceList = {
  basic: 3000 * 100,
  pro: 3000 * 100,
  enterprise: 5000 * 100,
};

export const freeSeats = {
  basic: 2,
  pro: 2,
  enterprise: 3,
};

const OrderSummary = ({
  selectedPayment,
  type,
  getAddonText,
  handlePayment,
  isLoading,
  billing,
  setBilling,
  isBuySeat,
  seats,
  setSeats,
}) => {
  const {
    viewPlan: { data: viewPlanData },
  } = useSelector(({ billing }) => billing);
  const location = useLocation();
  const { user } = useSelector(({ auth }) => auth);
  const { data: userData = {}, loading } = user;
  const plan = location.state?.plan;
  const {
    getBeneficiaries: { data: beneficiary = {}, loading: loadingBeneficiary },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const { meta } = beneficiary;

  const [isButtonToggle, setIsButtonToggle] = useState(false);

  const getUserPlan = userData?.user?.company?.paymentPlan;

  const totalBeneficiary = meta?.total - freeSeats[plan?.name];

  const totalBeneficiaryFee =
    meta?.total - freeSeats[getUserPlan?.name === 'pro' ? getUserPlan?.name : plan?.name];

  const additionaUserCost =
    getUserPlan?.configuration?.userManagement?.additionalUsers?.NGN;

  const planPayment =
    billing?.billingPeriod?.value === 'annually'
      ? (isBuySeat ? plan?.amount : viewPlanData?.amount) * (1 - 0.1) * 12
      : isBuySeat
      ? plan?.amount
      : viewPlanData?.amount;

  const oldPlanType = getCurrentPlanType(getUserPlan?.expiryDate, getUserPlan?.paidOn);

  const isNewPlan = !['start', 'growth', 'scale', 'custom'].includes(getUserPlan?.name);

  const oldMemberfee = isNewPlan
    ? (+totalBeneficiaryFee + getUserPlan?.additionalSeats) * additionaUserCost
    : 0;

  const seatAmount = proratedCosts(
    getUserPlan?.expiryDate,
    getUserPlan?.paidOn,
    additionaUserCost,
    planPayment,
    oldPlanType,
  );

  const planAmount = proratedCosts(
    getUserPlan?.expiryDate,
    getUserPlan?.paidOn,
    getUserPlan?.amount,
    planPayment,
    billing?.billingPeriod?.value,
  );

  const usersToUpgrade = isNewPlan ? seatPriceList[plan?.name] * totalBeneficiary : 0;

  const previousPlanBalance = isNaN(+planAmount?.balance + oldMemberfee)
    ? 0
    : +planAmount?.balance + oldMemberfee;

  const actualPrice = (
    billing?.billingPeriod?.value === 'annually'
      ? isBuySeat
        ? (seatAmount.balance * seats) / 100
        : +planPayment / 100
      : (isBuySeat ? seatAmount.balance * seats : +planPayment) / 100
  ).toFixed(2);

  const vat = Math.max(
    0,
    ((billing?.billingPeriod?.value === 'annually'
      ? (isBuySeat
          ? +seatAmount.balance * seats
          : Number(+planPayment - previousPlanBalance + usersToUpgrade)) / 100
      : (isBuySeat
          ? +seatAmount.balance * seats
          : Number(+planPayment - previousPlanBalance + usersToUpgrade)) / 100) *
      7.5) /
      100,
  ).toFixed(2);

  const total = Math.max(
    0,
    Number(+actualPrice - previousPlanBalance / 100) +
      Number(+vat) +
      Number(usersToUpgrade / 100),
  ).toFixed(2);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() => {
            setBilling({
              ...billing,
              billingPeriod: { value: 'monthly', label: 'Paid monthly' },
            });
            handleButtonToggle();
          }}
        >
          Monthly
        </div>
        <div
          className="actionLink"
          onClick={() => {
            setBilling({
              ...billing,
              billingPeriod: { value: 'annually', label: 'Paid yearly' },
            });
            handleButtonToggle();
          }}
        >
          Yearly
        </div>
      </div>
    );
  };

  return (
    <section className="order-summary-wrapper px-4">
      <h1 className="payment-title">Order Summary</h1>

      <div className="summary-container mt-4">
        <div className="border-bottom d-flex summary-first-item">
          <p className="m-0">Billing Interval</p>

          <CustomPopover
            zIndex="1"
            showPopover={isButtonToggle}
            clickOutside={handleButtonToggle}
            content={<Actions />}
          >
            <div
              className="interval cursor"
              onClick={isBuySeat ? () => null : handleButtonToggle}
            >
              <span>{capitalizeFirstLetter(billing?.billingPeriod?.value)}</span>{' '}
              <ChevronDown color="#d28b28" />
            </div>
          </CustomPopover>
        </div>

        {loading || loadingBeneficiary ? (
          <LoadingState />
        ) : (
          <div className="summary-items">
            {usersToUpgrade > 0 && !isBuySeat && (
              <div className="items mb-3">
                <p>Additional seat to upgrade ({+totalBeneficiary})</p>
                <p>
                  <CurrencyFormat
                    prefix={getCurrency(viewPlanData?.currency)}
                    value={usersToUpgrade / 100}
                    displayType="text"
                    thousandSeparator={true}
                  />
                </p>
              </div>
            )}
            {/* usersToUpgrade */}
            <div className="items mb-3">
              <p>{isBuySeat ? 'Cost per seat' : `${capitalizeFirstLetter(type)} plan`}</p>
              <p>
                <CurrencyFormat
                  prefix={getCurrency(viewPlanData?.currency)}
                  value={actualPrice}
                  displayType="text"
                  thousandSeparator={true}
                />
              </p>
            </div>

            {isBuySeat && (
              <div className="items mb-3">
                <p>Quantity</p>
                <p>
                  <Counter setSeats={setSeats} seats={seats} min={plan?.seats} />
                </p>
              </div>
            )}

            <div className="items mb-3">
              <p>VAT (7.5%)</p>
              <p>
                <CurrencyFormat
                  prefix={getCurrency(viewPlanData?.currency)}
                  value={vat}
                  displayType="text"
                  thousandSeparator={true}
                />
              </p>
            </div>

            {/* <div className="items mb-3">
            <p>Discount (0%)</p>
            <p>
              <CurrencyFormat
                prefix={getCurrency(viewPlanData?.currency)}
                value={actualPrice}
                displayType="text"
                thousandSeparator={true}
              />
            </p>
          </div> */}

            {planAmount?.remainingDurationDays > 0 && !isBuySeat && (
              <div className="items mb-3">
                <p>Previous plan balance</p>
                <p>
                  -{' '}
                  <CurrencyFormat
                    prefix={getCurrency(viewPlanData?.currency)}
                    value={previousPlanBalance / 100}
                    displayType="text"
                    thousandSeparator={true}
                  />
                </p>
              </div>
            )}

            <div className="total-items">
              <p>Total</p>
              <p>
                <CurrencyFormat
                  prefix={getCurrency(viewPlanData?.currency)}
                  value={total}
                  displayType="text"
                  thousandSeparator={true}
                />
              </p>
            </div>
          </div>
        )}
      </div>

      {/* {selectedPayment !== 'bujeti' && (
        <p className="payment-subtext mt-2">
          Click <b>Continue to payment</b> to link your bank account and confirm your
          payment. We will return you to this page to complete your subscription.
        </p>
      )} */}
      <div className="mt-4">
        <CustomButton
          fullWidth={true}
          onClick={handlePayment}
          loading={isLoading}
          disabled={isLoading}
        >
          {selectedPayment === 'bujeti' && !isBuySeat
            ? `${getAddonText} to ${type}`
            : 'Confirm payment'}
        </CustomButton>
      </div>
    </section>
  );
};

export default OrderSummary;

const LoadingState = () => {
  return (
    <div className="summary-items">
      {Array(3)
        .fill(1)
        .map((_, index) => (
          <div className="items mb-3" key={index}>
            <p>
              <Skeleton.Input
                active
                shape="square"
                style={{ borderRadius: 8, height: '15px', width: '1rem' }}
              />
            </p>
            <p>
              <Skeleton.Input
                active
                shape="square"
                style={{ borderRadius: 8, height: '15px', width: '1rem' }}
              />
            </p>
          </div>
        ))}

      <div className="total-items">
        <p>
          <Skeleton.Input
            active
            shape="square"
            style={{ borderRadius: 8, height: '20px', width: '1rem' }}
          />
        </p>
        <p>
          <Skeleton.Input
            active
            shape="square"
            style={{ borderRadius: 8, height: '20px', width: '1rem' }}
          />
        </p>
      </div>
    </div>
  );
};
