import { Skeleton } from 'antd';
import { ArrowRight, HintAlertIcon } from 'assets/icons';
import ModalContainer from 'components/ModalContainer';
import AccordionCard from 'components/UI/AccordionCard';
import CustomInput from 'components/UI/CustomInput';
import CustomPhoneNumberInput from 'components/UI/CustomPhoneNumberInput';
import CustomSelect from 'components/UI/CustomSelect';
import CustomTextarea from 'components/UI/CustomTextarea';
import MultipleSelect from 'components/UI/MultipleSelect';
import { toastError } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import useTextCounter from 'hooks/useTextCounter';
import { memo, useEffect, useMemo, useState } from 'react';
import { Accordion, Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchCategories } from 'redux/actions/CategoryAction';
import { getCountries, getState } from 'redux/actions/CompaniesAction';
import { getAllBanks, verifyBankAccount } from 'redux/actions/PaymentAction';
import {
  createVendors,
  inviteVendor,
  resetBlockVendor,
  updateVendors,
} from 'redux/actions/VendorsAction';
import { getInternationalFormat, removeEmptyString } from 'utils/helper';
import './styles.scss';

const MAX_LENGTH = '100';

const vendorInitial = {
  name: '',
  email: '',
  city: '',
  description: '',
  taxIdentificationNumber: '',
  country: '',
  state: '',
  street: '',
  taxWithHolding: '',
  accountNumber: '',
  accountName: '',
  bankName: '',
  currency: 'NGN',
  categories: '',
};

const CreateNewVendor = ({
  isOpen = false,
  data = null,
  isUpdate,
  toggleHandler,
  isRequest,
  isNew,
  restrictState,
  editTab = '0',
}) => {
  const [activeKey, setActiveKey] = useState(editTab);
  const [steps, setSteps] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const menuPortalTarget = document.body;

  const handleSelect = (key) => {
    if (activeKey === key) return setActiveKey('999999999');
    setActiveKey(key);
  };

  const {
    createVendor: { loading: creatingVendor, success: successVendors },
    updateVendor: { loading: updatingVendor, success: successUpdateVendors },
    inviteVendor: { loading: invitingVendor, success: successInviteVendors },
  } = useSelector(({ vendors }) => vendors);

  const {
    verifyBankAccount: {
      data: accName,
      loading: accountNameLoading,
      success: accountNameSuccess,
      error: accountNameError,
    },
  } = useSelector(({ payments }) => payments);

  const [vendors, setVendors] = useState(vendorInitial);

  const closeModal = () => {
    toggleHandler();
    setActiveKey('0');
    setSteps(0);
    setVendors(vendorInitial);
  };

  const {
    getCountry: { data: countryData = {} },
    getState: { data: states = {}, success: successState, loading: loadingState },
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);

  const {
    fetchCategories: { data: { categories = [], meta } = {}, loading: loadingCategory },
  } = useSelector(({ categories }) => categories);

  const {
    getAllBanks: {
      data: banksData,
      loading: loadingBanks,
      success: successBanks,
      error: errorBanks,
    },
  } = useSelector(({ payments }) => payments);
  // Get All banks starts here

  const [bankValue, setBankValue] = useState('');
  const bankValuedebounced = useDebounce(bankValue, 200);

  const handleGetBankOnChange = (val) => setBankValue(val);

  const mappedBanks = banksData?.map((item) => item.name);

  const allBanks = useMemo(() => {
    return banksData?.map((item) => ({
      label: item.label,
      value: item.bankCode,
    }));
  }, [errorBanks, mappedBanks?.length]);

  useEffect(() => {
    const banks = allBanks?.find((option) =>
      option?.label?.toLowerCase().includes(bankValuedebounced?.toLowerCase()),
    );
    if (!banks?.value && bankValuedebounced) {
      dispatch(getAllBanks({ search: bankValuedebounced?.toLowerCase() }));
    }
  }, [bankValuedebounced]);

  useEffect(() => {
    if (!countryData?.length) dispatch(getCountries());
    if (!categories?.length) dispatch(fetchCategories());
  }, []);

  const generateCategories = useMemo(() => {
    return categories?.map(({ name, code }) => ({
      label: name,
      value: code,
    }));
  }, [categories]);

  const generateCountry = useMemo(() => {
    if (countryData?.length > 0) {
      return countryData?.map(({ code: value, name: label }) => ({
        value,
        label,
        isDisabled: label !== 'NIGERIA' ? true : false,
      }));
    }
  }, [countryData]);

  const generateState = useMemo(() => {
    return states.states?.map((value) => ({
      label: value,
      value: value,
    }));
  }, [successState]);

  useMemo(() => {
    if (editTab) setActiveKey(editTab);
  }, [editTab]);

  const getCountryStates = async (val) => {
    dispatch(getState(val.value));
  };

  const handleDescriptionChange = (value) => {
    setVendors({
      ...vendors,
      description: value,
    });
  };

  const { text, charCount, handleCharChange } = useTextCounter(
    vendors?.description,
    MAX_LENGTH,
    handleDescriptionChange,
  );

  const handleChange = ({ name, value, validity, rawValue }) => {
    if (['accountName', 'accountNumber'].includes(name))
      return validity.valid && setVendors({ ...vendors, [name]: value });
    return setVendors({ ...vendors, [name]: rawValue ? rawValue : value });
  };

  const handlePhoneNumberChange = (localFormat, internationalFormat, countryCode) => {
    setVendors({ ...vendors, internationalFormat, localFormat, countryCode });
  };

  useEffect(() => {
    if (data) {
      const { vendorData } = data;

      const payload = {
        ...vendorData,
        ...vendorData?.address,
        categories: vendorData?.categories?.map((item) => ({
          label: item.name,
          value: item.code,
        })),
        state: generateState?.find((item) => item.value === data?.address?.state),
        taxIdentificationNumber: vendorData?.tin,
        accountNumber: data?.bankAccounts?.[0]?.number ?? '',
        bankName:
          allBanks?.find((item) => item.value === data?.bankAccounts?.[0]?.bankCode) ??
          '',
        currency: 'NGN',
        localFormat: vendorData?.phoneNumber?.localFormat || '',
        countryCode: vendorData?.phoneNumber?.countryCode || '',
        internationalFormat:
          getInternationalFormat(
            vendorData?.phoneNumber?.countryCode,
            vendorData?.phoneNumber?.localFormat,
          ) || '',
      };

      setVendors({
        ...payload,
        // country: countryDetails,
        state: generateState?.find((item) => item.value === data.address?.state),
        accountNumber: data?.bankAccounts?.[0]?.number ?? '',
      });
    }
  }, [data]);

  useEffect(() => {
    if (successVendors || successUpdateVendors || successInviteVendors) {
      closeModal();

      if (successUpdateVendors) {
        dispatch(resetBlockVendor('updateVendor'));
      }

      if (successVendors && !restrictState) {
        dispatch(resetBlockVendor('createVendor'));
      }
    }
  }, [successVendors, successUpdateVendors, successInviteVendors]);

  useEffect(() => {
    if (vendors.taxWithHolding > 100) {
      setVendors({ ...vendors, taxWithHolding: 100 });
    }
  }, [vendors.taxWithHolding]);

  const handleSubmit = () => {
    if (!vendors.name) return toastError('Please enter name');
    // if (!vendors.email) return toastError('Please enter email');

    const hasAccount =
      vendors?.bankName?.value && vendors?.accountName && vendors?.accountNumber;
    const hasAddress =
      vendors?.country?.value &&
      vendors?.city &&
      vendors?.state?.value &&
      vendors?.street;

    const categoriesList = vendors?.categories?.length
      ? vendors?.categories?.map((items) => items?.value)
      : undefined;

    const payload = {
      name: vendors.name,
      email: vendors?.email,
      ...(hasAddress && {
        address: {
          country: vendors?.country?.value,
          city: vendors?.city,
          stateOrProvince: vendors?.state?.value,
          street: vendors?.street,
        },
      }),
      ...(hasAccount && {
        bankAccount: {
          bankName: vendors?.bankName?.label,
          bankCode: vendors?.bankName?.value,
          accountName: vendors?.accountName,
          number: vendors?.accountNumber,
          currency: vendors?.currency,
        },
      }),
      phoneNumber: vendors?.localFormat
        ? {
            countryCode: Number(vendors?.countryCode),
            localFormat: vendors?.localFormat,
          }
        : undefined,
      description: vendors?.description,
      taxIdentificationNumber: vendors?.taxIdentificationNumber || undefined,
      taxWithHolding: vendors?.taxWithHolding || undefined,
      categories: categoriesList,
    };

    const payloads = removeEmptyString(payload);
    const invitePayload =
      isRequest || (!data?.vendorData?.email && !isNew)
        ? { ...payloads, code: data?.code, request: true }
        : payloads;

    if (steps === 0) {
      if (isUpdate) {
        dispatch(updateVendors({ ...payloads, code: data?.code }));
      } else dispatch(createVendors(payloads));
    } else dispatch(inviteVendor(invitePayload));
  };

  useEffect(() => {
    if (vendors.accountNumber.length === 10 && vendors.bankName.value) {
      const { accountNumber, bankName } = vendors;
      dispatch(verifyBankAccount({ accountNumber, bankCode: bankName.value }));
    }

    if (vendors.accountNumber.length < 10 && vendors.bankName.value) {
      setVendors({ ...vendors, accountName: undefined });
    }
  }, [vendors.accountNumber, vendors.bankName.value]);

  useEffect(() => {
    if (accountNameSuccess) {
      setVendors({ ...vendors, accountName: accName.account_name });
    }
    if (accountNameError) setVendors({ ...vendors, accountName: undefined });
    if (accountNameLoading) setVendors({ ...vendors, accountName: undefined });
  }, [accountNameSuccess, accountNameError, accountNameLoading]);

  const visible = accountNameLoading || accountNameError;

  const AddVendorInputs = (
    <>
      <Accordion defaultActiveKey="0" className="p-0 m-0" activeKey={activeKey}>
        <AccordionCard
          eventKey="0"
          activeKey={activeKey}
          title="Vendor details"
          handleClick={handleSelect}
          wrapperClass="p-0 m-0 border-bottom pb-3 bg-transparent"
        >
          <Col className="">
            <div className="px-0">
              <Row className="mb-2">
                <CustomInput
                  type="text"
                  label="Vendor name *"
                  placeholder="Bujeti Ltd."
                  name="name"
                  value={vendors.name}
                  onChange={({ target: { name, value } }) =>
                    handleChange({ name, value })
                  }
                />
              </Row>
              <Row className="mb-2">
                <CustomInput
                  type="text"
                  label="Vendor email"
                  placeholder="contact@bujeti.com"
                  name="email"
                  value={vendors.email}
                  onChange={({ target: { name, value } }) =>
                    handleChange({ name, value })
                  }
                />
              </Row>
              <Row className="mb-2">
                <MultipleSelect
                  label="Expense categories"
                  name="category"
                  placeholder="Select category"
                  onChange={(value) => handleChange({ name: 'categories', value: value })}
                  value={vendors.categories}
                  isLoading={loadingCategory}
                  isDisabled={loadingCategory}
                  options={generateCategories}
                  menuPortalTarget={menuPortalTarget}
                />
              </Row>

              <Row className="mb-2">
                <CustomTextarea
                  rowSize={3}
                  label="Description"
                  name="description"
                  onChange={handleCharChange}
                  value={text || vendors.description}
                  placeholder="e.g. Secure online payment gateway for transactions"
                  maxLength={MAX_LENGTH}
                  showCounter={true}
                  charCount={charCount}
                />
              </Row>
            </div>
          </Col>
        </AccordionCard>

        {/******************** Payment details  ***************/}
        <AccordionCard
          eventKey="1"
          activeKey={activeKey}
          title="Payment details"
          handleClick={handleSelect}
          wrapperClass=" m-0 border-bottom bg-transparent"
        >
          <Col className="">
            <div className="px-0">
              <Row className="mb-2">
                <CustomSelect
                  label="Bank name"
                  name="bankName"
                  placeholder="Select bank"
                  onChange={(value) => handleChange({ name: 'bankName', value: value })}
                  value={vendors.bankName}
                  options={allBanks}
                  onInputChange={handleGetBankOnChange}
                  isDisabled={loadingBanks && !bankValuedebounced}
                  isLoading={loadingBanks && !bankValuedebounced}
                  menuPortalTarget={menuPortalTarget}
                />
              </Row>
              <Row className="mb-2 ">
                <CustomInput
                  type="text"
                  label="Account number"
                  placeholder="0584932020"
                  name="accountNumber"
                  onChange={({ target: { name, value, validity, rawValue } }) =>
                    handleChange({ name, value, validity, rawValue })
                  }
                  value={vendors.accountNumber}
                  maxLength="10"
                  pattern="[0-9]*"
                />
              </Row>

              {!visible && vendors.accountName && (
                <Row className="mb-2 ">
                  <CustomInput
                    type="text"
                    label="Full name of account holder"
                    placeholder="Bujeti Limited"
                    name="accountName"
                    onChange={({ target: { name, value, validity, rawValue } }) =>
                      handleChange({ name, value, validity, rawValue })
                    }
                    value={vendors.accountName}
                    maxLength="50"
                    disabled
                  />
                </Row>
              )}

              {accountNameLoading && (
                <Row className="mb-2 ">
                  <Skeleton.Input
                    size="large"
                    active
                    className="w-100"
                    style={{ height: '38px', borderRadius: '4px' }}
                  />
                </Row>
              )}

              <Row className="mb-2 align-items-center">
                <CustomInput
                  type="text"
                  label="Tax identification number"
                  placeholder="2234561234"
                  name="taxIdentificationNumber"
                  onChange={({ target: { name, value, validity, rawValue } }) =>
                    handleChange({ name, value, validity, rawValue })
                  }
                  value={vendors.taxIdentificationNumber}
                  md={6}
                />

                <CustomInput
                  label="Tax witholding percentage"
                  placeholder="Tax withholding %"
                  name="taxWithHolding"
                  onChange={({ target: { name, value, validity, rawValue } }) =>
                    handleChange({ name, value, validity, rawValue })
                  }
                  value={vendors.taxWithHolding}
                  type="number"
                  isAmount
                  maxLength="5"
                  useCurrency={false}
                  md={6}
                />
              </Row>
            </div>
          </Col>
        </AccordionCard>

        {/******************** Contact information  ***************/}
        <AccordionCard
          eventKey="2"
          activeKey={activeKey}
          title="Contact information"
          handleClick={handleSelect}
        >
          <Col>
            <div className="px-0">
              <Row className="mb-2">
                <CustomPhoneNumberInput
                  label="Phone number"
                  placeholder="Enter your mobile number"
                  onChange={(localFormat, international, countryCode) =>
                    handlePhoneNumberChange(localFormat, international, countryCode)
                  }
                  value={vendors.internationalFormat}
                />
              </Row>
              <Row className="mb-2">
                <CustomInput
                  type="text"
                  label="Address"
                  placeholder="Plot 3 osolo way"
                  name="street"
                  onChange={({ target: { name, value } }) =>
                    handleChange({ name, value })
                  }
                  value={vendors.street}
                />
              </Row>
              <Row className="mb-2">
                <CustomInput
                  type="text"
                  label="City"
                  placeholder="Ikeja"
                  name="city"
                  onChange={({ target: { name, value } }) =>
                    handleChange({ name, value })
                  }
                  value={vendors.city}
                />
              </Row>

              <Row className="mb-2">
                <CustomSelect
                  label="Country"
                  name="country"
                  placeholder="Select country"
                  value={vendors.country}
                  options={generateCountry}
                  onChange={(value) => {
                    handleChange({ name: 'country', value: value });
                    getCountryStates(value);
                  }}
                  menuPortalTarget={menuPortalTarget}
                />
              </Row>

              <Row className="mb-2 align-items-center">
                <CustomSelect
                  label="State / Province"
                  placeholder="Enter state"
                  name="state"
                  value={vendors.state}
                  isDisabled={loadingState}
                  options={generateState}
                  onChange={(value) => handleChange({ name: 'state', value: value })}
                  md={6}
                  menuPortalTarget={menuPortalTarget}
                />

                <CustomInput
                  label="Postcode"
                  placeholder="Enter your post code"
                  name="postCode"
                  onChange={({ target: { name, value } }) =>
                    handleChange({ name, value })
                  }
                  value={vendors.postCode}
                  md={6}
                />
              </Row>
            </div>
          </Col>
        </AccordionCard>
      </Accordion>

      {(!isUpdate || isRequest) && (
        <div className="border-top bg-transparent pb-3">
          <div className="create__vendor-banner">
            <div className="m-0 p-0">
              <HintAlertIcon width={20} height={20} />
            </div>

            <div>
              <div className="banner-text text-start">
                <h1>Don’t known all their details?</h1>
                <p>
                  You can send an invitation to this vendor to provide necessary
                  information.
                </p>
              </div>
              <div className="actions">
                <button
                  onClick={() => setSteps(1)}
                  // href={customerTemplate}
                  className="text-decoration-none text-reset btn xxs"
                >
                  Invite them to fill out their own profile{' '}
                  <ArrowRight width={12} color="#D28B28" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

  const InviteVendorInput = (
    <>
      <Row className="mb-2">
        <CustomInput
          type="text"
          label="Vendor name *"
          placeholder="Bujeti Ltd."
          name="name"
          onChange={({ target: { name, value } }) => handleChange({ name, value })}
          value={vendors.name}
        />
      </Row>
      <Row className="mb-2">
        <CustomInput
          type="text"
          label="Vendor email *"
          placeholder="contact@bujeti.com"
          name="email"
          value={vendors.email}
          onChange={({ target: { name, value } }) => handleChange({ name, value })}
        />
      </Row>
    </>
  );

  const renderScreen = (step) => {
    switch (step) {
      case 1:
        return InviteVendorInput;

      default:
        return AddVendorInputs;
    }
  };

  const title =
    steps === 1
      ? 'Invite vendor to provide details'
      : `${isUpdate ? 'Update' : 'Create new'} vendor`;
  const buttonTitle =
    steps === 1 ? 'Send invite' : `${isUpdate ? 'Update' : 'Create'} vendor`;
  const subTitle =
    steps === 1
      ? 'Send a secure Bujeti link to provide additional information. Vendor will have 10 calendar days to complete this secure invite. Pro tip: Give them a heads-up.'
      : `Enter vendor details once, and we'll automatically fill them in for all future transactions.`;

  return (
    <Modal
      show={isOpen}
      centered
      dialogClassName="custom-dialog"
      className="custom-dialog"
    >
      <ModalContainer
        lg
        onCancel={closeModal}
        actionBtnText={buttonTitle}
        onConfirm={handleSubmit}
        title={title}
        subTitle={subTitle}
        loading={creatingVendor || updatingVendor || invitingVendor}
        goBack={steps === 1 ? () => setSteps(0) : null}
      >
        <Row className="w-100 p-0 m-0">
          <Col className="p-0 m-0">{renderScreen(steps)}</Col>
        </Row>
      </ModalContainer>
    </Modal>
  );
};

export default memo(CreateNewVendor);
