import { useMemo, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getState,
  getCompany,
  getCountries,
  getCompanyPreference,
  updateCompanyPreference,
  updateCompany,
} from 'redux/actions/CompaniesAction';
import { RESET_FLAGS_COMPANY } from 'redux/reducers/CompaniesReducer';

import { TEMPLATE_MODES } from './components/TemplateSelector';
import classNames from 'classnames';
import { toastSuccess } from 'components/UI/toast';
import { ArrowLeft } from 'assets/icons';
import { Col, Row } from 'react-bootstrap';
import Empty from 'assets/images/empty.png';
import FileUpload from 'components/UI/FileUpload';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import CustomButton, { ActiveButton } from 'components/UI/CustomButton';
import TemplateSelector from './components/TemplateSelector';
import { capitalizeFirstLetter } from 'utils/helper';

const InvoiceSettings = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const {
    getState: { data: { states } = [] },
    getCountry: { data: countryData = [] },
    updateCompany: { loading: isCompanyUpdating },
    getCompanyPreference: { data: { templatePreference = {} } = {} },
    getCompany: { data: companyData = {}, loading: isFetchingCompany },
    updateCompanyPreference: { loading: isUpdating, success: updateSuccess },
  } = useSelector(({ companies }) => companies);

  const aliaseCountryCode = (iso) =>
    countryData.find((country) => country?.iso_code === iso) ?? {};

  const initialValues = useRef(null);
  const [template, setTemplate] = useState('');
  const [isUpload, setIsUpload] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [businessInfo, setBusinessInfo] = useState({
    street: '',
    city: '',
    state: { label: '', value: '' },
    country: { label: '', value: '' },
    postalCode: '',
  });

  useEffect(() => {
    if (!countryData?.length) dispatch(getCountries());
  }, []);

  useEffect(() => {
    if (!isUpdating && updateSuccess && !isCompanyUpdating) {
      dispatch({ type: RESET_FLAGS_COMPANY, blockType: 'updateCompany' });
      dispatch({ type: RESET_FLAGS_COMPANY, blockType: 'updateCompanyPreference' });

      dispatch(getCompanyPreference());
      dispatch(getCompany({ code: companyData?.code }));

      toastSuccess('Settings have been updated.');
      push('/receivables/invoices');
    }
  }, [isUpdating, updateSuccess, isCompanyUpdating]);

  useEffect(() => {
    if (Object.keys(companyData)?.length && !isFetchingCompany) {
      const formattedData = {
        street: companyData?.address?.street || '',
        city: companyData?.address?.city || '',
        state: {
          label: companyData?.address?.state || '',
          value: companyData?.address?.state || '',
        },
        country: {
          label: capitalizeFirstLetter(companyData?.address?.country.toLowerCase() || ''),
          value: aliaseCountryCode(companyData?.address?.countryIso)?.code || '',
        },
        postalCode: companyData?.address?.postalCode || '',
        logo: companyData?.logo?.code || '',
      };

      setBusinessInfo(formattedData);
      initialValues.current = formattedData;
    }
  }, [companyData, isFetchingCompany]);

  useEffect(() => {
    if (initialValues.current) {
      setHasChanges(checkForChanges(businessInfo, initialValues.current));
    }
  }, [businessInfo]);

  useEffect(() => {
    if (businessInfo?.country?.value) {
      const country = countryData?.find(
        (country) => country.code === businessInfo?.country?.value,
      );

      if (country) dispatch(getState(country.code));
    }

    dispatch(getCompanyPreference());
  }, [businessInfo?.country]);

  const countryList = useMemo(() => {
    if (countryData?.length) {
      return countryData
        ?.filter((item) => item?.name === 'NIGERIA')
        ?.map(({ code: value, name: label = '' }) => ({
          value,
          label: capitalizeFirstLetter(label.toLowerCase()),
          isDisabled: label !== 'NIGERIA',
        }));
    }
  }, [countryData]);

  const checkForChanges = (current, initial) => {
    const compareObjects = ['state', 'country'];
    const compareFields = ['street', 'city', 'postalCode', 'logo'];

    const hasSimpleChanges = compareFields.some(
      (field) => current[field] !== initial[field],
    );

    const hasObjectChanges = compareObjects.some(
      (field) =>
        current[field]?.value !== initial[field]?.value ||
        current[field]?.label !== initial[field]?.label,
    );

    return hasSimpleChanges || hasObjectChanges;
  };

  const stateList = useMemo(() => {
    return states?.map((state) => ({ label: state, value: state })) || [];
  }, [states]);

  const saveChanges = () => {
    const { component, ...data } = template;
    const payload = {
      postalCode: businessInfo.postalCode ? businessInfo.postalCode : undefined,
      state: businessInfo.state.value,
      street: businessInfo?.street,
      city: businessInfo.city,
      country: businessInfo.country.value,
    };

    dispatch(
      updateCompanyPreference({
        code: templatePreference?.code,
        value: data?.code || templatePreference?.value?.code,
      }),
    );
    if (hasChanges)
      dispatch(
        updateCompany({
          address: payload,
          logo: businessInfo.logo,
          code: companyData?.code,
        }),
      );
  };

  const handleChangeBusinessInfo = ({ target: { name, value } }) => {
    setBusinessInfo({ ...businessInfo, [name]: value });
  };
  return (
    <section className="invoice-settings__holder">
      <div className="coverage-area">
        <span className="back-click" onClick={() => push('/receivables/invoices')}>
          <span className="arrow-icon">
            <ArrowLeft color="#D28B28" height="16" width="16" />
          </span>
          Back
        </span>

        <div className="header-region">
          <h2 className="caption">Invoice settings</h2>
          <p className="sub-text">Set up your default settings for new invoices.</p>
        </div>

        <section className="main-area">
          <div className="form-area">
            <div style={{ borderBottom: '1px solid #e7e5e4' }}>
              <div className={classNames('companyLogo-wrapper', { 'pb-0': isUpload })}>
                <div className="logo-holder">
                  <img src={companyData?.logoUrl ?? Empty} alt="company logo" />
                </div>
                <div>
                  <p className="cta-text">
                    {!companyData?.logoUrl ? 'Upload' : 'Update'} logo
                  </p>
                  <p className="cta-sub-text">
                    Images should be at least 40x40px, PNG format
                  </p>

                  {!uploadingFile && (
                    <ActiveButton
                      text={!companyData?.logoUrl ? 'Upload' : 'Change'}
                      variant="light"
                      isStatic
                      onClick={() => setIsUpload(!isUpload)}
                      className="add-custom add-button min-w-fit gap-2"
                    />
                  )}
                </div>
              </div>

              {isUpload && (
                <div className="col-md-8 pb-3">
                  <FileUpload
                    acceptedFile={{
                      'image/jpeg': ['.jpeg', '.jpg'],
                      'image/png': ['.png'],
                    }}
                    name="logo"
                    supportType="Supported file types: jpeg, png. Max file size: 5mb"
                    onChange={(value) =>
                      setBusinessInfo({ ...businessInfo, logo: value?.assetCode })
                    }
                    wrapperClass=""
                    setUploadingFile={setUploadingFile}
                  />
                </div>
              )}
            </div>

            {/* Form Inputs  */}
            <section className="mt-3">
              <div className="mb-3">
                <CustomInput
                  label="Address"
                  placeholder="Plot 3 Agbor CloseOff Osolo Way"
                  type="text"
                  name="street"
                  onChange={handleChangeBusinessInfo}
                  value={businessInfo.street}
                />
              </div>
              <div className="mb-3">
                <CustomInput
                  label="City"
                  placeholder="Ikeja"
                  type="text"
                  name="city"
                  onChange={handleChangeBusinessInfo}
                  value={businessInfo.city}
                />
              </div>

              <Row>
                <Col md={6} className="mb-3">
                  <CustomSelect
                    label="State / Province"
                    placeholder="Lagos"
                    name="state"
                    onChange={(val) => setBusinessInfo({ ...businessInfo, state: val })}
                    value={businessInfo.state}
                    options={stateList}
                  />
                </Col>

                <Col md={6} className="mb-3">
                  <CustomInput
                    label="Postcode"
                    placeholder="10001"
                    type="text"
                    name="postalCode"
                    onChange={handleChangeBusinessInfo}
                    value={businessInfo.postalCode}
                  />
                </Col>
              </Row>

              <div className="mb-3">
                <CustomSelect
                  label="Country"
                  name="country"
                  placeholder="Select country"
                  onChange={(val) => setBusinessInfo({ ...businessInfo, country: val })}
                  value={businessInfo.country}
                  options={countryList}
                />
              </div>
            </section>
          </div>
          <div className="template-area">
            <p className="header">
              Invoice template
              <span>
                <span className="new-template-tag">NEW</span>
              </span>
            </p>
            <p className="sub-text">
              Templates are pre-defined invoices. You can set a default template which
              will always show up as your first choice when creating an invoice.
            </p>

            <div className="selector-arena">
              <TemplateSelector
                mode={TEMPLATE_MODES.DEMO}
                preference={templatePreference}
                handleSelect={(template) => setTemplate(template)}
              />
            </div>
          </div>
        </section>

        {/* Action Buttons Here */}
        <div className="action-button-region">
          <ActiveButton
            isStatic
            text="Cancel"
            variant="light"
            onClick={() => push('/receivables/invoices')}
            className="add-custom add-button min-w-fit gap-2"
          />

          <CustomButton
            onClick={saveChanges}
            loading={isUpdating || isCompanyUpdating}
            disabled={(!template && !hasChanges) || isUpdating}
            className="customButton add-button min-w-fit px-3 gap-2"
          >
            Save changes
          </CustomButton>
        </div>
      </div>
    </section>
  );
};

export default InvoiceSettings;
