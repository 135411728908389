import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';

import dayjs from 'dayjs';
import LogoIcon from 'assets/icons/Vector.png';
import DemoLogo from 'assets/icons/demo-logo.png';
import { TEMPLATE_MODES } from '../TemplateSelector';

import CurrencyFormat from 'react-currency-format';
import { getCurrency, capitalizeFirstLetter, convertNaNToZero } from 'utils/helper';

const sampleData = {
  vat: 7,
  logo: DemoLogo,
  discount: 1000000,
  subTotal: 3240000,
  amount: 345680000,
  vatAmount: 22680000,
  dueDate: new Date(),
  invoiceNumber: 'INV-006',
  address: 'London W125 235 England',
  description:
    'Fees and payment terms will be established in the contract or agreement. Payment is due within 15 days.',

  customer: {
    name: 'Company name',
    email: 'customer@email.com',
    phoneNumber: { localFormat: ' (000) 123-4567', countryCode: '0' },
    address: { street: 'Address', city: 'City', country: 'Country' },
  },

  company: {
    name: 'Railspeed',
    contact_phone: '(44) 123-4567',
    contactEmail: 'company@email.com',
  },

  bankDetails: {
    bank: 'Bank of Nigeria',
    accountName: 'Agency name',
    accountNumber: '98765432',
  },

  invoiceProducts: [
    {
      quantity: 1,
      amount: 200000000,
      product: {
        currency: 'NGN',
        price: 200000000,
        name: 'Website Development',
        description:
          'Website Framework, Content Literature Development, Domain and Hosting.',
      },
    },

    {
      quantity: 1,
      amount: 124000000,
      product: {
        currency: 'NGN',
        price: 124000000,
        name: 'Design',
      },
    },
  ],
};

const Templates = ({ activeTemplate, data, mode }) => {
  const isRecurring = !!data?.recurring;
  const isDemo = mode === TEMPLATE_MODES.DEMO;
  const isCustomerView = mode === TEMPLATE_MODES.CUSTOMER_VIEW;

  const isValidInstalment = useMemo(() => {
    return data?.instalmentPayload?.payments?.every(
      (item) => item?.amount && item?.dueDate,
    );
  }, [data?.instalmentPayload?.payments]);

  const isInstalment =
    mode === TEMPLATE_MODES.PREVIEW ? isValidInstalment : !!data?.installments?.length;

  const {
    getCompany: { data: companyData = {} },
  } = useSelector(({ companies }) => companies);

  const addressAliaser = () => {
    const dataPoint = isCustomerView ? data?.company : companyData;

    return `${dataPoint?.address?.street ?? '-'} ${dataPoint?.address?.city ?? '-'} ${
      dataPoint?.address?.state ?? '-'
    }, ${
      dataPoint?.address?.country
        ? capitalizeFirstLetter(dataPoint.address.country.toLowerCase())
        : '-'
    }`;
  };

  const aliasedData = useMemo(() => {
    if (mode === TEMPLATE_MODES.PREVIEW) {
      const type = data?.instalmentPayload?.type;
      return {
        ...data,
        title: data?.purchaseOrder,
        vatAmount: data?.vatValue,
        amount: data?.totalAmount * 100,
        currency: data?.currency?.value,
        installments: isValidInstalment
          ? data?.instalmentPayload?.payments.map((instalment) => {
              const amount = instalment?.amount;
              return {
                type,
                ...instalment,
                due_date: instalment?.dueDate,
                ...(type === 'percentage' && { percentage: amount }),
                amount: type === 'percentage' ? amount : +amount * 100,
              };
            })
          : [],
        invoiceProducts: data.products.map((product) => {
          const { quantity, unitPrice } = product;
          return {
            quantity,
            amount: quantity * (unitPrice * 100),
            product: {
              ...product,
              price: unitPrice * 100,
              currency: data?.currency?.value,
            },
          };
        }),
      };
    }

    return data;
  }, [data]);

  const CurrentTemplate = activeTemplate?.component;
  if (!CurrentTemplate) return null;

  return (
    <>
      <CurrentTemplate
        mode={mode}
        LogoIcon={LogoIcon}
        isRecurring={isRecurring}
        isInstalment={isInstalment}
        data={
          !isDemo
            ? {
                ...aliasedData,
                address: addressAliaser(),
                company: isCustomerView ? data?.company : companyData,
              }
            : sampleData
        }
      />
    </>
  );
};

export const InvoicePreviewTable = ({ headers, data, isRecurring }) => {
  const renderCell = (row, header, rowIndex) => {
    switch (header.key) {
      case 'index':
        return rowIndex + 1;

      case 'item':
        return (
          <>
            {row.product?.name}
            {row.product?.description && (
              <p style={{ color: '#586068', fontWeight: 400, marginBottom: 0 }}>
                {row.product?.description}
              </p>
            )}
          </>
        );

      case 'quantity':
        return row.quantity;

      case 'rate':
        return formatCurrency(row.product?.price / 100, row.product?.currency);

      case 'amount':
        return formatCurrency(
          isRecurring ? (row.unitPrice / 100) * row.quantity : row.amount / 100,
          row.product?.currency,
        );

      default:
        return row[header.key];
    }
  };

  return (
    <Table>
      <thead>
        <tr style={{ borderBottomWidth: 2, borderColor: '#1C1917' }}>
          {headers.map((header) => (
            <th
              key={header.key}
              style={{
                minWidth: header.minWidth,
                textAlign: header.align || 'left',
              }}
            >
              {header.label}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {(data?.invoiceProducts || []).map((row, rowIndex) => (
          <tr key={rowIndex}>
            {headers.map((header) => (
              <td
                key={`${rowIndex}-${header.key}`}
                style={{
                  textAlign: header.align || 'left',
                  maxWidth: header.key === 'item' ? 50 : undefined,
                }}
              >
                {renderCell(row, header, rowIndex)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export const InvoiceAmountInformation = ({
  data,
  isRecurring,
  taxText = 'Tax',
  totalText = 'Total incl. Tax',
  caption = 'Subtotal excl. Tax',
}) => {
  const payments = data?.installments || [];
  const subTotal = invoiceSubTotalHandler(data, isRecurring);
  const discount = invoiceDiscountHandler(data, isRecurring);

  const handleAmount = (payment) => {
    const { type, percentage, amount = 0 } = payment;
    if (type === 'percentage') {
      return (((percentage / 100) * data.amount) / 100).toFixed(2);
    }

    return amount / 100;
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center info pt-2">
        <h1>{caption}</h1>
        <p>{formatCurrency(subTotal / 100)}</p>
      </div>

      <div className="d-flex justify-content-between align-items-center info">
        <h1>
          Discount
          {data?.discount_type === 'percentage' && <span> ({data?.discount}%)</span>}
        </h1>

        <p>
          <span>- </span>
          {formatCurrency(discount ? discount / 100 : 0)}
        </p>
      </div>

      <div className="d-flex justify-content-between align-items-center info">
        <h1>
          {taxText} ({data?.vat}%)
        </h1>
        <p>{formatCurrency(data?.vatAmount / 100)}</p>
      </div>

      <div className="d-flex justify-content-between align-items-center total">
        <h1>{totalText}</h1>
        <p>{formatCurrency(data?.amount / 100)}</p>
      </div>

      {!!payments?.length && (
        <section className="mt-3">
          {payments.map((payment, index) => (
            <div className="instalment-holder" key={index}>
              <div className="mb-2">
                <p className="payment">Payment {index + 1}</p>
                {payment?.status === 'paid' ? (
                  <p className="due-date">
                    Paid on {dayjs(payment?.paid_on).format('DD MMM, YYYY')}
                  </p>
                ) : (
                  <p className="due-date">
                    Due on {dayjs(payment?.due_date).format('DD MMM, YYYY')}
                  </p>
                )}
              </div>

              <div className="ms-auto">
                <p className="amount">{formatCurrency(handleAmount(payment))}</p>
              </div>
            </div>
          ))}
        </section>
      )}
    </>
  );
};

export const invoiceSubTotalHandler = (data = {}, isRecurring = false) => {
  return (data?.invoiceProducts ?? [])?.reduce((accumulator, currentValue) => {
    if (isRecurring) {
      return accumulator + currentValue?.unitPrice * currentValue?.quantity;
    } else {
      return accumulator + currentValue?.amount;
    }
  }, 0);
};

export const invoiceDiscountHandler = (data = {}, isRecurring = false) => {
  const subTotal = invoiceSubTotalHandler(data, isRecurring);
  return data?.discount_type === 'percentage'
    ? (data?.discount / 100) * subTotal
    : data?.discount;
};

const formatCurrency = (value = 0, currency = 'NGN') => {
  return (
    <CurrencyFormat
      value={value}
      displayType="text"
      thousandSeparator={true}
      prefix={getCurrency(currency || 'NGN')}
    />
  );
};
export default Templates;
