import { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { motion, AnimatePresence } from 'motion/react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInvoiceTemplates } from 'redux/actions/InvoiceAction';

import Templates from './Templates';
import NewDawn from './Templates/NewDawn';
import Classic from './Templates/Classic';
import Foundation from './Templates/Foundation';
import { TripleLayers, Stars02, Colors, CheckCircle, LineUnion } from 'assets/icons';

export const TEMPLATE_MODES = {
  DEMO: 'demo',
  PREVIEW: 'preview',
  CUSTOMER_VIEW: 'customer-view',
  DASHBOARD_VIEW: 'dashboard-view',
};

const TemplateSelector = ({
  data,
  preference,
  isSingleView,
  handleSelect = () => null,
  mode = TEMPLATE_MODES.DEMO,
}) => {
  const dispatch = useDispatch();

  const {
    fetchInvoiceTemplates: { data: templates = [], loading },
  } = useSelector(({ invoices }) => invoices);

  const TemplateFactory = new Map([
    ['classic', Classic],
    ['new dawn', NewDawn],
    ['foundation', Foundation],
  ]);

  const retrieveTemplate = (name = '') => {
    return TemplateFactory.get(name.toLowerCase()) ?? null;
  };

  const templateData = useMemo(
    () =>
      templates.map((template) => ({
        ...template,
        component: retrieveTemplate(template?.name),
      })),
    [templates],
  );

  const [direction, setDirection] = useState(0);
  const [activeTemplate, setActiveTemplate] = useState(templateData[0]);

  useEffect(() => {
    if (!templates.length && mode !== TEMPLATE_MODES.CUSTOMER_VIEW)
      dispatch(fetchInvoiceTemplates());
  }, [templates?.length]);

  useEffect(() => {
    if (preference) {
      setActiveTemplate({
        ...preference.value,
        component: retrieveTemplate(preference?.value?.name),
      });
    }
  }, [preference]);

  const swipeConfidenceThreshold = 500;

  const handleTemplateChange = (template) => {
    const currentIndex = templateData.findIndex((t) => t?.code === activeTemplate?.code);
    const nextIndex = templateData.findIndex((t) => t?.code === template?.code);
    const direction = nextIndex > currentIndex ? 1 : -1;

    setTimeout(() => {
      handleSelect(template);
      setDirection(direction);
      setActiveTemplate(template);
    }, 0);
  };

  const currentIndex = useMemo(() => {
    return templateData.findIndex((t) => t.code === activeTemplate?.code);
  }, [activeTemplate?.code]);

  const handleSwipe = (swipe) => {
    const isLastItem = currentIndex === templateData.length - 1;
    const isFirstItem = currentIndex === 0;

    if (swipe < -swipeConfidenceThreshold) {
      const nextTemplate = templateData[(currentIndex + 1) % templateData.length];
      setDirection(isLastItem ? -1 : 1);
      handleTemplateChange(nextTemplate);
    } else if (swipe > swipeConfidenceThreshold) {
      const prevTemplate =
        templateData[(currentIndex - 1 + templateData.length) % templateData.length];
      setDirection(isFirstItem ? 1 : -1);
      handleTemplateChange(prevTemplate);
    }
  };

  const slideVariants = {
    enter: (direction) => ({
      x: direction > 0 ? '100%' : '-100%',
      rotateY: direction > 0 ? 15 : -15,
      scale: 0.95,
    }),
    center: {
      x: 0,
      rotateY: 0,
      scale: 1,
    },
    exit: (direction) => ({
      x: direction > 0 ? '-100%' : '100%',
      rotateY: direction > 0 ? -15 : 15,
      scale: 0.7,
    }),
  };

  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

  return (
    <>
      {isSingleView ? (
        <Templates
          mode={mode}
          data={data}
          templates={templateData}
          activeTemplate={activeTemplate}
        />
      ) : (
        <section className="template-picker__holder">
          <div className="pick-area">
            {templateData.map((template) => (
              <CardPicker
                {...template}
                key={template.code}
                active={template.code === activeTemplate?.code}
                onClick={() => handleTemplateChange(template)}
              />
            ))}
          </div>

          {/* Template Carousel */}
          <section
            className={classNames('template-carousel-holder', {
              'full-height': mode !== TEMPLATE_MODES.DEMO,
            })}
          >
            <aside className="line-union">
              <LineUnion />
            </aside>

            <div
              className={classNames('carousel-container', {
                'is-demo': mode === TEMPLATE_MODES.DEMO,
              })}
            >
              <div className="content-stage">
                <AnimatePresence initial={false} custom={direction} mode="sync">
                  {templateData.map(
                    (template) =>
                      template.code === activeTemplate?.code && (
                        <motion.div
                          key={template.code}
                          className={classNames('template-slide', {
                            'is-demo': mode === TEMPLATE_MODES.DEMO,
                          })}
                          custom={direction}
                          variants={slideVariants}
                          initial="enter"
                          animate="center"
                          exit="exit"
                          transition={{
                            x: {
                              type: 'tween',
                              duration: 0.8,
                              ease: [0.65, 0.05, 0.36, 1],
                            },
                            rotateY: {
                              duration: 0.8,
                              ease: [0.65, 0.05, 0.36, 1],
                            },
                            scale: {
                              duration: 0.4,
                              ease: [0.65, 0.05, 0.36, 1],
                            },
                          }}
                          drag="x"
                          dragConstraints={{ left: 0, right: 0 }}
                          dragElastic={1}
                          onDragEnd={(e, { offset, velocity }) => {
                            const swipe = swipePower(offset.x, velocity.x);
                            handleSwipe(swipe);
                          }}
                        >
                          <Templates
                            mode={mode}
                            data={data}
                            templates={templateData}
                            activeTemplate={activeTemplate}
                          />
                        </motion.div>
                      ),
                  )}
                </AnimatePresence>
              </div>
            </div>
          </section>
        </section>
      )}
    </>
  );
};

// Abstracted for possible reusability
const CardPicker = ({ name, icon, active, onClick }) => {
  const iconMapping = {
    star: Stars02,
    layers: TripleLayers,
    intersect: Colors,
  };

  const IconComponent = iconMapping[icon.toLowerCase()];

  return (
    <div
      onClick={onClick}
      className={classNames('template-card', { 'is-active': active })}
    >
      <p className="template-name">{name}</p>
      <div className="template-icon">
        <span className="active-icon">{IconComponent && <IconComponent />}</span>
        <span className="filler-icon">
          <CheckCircle fill={active ? '#77B300' : ''} />
        </span>
      </div>
    </div>
  );
};

export default TemplateSelector;
