import { Axios } from 'api/axios';
import { toastError, toastSuccess } from 'components/UI/toast';
import { push } from 'connected-react-router';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import {
  GET_SINGLE_TRANSACTION_ERROR,
  GET_SINGLE_TRANSACTION_REQUEST,
  GET_SINGLE_TRANSACTION_SUCCESS,
} from 'redux/reducers/BudgetsReducer';
import { getSimplifiedError } from 'utils/error';
import {
  ADD_BENEFICIARIES_BANK_ERROR,
  ADD_BENEFICIARIES_BANK_REQUEST,
  ADD_BENEFICIARIES_BANK_SUCCESS,
  BULK_CREATE_BENEFICIARIES_ERROR,
  BULK_CREATE_BENEFICIARIES_REQUEST,
  BULK_CREATE_BENEFICIARIES_SUCCESS,
  CREATE_BENEFICIARIES_ERROR,
  CREATE_BENEFICIARIES_REQUEST,
  CREATE_BENEFICIARIES_SUCCESS,
  DEFAULT_BENEFICIARIES_BANK_ERROR,
  DEFAULT_BENEFICIARIES_BANK_REQUEST,
  DEFAULT_BENEFICIARIES_BANK_SUCCESS,
  DELETE_BENEFICIARIES_BANK_ERROR,
  DELETE_BENEFICIARIES_BANK_REQUEST,
  DELETE_BENEFICIARIES_BANK_SUCCESS,
  DELETE_BENEFICIARIES_ERROR,
  DELETE_BENEFICIARIES_REQUEST,
  DELETE_BENEFICIARIES_SUCCESS,
  GET_BENEFICIARIES_BANK_ERROR,
  GET_BENEFICIARIES_BANK_REQUEST,
  GET_BENEFICIARIES_BANK_SUCCESS,
  GET_BENEFICIARIES_ERROR,
  GET_BENEFICIARIES_REQUEST,
  GET_BENEFICIARIES_SUCCESS,
  GET_INVITED_BENEFICIARIES_ERROR,
  GET_INVITED_BENEFICIARIES_REQUEST,
  GET_INVITED_BENEFICIARIES_SUCCESS,
  RESEND_BENEFICIARY_INVITE_ERROR,
  RESEND_BENEFICIARY_INVITE_REQUEST,
  RESEND_BENEFICIARY_INVITE_SUCCESS,
  RESET_BLOCK_BENEFICIARIE,
  RESET_FLAGS_BENEFICIARIE,
  SEARCH_RECIPIENT_LIST_REQUEST,
  SEARCH_RECIPIENTS_ERROR,
  SEARCH_RECIPIENTS_REQUEST,
  SEARCH_RECIPIENTS_SUCCESS,
  UPDATE_BENEFICIARIES_ERROR,
  UPDATE_BENEFICIARIES_REQUEST,
  UPDATE_BENEFICIARIES_SUCCESS,
} from '../reducers/BeneficiariesReducer';
import { GET_USER_PROFILE_REQUEST } from 'redux/reducers/AuthReducer';

async function getBeneficiaries(params = {}) {
  return await Axios.get('/beneficiaries/', { params });
}
function* handleGetBeneficiaries({ payload }) {
  try {
    const response = yield call(getBeneficiaries, payload);
    if (response) {
      yield put({
        type: GET_BENEFICIARIES_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_BENEFICIARIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function searchRecipients(params = {}) {
  return await Axios.get('/recipients', { params });
}
function* handlesearchRecipients({ payload }) {
  try {
    const response = yield call(searchRecipients, payload);
    if (response) {
      yield put({
        type: SEARCH_RECIPIENTS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: SEARCH_RECIPIENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function searchRecipientList(params = {}) {
  return await Axios.get('/recipients/search', { params });
}
function* handleSearchRecipientList({ payload }) {
  try {
    const response = yield call(searchRecipientList, payload);
    if (response) {
      yield put({
        type: SEARCH_RECIPIENTS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: SEARCH_RECIPIENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createBeneficiaries({ payload }) {
  return await Axios.post('/beneficiaries/', payload);
}
function* handleCreateBeneficiaries({ payload }) {
  try {
    const response = yield call(createBeneficiaries, { payload });
    if (response) {
      yield put({
        type: CREATE_BENEFICIARIES_SUCCESS,
        data: response,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BENEFICIARIE,
        blockType: 'createBeneficiaries',
      });

      yield put({
        type: GET_USER_PROFILE_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_BENEFICIARIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function bulkCreateBeneficiaries({ payload }) {
  return await Axios.post('beneficiaries/bulk/', payload);
}
function* handleBulkCreateBeneficiaries({ payload }) {
  try {
    const response = yield call(bulkCreateBeneficiaries, { payload });
    if (response) {
      yield put({
        type: BULK_CREATE_BENEFICIARIES_SUCCESS,
        data: response,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BENEFICIARIE,
        blockType: 'bulkCreateBeneficiaries',
      });
      yield put({
        type: RESET_BLOCK_BENEFICIARIE,
        blockType: 'buildBulkBeneficiariesPayload',
      });
      yield put({
        type: GET_BENEFICIARIES_REQUEST,
        payload: { excludeInactiveBudgets: true },
      });

      yield put({
        type: GET_USER_PROFILE_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: BULK_CREATE_BENEFICIARIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateBeneficiaries({ payload, code }) {
  return await Axios.put(`/beneficiaries/${code}`, payload);
}
function* handleUpdateBeneficiaries({ payload }) {
  try {
    const getPayload = { ...payload };
    delete payload.code;

    const response = yield call(updateBeneficiaries, { payload, code: getPayload.code });
    if (response) {
      yield put({
        type: UPDATE_BENEFICIARIES_SUCCESS,
        data: payload,
      });

      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BENEFICIARIE,
        blockType: 'updateBeneficiaries',
      });

      yield put({
        type: GET_BENEFICIARIES_REQUEST,
        payload: { excludeInactiveBudgets: true },
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_BENEFICIARIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSingleTransactionAPI(params = {}) {
  return await Axios.get('/transactions/', { params });
}
function* handleGetSingleTransaction({ payload }) {
  try {
    const response = yield call(getSingleTransactionAPI, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_TRANSACTION_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SINGLE_TRANSACTION_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getInvitedBeneficiaryDetails(code) {
  return await Axios.get('users/get-invited-user?hash=' + code);
}
function* handleGetInvitedBeneficiaryDetails({ payload }) {
  try {
    const response = yield call(getInvitedBeneficiaryDetails, payload);
    if (response) {
      yield put({
        type: GET_INVITED_BENEFICIARIES_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
      yield put(push('/login'));
    }
    yield put({
      type: GET_INVITED_BENEFICIARIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function resendBeneficiaryInvite(code) {
  return await Axios.post(`beneficiaries/${code}/resend-invite`);
}
function* handleResendBeneficiaryInvite({ payload }) {
  try {
    const response = yield call(resendBeneficiaryInvite, payload);
    if (response) {
      yield put({
        type: RESEND_BENEFICIARY_INVITE_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BENEFICIARIE,
        blockType: 'resendBeneficiaryInvite',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: RESEND_BENEFICIARY_INVITE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function addBeneficiaryBank(payload) {
  return await Axios.post(`/banks`, payload);
}
function* handleAddBeneficiaryBank({ payload }) {
  try {
    const response = yield call(addBeneficiaryBank, payload);
    if (response) {
      yield put({
        type: ADD_BENEFICIARIES_BANK_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BENEFICIARIE,
        blockType: 'addBeneficiaryBank',
      });

      yield put({
        type: GET_BENEFICIARIES_BANK_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: ADD_BENEFICIARIES_BANK_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function defaultBeneficiaryBank(payload) {
  const { code } = { ...payload };
  delete payload['code'];
  return await Axios.post(`/banks/default/${code}`, payload);
}
function* handleDefaultBeneficiaryBank({ payload }) {
  try {
    const response = yield call(defaultBeneficiaryBank, payload);
    if (response) {
      yield put({
        type: DEFAULT_BENEFICIARIES_BANK_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BENEFICIARIE,
        blockType: 'defaultBeneficiaryBank',
      });

      yield put({
        type: GET_BENEFICIARIES_BANK_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DEFAULT_BENEFICIARIES_BANK_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteBeneficiaryBank(code) {
  return await Axios.delete(`/banks/${code}`);
}
function* handleDeleteBeneficiaryBank({ payload }) {
  try {
    const response = yield call(deleteBeneficiaryBank, payload);
    if (response) {
      yield put({
        type: DELETE_BENEFICIARIES_BANK_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_BENEFICIARIE,
        blockType: 'deleteBeneficiaryBank',
      });

      yield put({
        type: GET_BENEFICIARIES_BANK_REQUEST,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_BENEFICIARIES_BANK_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getBeneficiaryBank() {
  return await Axios.get('/banks');
}
function* handleGetBeneficiaryBank({ payload }) {
  try {
    const response = yield call(getBeneficiaryBank, payload);
    if (response) {
      yield put({
        type: GET_BENEFICIARIES_BANK_SUCCESS,
        data: response.data,
      });
      // yield put({
      //   type: RESET_FLAGS_BENEFICIARIE,
      //   blockType: 'getBeneficiaryBank',
      // });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_BENEFICIARIES_BANK_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteBeneficiaries({ code }) {
  return await Axios.delete(`/beneficiaries/${code}`);
}
function* handleDeleteBeneficiaries({ payload }) {
  try {
    const response = yield call(deleteBeneficiaries, payload);
    if (response) {
      yield put({
        type: DELETE_BENEFICIARIES_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_FLAGS_BENEFICIARIE,
        blockType: 'deleteBeneficiaries',
      });

      yield put({
        type: GET_BENEFICIARIES_REQUEST,
        payload: { excludeInactiveBudgets: true },
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_BENEFICIARIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_BENEFICIARIES_REQUEST, handleGetBeneficiaries),
  takeLatest(SEARCH_RECIPIENTS_REQUEST, handlesearchRecipients),
  takeLatest(SEARCH_RECIPIENT_LIST_REQUEST, handleSearchRecipientList),
  takeLatest(GET_SINGLE_TRANSACTION_REQUEST, handleGetSingleTransaction),
  takeLatest(CREATE_BENEFICIARIES_REQUEST, handleCreateBeneficiaries),
  takeLatest(BULK_CREATE_BENEFICIARIES_REQUEST, handleBulkCreateBeneficiaries),
  takeLatest(UPDATE_BENEFICIARIES_REQUEST, handleUpdateBeneficiaries),
  takeLatest(GET_INVITED_BENEFICIARIES_REQUEST, handleGetInvitedBeneficiaryDetails),
  takeLatest(ADD_BENEFICIARIES_BANK_REQUEST, handleAddBeneficiaryBank),
  takeLatest(GET_BENEFICIARIES_BANK_REQUEST, handleGetBeneficiaryBank),
  takeLatest(DEFAULT_BENEFICIARIES_BANK_REQUEST, handleDefaultBeneficiaryBank),
  takeLatest(DELETE_BENEFICIARIES_BANK_REQUEST, handleDeleteBeneficiaryBank),
  takeLatest(RESEND_BENEFICIARY_INVITE_REQUEST, handleResendBeneficiaryInvite),
  takeLatest(DELETE_BENEFICIARIES_REQUEST, handleDeleteBeneficiaries),
]);
