import { all, call, put, takeLatest, delay } from 'redux-saga/effects';
import { Axios } from 'api/axios';
import { getSimplifiedError } from 'utils/error';
import { encryptAndSaveToLocalStorage } from 'utils/utility';
import {
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_ERROR,
  GET_COUNTRY_REQUEST,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_ERROR,
  GET_STATE_REQUEST,
  GET_STATE_SUCCESS,
  GET_STATE_ERROR,
  GET_COMPANY_STATS_REQUEST,
  GET_COMPANY_STATS_SUCCESS,
  GET_COMPANY_STATS_ERROR,
  GET_BANK_ACCOUNT_REQUEST,
  GET_BANK_ACCOUNT_SUCCESS,
  GET_BANK_ACCOUNT_ERROR,
  CREATE_BANK_ACCOUNT_REQUEST,
  CREATE_BANK_ACCOUNT_SUCCESS,
  CREATE_BANK_ACCOUNT_ERROR,
  UPDATE_COMPANY_ERROR,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_REQUEST,
  REVIEW_COMPANY_REQUEST,
  REVIEW_COMPANY_SUCCESS,
  REVIEW_COMPANY_ERROR,
  ONBOARDING_BUSINESS_INFORMATION_REQUEST,
  ONBOARDING_BUSINESS_INFORMATION_SUCCESS,
  ONBOARDING_BUSINESS_INFORMATION_ERROR,
  ONBOARDING_BUSINESS_ADDRESS_REQUEST,
  ONBOARDING_BUSINESS_ADDRESS_SUCCESS,
  ONBOARDING_BUSINESS_ADDRESS_ERROR,
  ONBOARDING_BUSINESS_DOC_REQUEST,
  ONBOARDING_BUSINESS_DOC_SUCCESS,
  ONBOARDING_BUSINESS_DOC_ERROR,
  FUND_WIDTH_CARDS_REQUEST,
  FUND_WIDTH_CARDS_SUCCESS,
  FUND_WIDTH_CARDS_ERROR,
  GET_REJECTED_DOCUMENTS_REQUEST,
  GET_REJECTED_DOCUMENTS_SUCCESS,
  GET_REJECTED_DOCUMENTS_ERROR,
  RE_SUBMIT_REJECTED_DOCUMENTS_REQUEST,
  RE_SUBMIT_REJECTED_DOCUMENTS_SUCCESS,
  RE_SUBMIT_REJECTED_DOCUMENTS_ERROR,
  RE_SUBMIT_REJECTED_DOCUMENTS_IS_COMPLETE_ERROR,
  RE_SUBMIT_REJECTED_DOCUMENTS_IS_COMPLETE_REQUEST,
  RE_SUBMIT_REJECTED_DOCUMENTS_IS_COMPLETE_SUCCESS,
  FUND_DOLLAR_BALANCE_REQUEST,
  FUND_DOLLAR_BALANCE_SUCCESS,
  FUND_DOLLAR_BALANCE_ERROR,
  GET_DOLLAR_ACCOUNT_REQUEST,
  GET_DOLLAR_ACCOUNT_SUCCESS,
  GET_DOLLAR_ACCOUNT_ERROR,
  INVITE_DIRECTOR_SUCCESS,
  INVITE_DIRECTOR_ERROR,
  INVITE_DIRECTOR_REQUEST,
  GET_INVITED_DIRECTORS_SUCCESS,
  GET_INVITED_DIRECTORS_ERROR,
  GET_INVITED_DIRECTORS_REQUEST,
  UPLOAD_DIRECTOR_INFO_REQUEST,
  UPLOAD_DIRECTOR_INFO_SUCCESS,
  UPLOAD_DIRECTOR_INFO_ERROR,
  UPLOAD_INVITED_DIRECTOR_INFO_SUCCESS,
  UPLOAD_INVITED_DIRECTOR_INFO_ERROR,
  UPLOAD_INVITED_DIRECTOR_INFO_REQUEST,
  GET_SINGLE_INVITED_DIRECTOR_SUCCESS,
  GET_SINGLE_INVITED_DIRECTOR_ERROR,
  GET_SINGLE_INVITED_DIRECTOR_REQUEST,
  RESEND_ONBOARDING_INVITE_REQUEST,
  RESEND_ONBOARDING_INVITE_SUCCESS,
  RESEND_ONBOARDING_INVITE_ERROR,
  DELETE_INVITED_DIRECTOR_SUCCESS,
  DELETE_INVITED_DIRECTOR_ERROR,
  UPDATE_INVITED_DIRECTOR_SUCCESS,
  DELETE_INVITED_DIRECTOR_REQUEST,
  UPDATE_INVITED_DIRECTOR_REQUEST,
  UPDATE_INVITED_DIRECTOR_ERROR,
  SEND_MONO_AUTH_CODE_REQUEST,
  SEND_MONO_AUTH_CODE_SUCCESS,
  SEND_MONO_AUTH_CODE_ERROR,
  GET_MONO_PAYMENT_LINK_REQUEST,
  GET_MONO_PAYMENT_LINK_SUCCESS,
  GET_MONO_PAYMENT_LINK_ERROR,
  VERIFY_MONO_PAYMENT_REFERENCE_REQUEST,
  VERIFY_MONO_PAYMENT_REFERENCE_SUCCESS,
  VERIFY_MONO_PAYMENT_REFERENCE_ERROR,
  MONO_ACCOUNT_REAUTHORIZATION_CODE_REQUEST,
  MONO_ACCOUNT_REAUTHORIZATION_CODE_SUCCESS,
  MONO_ACCOUNT_REAUTHORIZATION_CODE_ERROR,
  GET_COMPANY_ASSET_SUCCESS,
  GET_COMPANY_ASSET_ERROR,
  GET_COMPANY_ASSET_REQUEST,
  ADD_FUNDS_REQUEST,
  ADD_FUNDS_SUCCESS,
  ADD_FUNDS_ERROR,
  CREATE_MANDATE_REQUEST,
  CREATE_MANDATE_SUCCESS,
  CREATE_MANDATE_ERROR,
  SYNC_BANK_ACCOUNT_SUCCESS,
  SYNC_BANK_ACCOUNT_ERROR,
  SYNC_BANK_ACCOUNT_REQUEST,
  GET_COMPANIES_REQUEST,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_ERROR,
  ADD_COMPANY_REQUEST,
  ADD_COMPANY_ERROR,
  ADD_COMPANY_SUCCESS,
  SWITCH_COMPANY_REQUEST,
  SWITCH_COMPANY_SUCCESS,
  SWITCH_COMPANY_ERROR,
  AUTHENTICATE_COMPANY_SUCCESS,
  AUTHENTICATE_COMPANY_ERROR,
  RESET_BLOCK_COMPANY,
  GET_MANDATE_STATUS_SUCCESS,
  GET_MANDATE_STATUS_REQUEST,
  GET_MANDATE_STATUS_ERROR,
  CANCEL_MANDATE_SUCCESS,
  CANCEL_MANDATE_ERROR,
  CANCEL_MANDATE_REQUEST,
  UPLOAD_ONBOARDING_DOCUMENTS_REQUEST,
  UPLOAD_ONBOARDING_DOCUMENTS_SUCCESS,
  UPLOAD_ONBOARDING_DOCUMENTS_ERROR,
  GET_COMPANY_PREFERENCE_REQUEST,
  GET_COMPANY_PREFERENCE_SUCCESS,
  GET_COMPANY_PREFERENCE_ERROR,
  UPDATE_COMPANY_PREFERENCE_SUCCESS,
  UPDATE_COMPANY_PREFERENCE_REQUEST,
  UPDATE_COMPANY_PREFERENCE_ERROR,
} from '../reducers/CompaniesReducer';

import { RESET_STATE, OTP_SUCCESS } from '../reducers/AuthReducer';
import { GET_ROLES_REQUEST } from 'redux/reducers/RolesReducer';
import { GET_BENEFICIARIES_BANK_REQUEST } from 'redux/reducers/BeneficiariesReducer';
import { toastError, toastSuccess } from 'components/UI/toast';
import { OPEN_CONNECT_SUCCESS } from 'redux/reducers/MonoAccountLinkingReducer';
import { openMonoConnectAction } from 'redux/actions/MonoAccountLinkingAction';

async function getCompany(payload) {
  const { code, ...params } = payload;
  return await Axios.get(`/companies/${code}/`, { params });
}
function* handleGetCompany({ payload }) {
  try {
    const response = yield call(getCompany, payload);
    if (response) {
      yield put({
        type: GET_COMPANY_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_COMPANY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getCompanyPreference(payload) {
  const type = payload || 'invoices';
  return Axios.get(`company-preferences?feature=${type}`);
}

function* handleGetCompanyPreference({ payload }) {
  try {
    const response = yield call(getCompanyPreference, payload);
    if (response) {
      const transformedData = {
        raw: response.data,
        templatePreference: (response.data || []).find(
          ({ key = '', feature = '' }) => key === 'template' && feature === 'invoices',
        ),
      };

      yield put({
        type: GET_COMPANY_PREFERENCE_SUCCESS,
        data: transformedData,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_COMPANY_PREFERENCE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateCompanyPreference(payload) {
  const { code = '', ...data } = payload;
  return Axios.put(`company-preferences/${code}`, data);
}

function* handleUpdateCompanyPreference({ payload }) {
  try {
    const response = yield call(updateCompanyPreference, payload);
    if (response) {
      yield put({
        type: UPDATE_COMPANY_PREFERENCE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_COMPANY_PREFERENCE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function addEntity(payload) {
  return await Axios.post(`/companies`, payload);
}

function* handleaddEntity({ payload }) {
  try {
    const response = yield call(addEntity, payload);
    if (response) {
      yield put({
        type: ADD_COMPANY_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: ADD_COMPANY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function switchCompany(payload) {
  return await Axios.post(`/users/switch-company/${payload.code}`);
}

function* handleSwitchCompany({ payload }) {
  try {
    const response = yield call(switchCompany, payload);
    if (response) {
      yield call(handleAuthenticate, payload.code);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: SWITCH_COMPANY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getCompanies(payload) {
  if (payload?.subsidiaries)
    return await Axios.get(`/companies/subsidiaries`, { params: payload });
  return await Axios.get(`/companies`);
}
function* handleGetCompanies({ payload }) {
  try {
    const response = yield call(getCompanies, payload);
    if (response) {
      yield put({
        type: GET_COMPANIES_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_COMPANIES_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getAsset(payload) {
  return await Axios.get(`/assets/${payload}`);
}

function* handleGetAsset({ payload }) {
  try {
    const response = yield call(getAsset, payload);
    if (response) {
      yield put({
        type: GET_COMPANY_ASSET_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      // toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_COMPANY_ASSET_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getCountry() {
  // return await Axios.get(`/countryData/${payload}/`);
  return await Axios.get(`/countries`);
}

function* handleGetCountries({ payload }) {
  try {
    const response = yield call(getCountry, payload);
    if (response) {
      yield put({
        type: GET_COUNTRY_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_COUNTRY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getState(payload) {
  // return await Axios.get(`/countryData/${payload}/`);
  return await Axios.get(`/countries?country_code=${payload}`);
}

function* handleGeStates({ payload }) {
  try {
    const response = yield call(getState, payload);
    if (response) {
      yield put({
        type: GET_STATE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_STATE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function onboardingBusinessInformation(payload) {
  // const { code } = { ...payload };
  // delete payload['code'];
  return await Axios.post(`/onboarding/business-information`, payload);
}
function* handleOnboardingBusinessInformation({ payload }) {
  try {
    const response = yield call(onboardingBusinessInformation, payload);
    if (response) {
      yield put({
        type: ONBOARDING_BUSINESS_INFORMATION_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: ONBOARDING_BUSINESS_INFORMATION_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function onboardingBusinessAddress(payload) {
  // const { code } = { ...payload };
  // delete payload['code'];
  return await Axios.patch(`/onboarding/business-address`, payload);
}
function* handleOnboardingBusinessAdress({ payload }) {
  try {
    const response = yield call(onboardingBusinessAddress, payload);
    if (response) {
      yield put({
        type: ONBOARDING_BUSINESS_ADDRESS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: ONBOARDING_BUSINESS_ADDRESS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function onboardingBusinessDoc(payload) {
  // const { code } = { ...payload };
  // delete payload['code'];
  return await Axios.post(`/onboarding/business-docs`, payload);
}
function* handleOnboardingBusinessDoc({ payload }) {
  try {
    const response = yield call(onboardingBusinessDoc, payload);
    if (response) {
      yield put({
        type: ONBOARDING_BUSINESS_DOC_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: ONBOARDING_BUSINESS_DOC_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getStats({ id, params }) {
  return await Axios.get(`/companies/${id}/statistics`, { params });
}
function* handleStats({ payload }) {
  try {
    const response = yield call(getStats, payload);
    if (response) {
      yield put({
        type: GET_COMPANY_STATS_SUCCESS,
        data: response.data,
      });
      // toastSuccess(response.data.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_COMPANY_STATS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createBankAccount(payload) {
  if (payload.currency === 'USD') {
    payload.balanceType = payload.purpose;
    delete payload.purpose;
    return await Axios.post(`/accounts`, payload);
  }
  return await Axios.post(`/banks/virtual-account`, payload);
}
function* handleCreateBankAccount({ payload }) {
  try {
    const response = yield call(createBankAccount, payload);
    if (response) {
      yield put({
        type: CREATE_BANK_ACCOUNT_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_BANK_ACCOUNT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getBankAccount({ code }) {
  return await Axios.get(`/companies/${code}/virtual-account`);
}
function* handleGetBankAccount({ payload }) {
  try {
    const response = yield call(getBankAccount, payload);
    if (response) {
      yield put({
        type: GET_BANK_ACCOUNT_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_BANK_ACCOUNT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}
async function sendMonoAuthCode({ code, monoAuthCode }) {
  return await Axios.post(`/companies/${code}/link-accounts/${monoAuthCode}`);
}
function* handleSendMonoAuthCode({ payload }) {
  try {
    const response = yield call(sendMonoAuthCode, payload);
    if (response) {
      yield put({
        type: SEND_MONO_AUTH_CODE_SUCCESS,
        data: response.data,
      });

      yield delay(400);
      yield put({
        type: RESET_BLOCK_COMPANY,
        blockType: 'sendMonoAuthCode',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: SEND_MONO_AUTH_CODE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function authenticate({ code }) {
  return await Axios.post(`/companies/${code}/authenticate`);
}
function* handleAuthenticate(code) {
  try {
    const response = yield call(authenticate, { code });
    if (response.data.token) {
      encryptAndSaveToLocalStorage('app-session', response.data.token);
      if (response.data.refreshToken)
        encryptAndSaveToLocalStorage('auth-recall', response.data.refreshToken);

      const {
        data: { user },
      } = response;

      const { company } = user;
      const currentTime = new Date();
      const setTimeExp = currentTime.setTime(currentTime.getTime() + 58 * 60 * 1000);
      encryptAndSaveToLocalStorage('exp-app-session', setTimeExp);

      yield put({
        type: RESET_STATE,
      });

      yield put({
        type: AUTHENTICATE_COMPANY_SUCCESS,
        data: response.data,
      });

      if (company?.code) {
        yield put({
          type: GET_ROLES_REQUEST,
        });

        yield put({
          type: GET_BENEFICIARIES_BANK_REQUEST,
        });

        yield put({
          type: GET_COMPANY_REQUEST,
          payload: company?.code,
        });

        yield put({
          type: SWITCH_COMPANY_SUCCESS,
          data: response.data,
        });
      }

      yield delay(100);
      yield put({
        type: OTP_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: AUTHENTICATE_COMPANY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getPaymentLink({ code, payload }) {
  return await Axios.post(`/companies/${code}/payment-links`, payload);
}
function* handleGetPaymentLink({ payload }) {
  try {
    const response = yield call(getPaymentLink, payload);
    if (response) {
      yield put({
        type: GET_MONO_PAYMENT_LINK_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_MONO_PAYMENT_LINK_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function verifyPaymentReference({ code, reference }) {
  return await Axios.get(`/companies/${code}/payment-links/${reference}`);
}
function* handleVerifyPaymentReference({ payload }) {
  try {
    const response = yield call(verifyPaymentReference, payload);
    if (response) {
      yield put({
        type: VERIFY_MONO_PAYMENT_REFERENCE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: VERIFY_MONO_PAYMENT_REFERENCE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getAccountReauthorizationCode({ code, authorizationCode }) {
  return await Axios.get(`/companies/${code}/bank-reauthorizations/${authorizationCode}`);
}
function* handleGetAccountReauthorizationCode({ payload }) {
  try {
    const response = yield call(getAccountReauthorizationCode, payload);
    if (response) {
      yield put({
        type: MONO_ACCOUNT_REAUTHORIZATION_CODE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: MONO_ACCOUNT_REAUTHORIZATION_CODE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function fundDollarBalance(payload) {
  return await Axios.post(`/balances/fund-usd`, payload);
}
function* handleFundDollarBalance({ payload }) {
  try {
    const response = yield call(fundDollarBalance, payload);
    if (response) {
      yield put({
        type: FUND_DOLLAR_BALANCE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FUND_DOLLAR_BALANCE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getDollarAccount(payload) {
  return await Axios.post(`/balances/virtual-usd-account`, payload);
}
function* handleGetDollarAccount({ payload }) {
  try {
    const response = yield call(getDollarAccount, payload);
    if (response) {
      yield put({
        type: GET_DOLLAR_ACCOUNT_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_DOLLAR_ACCOUNT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateCompany(payload) {
  const { code, ...data } = payload;
  return await Axios.put(`/companies/${code}`, data);
}
function* handleUpdateCompany({ payload }) {
  try {
    const { toastMessage, ...data } = payload;
    const response = yield call(updateCompany, data);
    if (response) {
      yield put({
        type: UPDATE_COMPANY_SUCCESS,
        data: response.data,
      });
      if (toastMessage) toastSuccess(toastMessage);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_COMPANY,
        blockType: 'updateCompany',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_COMPANY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function uploadOnboardingDocuments(payload) {
  const { code, ...data } = payload;
  return await Axios.post(`/companies/${code}/upload-docs`, data);
}
function* handleUploadOnboardingDocuments({ payload }) {
  try {
    const { toastMessage, ...data } = payload;
    const response = yield call(uploadOnboardingDocuments, data);
    if (response) {
      yield put({
        type: UPLOAD_ONBOARDING_DOCUMENTS_SUCCESS,
        data: response.data,
      });

      if (toastMessage) toastSuccess(toastMessage);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_COMPANY,
        blockType: 'uploadOnboardingDocuments',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPLOAD_ONBOARDING_DOCUMENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

// TODO @hitesh remove everything related to /companies/review this endpoint shouldn't be on the dashboard
async function reviewCompany() {
  return await Axios.post(`companies/review`);
}
function* handleFreezeUnfreezeCard({ payload }) {
  try {
    const response = yield call(reviewCompany, payload);
    if (response) {
      yield put({
        type: REVIEW_COMPANY_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: REVIEW_COMPANY_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

// TODO @hitesh remove everything related to /companies/review this endpoint shouldn't be on the dashboard
async function fundCreateCall(payload) {
  const { code } = { ...payload };
  delete payload['code'];
  return await Axios.post(`/companies/${code}/fund`, payload);
}
function* handleFundCreateAPI({ payload }) {
  try {
    const response = yield call(fundCreateCall, payload);
    if (response) {
      yield put({
        type: FUND_WIDTH_CARDS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: FUND_WIDTH_CARDS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getRejectedDocumentDetails() {
  return await Axios.get(`/onboarding/status`);
}

function* handleGetRejectedDocumentDetails({ payload }) {
  try {
    const response = yield call(getRejectedDocumentDetails, payload);
    if (response) {
      yield put({
        type: GET_REJECTED_DOCUMENTS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_REJECTED_DOCUMENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function reSubmitRejectedDocumentDetails(payload) {
  return await Axios.post('/onboarding/resubmission', payload);
}
function* handleReSubmitRejectedDocument({ payload }) {
  try {
    const response = yield call(reSubmitRejectedDocumentDetails, payload);
    if (response) {
      yield put({
        type: RE_SUBMIT_REJECTED_DOCUMENTS_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: RE_SUBMIT_REJECTED_DOCUMENTS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function reSubmitRejectedIsComplete() {
  return await Axios.post('/onboarding/complete');
}
function* handleReSubmitRejectedIsComplete() {
  try {
    const response = yield call(reSubmitRejectedIsComplete);
    if (response) {
      yield put({
        type: RE_SUBMIT_REJECTED_DOCUMENTS_IS_COMPLETE_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: RE_SUBMIT_REJECTED_DOCUMENTS_IS_COMPLETE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function inviteDirector(payload) {
  const { code, ...data } = payload;
  return await Axios.post(`/companies/${code}/directors/invite`, data);
}
function* handleInviteDirector({ payload }) {
  try {
    const response = yield call(inviteDirector, payload);
    if (response) {
      yield put({
        type: INVITE_DIRECTOR_SUCCESS,
        data: response.data,
      });

      yield delay(300);
      yield put({
        type: RESET_BLOCK_COMPANY,
        blockType: 'inviteDirector',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: INVITE_DIRECTOR_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function uploadDirectorInfo(payload) {
  const { code, ...data } = payload;
  return await Axios.post(`/companies/${code}/directors`, data);
}
function* handleUploadDirectorInfo({ payload }) {
  try {
    const response = yield call(uploadDirectorInfo, payload);
    if (response) {
      yield put({
        type: UPLOAD_DIRECTOR_INFO_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_COMPANY,
        blockType: 'uploadDirectorInfo',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPLOAD_DIRECTOR_INFO_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function uploadInvitedDirectorInfo(payload) {
  const { code, ...data } = payload;
  return await Axios.post(`/public/directors/${code}`, data);
}
function* handleUploadInvitedDirectorInfo({ payload }) {
  try {
    const response = yield call(uploadInvitedDirectorInfo, payload);
    if (response) {
      yield put({
        type: UPLOAD_INVITED_DIRECTOR_INFO_SUCCESS,
        data: response.data,
      });
      // toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_COMPANY,
        blockType: 'uploadInvitedDirectorInfo',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPLOAD_INVITED_DIRECTOR_INFO_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function resendOnboardingInvite(payload) {
  return await Axios.post('/onboarding/invites/resend', payload);
}
function* handleResendOnboardingInvite({ payload }) {
  try {
    const response = yield call(resendOnboardingInvite, payload);
    if (response) {
      yield put({
        type: RESEND_ONBOARDING_INVITE_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_COMPANY,
        blockType: 'resendOnboardingInvite',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: RESEND_ONBOARDING_INVITE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getInvitedDirectors(code) {
  return await Axios.get(`/companies/${code}/directors`);
}
function* handleGetInvitedDirectors({ payload }) {
  try {
    const response = yield call(getInvitedDirectors, payload);
    if (response) {
      yield put({
        type: GET_INVITED_DIRECTORS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_INVITED_DIRECTORS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getSingleInvitedDirector(code) {
  return await Axios.get(`/public/directors/${code}`);
}
function* handleGetSingleInvitedDirector({ payload }) {
  try {
    const response = yield call(getSingleInvitedDirector, payload);
    if (response) {
      yield put({
        type: GET_SINGLE_INVITED_DIRECTOR_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_SINGLE_INVITED_DIRECTOR_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function deleteInvitedDirector(code) {
  return await Axios.delete(`/onboarding/invites/${code}`);
}
function* handleDeleteInvitedDirector({ payload }) {
  try {
    const response = yield call(deleteInvitedDirector, payload);
    if (response) {
      yield put({
        type: DELETE_INVITED_DIRECTOR_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_COMPANY,
        blockType: 'deleteInvitedDirector',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: DELETE_INVITED_DIRECTOR_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function updateInvitedDirector(payload) {
  return await Axios.patch(`/onboarding/invites/${payload.code}`, payload);
}
function* handleUpdateInvitedDirector({ payload }) {
  try {
    const response = yield call(updateInvitedDirector, payload);
    if (response) {
      yield put({
        type: UPDATE_INVITED_DIRECTOR_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_COMPANY,
        blockType: 'updateInvitedDirector',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: UPDATE_INVITED_DIRECTOR_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function addFundsToAccount(payload) {
  return await Axios.patch(`/balances/${payload.destination}/add-funds`, payload);
}
function* handleAddFundsToAccount({ payload }) {
  try {
    const response = yield call(addFundsToAccount, payload);
    if (response) {
      yield put({
        type: ADD_FUNDS_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: ADD_FUNDS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function syncBankAccountData(payload) {
  return await Axios.post(`/banks/${payload.code}/data-sync`);
}
function* handleSyncBankAccountData({ payload }) {
  try {
    const response = yield call(syncBankAccountData, payload);
    if (response) {
      yield put({
        type: SYNC_BANK_ACCOUNT_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      let errorMessages = getSimplifiedError(error);
      errorMessages = Array.isArray(errorMessages) ? errorMessages : [errorMessages];
      if (
        errorMessages.some((message) =>
          ['Reauth required', 'Connected account not found'].includes(message),
        )
      ) {
        yield put(
          openMonoConnectAction({
            open: true,
            reauthenticationCode: error?.response?.data?.data?.reconnectId,
          }),
        );
      } else toastError(errorMessages);
    }
    yield put({
      type: SYNC_BANK_ACCOUNT_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function createMandate(payload) {
  return await Axios.post(`/banks/mandates/create`, payload);
}
function* handleCreateMandate({ payload }) {
  try {
    const response = yield call(createMandate, payload);
    if (response) {
      yield put({
        type: CREATE_MANDATE_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CREATE_MANDATE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function cancelMandate(payload) {
  const { code, ...data } = payload;
  return await Axios.post(`/banks/${code}/cancel`, data);
}
function* handleCancelMandate({ payload }) {
  try {
    const response = yield call(cancelMandate, payload);
    if (response) {
      yield put({
        type: CANCEL_MANDATE_SUCCESS,
        data: response.data,
      });
      toastSuccess(response.message);

      yield delay(300);
      yield put({
        type: RESET_BLOCK_COMPANY,
        blockType: 'cancelMandate',
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: CANCEL_MANDATE_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

async function getMandateStatus(payload) {
  return await Axios.get(`/banks/mandates/${payload}/status`);
}
function* handleGetMandateStatus({ payload }) {
  try {
    const response = yield call(getMandateStatus, payload);
    if (response) {
      yield put({
        type: GET_MANDATE_STATUS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error) {
      toastError(getSimplifiedError(error));
    }
    yield put({
      type: GET_MANDATE_STATUS_ERROR,
      error: getSimplifiedError(error),
    });
  }
}

export default all([
  takeLatest(GET_COMPANY_REQUEST, handleGetCompany),
  takeLatest(GET_COMPANY_PREFERENCE_REQUEST, handleGetCompanyPreference),
  takeLatest(UPDATE_COMPANY_PREFERENCE_REQUEST, handleUpdateCompanyPreference),
  takeLatest(UPLOAD_ONBOARDING_DOCUMENTS_REQUEST, handleUploadOnboardingDocuments),
  takeLatest(GET_COMPANIES_REQUEST, handleGetCompanies),
  takeLatest(GET_COUNTRY_REQUEST, handleGetCountries),
  takeLatest(GET_STATE_REQUEST, handleGeStates),
  takeLatest(
    ONBOARDING_BUSINESS_INFORMATION_REQUEST,
    handleOnboardingBusinessInformation,
  ),
  takeLatest(ONBOARDING_BUSINESS_ADDRESS_REQUEST, handleOnboardingBusinessAdress),
  takeLatest(ONBOARDING_BUSINESS_DOC_REQUEST, handleOnboardingBusinessDoc),
  takeLatest(GET_COMPANY_STATS_REQUEST, handleStats),
  takeLatest(GET_BANK_ACCOUNT_REQUEST, handleGetBankAccount),
  takeLatest(CREATE_BANK_ACCOUNT_REQUEST, handleCreateBankAccount),
  takeLatest(UPDATE_COMPANY_REQUEST, handleUpdateCompany),
  takeLatest(REVIEW_COMPANY_REQUEST, handleFreezeUnfreezeCard),
  takeLatest(FUND_DOLLAR_BALANCE_REQUEST, handleFundDollarBalance),
  takeLatest(FUND_WIDTH_CARDS_REQUEST, handleFundCreateAPI),
  takeLatest(FUND_WIDTH_CARDS_REQUEST, handleFundCreateAPI),
  takeLatest(GET_DOLLAR_ACCOUNT_REQUEST, handleGetDollarAccount),
  takeLatest(GET_REJECTED_DOCUMENTS_REQUEST, handleGetRejectedDocumentDetails),
  takeLatest(RE_SUBMIT_REJECTED_DOCUMENTS_REQUEST, handleReSubmitRejectedDocument),
  takeLatest(
    RE_SUBMIT_REJECTED_DOCUMENTS_IS_COMPLETE_REQUEST,
    handleReSubmitRejectedIsComplete,
  ),
  takeLatest(INVITE_DIRECTOR_REQUEST, handleInviteDirector),
  takeLatest(GET_INVITED_DIRECTORS_REQUEST, handleGetInvitedDirectors),
  takeLatest(UPLOAD_DIRECTOR_INFO_REQUEST, handleUploadDirectorInfo),
  takeLatest(UPLOAD_INVITED_DIRECTOR_INFO_REQUEST, handleUploadInvitedDirectorInfo),
  takeLatest(GET_SINGLE_INVITED_DIRECTOR_REQUEST, handleGetSingleInvitedDirector),
  takeLatest(RESEND_ONBOARDING_INVITE_REQUEST, handleResendOnboardingInvite),
  takeLatest(DELETE_INVITED_DIRECTOR_REQUEST, handleDeleteInvitedDirector),
  takeLatest(UPDATE_INVITED_DIRECTOR_REQUEST, handleUpdateInvitedDirector),
  takeLatest(SEND_MONO_AUTH_CODE_REQUEST, handleSendMonoAuthCode),
  takeLatest(GET_MONO_PAYMENT_LINK_REQUEST, handleGetPaymentLink),
  takeLatest(VERIFY_MONO_PAYMENT_REFERENCE_REQUEST, handleVerifyPaymentReference),
  takeLatest(
    MONO_ACCOUNT_REAUTHORIZATION_CODE_REQUEST,
    handleGetAccountReauthorizationCode,
  ),
  takeLatest(GET_COMPANY_ASSET_REQUEST, handleGetAsset),
  takeLatest(ADD_FUNDS_REQUEST, handleAddFundsToAccount),
  takeLatest(CREATE_MANDATE_REQUEST, handleCreateMandate),
  takeLatest(SYNC_BANK_ACCOUNT_REQUEST, handleSyncBankAccountData),
  takeLatest(ADD_COMPANY_REQUEST, handleaddEntity),
  takeLatest(SWITCH_COMPANY_REQUEST, handleSwitchCompany),
  takeLatest(GET_MANDATE_STATUS_REQUEST, handleGetMandateStatus),
  takeLatest(CANCEL_MANDATE_REQUEST, handleCancelMandate),
]);
