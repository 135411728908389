import { CloseOutlined, EyeOutlined } from '@ant-design/icons';
import { Progress } from 'antd';
import { ArrowTop, CreditCardLock, CreditCardPlus } from 'assets/icons';
import classNames from 'classnames';
import CardView from 'components/CardView';
import ConfirmDialog from 'components/ConfirmDialog';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import PanInput from 'components/UI/CustomInput/PanInput';
import Modal from 'components/UI/Modal';
import { toastError } from 'components/UI/toast';
import { useEffect, useState } from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import { reviewRequest } from 'redux/actions/ApprovalAction';
import {
  activatePhysicalCard,
  getMyCardsAction,
  getRequestedCard,
  getRequestedCards,
} from 'redux/actions/CardAction';
import { RESET_BLOCK_CARD } from 'redux/reducers/CardReducer';
import { getCurrency } from 'utils/helper';
import { SkeletalLoader } from '../CardsTableModal';
import ViewDefualtPin from './PhysicalCardSection/ViewDefualtPin';
import './styles.scss';
import DeclineRequest from './DeclineRequest';
import ApprovalModal from 'pages/Approvals/components/ApprovalModal';
import Timeline from 'components/Timeline';
import { approvalState } from 'components/FundRequest/ReviewerModal/approvalState';

const PhysicalCardDetails = ({
  handleClose,
  step,
  selectCards,
  setSelectCards,
  setStep,
}) => {
  if (!selectCards) return <div />;

  const [cardDetails, setCardDetails] = useState({});
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const [approve, setIsApproving] = useState(false);
  const [approvalsteps, setApprovalSteps] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ruleCode, setRuleCode] = useState(null);

  const closeHandler = () => {
    if (approve) return;
    dispatch({ type: RESET_BLOCK_CARD, blockType: 'activatePhysicalCard' });
    setSelectCards(null);
  };

  const payerCode = query.get('payer');

  const { user: { data: userData = {} } = {} } = useSelector(({ auth }) => auth);

  const onDone = () => {
    dispatch({ type: RESET_BLOCK_CARD, blockType: 'activatePhysicalCard' });
    handleClose();
    dispatch(getMyCardsAction({ owner: userData?.user?.code, payer: payerCode }));
    dispatch(getRequestedCards());
    setIsLoaded(false);
  };

  const handleChange = ({ target: { name, value, rawValue } }) => {
    return setCardDetails({ ...cardDetails, [name]: rawValue ? rawValue : value });
  };

  const {
    activatePhysicalCard: {
      loading: loadingActivate,
      success: successActivate,
      data: dataActivate,
    },
    getRequestedCard: { loading: loadingRequested, data },
  } = useSelector(({ card }) => card);

  const {
    reviewRequest: { loading: loadingReview, success: successReview },
  } = useSelector(({ approval }) => approval);

  useEffect(() => {
    if (successActivate) {
      setStep(2);
      dispatch(getRequestedCards());
    }
  }, [successActivate]);

  useEffect(() => {
    dispatch(getRequestedCard(selectCards.code));
  }, []);

  const showBvn = selectCards?.cardOwner?.hasCardHolder;

  const handleActivateCard = () => {
    if (!cardDetails?.cardPan) return toastError('Please enter card pan');
    if (!cardDetails?.bvn && !showBvn) return toastError('Please enter card holder bvn');
    dispatch(
      activatePhysicalCard({
        cardPan: cardDetails?.cardPan,
        cardRequest: selectCards?.code,
        bvn: cardDetails?.bvn,
      }),
    );
  };

  const { yourTurnToApprove, code, approvers } = approvalState({
    data,
    user: userData,
  });

  const cardStatus = data?.status?.toLowerCase();

  const actionButtons = [
    {
      text: 'Reveal',
      icon: <EyeOutlined />,
      action: () => null,
    },

    {
      text: 'Top up',
      icon: <CreditCardPlus stroke="#57534E" />,
      action: () => null,
    },

    {
      text: 'Block',
      icon: <CreditCardLock stroke="#57534E" />,
      action: () => null,
    },
  ];

  const cardMockedData = {
    brand: 'verve',
    user: { firstName: data?.firstName, lastName: data?.lastName },
    cvv: '•••',
    pan: '••••••••',
    status: data?.status,
    exp_month: '••',
    exp_year: '••••',
    type: 'physical',
  };

  const handleApproveRequest = () => {
    dispatch(
      reviewRequest({
        code,
        status: 'approved',
      }),
    );
  };

  useEffect(() => {
    if (successReview) {
      setIsApproving(false);
      setIsLoaded(true);
      if (approvalsteps !== 2) {
        dispatch(getRequestedCards());
        dispatch(getRequestedCard(selectCards.code));
      }
    }
  }, [successReview]);

  const openRule = (code) => {
    setRuleCode(code);
  };

  const closeApprovalRule = () => setRuleCode(null);

  if (ruleCode)
    return (
      <ApprovalModal
        selectedRuleCode={ruleCode}
        isOpen={!!ruleCode}
        isEdit
        handleClose={closeApprovalRule}
        clearSelectedRule={closeApprovalRule}
      />
    );

  const claimCard = (
    <div className="content">
      <div className="card-modal-body">
        <section className="card__typeSelector">
          <div className="information-wrapper">
            <h2 className="card-title w-100 border-hidden p-0 pt-4">
              Claim physical card
            </h2>
            <p className="text-sm">
              Claim your card by manually entering the information from the back of your
              physical card in the fields provided below.
            </p>
            {/* </div> */}
            <div className="my-4">
              <PanInput
                placeholder="Card number"
                label={'Card number'}
                onChange={handleChange}
                name="cardPan"
              />
              {!showBvn && (
                <div className="mt-2">
                  <CustomInput
                    label="BVN"
                    placeholder="Enter number"
                    type="text"
                    name="bvn"
                    onChange={handleChange}
                    value={cardDetails?.bvn}
                    maxLength="11"
                  />
                </div>
              )}
            </div>

            <div className="modal-footer mt-3">
              <CustomButton
                onClick={closeHandler}
                disabled={loadingActivate}
                fullWidth={true}
                className="custom-button ghost-button"
              >
                Cancel
              </CustomButton>
              <CustomButton
                fullWidth={true}
                className="custom-button primary-button"
                loading={loadingActivate}
                disabled={loadingActivate}
                onClick={handleActivateCard}
              >
                Confirm
              </CustomButton>
            </div>
          </div>
        </section>
      </div>
    </div>
  );

  const renderCard = () => {
    switch (step) {
      case 1:
        return claimCard;
      case 2:
        return <ViewDefualtPin dataActivate={dataActivate} onDone={onDone} />;

      default:
    }
  };

  const SelectedCardDetail = (
    <div className="content px-md-0 py-md-0 px-3 py-4 beneficiaries">
      <>
        <div
          className={classNames('information-wrapper', {
            ['disabled-owners-input']: cardStatus === 'pending',
          })}
        >
          <div className="name-holder w-100">
            <h2 className="pt-1">{data?.card?.name}</h2>
          </div>

          <div className="card-region-holder">
            <div className="card-holder">
              <CardView
                viewType="view"
                card={cardMockedData}
                cardType="physical"
                requirePin={false}
              />

              <div className="budget-region mt-3">
                <span className="text">Available to spend</span>
                <span style={{ color: '#D28B28' }}>
                  <CurrencyFormat
                    prefix={getCurrency('NGN')}
                    value={0}
                    displayType="text"
                    thousandSeparator={true}
                    isNumericString
                  />
                </span>
              </div>

              <Progress
                percent={0}
                showInfo={false}
                strokeColor="#D28B28"
                trailColor="#E7E5E4"
              />

              <div className="budget-region">
                <span className="text">Active budget</span>
                <a
                  href={
                    data?.budget?.status !== 'deleted' &&
                    `/expenses/budgets/${data?.budget?.code}/overview`
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="d-flex align-items-center gap-1"
                  style={{ color: '#1C1917', cursor: 'pointer' }}
                >
                  {data?.budget?.name} <ArrowTop />
                </a>
              </div>
            </div>

            <div className="mt-4">
              {/* Number Here */}
              <div className="pan-details">
                <span className="caption">Card number</span>
                <span className={`value`}>••••••••</span>
              </div>

              {/* Expiry Date Here */}
              <div className="pan-details">
                <span className="caption">Expiry date</span>
                <span className="value">••••••••</span>
              </div>

              {/* CVC Here */}
              <div className="pan-details">
                <span className="caption">CVV</span>

                <span className={`value`}>••••••••</span>
              </div>

              {/* Pin Here */}
              <div className="pan-details">
                <span className="caption">PIN</span>

                <span className={`value`}>••••••••</span>
              </div>

              <div className="action-buttons mt-4 pt-1">
                {actionButtons.map(({ icon, text, action }) => (
                  <button
                    key={text}
                    className="details-btn tab-hover-effect"
                    onClick={action}
                    disabled
                  >
                    {icon}
                    {text}
                  </button>
                ))}
              </div>
            </div>

            {/* Recent Transactions */}

            <div className="mt-4 transactions-holder">
              <div className="px-3">
                <Timeline
                  data={approvers}
                  multipleRule={data?.relatedApprovalRequests.length > 1}
                  goToRule={openRule}
                  code={code}
                  pageFrom={`/requests/funds`}
                />
              </div>
            </div>
          </div>
        </div>

        {yourTurnToApprove && (
          <div className="modal-footer mt-3">
            <CustomButton
              fullWidth={true}
              onClick={() => setApprovalSteps(2)}
              className="custom-button ghost-button text-nowrap"
            >
              Decline
            </CustomButton>
            <CustomButton
              fullWidth={true}
              onClick={() => setIsApproving(true)}
              className="custom-button primary-button"
            >
              Approve
            </CustomButton>
          </div>
        )}
      </>

      {approve && (
        <BootstrapModal show={true} centered dialogClassName="custom-dialog">
          <ConfirmDialog
            title="Approve request"
            subTitle="Are you sure you want to approve this request"
            onConfirm={handleApproveRequest}
            loading={loadingReview}
            onCancel={() => setIsApproving(false)}
            isDeleteDialog={false}
          />
        </BootstrapModal>
      )}
    </div>
  );

  const renderApprovalCards = (steps) => {
    switch (steps) {
      case 1:
        return SelectedCardDetail;
      case 2:
        return (
          <DeclineRequest
            setStep={setApprovalSteps}
            step={approvalsteps}
            approvalCode={code}
            isLoaded={isLoaded}
            onClose={onDone}
          />
        );

      default:
    }
  };
  return (
    <>
      <Modal show={selectCards} onClose={closeHandler}>
        {loadingRequested ? (
          <SkeletalLoader />
        ) : (
          <>
            <div className="card-modal-header px-md-0 py-md-0 px-3 py-4">
              {approvalsteps !== 2 && (
                <div className="d-flex align-items-center cursor" onClick={closeHandler}>
                  <CloseOutlined />
                  <span className="ps-1">Close</span>
                </div>
              )}
            </div>
            <div className="card-modal-body">
              {['pending', 'declined'].includes(cardStatus)
                ? renderApprovalCards(approvalsteps)
                : renderCard()}
            </div>
          </>
        )}
      </Modal>
    </>
  );
};
export default PhysicalCardDetails;
///{renderCard()}

// const Loading
