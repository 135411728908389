import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { Checkbox } from 'antd';
import { BankIcon, ChevronDown, WalletIcon, WalletIcon3 } from 'assets/icons';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import numeral from 'numeral';
import { useDispatch, useSelector } from 'react-redux';
import { getBalances } from 'redux/actions/BudgetsAction';
import { getCountries, getState } from 'redux/actions/CompaniesAction';
import {
  addOneYear,
  calculateProratedCost,
  getAvailableBalance,
  getCurrentPlanType,
  groupSourceOptions,
  proratedCosts,
} from 'utils/helper';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useBillingPeriod } from './useBillingPeriod';
import { freeSeats, seatPriceList } from '../OrderSummary';

const options = [
  {
    id: 1,
    name: 'My Bujeti Balance',
    icons: WalletIcon,
    type: 'balance',
  },
  {
    id: 2,
    name: 'Bank Account',
    icons: BankIcon,
    type: 'directdebit',
  },
  // {
  //   id: 3,
  //   name: 'Credit or Debit Card',
  //   icons: [VisaIcon, MastercardIcon, AmexIcon, DinnersClubIcon, DiscoverIcon],
  //   type: 'card',
  // },
];

const BillingAddress = ({
  getSelectedOption,
  info,
  billing,
  setBilling,
  isBuySeat,
  seats,
}) => {
  const [active, setActive] = useState('balance');

  const dispatch = useDispatch();
  const location = useLocation();

  const {
    getCountry: { data: countryData = {} },
    getState: { data: states = {}, success: successState, loading: loadingState },
    getCompany: { data: companyInfo },
  } = useSelector(({ companies }) => companies);

  const {
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);

  const {
    viewPlan: { data: viewPlanData },
  } = useSelector(({ billing }) => billing);

  const {
    getBeneficiaries: { data: beneficiary = {} },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const { meta } = beneficiary;

  const { user } = useSelector(({ auth }) => auth);
  const { data: userData = {} } = user;

  const plan = location.state?.plan;

  useEffect(() => {
    if (!countryData?.length) dispatch(getCountries());
  }, []);

  const generateCountry = useMemo(() => {
    if (countryData?.length > 0) {
      return countryData?.map(({ code: value, name: label }) => ({
        value,
        label,
        isDisabled: label !== 'NIGERIA' ? true : false,
      }));
    }
  }, [countryData]);

  const generateState = useMemo(() => {
    return states.states?.map((value) => ({
      label: value,
      value: value,
    }));
  }, [successState]);

  const getCountryStates = async (val) => {
    dispatch(getState(val.value));
  };

  // dispatch state is value change
  useEffect(() => {
    if (billing?.country?.value && countryData.length > 0) {
      const country = countryData?.find((item) =>
        item.alpha.includes(billing?.country?.value),
      );

      if (country?.code) dispatch(getState(country?.code));
    }
  }, [countryData, billing]);

  // dispatch state if data found
  useEffect(() => {
    if (info?.address?.countryIso && countryData.length > 0) {
      const country = countryData?.find((item) =>
        item.alpha.includes(info?.address?.countryIso),
      );
      if (country?.code) dispatch(getState(country?.code));
    }
  }, [countryData]);

  const onHandleClick = (type) => {
    if (type === active) return;
    setActive(type);
    getSelectedOption(type);
    setBilling({
      ...billing,
      balance: '',
      method: type,
    });
  };

  const handleChange = ({ name, value }) => {
    setBilling({
      ...billing,
      [name]: value,
    });
  };

  useEffect(() => {
    if (info) {
      const { firstName, lastName, email, company } = info;
      setBilling({
        ...billing,
        firstName,
        email,
        lastName,
        companyName: company?.name,
        country: companyInfo?.address?.country
          ? {
              value: companyInfo?.address?.countryIso,
              label: companyInfo?.address?.country,
            }
          : '',
        address: companyInfo?.address?.street || '',
        state: companyInfo?.address?.state
          ? {
              value: companyInfo?.address?.state,
              label: companyInfo?.address?.state,
            }
          : '',
        city: companyInfo?.address?.city || '',
      });
    }
  }, [companyInfo]);

  useEffect(() => {
    dispatch(getBalances());
  }, []);

  const [loadPage, setLoadPage] = useState(0);

  useEffect(() => {
    if (loadPage > 1) dispatch(getBalances({ page: loadPage }));
  }, [loadPage]);

  const [hasMore, setHasMore] = useState(false);
  const [budgetList, setBudgetList] = useState([]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets);
      const available_balance = getAvailableBalance(balances?.balances);
      setBudgetList((prevOptions) => [...budget, ...available_balance]);
    }
  }, [balances?.budgets]);

  async function loadOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  async function loadAcctOptions(search, loadedOptions, { page }) {
    setLoadPage(page);

    let filteredOption = budgetList.filter(
      (item) =>
        item?.name?.toLowerCase().includes(search?.toLowerCase()) &&
        item?.value?.startsWith('bnk_'),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  //

  let filteredBank = budgetList.filter((item) => item?.value?.startsWith('bnk_'));

  const getUserPlan = userData?.user?.company?.paymentPlan;

  const totalBeneficiary = meta?.total - freeSeats[plan?.name];

  const totalBeneficiaryFee =
    meta?.total - freeSeats[getUserPlan?.name === 'pro' ? getUserPlan?.name : plan?.name];

  const additionaUserCost =
    getUserPlan?.configuration?.userManagement?.additionalUsers?.NGN;

  const planPayment =
    billing?.billingPeriod?.value === 'annually'
      ? (isBuySeat ? plan?.amount : viewPlanData?.amount) * (1 - 0.1) * 12
      : isBuySeat
      ? plan?.amount
      : viewPlanData?.amount;

  const oldPlanType = getCurrentPlanType(getUserPlan?.expiryDate, getUserPlan?.paidOn);

  const isNewPlan = !['start', 'growth', 'scale', 'custom'].includes(getUserPlan?.name);

  const oldMemberfee = isNewPlan
    ? (+totalBeneficiaryFee + getUserPlan?.additionalSeats) * additionaUserCost
    : 0;

  const seatAmount = proratedCosts(
    getUserPlan?.expiryDate,
    getUserPlan?.paidOn,
    additionaUserCost,
    planPayment,
    oldPlanType,
  );

  const planAmount = proratedCosts(
    getUserPlan?.expiryDate,
    getUserPlan?.paidOn,
    getUserPlan?.amount,
    planPayment,
    billing?.billingPeriod?.value,
  );

  const usersToUpgrade = isNewPlan ? seatPriceList[plan?.name] * totalBeneficiary : 0;

  const previousPlanBalance = isNaN(+planAmount?.balance + oldMemberfee)
    ? 0
    : +planAmount?.balance + oldMemberfee;

  const actualPrice = (
    billing?.billingPeriod?.value === 'annually'
      ? isBuySeat
        ? (seatAmount.balance * seats) / 100
        : +planPayment / 100
      : (isBuySeat ? seatAmount.balance * seats : +planPayment) / 100
  ).toFixed(2);

  const vat = Math.max(
    0,
    ((billing?.billingPeriod?.value === 'annually'
      ? (isBuySeat
          ? +seatAmount.balance * seats
          : Number(+planPayment - previousPlanBalance + usersToUpgrade)) / 100
      : (isBuySeat
          ? +seatAmount.balance * seats
          : Number(+planPayment - previousPlanBalance + usersToUpgrade)) / 100) *
      7.5) /
      100,
  ).toFixed(2);

  const total = Math.max(
    0,
    Number(+actualPrice - previousPlanBalance / 100) +
      Number(+vat) +
      Number(usersToUpgrade / 100),
  ).toFixed(2);

  return (
    <section className="billing-address px-3">
      <section className="payment-options">
        <div className="order-summary pb-4">
          <h1 className="payment-title">Your payment method</h1>
          <h6 className="subtext body m-0">
            Set the triggers and rules of the approval flow.
          </h6>
        </div>

        <div className="grid grid-cols-2 selections">
          <div
            className={classNames('selections-options', {
              'active-tab': active === 'balance',
            })}
            // onClick={() => onHandleClick(active === 'balance' ? '' : 'balance')}
          >
            <div className="item">
              <div className="d-flex align-items-center gap-2">
                <div className="header-icon md bg-green">
                  <WalletIcon3 />
                </div>
                <div className="bujeti-balance">
                  <h6 className="subtext mb-1">Bujeti Balance</h6>
                  <p>{budgetList?.length} accounts available</p>
                  {/* <h6 className="subtext">₦{numeral(total).format('0,0')}</h6> */}
                </div>
              </div>
              <ChevronDown
                onClick={() => {
                  onHandleClick(active === 'balance' ? '' : 'balance');
                }}
              />
            </div>
            {active === 'balance' && (
              <div className="mt-3">
                <CustomSelectRadio
                  placeholderText="Select a source"
                  name="from"
                  onChange={(val) => handleChange({ name: 'balance', value: val })}
                  value={billing.balance}
                  isLoading={loadingBalances}
                  loadOptions={active === 'balance' ? loadOptions : loadAcctOptions}
                />
              </div>
            )}
          </div>
          <div
            className={classNames('selections-options', {
              'active-tab': active === 'account',
            })}
            // onClick={() => onHandleClick(active === 'account' ? '' : 'account')}
          >
            <div className="item">
              <div className="d-flex align-items-center gap-2">
                <div className="header-icon md bg-white">
                  <BankIcon height="20" width="20" stroke="#7F7F7D" />
                </div>
                <div className="bujeti-balance">
                  <h6 className="subtext mb-1">Bank account</h6>
                  <p>{filteredBank?.length} accounts available</p>
                </div>
              </div>
              <ChevronDown
                onClick={() => {
                  onHandleClick(active === 'account' ? '' : 'account');
                }}
              />
            </div>

            {active === 'account' && (
              <div className="mt-3">
                <CustomSelectRadio
                  placeholderText="Select a source"
                  name="from"
                  onChange={(val) => handleChange({ name: 'balance', value: val })}
                  value={billing.balance}
                  isLoading={loadingBalances}
                  loadOptions={active === 'balance' ? loadOptions : loadAcctOptions}
                />
              </div>
            )}
          </div>
        </div>

        {/* {options.map((option) => {
          const Icon = option.icons;
          return (
            <div
              key={option.id}
              className={`payment-item ${option.type == active && 'active'} d-flex gap-3`}
              onClick={() => onHandleClick(option.type)}
            >
              <div
                className={`radio ${option.type === active ? 'checked' : 'unchecked'}`}
              >
                {active && (
                  <svg
                    width="10"
                    height="7"
                    viewBox="0 0 10 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.33317 1.5L3.74984 6.08333L1.6665 4"
                      stroke="white"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </div>
              <div className="w-100">
                <h6>{option.name}</h6>
                <div className="d-flex gap-2">
                  <span className="icon-wrapper">
                    <Icon />
                  </span>
                </div>
                {option.type == active && (
                  <div className="row mt-2">
                    <div className="col-lg-6">
                      <CustomSelectRadio
                        label="From"
                        placeholderText="Select a source"
                        name="from"
                        onChange={(val) => handleChange({ name: 'balance', value: val })}
                        value={billing.balance}
                        isLoading={loadingBalances}
                        loadOptions={
                          option.type === 'balance' ? loadOptions : loadAcctOptions
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })} */}
      </section>
      <section className="mt-4">
        <h1 className="payment-title pb-3">Billing address</h1>
        <section>
          <div className="row">
            <div className="col-sm-6 col-lg-6">
              <CustomInput
                label="First name"
                value={billing?.firstName}
                onChange={(value) =>
                  handleChange({ name: 'firstName', value: value.target.value })
                }
              />
            </div>
            <div className="col-sm-6 col-lg-6">
              <CustomInput
                label="Last name"
                value={billing?.lastName}
                onChange={(value) =>
                  handleChange({ name: 'lastName', value: value.target.value })
                }
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-sm-6 col-lg-6">
              <CustomInput
                label="Contact email"
                value={billing?.email}
                onChange={(value) =>
                  handleChange({ name: 'email', value: value.target.value })
                }
              />
            </div>
            <div className="col-sm-6 col-lg-6">
              <CustomInput
                label="Company name (optional)"
                value={billing?.companyName}
                onChange={(value) =>
                  handleChange({ name: 'companyName', value: value.target.value })
                }
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-sm-6 col-lg-6">
              <CustomSelect
                placeholder="Select a country"
                type="text"
                name="country"
                value={billing.country}
                label="Country *"
                options={generateCountry}
                onChange={(value) => {
                  handleChange({ name: 'country', value: value });
                  getCountryStates(value);
                }}
              />
            </div>
            <div className="col-sm-6 col-lg-6">
              <CustomInput
                label="Address"
                value={billing?.address}
                onChange={(value) =>
                  handleChange({ name: 'address', value: value.target.value })
                }
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-sm-6 col-lg-6">
              <CustomSelect
                placeholder="Select a state"
                type="text"
                name="state"
                value={billing.state}
                label="State *"
                isDisabled={loadingState}
                options={generateState}
                onChange={(value) => handleChange({ name: 'state', value: value })}
              />
            </div>
            <div className="col-sm-6 col-lg-6">
              <CustomInput
                label="City"
                onChange={(value) =>
                  handleChange({ name: 'city', value: value.target.value })
                }
                value={billing?.city}
              />
            </div>
          </div>
        </section>
      </section>
      <section className="mt-4">
        <p className="billing-footer-text">
          Your subscription will automatically renew every month using your default
          payment method. After your first{' '}
          {billing?.billingPeriod === 'monthly' ? 'month' : 'year'}, you will be charged ₦
          {numeral(total).format('0,0')}, plus applicable taxes, on each renewal until you
          cancel your subscription. You can cancel at any time on the{' '}
          <Link to="/settings/billings">Billing page </Link>of your account. If you
          cancel, previous charges will not be refunded, but you may continue to use the
          service until the end of the term you paid for.
        </p>
      </section>
    </section>
  );
};

export default BillingAddress;
