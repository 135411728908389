import { useEffect, useState } from 'react';

const useBillingUpgrade = (expiryDate, paidOn, planAmount) => {
  if (!expiryDate || !paidOn) return planAmount ?? undefined;

  const [billingPeriod, setBillingPeriod] = useState(null);
  const [remainingDays, setRemainingDays] = useState(0);
  const [daysInMonth, setDaysInMonth] = useState(0);

  useEffect(() => {
    const expiry = new Date(expiryDate);
    const paid = new Date(paidOn);
    const currentDate = new Date(); // Get the current date
    const diffTime = expiry - paid; // Difference in milliseconds
    const diffDays = diffTime / (1000 * 60 * 60 * 24); // Convert to days

    // Calculate the number of days in the current month
    const currentMonthDays = new Date(
      paid.getFullYear(),
      paid.getMonth() + 1,
      0,
    ).getDate();

    if (diffDays >= 365) {
      setBillingPeriod('annually');
      setRemainingDays(0); // No remaining days if it's already annual
      setDaysInMonth(0);
    } else if (diffDays >= 30) {
      setBillingPeriod('monthly');
      const daysPassed = Math.floor((currentDate - paid) / (1000 * 60 * 60 * 24)); // Calculate days passed since paidOn
      setRemainingDays(currentMonthDays - daysPassed); // Set remaining days for monthly
      setDaysInMonth(currentMonthDays); // Set days in the current month
    } else {
      setBillingPeriod(''); // Set to empty string if less than 30 days
      setRemainingDays(0);
      setDaysInMonth(0);
    }
  }, [expiryDate, paidOn]);

  const calculateAdjustedCost = () => {
    if (billingPeriod === 'monthly' && remainingDays > 0) {
      const dailyCost = planAmount / daysInMonth; // Calculate daily cost based on days in the month
      const costAdjustment = dailyCost * remainingDays; // Calculate the cost adjustment based on remaining days
      const totalCost = planAmount - costAdjustment; // Adjust the total cost
      return totalCost;
    }
    return planAmount; // Return original plan amount if not applicable
  };

  return { billingPeriod, remainingDays, calculateAdjustedCost };
};

export { useBillingUpgrade };
