import { TicketIcon, TrendUpIcon } from 'assets/icons';
import ModalContainerWrap from 'components/UI/Modal/ModalContainerWrap';
import { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { allPermissions } from 'utils/AllowedTo';
import { capitalizeFirstLetter, getCurrency, getCurrentPlanType } from 'utils/helper';
import './style.less';
import useSegmentVisibility from 'hooks/useSegmentVisibility';
import { seatPriceList } from 'pages/Settings/Billings/components/OrderSummary';

const PayAdditionalSeat = ({ toggleHandler, values }) => {
  const [progress, setProgress] = useState(0);
  const { push } = useHistory();

  const { isAdmin } = allPermissions();

  const {
    user: { data = {} },
  } = useSelector(({ auth }) => auth);

  const {
    getBeneficiaries: { data: beneficiary = {} },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const {
    fetchPlans: { data: planData },
  } = useSelector(({ billing }) => billing);

  const { meta } = beneficiary;

  const value = meta?.total;

  const plan = data?.user?.company?.paymentPlan;
  const additionalSeat = data?.user?.company?.paymentPlan.additionalSeats;

  const filteredPlan = planData?.plans?.find((item) =>
    plan?.name === 'basic' ? item?.name === 'pro' : item?.name === 'enterprise',
  );

  const userManagement = data?.user?.company?.paymentPlan?.configuration?.userManagement;

  const maxValue = value + additionalSeat;

  useEffect(() => {
    let start = 0;
    const interval = setInterval(() => {
      if (start <= value) {
        setProgress((start / maxValue) * 125.6); // Calculate the arc length dynamically
        start++;
      } else {
        clearInterval(interval);
      }
    }, 50); // Adjust speed as needed
    return () => clearInterval(interval);
  }, [value, maxValue]);

  const planCode = data?.user?.company?.paymentPlan;

  const url = `/settings/checkout/${planCode?.code}/${planCode?.name}?type=buy_seat`;
  const planUpgrade = `/settings/checkout/${filteredPlan?.code}/${filteredPlan?.name}`;

  const oldPlanType = getCurrentPlanType(planCode?.expiryDate, planCode?.paidOn);

  const handleAdditionalSeat = (type) => {
    if (type === 'upgrade') {
      push({
        state: {
          plan: filteredPlan,
          type: 'annually',
          action: 'upgrade',
        },
        pathname: planUpgrade,
      });
    } else
      push({
        state: {
          plan: {
            ...values,
            seats: values?.seats - additionalSeat,
            currency: 'NGN',
            amount: Number(
              userManagement?.additionalUsers?.NGN * (+values?.seats - additionalSeat),
            ),
          },
          type: oldPlanType,
          action: 'buy_seat',
        },
        pathname: url,
      });
  };

  const visibleSegments = useSegmentVisibility(2);
  return (
    <ModalContainerWrap
      isWhite
      goBack={null}
      modalName="Team Limit Reached"
      onClose={toggleHandler}
      scroll={false}
    >
      <div className="upgrade-subscription-wrapper">
        {visibleSegments >= 1 && (
          <div className="d-flex justify-content-evenly flex-column main-section slide-in-up">
            <div className="info-section px-4">
              <h4>All seats in use</h4>
              <p>
                You’ve reached the maximum number of team members for your current plan.
                To add a new member, you can upgrade your plan or include an additional
                seat for a small fee of{' '}
                <span>
                  <CurrencyFormat
                    prefix={getCurrency('NGN')}
                    value={seatPriceList[plan?.name] / 100}
                    displayType="text"
                    thousandSeparator={true}
                  />
                </span>
                .
              </p>
            </div>
            <div className="seat-number-section">
              <div className="arc-container">
                <svg className="arc" viewBox="0 0 100 100">
                  <defs>
                    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                      <stop
                        offset="0%"
                        style={{ stopColor: '#FF8373', stopOpacity: 1 }}
                      />
                      <stop
                        offset="100%"
                        style={{ stopColor: '#FF0000', stopOpacity: 1 }}
                      />
                    </linearGradient>
                  </defs>
                  {/* Background Arc */}
                  <path className="arc-path" d="M 10 50 A 40 40 0 0 1 90 50" />
                  {/* Foreground Animated Arc */}
                  <path
                    className="arc-progress"
                    d="M 10 50 A 40 40 0 0 1 90 50"
                    style={{
                      stroke: 'url(#gradient)',
                      strokeDashoffset: 125.6 - progress,
                    }}
                  />
                </svg>
                <div className="arc-value">{value}</div>
                <div className="arc-label">
                  {value} of {maxValue} members used
                </div>
              </div>
            </div>
          </div>
        )}

        {isAdmin && visibleSegments >= 2 && (
          <div className="plan-section fade-in-up">
            <div onClick={() => handleAdditionalSeat()}>
              <div className="header-icon lg">
                <TicketIcon />
              </div>

              <h5 className="mt-4 m-0">Additional Seat</h5>
              <p>Expand at your pace</p>
              <h3 className="m-0">₦3,000</h3>
              <p className="m-0">Per seat monthly</p>

              <button
                onClick={() => handleAdditionalSeat()}
                className="border btn w-full mt-4 py-2"
              >
                Buy additional seat
              </button>
            </div>
            <div onClick={() => handleAdditionalSeat('upgrade')}>
              <div className="header-icon lg bg-green">
                <TrendUpIcon />
              </div>
              <h5 className="mt-4 m-0">{capitalizeFirstLetter(filteredPlan?.name)}</h5>
              <p>{filteredPlan?.description}</p>
              <h3 className="m-0">
                <CurrencyFormat
                  prefix={getCurrency('NGN')}
                  value={filteredPlan?.amount / 100}
                  displayType="text"
                  thousandSeparator={true}
                />
              </h3>
              <p className="m-0">Monthly</p>

              <button
                onClick={() => handleAdditionalSeat('upgrade')}
                className="black btn w-full mt-4 py-2 text-nowrap"
              >
                Upgrade to {capitalizeFirstLetter(filteredPlan?.name)}
              </button>
            </div>
          </div>
        )}
      </div>
    </ModalContainerWrap>
  );
};

export default PayAdditionalSeat;
